import React, { FC, ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
import styled from 'styled-components';

import BrandLogo, { Props as BrandLogoProps } from 'client/components/brandLogo';

import '../styles/header.scss';

const BackButtonSpace = styled.div`
  width: 1.5rem;
`;
const mapStateToProps = (state: any) => ({
  siteConfigs: state.siteConfigs,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type NavProps = PropsFromRedux &
  Partial<BrandLogoProps> & {
    leftNav?: ReactNode;
    rightNav?: ReactNode;
    showMobileBackBtn?: boolean;
  };

const Nav = (props: NavProps) => {
  const { leftNav, rightNav, showMobileBackBtn } = props;
  return (
    <div className="row flex-row" style={{ height: '100%' }}>
      {showMobileBackBtn && <BackButtonSpace />}
      <div className="flex-row header-logo-container-row">
        <BrandLogo {...props} />
        {leftNav && <span className="hide-for-small-only">{leftNav}</span>}
      </div>
      {rightNav && <div className="right nav flex-row flex-child-expand">{rightNav}</div>}
    </div>
  );
};

Nav.defaultProps = {
  leftNav: null,
  rightNav: null,
  showMobileBackBtn: false,
};

type HeaderProps = PropsFromRedux &
  Partial<BrandLogoProps> &
  Partial<NavProps> & {
    className?: string | null;
  };

const Header: FC<HeaderProps> = (props) => {
  const { className } = props;
  const headerClasses = cx('site', 'band', className);

  return (
    <header className={headerClasses} style={{ overflow: 'visible' }}>
      <Nav {...props} />
    </header>
  );
};

Header.defaultProps = {
  className: null,
};

export default connector(Header);
