// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { PhoneIcon } from 'client/components/icons/index';
import LinkButton from 'client/components/button/linkButton';
import { Tracking } from 'web-core/src/tracking';

export const StyledNavLink = styled(LinkButton)`
  && {
    padding: 0rem;
  }
`;

const StyledNavLinkWrapper = styled.li`
  margin-right: 0;
  padding-right: 1rem;
  border-right: 1px solid #fff;
`;


type Props = {
  clickHandler: Function,
  phone: string,
};

class NavPhoneButton extends PureComponent<Props> {
  static defaultProps = {
    clickHandler: () => {},
  };

  render() {
    const { phone } = this.props;
    return (
      <StyledNavLinkWrapper id="nav-phone-link-wrapper" className="nav-link">
        <StyledNavLink
          className="transparent"
          href={`tel:${phone}`}
          onClick={() => {
            Tracking.track('Call us button clicked');
            this.props.clickHandler();
          }}>
          <div className="flex-column" id="nav-phone-wrapper">
            <PhoneIcon />
            <div className="text-center hide-for-small-only">
              <span id="nav-phone-number">{phone}</span>
            </div>
          </div>
        </StyledNavLink>
      </StyledNavLinkWrapper>
    );
  }
}

export default NavPhoneButton;
