// @flow

import _ from 'lodash';
import MemberUtils from 'web-core/src/utils/member';
import { formatSSN, slashDateToISO } from 'routes/marketplace-ede/web-ui/utils/format';
import { interpolateWith } from 'web-core/src/utils/format';
import { parseNumber } from 'routes/marketplace-ede/web-ui/utils/finance';
import { dateIsBetween } from 'routes/marketplace-ede/web-ui/utils/validate';
import type { MemberType } from 'web-core/types/member';
import type {
  ArrayOfFinancesType,
  Finance,
} from 'web-core/types/finance';
import { APPLICATION_MEMBER_TYPES } from 'routes/marketplace-ede/web-ui/constants/globals';
import { getMemberAttestedAnnualizedAptcIndividualIncomeAmt } from 'routes/marketplace-ede/utils/selectors';

export type FinanceWithBaseMultiplierType = {
  ...$Exact<Finance>,
  baseMultiplier: number,
};

const AGE = 'age';
const COVERAGE = 'coverage';
const MEDICAID = 'medicaid';
const MEM_APP_TYPE = 'member_application.type';
const SSN = 'ssn';

export const fieldMap = {
  first_name: 'first_name',
  last_name: 'last_name',
  gender: 'gender',
  marital_status: 'marital_status',
  relation: 'relation',
  ssn: 'ssn',
  dob: 'dob',
  middle_name: 'middle_name',
  suffix: 'suffix',
  race: 'races',
  ethnicity: 'ethnicities',
  spouse_relation: 'spouse_relation',
};

export const mapFieldsFromSelectionList = (fieldValues) =>
  _.map(fieldValues, 'id');

export const mapFormToSelectionList = (formValues) =>
  _.map(formValues, (id) => ({ id }));

const mapFieldsToRaceEthnicity = (fieldValue, type) => ({
  ...fieldValue,
  [fieldMap[type]]: mapFieldsFromSelectionList(
    fieldValue[fieldMap[type]]
  ),
});

const mapRaceEthnicityToFields = (formValue, type) => ({
  ...formValue,
  [fieldMap[type]]: mapFormToSelectionList(formValue[fieldMap[type]]),
});

export function mapFieldsToMember(fieldValues: Object) {
  return Object.keys(fieldMap).reduce((acc, key) => {
    const fieldValue = fieldValues[key];
    let val = key === 'dob' ? slashDateToISO(fieldValue) : fieldValue;
    // check on ssn val, api does not allow empty string
    if (key === SSN && fieldValue === '') {
      val = null;
    }
    if (
      (key === 'race' || key === 'ethnicity') &&
      !_.isEmpty(fieldValue)
    ) {
      val = mapFieldsToRaceEthnicity(fieldValue, key);
    }

    return {
      ...acc,
      [key]: val,
    };
  }, {});
}

export function mapMemberToFields(member: Object) {
  return Object.keys(fieldMap).reduce((acc, key) => {
    let val = key === SSN ? formatSSN(member.ssn) : member[key];

    if (
      (key === 'race' || key === 'ethnicity') &&
      !_.isEmpty(member[key])
    ) {
      val = mapRaceEthnicityToFields(member[key], key);
    }

    return {
      ...acc,
      [key]: val,
    };
  }, {});
}

export function isComplete(
  member: MemberType,
  coverageStatus: string = COVERAGE
) {
  const requiredEveryone =
    member.first_name &&
    member.last_name &&
    member.gender &&
    member.dob;
  if (member.type === 'primary') {
    return requiredEveryone && !!member.marital_status && member.ssn;
  } else if (coverageStatus === COVERAGE) {
    return requiredEveryone && !!member.relation && member.ssn;
  }
  return requiredEveryone && !!member.relation;
}

export function isMemberCovered(member: MemberType) {
  return _.get(member, MEM_APP_TYPE) === COVERAGE;
}

export function isMemberMedicaid(member: MemberType) {
  return _.get(member, MEM_APP_TYPE) === MEDICAID;
}

export function isMemberApplying(member: MemberType) {
  return isMemberCovered(member) || isMemberMedicaid(member);
}

export const isPrimaryFiler = (member: MemberType) =>
  _.get(member, 'type') === APPLICATION_MEMBER_TYPES.PRIMARY;

export const isMemberDependent = (member: MemberType) =>
  _.get(member, 'type') === APPLICATION_MEMBER_TYPES.DEPENDENT;

export function filterCoveredMembers(members: Array<MemberType>) {
  return _.filter(members, isMemberCovered);
}

export function filterCoveredMedicaidMembers(
  members: Array<MemberType>
) {
  return _.filter(members, isMemberApplying);
}

export function filterMedicaidMembers(members: Array<MemberType>) {
  return _.filter(members, isMemberMedicaid);
}

const sortDesc = (a, b) => b - a;

export function ageDiscrepancy(
  members_1: Array<MemberType>,
  members_2: Array<MemberType>
) {
  const memberAges_1 = _.map(
    filterCoveredMedicaidMembers(members_1),
    AGE
  ).sort(sortDesc);
  const memberAges_2 = _.map(
    filterCoveredMedicaidMembers(members_2),
    AGE
  ).sort(sortDesc);
  return !_.isEqual(memberAges_1, memberAges_2);
}

export function medicaidDiscrepancy(
  members_1: Array<MemberType>,
  members_2: Array<MemberType>
) {
  return !_.every(
    members_1,
    (member) =>
      _.get(_.find(members_2, ['id', member.id]), MEM_APP_TYPE) ===
      _.get(member, MEM_APP_TYPE)
  );
}

export function subsidyDiscrepancy(
  appJson_1: Object,
  appJson_2: Object
) {
  const key = 'subsidy.amount';
  const subsidy_1 = Math.round(_.get(appJson_1, key, 0));
  const subsidy_2 = Math.round(_.get(appJson_2, key, 0));
  return !_.isEqual(subsidy_1, subsidy_2);
}

export function sumFinance(
  finances: Array<FinanceWithBaseMultiplierType>
) {
  const total = _.reduce(
    finances,
    (sum, finance) => {
      const { amount, baseMultiplier = 1, multiplier = 1 } = finance;
      switch (finance.type) {
        case 'income':
          /* eslint-disable no-param-reassign */
          sum += parseNumber(amount) * multiplier * baseMultiplier;
          break;
        default:
          sum -= parseNumber(amount) * multiplier * baseMultiplier;
        /* eslint-enable no-param-reassign */
      }
      return sum;
    },
    0
  );
  return Math.round(total * 100) / 100;
}

const getIncomeAttestation = (member) =>
  _.get(member, 'attestations.income');

function getMembersUsingAnnualEsimate(ede_application: Object) {
  return _.get(ede_application, 'edeMembers', [])
    .map((member) => {
      // prefer attestation over app_json storage
      const edeAttestation = _.get(
        getIncomeAttestation(member),
        'annualTaxIncome.variableIncomeIndicator'
      );
      if (_.isBoolean(edeAttestation)) {
        return edeAttestation
          ? {
              ...member,
              incomeAmount: _.get(
                getIncomeAttestation(member),
                'annualTaxIncome.incomeAmount'
              ),
            }
          : {
              ...member,
              incomeAmount: getMemberAttestedAnnualizedAptcIndividualIncomeAmt(
                member
              ),
            };
      }

      const edeJsonMatch =
        _.find(
          _.get(
            ede_application,
            'ifp_app.app_json.ede.income.member_income',
            []
          ),
          {
            id: member.id,
          }
        ) || {};
      return edeJsonMatch.totalConfirmation
        ? null
        : edeJsonMatch.annualEstimate;
    })
    .filter((m) => m);
}

export function getMemberEDEIncome(
  memberIdentifier: string,
  finances: ArrayOfFinancesType,
  ede_application: Object
) {
  const membersUsingAnnualEstimate = getMembersUsingAnnualEsimate(
    ede_application
  );
  const member = _.find(membersUsingAnnualEstimate, {
    memberIdentifier: memberIdentifier,
  });

  if (member) {
    return member.incomeAmount;
  }

  return sumFinance(_.filter(finances, { memberIdentifier }));
}

export function sumEDEIncomes(
  finances: Array<Object>,
  ede_application: Object
) {
  const membersUsingAnnualEstimate = getMembersUsingAnnualEsimate(
    ede_application
  );
  const annualEstimateIds = membersUsingAnnualEstimate.map(
    (res) => res.id
  );

  const financesToSum = finances.filter(
    (f) => annualEstimateIds.indexOf(f.member_id) === -1
  );

  const financesTotal = sumFinance(financesToSum);
  const annualEstimateTotal = membersUsingAnnualEstimate.reduce(
    (acc, member) => acc + member.incomeAmount,
    0
  );

  return financesTotal + annualEstimateTotal;
}

export function toReadableFormat(
  members: Array<MemberType>,
  commonTranslations: Object
) {
  return _.map(members, (member) => {
    const gender =
      _.get(member, 'gender') === 'M'
        ? _.get(commonTranslations, 'member.male', 'Male')
        : _.get(commonTranslations, 'member.female', 'Female');
    let subtitle = `${gender}, ${member.age}`;
    if (commonTranslations) {
      subtitle = interpolateWith(
        _.get(
          commonTranslations,
          'member.ageAndGenderWithComma',
          undefined
        ),
        {
          age: member.age,
          gender,
        }
      );
    }

    return {
      id: _.get(member, 'member_application.member_id'),
      title: `${member.first_name} ${member.last_name}`,
      subtitle,
    };
  });
}

export function getAgeValidator(
  member: MemberType,
  appType: string = 'health'
) {
  const isCovered = isMemberCovered(member);
  const { min, max } = MemberUtils.getMinMaxAge({
    memberType: member.type,
    appType,
    isCovered,
  });
  return (val: Date) =>
    dateIsBetween(max, min)(val) && {
      path: 'common.error.ageOutOfBounds',
      using: { min, max },
    };
}

export function getMemberFullName(member: MemberType) {
  return [
    member.first_name,
    member.middle_name,
    member.last_name,
    member.suffix,
  ]
    .filter((n) => n)
    .join(' ');
}

export const getAge = (dob: string) => {
  /**
   * In iOS, calling new Date on "02 / 04 / 1991" (with spaces) will result in "Invalid Date"
   */
  const replacedDOBString = dob.replace(/ /gi, '');
  const dateDifference = Date.now() - new Date(replacedDOBString);
  const millisecondsAge = new Date(dateDifference);
  return millisecondsAge.getUTCFullYear() - 1970;
};
