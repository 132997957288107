// @flow

import _ from 'lodash';
import React, { Component, type Node } from 'react';
import cx from 'classnames';
import GLOBALS from 'client/constants/globals';
import NavProfileButton from 'client/components/nav/navProfileButton';
import Translate from 'client/components/translate';
import NavPhoneButton from './navPhoneButton';
import LoginLogoutLink from './loginLogoutLink';

type Props = {
  activeLink: ?string,
  byName: ?Array<string>,
  children: Node | Array<Node>,
  className: string,
  id: string,
  isHealthLogin: Function,
  showLoginIcon: boolean,
};

const navLookup = {
  about: {
    url: '/about',
    linkText: 'About us',
  },
  dental: {
    url: '/get-dental',
    linkText: 'Dental',
  },
  vision: {
    url: '/vision',
    linkText: 'Vision',
  },
  life: {
    url: '/life',
    linkText: 'Life',
  },
  health: {
    url: '/coverage',
    linkText: 'Health',
  },
  dashboard: {
    url: '/my/insurance',
    linkText: 'Dashboard',
  },
  dashboardTax: {
    url: '/my/taxes',
    linkText: 'Dashboard',
  },
  actionCenter: {
    url: '/tax/rewards/my',
    linkText: 'Action Center',
  },
  openPositions: {
    url: 'https://jobs.lever.co/stridehealth',
    linkText: 'See All Open Positions',
  },
  findYourPlan: {
    url: '/health/profile',
    linkText: 'Find your plan',
  },
  jobs: {
    url: 'https://www.stridebenefits.com/careers',
    linkText: 'Careers',
  },
  employers: {
    url: 'https://www.stridebenefits.com/',
    linkText: 'Employer',
  },
};

class Nav extends Component<Props> {
  static defaultProps = {
    activeLink: null,
    byName: null,
    phoneNumber: '',
    children: null,
    className: '',
    id: '',
    isHealthLogin: false,
    showLoginIcon: false,
  };

  renderNavsByName(names: Array<string>): Array<Node> {
    const { isHealthLogin, showLoginIcon, phoneNumber } = this.props;

    const uniqueNames = _.uniq(names || []);

    return uniqueNames.map((name) => {
      if (name === 'phone') {
        const { phone } = GLOBALS;
        const tel = phoneNumber || phone;
        return <NavPhoneButton key={name} phone={tel} />;
      }
      if (name === 'account') {
        return (
          <LoginLogoutLink isHealthLogin={isHealthLogin} key={name} withIcon={showLoginIcon} />
        );
      }
      if (name === 'editProfile') {
        return <NavProfileButton key={name} />;
      }

      const hideForSmallOnly = name !== 'findYourPlan';

      return (
        <li
          key={name}
          className={cx('nav-link', {
            active: this.props.activeLink === name,
            'hide-for-small-only': hideForSmallOnly,
          })}>
          <a href={navLookup[name].url}>
            <Translate path={`common.footer.${name}`} fallback={navLookup[name].linkText} />
          </a>
        </li>
      );
    });
  }

  render() {
    const { byName, children, className, id } = this.props;
    return (
      <nav className={className} id={id}>
        <ul className="flex-row inline-block-list small-text-center medium-text-right">
          {byName && this.renderNavsByName(byName)}
          {children && children}
        </ul>
      </nav>
    );
  }
}

export default Nav;
