import React from 'react';

import Translate from 'routes/marketplace-ede/web-ui/components/translate';

import 'routes/marketplace-ede/styles/error.scss';

const UnauthorizedErrorPage = () => (
  <div className="error-page page-dne">
    <div className="error-page--wrapper">
      <div className="error-bg-container">
        <div className="sorry">
          <img
            alt="sorry"
            src="https://images.contentful.com/heg7gyzix6dq/3kEqE5MkYMQes4ec0ouM4W/6614c448fe9f575668d4199890b13f50/sorry.svg"
          />
        </div>
      </div>
      <div className="error-content-container">
        <div className="text-center pad">
          <h3>
            <Translate path="common.error.inactiveSession" />
          </h3>
        </div>
      </div>
    </div>
  </div>
);

export default UnauthorizedErrorPage;
