// @flow

import _ from 'lodash';
import React, { Component, Children, type Node } from 'react';
import { connect } from 'react-redux';

import { fetchContentful } from 'client/actions/contentful';
import { fetchUser } from 'client/actions/user';
import CONTENTFUL_ENTRY_ID from 'client/constants/contentfulEntry';
import NpnFooter from 'client/components/npnFooter';
import { isStridePartner } from 'client/utils/configUtils';
import StrideHeaderWrapper from 'client/components/strideHeaderWrapper';
import { InfoModalProvider } from 'client/context/infoModalContext';
import 'client/styles/common.scss';
import 'partner-styles/partners.scss';

type Props = {
  children: Node,
  isPartner: Boolean,
};

const mapStateToProps: Object = (state: Object) => {
  const { siteConfigs } = state;
  const isPartner = isStridePartner(siteConfigs);

  return {
    isPartner,
  };
};

class App extends Component<Props> {
  static fetchData({ dispatch }: { dispatch: Function }) {
    return Promise.resolve(dispatch(fetchUser())).then((user) => {
      const locale = _.get(user, 'locale', 'en-US');
      return dispatch(fetchContentful(CONTENTFUL_ENTRY_ID.ERROR_PAGE, locale));
    });
  }

  render() {
    const { isPartner } = this.props;

    return (
      <div>
        <InfoModalProvider>
          <StrideHeaderWrapper noShadow />
          {Children.only(this.props.children)}
          {!isPartner && <NpnFooter />}
        </InfoModalProvider>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(App);
