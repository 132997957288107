import { BANKING_MFA_SESSION_TOKEN, BANKING_SESSION_TOKEN } from '@coveredinc/auth';

import UserService from 'web-core/src/user-service';

export function logout(path) {
  return (dispatch, getState, { apiClient }) => {
    window.sessionStorage.removeItem(BANKING_SESSION_TOKEN);
    window.sessionStorage.removeItem(BANKING_MFA_SESSION_TOKEN);

    const userService = new UserService({ client: apiClient });
    return userService.logout().then((success) => {
      if (success && path) {
        window.location.href = path;
      }

      return success;
    });
  };
}
