// @flow

import styled from 'styled-components';
import React from 'react';

type SvgType = {
  className: string,
  height: string,
  stroke: string,
  viewBox: string,
  width: string,
};

const SvgWrapper = styled.div`
  display: inline-block;
  height: ${props => props.height};
  width: ${props => props.width};
  color: inherit;
`;

const SearchIcon = ({ stroke, className, width, height, viewBox }: SvgType) => (
  <SvgWrapper className={className} width={width} height={height}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox={viewBox}
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-search">
      <circle cx="11" cy="11" r="8" />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
  </SvgWrapper>
);

SearchIcon.defaultProps = {
  stroke: 'currentColor',
  className: '',
  width: '20px',
  height: '20px',
  viewBox: '0 0 23 23',
};

export default SearchIcon;
