import React, { type Node } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import COLORS from 'routes/marketplace-ede/web-ui/constants/colors';

const AlertContainer = styled.div`
  background-color: ${COLORS.BRAND_50};
  padding: 0.8rem;
  margin-top: 1.5rem;
  position: relative;
  font-weight: 400;
  line-height: ${rem(20)};
  font-size: ${rem(14)};
  border: ${rem('1px')} solid ${COLORS.NEUTRAL_900};
  border-radius: ${rem('8px')};
  color: ${COLORS.NEUTRAL_900};
  text-align: ${props => props.align};
`;

const BoxContainer = ({ children, align = 'left' }: { children: Node }) => (
  <AlertContainer className="alert__partner" align={align}>
    {children}
  </AlertContainer>
);

export default BoxContainer;
