// @flow

import _ from 'lodash';

// TODO: replace all references to ifpApps[0] in app/routes/marketplace with this method
// may have to update anything that relies on ifp_app.address to pull from primary member.address
export const getHealthApp = (state: Object) =>
  _.get(state, 'edeApplication.data.ifp_app', _.find(state.ifpApps, { type: 'health' }) || {});

export const getEdeApp = (state: Object) => _.get(state, 'edeApplication.data', {});

export const getAppMembers = (state: Object) => getHealthApp(state).members || [];

export const getCoverageMembers = (state: Object) =>
  getAppMembers(state).filter(m => _.get(m, 'member_application.type') !== 'household');

export const getCoverageYear = (state: Object) =>
  // prefer year from ede payload
  _.get(state, 'edeApplication.data.ede_payload.coverageYear') ||
  // fall back to app_json ede blob if ede app hasn't been created yet
  _.get(_.find(state.ifpApps, { type: 'health' }), 'app_json.ede.coverageYear');

export const getAppPrimary = (state: Object) => _.find(getAppMembers(state), { type: 'primary' });

const getDeconstructedAddress = (address: Object) => {
  const {
    cityName: city = '',
    countyName: county = '',
    stateCode: state = '',
    streetName1: streetOne = '',
    streetName2: streetTwo = '',
    zipCode: zipcode = '',
  } = address;

  const street = streetTwo && streetTwo !== '' ? `${streetOne}, ${streetTwo}` : streetOne;

  return {
    city,
    county,
    state,
    street,
    zipcode,
  };
};

export const getEdeHomeAddress = (state: Object) => {
  const primary = getAppPrimary(state);

  const homeAddress = _.get(
    primary,
    'ede_member.ede_payload.attestation.demographic.homeAddress',
    {},
  );

  return getDeconstructedAddress(homeAddress);
};

export const getEdeMailingAddress = (state: Object) => {
  const primary = getAppPrimary(state);

  const mailingAddress = _.get(
    primary,
    'ede_member.ede_payload.attestation.demographic.mailingAddress',
    {},
  );

  return getDeconstructedAddress(mailingAddress);
};

const getEdeContactInformation = (state: Object) =>
  _.get(state, 'edeApplication.data.ede_payload.attestations.application.contactInformation', {});

export const getEdePrimaryPhoneNumber = (state: Object) =>
  _.get(getEdeContactInformation(state), 'primaryPhoneNumber', {});

export const getEdeApplicationAssistors: Object => Array<Object> = (state: Object) => {
  const applicationAssistors = _.get(
    getEdeApp(state),
    'ede_payload.attestations.application.applicationAssistors',
    [],
  );
  const assistorFullName = assistor =>
    [assistor.assistorFirstName, assistor.assistorLastName, assistor.assistorSuffix]
      .filter(n => n)
      .join(' ');

  return _.map(applicationAssistors, assistor => ({
    ...assistor,
    assistorName: assistorFullName(assistor),
  }));
};

export const getSesMetaData = (state: Object) => _.get(state, 'ses.stateMetaData', {});
