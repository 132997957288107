import _ from 'lodash';
import ContentfulService from 'web-core/src/contentful-service';

export const RECEIVE_CONTENTFUL_DATA = 'RECEIVE_CONTENTFUL_DATA';
export const RECEIVE_CONTENTFUL_TRANSLATIONS = 'RECEIVE_CONTENTFUL_TRANSLATIONS';
export const RECEIVE_STATE_CONFIG_DATA = 'RECEIVE_CONTENTFUL_DATA';

export function receiveContentfulData(payload, prefix = null) {
  return {
    type: RECEIVE_CONTENTFUL_DATA,
    data: payload,
    prefix,
  };
}

export function receiveContentfulTranslations(payload) {
  return {
    type: RECEIVE_CONTENTFUL_TRANSLATIONS,
    data: payload,
  };
}

export function receiveStateConfig(payload) {
  return {
    type: RECEIVE_STATE_CONFIG_DATA,
    data: payload,
  };
}

const omitKeys = ['id', 'updatedAt', 'name', 'key'];

const parseContentfulResponse = response =>
  _.get(response, 'data.config', _.get(response, 'data', {}));

/* eslint-disable no-unused-vars */
export function fetchContentful(contentfulEntryId, locale, prefix = null) {
  return (dispatch, getState, { apiClient }) => {
    const svc = new ContentfulService({ client: apiClient });
    return svc.getEntryById(contentfulEntryId, locale).then((response) => {
      const data = parseContentfulResponse(response);
      dispatch(receiveContentfulData(_.omit(data, omitKeys), prefix));
      return response;
    });
  };
}

export function getStateConfig(stateCode) {
  return (dispatch, getState, { apiClient }) => {
    const svc = new ContentfulService({ client: apiClient });
    return svc.getStateConfig(stateCode).then(({ data }) => {
      dispatch(receiveStateConfig(data));
      return data;
    });
  };
}

export function fetchContentfulTranslations(contentfulEntryId, locale) {
  return (dispatch, getState, { apiClient }) => {
    const svc = new ContentfulService({ client: apiClient });
    return svc.getEntryById(contentfulEntryId, locale).then((response) => {
      const data = parseContentfulResponse(response);
      const translationKey = _.get(data, 'key', 'default');
      const payload = { [translationKey]: _.omit(data, omitKeys) };
      dispatch(receiveContentfulTranslations(payload));
      return response;
    });
  };
}

export function fetchContentfulEntriesByTypeAndMerge(
  type,
  params = {},
  baseKey = 'default',
  prefix = null,
) {
  return (dispatch, getState, { apiClient }) => {
    const svc = new ContentfulService({ client: apiClient });
    return svc.getEntriesByType(type, params).then((response) => {
      const defaultBaseIndex = response.findIndex(obj => obj.key === baseKey);
      const defaultBase = response[defaultBaseIndex];
      response.splice(defaultBaseIndex, 1);
      const mergedData = _.merge(defaultBase, ...response);
      dispatch(receiveContentfulData(_.omit(mergedData, omitKeys), prefix));
      return mergedData;
    });
  };
}
