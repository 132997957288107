// @flow

import _ from 'lodash';
import moment from 'moment';
import {
  getPrimaryMember,
  isMemberRequestingCoverage,
} from 'routes/marketplace-ede/utils/selectors';

import GLOBALS from 'routes/marketplace-ede/web-ui/constants/globals';

import type { EdeMemberType } from 'routes/marketplace-ede/types/ede/edeMember.flow';

export const getProfile = (state: Object) => _.get(state, 'profile', {});

export const getPersonId = (state: Object) => _.get(state, 'personId', null);

export const getProfileIsVerified = (state: Object) => _.get(state, 'profile.isVerified');

export const getProductApplication = (state: Object) => _.get(state, 'productApplication', {});

export const getEdeProductApp = (state: Object) => {
  const productApps = _.get(state, 'productApplications', []);
  return _.find(productApps, { type: 'health', subType: 'ede' });
};

// TODO HealthApp selector might need to update once we implement product application service
export const getHealthApp = (state: Object) => _.get(state, 'edeApplication', {});

export const getEdeApp = (state: Object) => _.get(state, 'edeApplication', {});

export const getAppId = (state: Object): number =>
  _.get(getEdeApp(state), 'insuranceApplicationIdentifier');

export const getAppMembers = (state: Object): EdeMemberType[] =>
  _.get(state, 'edeApplication.edeMembers', []);

export const getCoverageMembers = (state: Object) =>
  getAppMembers(state).filter(isMemberRequestingCoverage);

export const getCoverageYear = (state: Object): number =>
  _.get(state, 'edeApplication.coverageYear');

export const getProductEffectiveYear = (state: Object) => {
  const effectiveDate = _.get(state, 'productApplications.products[0].effectiveDate');
  return effectiveDate ? new Date(effectiveDate).getUTCFullYear().toString() : null;
};

export const getProductAppId = (state: Object) => _.get(state, 'productApplications.id');

export const getPrimaryApplicantId = (state: Object) => {
  const applicants = _.get(state, 'productApplications.applicants', []);
  const primaryApplicant = _.find(applicants, { isFiler: true });
  return _.get(primaryApplicant, 'id');
};

export const getConsents = (state: Object) => _.get(state, 'productApplications.consents', []);

export const getAppPrimary = (state: Object) => {
  const edeApplication = getEdeApp(state);
  return getPrimaryMember(edeApplication);
};

export const getMemberIncomes = (state: Object) => {
  const members = getAppMembers(state);
  return members.reduce((acc, currentMember) => {
    const memberIdentifier = _.get(currentMember, 'memberIdentifier');
    const memberIncomes = _.get(currentMember, 'attestations.income.currentIncome', {});

    // Adds employer info in income object to use in ui form
    const insuranceCoverages = _.get(currentMember, 'attestations.insuranceCoverage.employerSponsoredCoverageOffers', []);
    const memberIncomesWithEmployerInfo = memberIncomes && Object.keys(memberIncomes).reduce((previous, key) => {
      const income = memberIncomes[key];
      const employerName = _.get(income, 'jobIncome.employerName', '');
      const insuranceCoveragesJob = _.find(insuranceCoverages, { employer: { name: employerName } });
      const employerInfo = _.get(insuranceCoveragesJob, 'employer', '');

      if (income) {
        previous[key] = {
          ...income,
          jobIncome: {
            ...income.jobIncome,
            ...employerInfo,
          },
        };
      }

      return previous;
    }, {});

    const prunedIncomes = _.omitBy(memberIncomesWithEmployerInfo, _.isNil);
    const mappedMemberIncomes = _.map(prunedIncomes, income => ({
      ...income,
      memberIdentifier,
    }));
    return [...acc, ...mappedMemberIncomes];
  }, []);
};

const getDeconstructedAddress = (address: Object) => {
  if (!address) return null;
  const {
    cityName: city = '',
    countyName: county = '',
    stateCode: state = '',
    streetName1: streetOne = '',
    streetName2: streetTwo = '',
    zipCode: zipcode = '',
    countyFipsCode = '',
  } = address;

  const street = streetTwo && streetTwo !== '' ? `${streetOne}, ${streetTwo}` : streetOne;

  return {
    city,
    county,
    state,
    street,
    zipcode,
    countyFipsCode,
  };
};
type AddressType = 'mailingAddress' | 'homeAddress' | 'transientAddress';

export const getEdeMemberAddress = (member: EdeMemberType | void, addressType: AddressType) => {
  if (member) {
    const address = _.get(member, `attestations.demographic.${addressType}`);
    return address ? getDeconstructedAddress(address) : {};
  }
  return {};
};

export const getEdeHomeAddress = (state: Object) => {
  const primary = getAppPrimary(state);
  return getEdeMemberAddress(primary, 'homeAddress');
};

export const getMemberHomeAddressString = (member: EdeMemberType | void, type: AddressType) => {
  const addressInfo =
    type === 'mailing'
      ? _.get(member, 'attestations.demographic.mailingAddress')
      : _.get(member, 'attestations.demographic.homeAddress');
  const address = getDeconstructedAddress(addressInfo);
  return !_.isEmpty(address) ? `${address.street}, ${address.city}, ${address.state}` : null;
};

export const getEdeMailingAddress = (state: Object) => {
  const primary = getAppPrimary(state);
  return getEdeMemberAddress(primary, 'mailingAddress');
};

const getEdeContactInformation = (state: Object) =>
  _.get(state, 'edeApplication.attestations.application.contactInformation', {});

export const getEdePrimaryPhoneNumber = (state: Object) =>
  _.get(getEdeContactInformation(state), 'primaryPhoneNumber', {});

export const getEdeSecondaryPhoneNumber = (state: Object) =>
  _.get(getEdeContactInformation(state), 'secondaryPhoneNumber', {});

export const getEdeApplicationAssistors: (Object) => Array<Object> = (state: Object) => {
  const applicationAssistors = _.get(
    state,
    'edeApplication.attestations.application.applicationAssistors',
    [],
  );
  const assistorFullName = assistor =>
    [assistor.assistorFirstName, assistor.assistorLastName, assistor.assistorSuffix]
      .filter(n => n)
      .join(' ');

  return _.map(applicationAssistors, assistor => ({
    ...assistor,
    assistorName: assistorFullName(assistor),
  }));
};

export const getEdeTaxHouseholds = (state: Object) =>
  _.get(state, 'edeApplication.computed.taxHouseholds', []);

// TODO: revisit when we have SES Mockdata
export const getSesMetaData = (state: Object) => _.get(state, 'ses.stateMetaData', {});

export const getEnrollmentStatus = (state: Object) =>
  _.get(state, 'enrollmentStatus.getEnrollmentStatus', []);

export const getPartnerConfig = (state: Object) =>
  _.get(state, 'siteConfigs.brandSiteConfig.partnerConfig', {});

export const getAgencyId = (state: Object) =>
  _.get(state, 'siteConfigs.partnerAgencyInfo.agencyId', null);

export const getAgentId = (state: Object) => _.get(state, 'agentId', null);

export const getAgentInfo = (state: Object) => _.get(state, 'agentInfo', null);

export const getPartnerId = (state: Object) =>
  _.get(state, 'siteConfigs.partnerAgencyInfo.partnerId', null);

export const getPhoneNumber = (state: Object, overwriteHidePhone = false) => {
  let { phone } = GLOBALS;
  const { siteConfigs, applicationInfo, partnerUser, agentId } = state;
  const applicationType = _.get(applicationInfo, 'applicationType');
  const agencyInfo = _.get(siteConfigs, 'brandSiteConfig.partnerConfig.agencyInfo', {});

  // verify partner specific pages to display/hide phone
  const mxPath = _.get(siteConfigs, 'brandSiteConfig.partnerConfig.mXPhoneNumberEnablePaths');
  if (mxPath && typeof window !== 'undefined') {
    const hidePhoneContentful = !mxPath.reduce(
      (prev, cur) => prev || window.location.href.includes(cur),
      false,
    );
    if (hidePhoneContentful) return null;
  }

  if (agencyInfo.hidePhoneNumber && !overwriteHidePhone) {
    return null;
  }
  const agencyPhonenumber = _.get(agencyInfo, 'phoneNumber', phone);
  const brokerSupportPhonenumber = _.get(
    agencyInfo,
    'agency_broker_support_phonenumber',
    agencyPhonenumber,
  );
  const initiationPhonenumber = _.get(
    agencyInfo,
    'agency_initiation_phonenumber',
    agencyPhonenumber,
  );
  const renewalPhonenumber = _.get(agencyInfo, 'agency_renewal_phonenumber', agencyPhonenumber);
  const userGroups = (partnerUser && partnerUser.groups) || [];
  const isBroker = userGroups.includes('broker') || !!agentId;
  const isConsumer = userGroups.includes('consumer');
  if (isBroker) {
    phone = brokerSupportPhonenumber;
  } else if (isConsumer) {
    if (_.get(applicationInfo, 'client.agent.phoneNumber')) {
      phone = applicationInfo.client.agent.phoneNumber;
    } else if (applicationType === 'INITIATION') {
      phone = initiationPhonenumber;
    } else if (applicationType === 'RENEWAL') {
      phone = renewalPhonenumber;
    } else {
      phone = agencyPhonenumber;
    }
  } else {
    phone = agencyPhonenumber;
  }
  return phone;
};

export const userIsBroker = (state: Object) => {
  const { partnerUser, agentId } = state;
  const userGroups = (partnerUser && partnerUser.groups) || [];
  return userGroups.includes('broker') || !!agentId;
};

export const getAgencyName = (state: Object) =>
  (_.get(state, 'siteConfigs.partnerAgencyInfo.agencyInfo.agencyCode')
    ? _.get(state, 'siteConfigs.brandSiteConfig.name', '')
    : 'Stride');

export const checkOEPStatus = (state: Object): boolean => {
  const now = moment();
  const oeStartDate = _.get(state, 'ses.systemReference.oeStartDate', null);
  const oeEndDate = _.get(state, 'ses.systemReference.oeEndDate', null);
  return moment(oeStartDate).isBefore(now) && moment(oeEndDate).isAfter(now);
};

export const getPartnershipType = (state: Object): ?string =>
  _.get(state, 'siteConfigs.partnerAgencyInfo.partnerInfo.partnershipType');

export const getIsIssuer = (state: Object): ?string =>
  _.get(state, 'siteConfigs.partnerAgencyInfo.partnerInfo.isIssuer');

export const getDashboardUrl = (state: Object): ?string => {
  const agencyInfo = _.get(state, 'siteConfigs.brandSiteConfig.partnerConfig.agencyInfo');
  if (userIsBroker(state)) {
    return _.get(agencyInfo, 'agentDashboardUrl');
  }

  return _.get(agencyInfo, 'dashboardUrl');
};

export const getDocumentsDashboardUrl = (state: Object): ?string => {
  const agencyInfo = _.get(state, 'siteConfigs.brandSiteConfig.partnerConfig.agencyInfo');
  if (userIsBroker(state)) {
    return _.get(agencyInfo, 'agentDocumentsUploadedReturnUrl');
  }

  return _.get(agencyInfo, 'documentsUploadedReturnUrl');
};

export const getPlanCancelledReturnUrl = (state: Object): ?string => {
  const agencyInfo = _.get(state, 'siteConfigs.brandSiteConfig.partnerConfig.agencyInfo');
  if (userIsBroker(state)) {
    return _.get(agencyInfo, 'agentPlanCancelledReturnUrl');
  }

  return _.get(agencyInfo, 'planCancelledReturnUrl');
};

export const getPartnerStateInfo = (state: Object) => {
  const partnershipType = getPartnershipType(state);
  return {
    isPartner: partnershipType === 'ENTERPRISE' || partnershipType === 'WHITE_LABEL',
    agentInfo: getAgentInfo(state) || (getAgentId(state) && { id: getAgentId(state) }),
    profile: getProfile(state),
  };
};

export const getApplicationInfo = (state: Object) => _.get(state, 'applicationInfo', {}) || {};
export const getApplicationId = (state: Object) => _.get(state, 'applicationId', null);

export const getBarOptOutWindow = (state: Object): Object => {
  const { barOptOutStartDate, barOptOutEndDate } = _.get(state, 'ses.systemReference', {});
  const today = moment();
  const startDate = moment(barOptOutStartDate);
  const endDate = moment(barOptOutEndDate);
  const isValidBarOptOutDate = today.isBetween(startDate, endDate, 'day', '[]');

  return {
    isValidBarOptOutDate,
    barOptOutEndDate,
    barOptOutStartDate,
  };
};

export const getSessionId = (state: Object) => {
  if (userIsBroker(state)) {
    return getApplicationId(state);
  }
  return getPersonId(state);
};

export const getQuote = (state: Object) => _.get(state, 'quote', {});
