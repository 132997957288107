// @flow

import React from 'react';

const DoctorsIcon = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>doctors</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-238.000000, -24.000000)"
        stroke="#B8BCC3"
        strokeWidth="2">
        <g id="doctors" transform="translate(239.000000, 25.000000)">
          <g
            id="plus"
            transform="translate(6.000000, 8.000000)"
            strokeLinecap="round"
            strokeLinejoin="round">
            <line x1="4" y1="0" x2="4" y2="8" id="Path" />
            <line x1="0" y1="4" x2="8" y2="4" id="Path" />
          </g>
          <path
            d="M4,3 L4,1.5 C4,0.671572875 5.34314575,0 7,0 L13,0 C14.6568542,0 16,0.671572875 16,1.5 L16,3"
            id="Path"
            strokeLinecap="round"
            strokeLinejoin="round" />
          <rect id="Rectangle" x="0" y="4" width="20" height="16" rx="1" />
        </g>
      </g>
    </g>
  </svg>
);

export default DoctorsIcon;
