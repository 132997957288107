// @flow

import _ from 'lodash';
import { CSR_LEVELS, SUBSIDY_TYPES } from 'client/constants/health';
import type { GetSubsidyEstimateType } from 'routes/health/queries';

export const checkApplicants = (estimate: GetSubsidyEstimateType, members: Array<Object>) => {
  const medicaidMembers = [];
  const subsidyMembers = [];
  const ineligibleMembers = [];
  const medicaidGapMembers = [];
  const FPL_THRESHOLD = 1;

  let subsidyType = SUBSIDY_TYPES.SOME_MEDICAID;

  _.forEach(members, (member, index) => {
    const eligibility = estimate.eligibilities[index];

    if (eligibility === 'medicaid') {
      medicaidMembers.push(member);
    } else if (eligibility === 'subsidy' && estimate.aptc >= 0) {
      subsidyMembers.push(member);
    } else if (eligibility === 'ineligible' && estimate.fpl < FPL_THRESHOLD) {
      medicaidGapMembers.push(member);
    } else {
      ineligibleMembers.push(member);
    }
  });

  if (medicaidMembers.length === members.length) {
    subsidyType = SUBSIDY_TYPES.ALL_MEDICAID;
  } else if (subsidyMembers.length === members.length) {
    subsidyType = SUBSIDY_TYPES.ALL_SUBSIDY;
  } else if (medicaidGapMembers.length === members.length) {
    subsidyType = SUBSIDY_TYPES.MEDICAID_GAP;
  } else if (ineligibleMembers.length === members.length) {
    subsidyType = SUBSIDY_TYPES.NO_SAVINGS;
  }

  return {
    medicaidGapMembers,
    medicaidMembers,
    subsidyMembers,
    ineligibleMembers,
    subsidyType,
  };
};

export const isEssentialPlanEligible = (estimate: GetSubsidyEstimateType) =>
  [CSR_LEVELS.NYEP1, CSR_LEVELS.NYEP2, CSR_LEVELS.NYEP3, CSR_LEVELS.NYEP4, CSR_LEVELS.NYEP200_250].includes(
    estimate.csr,
  );
