import { initialize, LDClient } from 'launchdarkly-js-client-sdk';

export const LD_READY = 'LD_READY';
export const LD_UPDATE_FLAGS = 'LD_UPDATE_FLAGS';

const updateChangedFlags = flags => ({
  type: LD_UPDATE_FLAGS,
  payload: flags,
});

export const initLD = () => (dispatch, getState) => {
  const {
    launchDarkly: { isLDReady, ldClient: ldStateInstance },
    siteConfigs,
    apolloFfmId,
  } = getState();

  const ldClientId = window?.__CONFIG__?.LAUNCH_DARKLY_CLIENT_KEY as string;
  const agency = siteConfigs?.brandSiteConfig?.name || 'stride';

  if (isLDReady) {
    return Promise.resolve<LDClient>(ldStateInstance);
  }

  const context = {
    kind: 'user',
    key: `agency__${agency}`,
    mxAgent: !!apolloFfmId,
  };

  const ldClient = initialize(ldClientId, context);

  return new Promise<LDClient>((resolve) => {
    function handleReady() {
      ldClient.on('change', (flags) => {
        dispatch(updateChangedFlags(flags));
      });

      dispatch({
        type: LD_READY,
        payload: ldClient,
      });

      resolve(ldClient);
    }

    ldClient.on('ready', handleReady);
  });
};
