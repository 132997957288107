// @flow

import React from 'react';

const HomeIcon = () => (
  <svg
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>home</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="Artboard"
        transform="translate(-42.000000, -151.000000)"
        stroke="#B8BCC3"
        strokeWidth="2">
        <g id="home" transform="translate(43.000000, 152.000000)">
          <path
            d="M0,7 L9,0 L18,7 L18,18 C18,19.1045695 17.1045695,20 16,20 L2,20 C0.8954305,20 0,19.1045695 0,18 L0,7 Z"
            id="Path" />
          <polyline id="Path" points="6 20 6 10 12 10 12 20" />
        </g>
      </g>
    </g>
  </svg>
);

export default HomeIcon;
