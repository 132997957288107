// @flow

export type CSRLevelType =
  | 'CS73'
  | 'CS77'
  | 'CS87'
  | 'CS94'
  | 'NYEP1'
  | 'NYEP2'
  | 'NYEP3'
  | 'NYEP4'
  | 'NYEP200_250';
export type CSRMapType = '01' | '04' | '05' | '06';
export type BenefitType =
  | 'ben_primary'
  | 'ben_specialist'
  | 'ben_preventive'
  | 'ben_er'
  | 'ben_outpatient'
  | 'ben_inpatient'
  | 'ben_urgent'
  | 'ben_imaging'
  | 'ben_labs'
  | 'ben_prenatal'
  | 'ben_delivery'
  | 'ben_postnatal'
  | 'ben_oon';
export type DrugType =
  | 'rx_generic'
  | 'rx_genericpreferred'
  | 'rx_brand'
  | 'rx_brandpreferred'
  | 'rx_specialty'
  | 'rx_specialtypreferred';
export type ForecastMethodType = 'LOGIT_REGRESSION' | 'NEAREST_NEIGHBORS' | 'USAGE_LEVEL';
export type GenderType = 'MALE' | 'FEMALE';
export type HouseholdType = 'single' | 'family';
export type HealthPlanCardViewType = 'compare' | 'full';
export type MemberApplicationType = 'coverage' | 'household' | 'medicaid';
export type MemberType = 'primary' | 'spouse' | 'dependent';
export type MetalType = 'bronze' | 'catastrophic' | 'gold' | 'platinum' | 'silver';
export type PlanFlagType = 'recommended' | 'csr' | 'goodDeal' | 'lowest' | 'lowestAlternative' | 'similar';
export type ExchangeType = 'TRUE' | 'FALSE' | 'CROSS';
export type FilterCategoryType = 'carrier' | 'hsaStatus' | 'metalType' | 'planType';
export type SubsidyType = {
  amount: number,
  csr: CSRLevelType,
  fpl: number,
};

export type ConditionsType = 'adhd' | 'art' | 'ast' | 'cold' | 'copd' | 'dep' | 'dia' | 'hd' | 'bp';
export type UsageLevelType = 'LOW' | 'MEDIUM' | 'HIGH' | null;
export type ProfileSectionType =
  | 'applicants'
  | 'conditions'
  | 'doctors'
  | 'drugs'
  | 'household'
  | 'income'
  | 'recalculate'
  | 'usage';
export type ProviderProductType = 'HEALTH' | 'DENTAL';

export class HEALTH_PLAN_CARD_VIEW_TYPES {
  static COMPARE: HealthPlanCardViewType = 'compare';
  static FULL: HealthPlanCardViewType = 'full';
}

export class GENDERS {
  static MALE: GenderType = 'MALE';
  static FEMALE: GenderType = 'FEMALE';
}

export class FILTER_CATEGORY_TYPES {
  static CARRIER: FilterCategoryType = 'carrier';
  static HSA_STATUS: FilterCategoryType = 'hsaStatus';
  static METAL_TYPE: FilterCategoryType = 'metalType';
  static PLAN_TYPE: FilterCategoryType = 'planType';
}

export class MEMBER_TYPES {
  static PRIMARY: MemberType = 'primary';
  static SPOUSE: MemberType = 'spouse';
  static DEPENDENT: MemberType = 'dependent';
}

export class MEMBER_APPLICATION_TYPES {
  static COVERAGE: MemberApplicationType = 'coverage';
  static HOUSEHOLD: MemberApplicationType = 'household';
  static MEDICAID: MemberApplicationType = 'medicaid';
}

export class HOUSEHOLD_TYPES {
  static SINGLE: HouseholdType = 'single';
  static FAMILY: HouseholdType = 'family';
}

export class METAL_TYPES {
  static BRONZE: MetalType = 'bronze';
  static CATASTROPHIC: MetalType = 'catastrophic';
  static GOLD: MetalType = 'gold';
  static PLATINUM: MetalType = 'platinum';
  static SILVER: MetalType = 'silver';
}

/**
 * Order of the metal type. Do not sort.
 */
export const METAL_TYPE_ORDERS = [
  METAL_TYPES.CATASTROPHIC,
  METAL_TYPES.BRONZE,
  METAL_TYPES.SILVER,
  METAL_TYPES.GOLD,
  METAL_TYPES.PLATINUM,
];

export class CSR_LEVELS {
  static CS73: CSRLevelType = 'CS73';
  static CS87: CSRLevelType = 'CS87';
  static CS94: CSRLevelType = 'CS94';
  static NYEP1: CSRLevelType = 'NYEP1';
  static NYEP2: CSRLevelType = 'NYEP2';
  static NYEP3: CSRLevelType = 'NYEP3';
  static NYEP4: CSRLevelType = 'NYEP4';
  static NYEP200_250: CSRLevelType = 'NYEP200_250';
}

export class EXCHANGE_TYPES {
  static TRUE: ExchangeType = 'TRUE';
  static FALSE: ExchangeType = 'FALSE';
  static CROSS: ExchangeType = 'CROSS';
}

export class PLAN_FLAGS {
  static ALTERNATE: PlanFlagType = 'lowestAlternative';
  static CHEAPEST: PlanFlagType = 'lowest';
  static CSR: PlanFlagType = 'csr';
  static GOOD_DEAL: PlanFlagType = 'goodDeal';
  static RECOMMENDED: PlanFlagType = 'recommended';
  static SIMILAR: PlanFlagType = 'similar';
}

export class INCOME_LEVELS {
  static ABOVE: string = 'above';
  static BELOW: string = 'below';
}

export class ELIGIBILITY_STATUSES {
  static INELIGIBLE: string = 'ineligible';
  static MEDICAID: string = 'medicaid';
  static SUBSIDY: string = 'subsidy';
}

export class ELIGIBILITIES {
  static subsidy: string = 'Eligible for Subsidy';
  static medicaidHousehold: string = 'Medicaid Eligible Household';
  static medicaidMember: string = 'Members dropped to Medicaid / CHIP';
}

export class SUBSIDY_TYPES {
  static ALL_MEDICAID = 'allMedicaid';
  static ALL_SUBSIDY = 'allSubsidy';
  static MEDICAID_GAP = 'medicaidGap';
  static NO_SAVINGS = 'noSavings';
  static SOME_MEDICAID = 'someMedicaid';
}

export class FORECAST_METHODS {
  static LOGIT_REGRESSION: ForecastMethodType = 'LOGIT_REGRESSION';
  static NEAREST_NEIGHBORS: ForecastMethodType = 'NEAREST_NEIGHBORS';
  static USAGE_LEVEL: ForecastMethodType = 'USAGE_LEVEL';
}

export class CONDITIONS {
  static ADHD: ConditionsType = 'adhd';
  static ARTHRITIS: ConditionsType = 'art';
  static ASTHMA: ConditionsType = 'ast';
  static CHOLESTEROL: ConditionsType = 'cold';
  static COPD: ConditionsType = 'copd';
  static DEPRESSION: ConditionsType = 'dep';
  static DIABETES: ConditionsType = 'dia';
  static HEART_DISEASE: ConditionsType = 'hd';
  static HIGH_BLOOD_PRESSURE: ConditionsType = 'bp';
}

export class USAGE_LEVELS {
  static LOW: UsageLevelType = 'LOW';
  static MEDIUM: UsageLevelType = 'MEDIUM';
  static HIGH: UsageLevelType = 'HIGH';
}

export class PROFILE_SECTIONS {
  static APPLICANTS: ProfileSectionType = 'applicants';
  static DOCTORS: ProfileSectionType = 'doctors';
  static DRUGS: ProfileSectionType = 'drugs';
  static HOUSEHOLD: ProfileSectionType = 'household';
  static CONDITIONS: ProfileSectionType = 'conditions';
  static INCOME: ProfileSectionType = 'income';
  static RECALCULATE: ProfileSectionType = 'recalculate';
  static USAGE: ProfileSectionType = 'usage';
}

export const MAX_DEPENDENT_COUNT: number = 20;

export class BENEFITS {
  static PRIMARY: BenefitType = 'ben_primary';
  static SPECIALIST: BenefitType = 'ben_specialist';
  static PREVENTIVE: BenefitType = 'ben_preventive';
  static ER: BenefitType = 'ben_er';
  static OUT_PATIENT: BenefitType = 'ben_outpatient';
  static IN_PATIENT: BenefitType = 'ben_inpatient';
  static URGENT: BenefitType = 'ben_urgent';
  static IMAGING: BenefitType = 'ben_imaging';
  static LABS: BenefitType = 'ben_labs';
  static PRENATAL: BenefitType = 'ben_prenatal';
  static DELIVERY: BenefitType = 'ben_delivery';
  static POSTNATAL: BenefitType = 'ben_postnatal';
  static OON: BenefitType = 'ben_oon';
}

export class DRUGS {
  static GENERIC: DrugType = 'rx_generic';
  static GENERIC_PREFERRED: DrugType = 'rx_genericpreferred';
  static BRAND: DrugType = 'rx_brand';
  static BRAND_PREFERRED: DrugType = 'rx_brandpreferred';
  static SPECIALTY: DrugType = 'rx_specialty';
  static SPECIALTY_PREFERRED: DrugType = 'rx_specialtypreferred';
}

export const TRANSLATION_SWITCH_PATHS = [
  '/intro/state',
  '/intro/coverage',
  '/profile/location/zipcode',
  '/profile/location/counties',
  '/profile/applicants',
  '/profile/conditions',
  '/profile/conditions/edit',
  '/profile/deadline',
  '/profile/drugs',
  '/profile/drugs/edit',
  '/profile/drugs/edit/quantity',
  '/profile/subsidy/start',
  '/profile/subsidy/household',
  '/profile/subsidy/income',
  '/profile/doctors',
  '/profile/doctors/search',
  '/profile/doctors/save',
  '/profile/usage',
  '/profile/account',
  '/profile/account/reset',
  '/profile/subsidy/qualify',
];

export class PROVIDER_PRODUCT_TYPE {
  static HEALTH = 'HEALTH';
  static DENTAL = 'DENTAL';
  static HEALTH_VALUE = 'health';
  static DENTAL_VALUE = 'dental';
}
