// @flow

import React from 'react';

const UsersIcon = () => (
  <svg
    width="24px"
    height="21px"
    viewBox="0 0 24 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>users</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="Artboard"
        transform="translate(-171.000000, -154.000000)"
        stroke="#B8BCC3"
        strokeWidth="2">
        <g id="users" transform="translate(172.000000, 155.000000)">
          <path
            d="M16.5,18 L16.5,16.125 C16.5,14.0539322 14.6531746,12.375 12.375,12.375 L4.125,12.375 C1.84682541,12.375 0,14.0539322 0,16.125 L0,18"
            id="Path" />
          <ellipse id="Oval" cx="8.25" cy="3.9375" rx="3.85" ry="3.9375" />
          <path
            d="M22,18 L22,16.0834753 C21.9985043,14.3366759 20.6414987,12.8116995 18.7,12.375"
            id="Path" />
          <path
            d="M15.4,0 C17.3418296,0.460478978 18.7,2.08102326 18.7,3.9375 C18.7,5.79397674 17.3418296,7.41452102 15.4,7.875"
            id="Path" />
        </g>
      </g>
    </g>
  </svg>
);

export default UsersIcon;
