// @flow

import React from 'react';

const PrescriptionIcon = ({ height, width }: { height: string, width: string }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>trash</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="Artboard"
        transform="translate(-83.000000, -152.000000)"
        stroke="#B8BCC3"
        strokeWidth="2">
        <g id="trash" transform="translate(84.000000, 153.000000)">
          <polyline id="Path" points="0 4 2 4 18 4" />
          <path
            d="M16,4 L16,18 C16,19.1045695 15.1045695,20 14,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,4 M0,4 L0,2 C0,0.8954305 2.01471863,0 4.5,0 L13.5,0 C15.9852814,0 18,0.8954305 18,2 L18,4"
            id="Shape" />
        </g>
      </g>
    </g>
  </svg>
);

PrescriptionIcon.defaultProps = {
  height: '22px',
  width: '20px',
};

export default PrescriptionIcon;
