// @flow

import React from 'react';

const TargetIcon = () => (
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>x-circle (1) copy</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.721569 0 0 0 0 0.737255 0 0 0 0 0.764706 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g
      id="Prototype---MVP-Milestone-2-Desktop--V9"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g id="d_personalize_all_conditions_option" transform="translate(-687.000000, -318.000000)">
        <g filter="url(#filter-1)" id="x-circle-(1)-copy">
          <g transform="translate(688.000000, 319.000000)">
            <circle
              id="Oval"
              stroke="#000000"
              strokeWidth="1.66666667"
              cx="7.35294118"
              cy="7.35294118"
              r="7.35294118" />
            <line
              x1="9.55882353"
              y1="5.14705882"
              x2="5.14705882"
              y2="9.55882353"
              id="Path"
              stroke="#000000"
              strokeWidth="1.66666667" />
            <line
              x1="5.14705882"
              y1="5.14705882"
              x2="9.55882353"
              y2="9.55882353"
              id="Path"
              stroke="#000000"
              strokeWidth="1.66666667" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default TargetIcon;
