// @flow

import React, { useContext, useReducer } from 'react';

const InfoModalStateContext = React.createContext();
const SHOW_MODAL = 'showModal';
const INITIATE_CLOSE = 'initiateClose';
const HIDE_MODAL = 'hideModal';

const infoModalReducer = (state, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        isModalVisible: true,
        closeInitiated: false,
        variant: action.variant,
      };
    }
    case INITIATE_CLOSE: {
      return { ...state, closeInitiated: true };
    }
    case HIDE_MODAL: {
      return { ...state, isModalVisible: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const InfoModalProvider = ({ children }: { children: React.Node }) => {
  const [state, dispatch] = useReducer(infoModalReducer, {
    isModalVisible: false,
    closeInitiated: false,
    variant: undefined,
  });
  return (
    <InfoModalStateContext.Provider value={[state, dispatch]}>
      {children}
    </InfoModalStateContext.Provider>
  );
};

export const useInfoModalState = () => {
  const [state] = useContext(InfoModalStateContext);
  if (state === undefined) {
    throw new Error('useInfoModalState must be used within an InfoModalStateContext Provider');
  }
  return state;
};

export const useInfoModalDispatch = () => {
  const [, dispatch] = useContext(InfoModalStateContext);
  if (dispatch === undefined) {
    throw new Error('useInfoModalDispatch must be used within an InfoModalStateContext Provider');
  }
  const handleModalShow = variant => dispatch({ type: SHOW_MODAL, variant });
  const initiateModalClose = () => dispatch({ type: INITIATE_CLOSE });
  const hideModal = () => dispatch({ type: HIDE_MODAL });

  return {
    handleModalShow,
    hideModal,
    initiateModalClose,
  };
};
