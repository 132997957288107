import { RECEIVE_USER_DATA } from '../actions/user';

export default function user(state = {}, action) {
  switch (action.type) {
    case RECEIVE_USER_DATA: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
}
