import UserService from 'web-core/src/user-service';
import TaxUserService from 'web-core/src/tax-user-service';

export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';

export function receiveUserData(data) {
  return {
    type: RECEIVE_USER_DATA,
    data,
  };
}

export function requestReset({ email, redirect, source }) {
  // this sends the user back to this experience (/account/reset)
  const newExp = true;
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.requestReset({ email, redirect, source, newExp });
  };
}

export function resetPassword({ code, ...body }) {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.resetPassword(code, body);
  };
}

export function fetchUser() {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.get().then((response) => {
      if (response.status === 'OK' && response.user) {
        dispatch(receiveUserData(response.user));
        return response.user;
      }
      return null;
    });
  };
}

export function createWithResources(resources) {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.createWithResources(resources).then((response) => {
      dispatch(receiveUserData(response.user));
      return response;
    });
  };
}

export function login({ email, password, source }) {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.login({ email, password, source }).then((user) => {
      if (user.status === 'ERROR') {
        return Promise.reject(user);
      }
      dispatch(receiveUserData(user));
      return user;
    });
  };
}

export function loginWithResources(resources) {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.loginWithResources(resources).then((user) => {
      if (user.status === 'ERROR') {
        return Promise.reject(user);
      }
      dispatch(receiveUserData(user));
      return user;
    });
  };
}

export function save(user) {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.post(user).then((data) => {
      if (data.status === 'ERROR') {
        return Promise.reject(data);
      }
      dispatch(receiveUserData(data.user));
      return data.user;
    });
  };
}

export function fetchTaxUserStatus() {
  return (dispatch, getState, { apiClient }) => {
    const taxUserService = new TaxUserService({ client: apiClient });
    return taxUserService.getTaxUser().then((taxUser) => {
      dispatch(receiveUserData({ taxUser }));
      return taxUser;
    });
  };
}

export function changeEmail({ newEmail, oldEmail, password }) {
  return (dispatch, getState, { apiClient }) => {
    const userService = new UserService({ client: apiClient });
    return userService.changeEmail({ newEmail, oldEmail, password }).then((data) => {
      dispatch(receiveUserData(data));
      return data;
    });
  };
}
