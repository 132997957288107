// @flow

import React, { PureComponent } from 'react';

import 'client/styles/error.scss';

type Props = {
  contentfulData: {
    errorMessage: string,
    help: string,
    imgURL: string,
  },
};

class UnprocessableEntityPage extends PureComponent<Props> {
  renderSupportMessage = () => {
    const {
      contentfulData: { help },
    } = this.props;

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: help,
        }} />
    );
  };
  render() {
    const {
      contentfulData: { errorMessage, imgURL },
    } = this.props;
    return (
      <div className="error-page page-dne">
        <div className="error-bg-container">
          <div className="process-error">
            <img alt="process-error" src={imgURL} />
          </div>
        </div>

        <div className="text-center">
          <span>{errorMessage}</span>
          {this.renderSupportMessage()}
        </div>
      </div>
    );
  }
}

export default UnprocessableEntityPage;
