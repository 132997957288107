import _ from 'lodash';
import TranslationService from 'web-core/src/translation-service';
import conf from 'client/conf';
import { REQUEST_TRANSLATIONS, RECEIVE_TRANSLATIONS } from '../constants/translationTypes';

function fetchTranslateKey(key) {
  return {
    type: REQUEST_TRANSLATIONS,
    key,
  };
}

export function receiveTranslations(data, key) {
  return {
    type: RECEIVE_TRANSLATIONS,
    data,
    key,
  };
}

export function fetchTranslateKeyIfNeeded(key, isClient) {
  return (dispatch, getState, { apiClient }) => {
    const state = getState();
    if (!_.includes(state.translations.fetching, key) && _.isEmpty(state.translations[key])) {
      dispatch(fetchTranslateKey(key));

      const locale = _.get(state, 'user.locale', 'en-US');

      const svc = new TranslationService({
        client: apiClient,
        conf: {
          path: {
            translateBase: conf.STATIC_BASE,
          },
        },
      });
      return svc
        .getTranslation({
          key,
          isLocal: __IS_LOCAL__,
          locale,
          options: isClient
            ? {}
            : {
              cache: {
                server: {
                  only: true,
                  durationSeconds: __IS_LOCAL__ ? 1 : 600,
                },
              },
            },
        })
        .then(data => dispatch(receiveTranslations(data, key)));
    }

    return null;
  };
}
