const MARKETPLACE_URL_BASE_PATHS = {
  prod: 'https://marketplace-api.stridehealth.com',
  staging: 'https://staging-marketplace-api.stridehealth.com',
  dev: 'https://dev-marketplace-api.stridehealth.com',
};

const getMarketplaceBaseUrlByEnv = (env) => {
  if (env === 'local' || env.startsWith('preview')) {
    return MARKETPLACE_URL_BASE_PATHS.dev;
  }

  return MARKETPLACE_URL_BASE_PATHS[env];
};

const LOCAL_STATIC_BASE = __ASSET_PATH__;
const STATIC_BASE = `${__ASSET_PATH__}/${__BUILD_VERSION__}`;

// const conf = appConf;
const conf = {};

const isProdButNotProdStaging = __IS_PROD__ && !__IS_PROD_STAGING__;

conf.ENV = typeof window !== 'undefined' ? window.__CONFIG__.environment : process.env.ENVIRONMENT;
conf.STATIC_BASE = __IS_LOCAL__ ? LOCAL_STATIC_BASE : STATIC_BASE;
conf.DOCUMENT_BUCKET = `document.${isProdButNotProdStaging ? '' : 'dev-'}www.stridehealth.com`;
conf.BRANCH_KEY = 'key_live_egh4cYidygaBMNd1pTP9hmjfDDoyBtmM';
conf.DRIVE_BRANCH_URL = 'https://stride.app.link/g4wEYJhAHB';
conf.CONTENTFUL_SPACE_URL = 'https://images.ctfassets.net/heg7gyzix6dq';
// this is the fallback URL, FFM_URL_SAML is set in AWS env variable
conf.FFM_URL = isProdButNotProdStaging
  ? 'https://www.healthcare.gov/'
  : 'https://uat0.healthcare.gov/?ACA=kbV0uKB8cG';
/* eslint-disable no-nested-ternary */

conf.MARKETPLACE_BASE_URL = getMarketplaceBaseUrlByEnv(conf.ENV);

conf.MARKETPLACE_URL = `${conf.MARKETPLACE_BASE_URL}/graphql`;

conf.STRIDE_WEB_API_BASE_URL =
  conf.ENV === 'prod'
    ? 'https://web-api.stridehealth.com/'
    : `https://web-api.${conf.ENV === 'staging' ? 'staging' : 'dev'}.stridehealth.io/`;

if (typeof window === 'undefined' && process.env.STRIDE_WEB_API_BASE_URL) {
  conf.STRIDE_WEB_API_BASE_URL = process.env.STRIDE_WEB_API_BASE_URL;
}

conf.STRIDE_WEB_API_URL = `${conf.STRIDE_WEB_API_BASE_URL}graphql`;

conf.OPTIMIZELY_SDK_KEY = isProdButNotProdStaging
  ? 'YXYQTbm8azFBDAQQtfjxd9'
  : 'E9CGwfFMN6istedqBQJYxY';

conf.GOOGLE_MAP_API_KEY = 'AIzaSyDWWhB_txB-UYSFFWbGcZzM-vpYsdWArHM';
conf.STRIDE_PRE_REGISTRATION_BASE_URL = `https://pre-registration.${
  conf.ENV === 'prod' ? 'prod' : 'dev'
}.stridehealth.io`;

if (typeof window === 'undefined' && process.env.PRE_REGISTRATION_BASE_URL) {
  conf.STRIDE_PRE_REGISTRATION_BASE_URL = process.env.PRE_REGISTRATION_BASE_URL;
}

export default conf;
