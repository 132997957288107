// @flow

import _ from 'lodash';

// import { isComplete } from 'routes/marketplace-ede/utils/member';
import {
  getSSNAlternateName,
  getMemberLawfulPresenceAttestations,
  getMemberDob,
  getMemberSex,
  isAIAN,
} from 'routes/marketplace-ede/utils/selectors';
import UpdateMemberLawfulPresenceModel from 'routes/marketplace-ede/components/member/models/updateMemberLawfulPresenceModel';
import { isoToSlashDate } from 'routes/marketplace-ede/web-ui/utils/format';
import { formatPhoneNumber } from 'client/utils/format';

import type {
  EdeMemberType,
  MemberAttestationDemographicType,
} from 'routes/marketplace-ede/types/ede/edeMember.flow';
import type { ToggleItemType } from 'routes/marketplace-ede/types/toggleItemType.flow';
import type { UpdateMemberLawfulPresenceModelType } from 'routes/marketplace-ede/components/member/models/updateMemberLawfulPresenceModel';

/*
Four potential states for Optional SSN fields:
(1) Applicant inputs SSN, attests to SSN name matching form field name
(2) Applicant inputs SSN, attests to SSN name *not* matching form field name, but
has yet to attest to what that name is. (**primary only**, this happens because in
our funnel we have not yet created an EDE application at /background/primary, so
we wait to ask the question until /household/members)
(3) Applicant inputs SSN, attests to SSN name *not* matching form field name, and
attests to SSN alternateName
(4) Applicant does not input SSN and continues without SSN
*/
// export const isCompleteWithOptionalSSN = (
//   member: EdeMemberType,
//   coverageStatus: string,
//   primaryNameMatchesSSNName: boolean | null
// ) => {
//   if (isComplete(member, coverageStatus) === false) {
//     return false;
//   }

//   if (member.type === 'primary') {
//     return (
//       primaryNameMatchesSSNName !== false ||
//       (primaryNameMatchesSSNName === false &&
//         !!getSSNAlternateName(member))
//     );
//   }

//   return member.type !== 'primary' && member.first_name != null;
// };

// export const hasDuplicateSSN = (edeMembers: Array<EdeMemberType>) => {
//   const ssnMap = {};
//   return _.some(edeMembers, (member) => {
//     // Already saved SSN will be decrypted to XXX-XXX-1111
//     // Since the likelihood for same household members with same last 4 digit is low,
//     // we only compared the last 4 digits to check duplicated
//     const lastFourDigit = member.ssn ? member.ssn.slice(-4) : null;
//     if (lastFourDigit) {
//       if (ssnMap[lastFourDigit]) {
//         return true;
//       }
//       ssnMap[lastFourDigit] = member.ssn;
//     }
//     return false;
//   });
// };

type MappedMembersType = Array<ToggleItemType>;
type PersistEdeMemberLawfulPresenceArgType = {
  allMembers: Array<EdeMemberType>,
  updatedMembers: Array<EdeMemberType>,
};

export const persistEdeMemberLawfulPresencePayload = ({
  allMembers,
  updatedMembers,
}: PersistEdeMemberLawfulPresenceArgType): Array<UpdateMemberLawfulPresenceModelType> => {
  const memberToPersist = _.filter(
    allMembers,
    member => !_.find(updatedMembers, m => m.memberIdentifier === member.memberIdentifier),
  );
  return _.map(
    memberToPersist,
    member =>
      new UpdateMemberLawfulPresenceModel({
        memberIdentifier: member.memberIdentifier,
        lawfulPresencePayload: {
          ...getMemberLawfulPresenceAttestations(member),
        },
      }),
  );
};

export const getEdeMemberDemographic = (member: EdeMemberType): MemberAttestationDemographicType =>
  _.get(member, 'attestations.demographic', {});

export const getSsnAltName = (member) => {
  const { firstName, lastName, middleName, suffix } = getSSNAlternateName(member);
  return [firstName, middleName, lastName, suffix].filter(n => n).join(' ');
};

export const getEdeMemberFullName = (member: EdeMemberType) => {
  const {
    name: { firstName, middleName, lastName, suffix },
  } = getEdeMemberDemographic(member);
  return [firstName, middleName, lastName, suffix].filter(n => n).join(' ');
};

export const getEdeMemberSubtitle = (member: EdeMemberType, memberTranslations: Object): string => {
  const dob = getMemberDob(member);
  const formattedDob = isoToSlashDate(dob);

  const genderOptions = _.get(memberTranslations, 'form.sex.options', []);
  const gender = getMemberSex(member);
  const formattedGender =
    gender === 'MALE' ? _.get(genderOptions[0], 'label') : _.get(genderOptions[1], 'label');

  return `${formattedGender} - ${formattedDob}`;
};

// returns true IF income > 0 and options are not pensions or socialSecurity
export const shouldTribalIncomeSectionRender = (member, selectedType, incomeAmount) => {
  const nonRenderableIncomeOptions = ['income.unemployment', 'income.socialSecurity'];

  return (
    isAIAN(member) &&
    selectedType &&
    !_.includes(nonRenderableIncomeOptions, selectedType) &&
    parseFloat(incomeAmount) > 0
  );
};

export const getPrimaryMember = (edeApp) => {
  const primaryId = _.get(edeApp, 'attestations.application.contactMemberIdentifier');

  if (!primaryId) {
    return null;
  }
  const member = edeApp.edeMembers.find(({ memberIdentifier }) => memberIdentifier === primaryId);

  return member;
};

export const getEdeMemberEmployerNumber = (
  member: EdeMemberType,
  employerName: string,
): { [key: string]: string } => {
  const allEmployers = _.get(
    member,
    'attestations.insuranceCoverage.employerSponsoredCoverageOffers',
    [],
  );
  const selectedEmployer = allEmployers.find(employer => employer.employer.name === employerName);
  return {
    employerPhoneNumber: selectedEmployer
      ? formatPhoneNumber(selectedEmployer.employer?.employerPhoneNumber)
      : '',
    employerContactNumber: selectedEmployer
      ? formatPhoneNumber(selectedEmployer.employer.contact?.phoneNumber)
      : '',
  };
};
