// @flow

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

import { FeatherEditIcon } from 'client/components/icons/index';
import { StyledNavLink } from 'client/components/nav/navPhoneButton';
import Translate from 'client/components/translate';

import { trackProfileIconClick } from 'routes/health/utils/trackings';
import { goToProfileWithRedirectUrl } from 'routes/health/utils/routes';

import type { LocationType, RouterType } from 'types/react-router.flow';

type Props = {
  location: LocationType,
  router: RouterType,
};

class NavProfileButton extends PureComponent<Props> {
  render() {
    const {
      location: { pathname: sourcePathname },
      router,
    } = this.props;
    return (
      <li className="nav-link">
        <StyledNavLink
          className="transparent"
          onClick={(e) => {
            trackProfileIconClick();
            goToProfileWithRedirectUrl(e, router, sourcePathname);
          }}>
          <div className="flex-column">
            <FeatherEditIcon />
            <div className="text-center">
              <span>
                <Translate path="common.account.edit" />
              </span>
            </div>
          </div>
        </StyledNavLink>
      </li>
    );
  }
}

export default withRouter(NavProfileButton);
