/* eslint-disable react/jsx-closing-tag-location */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


type Props = {
  location: string, // header / footer
  hideExtole: boolean,
};

const footerStyle = {
  float: 'left',
  position: 'relative',
  padding: '0px 0px 0px 7px',
};
const footerClass = 'full-footer-a';

const headerStyle = {};
const headerClass = '';

const ExtoleReferralSimpleButtonCardF = (props: Props) => {
  const { hideExtole } = props;
  const [extoleIntegration, setExtoleIntegration] = useState(null);

  useEffect(() => {
    setExtoleIntegration(window?.__INITIAL_STATE__?.useExtoleIntegration || null);
  }, []);

  return extoleIntegration && !hideExtole ? (
      <span
        id={`ExtoleReferralLinkSpan-${props.location}`}
        style={props.location === 'header' ? headerStyle : footerStyle}>
        <a
          className={props.location === 'header' ? headerClass : footerClass}
          href="https://share.stridehealth.com/"
          target="_new">
          Refer a Friend
        </a>
      </span>) : null;
};

const mapStateToProps = (state) => {
  return {
    hideExtole: !!state.siteConfigs?.brandSiteConfig?.partnerConfig?.agencyInfo?.hideExtole
  };
};

export const ExtoleReferralSimpleButtonCard = connect(mapStateToProps, null)(ExtoleReferralSimpleButtonCardF);
