import _ from 'lodash';
import { sumFinance } from 'client/utils/member';
import {
  getMemberAttestedAnnualizedAptcIndividualIncomeAmt,
  getMemberEstimatedForAptcCurrentIncome,
  isMemberIncomeVariable,
  isMemberIncomeHardToPredict,
} from 'routes/marketplace/reducers/selectors';
import type { Finance } from 'web-core/types/finance';

export function attachBaseMultiplier(finances = [], periodOptions) {
  return _.map(finances, (f) => {
    const periodOption = _.find(periodOptions, ({ value }) => value === +f.freq_id);
    const unit = _.get(periodOption, 'unit');
    const baseMultiplier = _.isNull(unit) ? 1 : _.get(periodOption, 'baseMultiplier', 1);
    return {
      ...f,
      baseMultiplier: _.toNumber(baseMultiplier),
    };
  });
}

export type MemberIncomeType = {
  annualEstimate?: number,
  hardToPredict?: boolean,
  hasDeduction?: boolean,
  hasIncome?: boolean,
  id: string,
  totalConfirmation?: boolean,
  [key: string]: Finance,
};

const OTHER = 'OTHER';

// These key names map to our member_finance table column names
const ANNUAL_TAX_INCOME_KEY = 'annualTaxIncome';
const CURRENT_INCOME_KEY = 'currentIncome';
const EMP_NAME_FIELD_NAME = 'emp_name';
const EXP_DATE = 'unemp_exp_date';
const FREQ_ID_FIELD_NAME = 'freq_id';
const NAME_FIELD_NAME = 'name';
export const [YES_VALUE, NO_VALUE] = [true, false];
export const ANNUAL_ESTIMATE_FIELD_NAME = 'annualEstimate';
export const DEDUCTION_TYPE = 'deduction';
export const HARD_TO_PREDICT = 'hardToPredict';
export const HAS_DEDUCTION_FIELD_NAME = 'hasDeduction';
export const HAS_INCOME_FIELD_NAME = 'hasIncome';
export const INCOME_TYPE = 'income';
export const TOTAL_CONF_FIELD_NAME = 'totalConfirmation';
export const TYPE_FIELD_NAME = 'source_key';
export const ID_PREFIX = 'z';

export function flattenFinances(mfsAcc, memberData) {
  if (!_.isArray(mfsAcc)) {
    /* eslint-disable no-param-reassign */
    memberData = mfsAcc;
    mfsAcc = [];
    /* eslint-enable no-param-reassign */
  }
  return _.reduce(
    memberData,
    (acc, data, key) => {
      if (_.isObject(data) && data && key.match(/^z\d+$/)) {
        acc.push(data);
      }
      return acc;
    },
    mfsAcc,
  );
}

export function sortIncomesAndDeductions(finances) {
  return _.reduce(
    finances,
    (acc, finance) => {
      switch (finance.type) {
        case INCOME_TYPE:
          acc.incomes.push(finance);
          break;
        case DEDUCTION_TYPE:
          acc.deductions.push(finance);
          break;
        default:
      }
      return acc;
    },
    { incomes: [], deductions: [] },
  );
}

export function cleanFinances(finances, { incomes, deductions }) {
  return _.reduce(
    finances,
    (acc, { ifp_apps, member, member_finance_applications, ...f }) => {
      if (incomes && f.type === INCOME_TYPE) {
        acc.push(f);
      }
      if (deductions && f.type === DEDUCTION_TYPE) {
        acc.push(f);
      }
      return acc;
    },
    [],
  );
}

export function findDelIds(initialIds, nextIds) {
  return _.reduce(
    initialIds,
    (delIds, id) => {
      if (nextIds.indexOf(id) === -1) {
        delIds.push(id);
      }
      return delIds;
    },
    [],
  ).join(',');
}

export function appendMultiplier(finances, periodOptions) {
  return _.map(finances, (f) => {
    const periodOption = _.find(periodOptions, ({ value }) => value === +f.freq_id) || {};
    const unit = _.get(periodOption, 'unit');
    const baseMultiplier = _.isNull(unit) ? 1 : _.get(periodOption, 'baseMultiplier', 1);
    const multiplier = _.isNull(unit)
      ? _.get(periodOption, 'baseMultiplier', 1)
      : f.multiplier || 0;
    const amount = _.get(f, 'amount', '0');
    return {
      ...f,
      amount: amount.replace(/,/g, ''),
      multiplier,
      baseMultiplier,
    };
  });
}

const memberSum = (memberFinanceData, periodOptions) => {
  const memberFinances = flattenFinances(memberFinanceData);
  const hasIncomes = _.get(memberFinanceData, HAS_INCOME_FIELD_NAME) === YES_VALUE;
  const hasDeductions = _.get(memberFinanceData, HAS_DEDUCTION_FIELD_NAME) === YES_VALUE;
  const financesToSum =
    !hasIncomes || !hasDeductions
      ? cleanFinances(memberFinances, {
        incomes: hasIncomes,
        deductions: hasDeductions,
      })
      : memberFinances;
  const finalFinances = appendMultiplier(financesToSum, periodOptions);
  return sumFinance(finalFinances);
};

const annualIncomeMap = {
  annualIncomeTotal: 'incomeAmount',
  [TOTAL_CONF_FIELD_NAME]: 'variableIncomeIndicator',
  [HARD_TO_PREDICT]: 'unknownIncomeIndicator',
  recievedUnemploymentCompensation: 'receivedUnemploymentCompensationIndicator',
};

const currentIncomeMap = {
  incomeAmount: 'incomeAmount',
  [TYPE_FIELD_NAME]: 'incomeSourceType',
  [FREQ_ID_FIELD_NAME]: 'incomeFrequencyType',
  estimatedForAptcIndicator: 'estimatedForAptcIndicator',
  jobIncome: 'jobIncome',
  selfEmploymentIncomeDescription: 'selfEmploymentIncomeDescription',
  unemploymentIncome: 'unemploymentIncome',
};

const jobIncomeObj = {
  [EMP_NAME_FIELD_NAME]: 'employerName',
  DAILY: 'averageWeeklyWorkDays',
  HOURLY: 'averageWeeklyWorkHours',
};

const incomeTypeMap = {
  'income.wages': 'JOB',
  'income.business': 'SELF_EMPLOYMENT',
  'income.unemployment': 'UNEMPLOYMENT',
  'income.pensions': 'PENSION',
  'income.socialSecurity': 'SOCIAL_SECURITY_BENEFIT',
  'income.capGains': 'CAPITAL_GAINS',
  'income.dividends': 'INVESTMENT_INCOME',
  'income.retirementRollover': 'RETIREMENT',
  'income.alimony': 'ALIMONY_RECEIVED',
  'income.farmingFishing': 'FARMING_OR_FISHING_INCOME',
  'income.rental': 'RENTAL_OR_ROYALTY_INCOME',
  'income.lsDistributions': 'CASH_SUPPORT',
  'income.scholarship': 'SCHOLARSHIP',
  'income.gambling': 'PRIZES_AWARDS_GAMBLING_WINNINGS',
  'income.courtAwards': 'COURT_AWARDS',
  'income.juryDuty': 'JURY_DUTY_PAY',
  'income.canceledDebt': 'CANCELED_DEBT',
  'income.other': 'OTHER_INCOME',
  'deduction.studentLoan': 'STUDENT_LOAN_INTEREST',
  'deduction.alimonyPaid': 'ALIMONY_PAYMENT',
  'deduction.other': 'OTHER_DEDUCTION',
};

const frequencyMap = [
  'WEEKLY',
  'MONTHLY',
  'ANNUALLY',
  'ONE_TIME',
  'HOURLY',
  'DAILY',
  'BI_WEEKLY',
  'SEMI_MONTHLY',
  'QUARTERLY',
];

function duplicateScholarshipIncome(memberFinances, mFormData) {
  const scholarshipInc = _.filter(memberFinances, ['source_key', 'income.scholarship']);
  if (_.isEmpty(scholarshipInc)) {
    return memberFinances;
  }
  const clonedScholarship = _.cloneDeep(scholarshipInc);
  const mappedScholarship = _.map(clonedScholarship, (incObj) => {
    const matchingScholarship = _.find(
      mFormData,
      f => _.get(f, 'client_created_on') === incObj.client_created_on,
    );
    _.set(incObj, 'amount', _.get(matchingScholarship, 'education_amount'));
    _.set(incObj, 'educationAmount', true);
    return incObj;
  });
  return memberFinances.concat(mappedScholarship);
}

function mapPrevEdeMemberIncome(edeMembers) {
  return _.reduce(
    edeMembers,
    (acc, member) => {
      const { id } = member;
      const currentIncome = _.get(
        member,
        'ede_member.ede_payload.attestation.income.currentIncome',
      );
      if (!_.isEmpty(currentIncome)) {
        acc[id] = { ...currentIncome };
      }
      return acc;
    },
    {},
  );
}

export function mapScholarshipToAppJson(memberFinances, formData) {
  const scholarshipInc = _.filter(memberFinances, ['source_key', 'income.scholarship']);
  if (_.isEmpty(scholarshipInc)) {
    return undefined;
  }
  return _.reduce(
    scholarshipInc,
    (acc, incObj) => {
      const { client_created_on, id, member_id } = incObj;
      const vals = _.values(formData[member_id]);
      const matchingInc = _.find(
        vals,
        val => _.get(val, 'id') === id || _.get(val, 'client_created_on') === client_created_on,
      );
      acc[id] = _.get(matchingInc, 'education_amount');
      return acc;
    },
    {},
  );
}

export const parseNumber = (value: string | number) =>
  (typeof value === 'string' ? Number(value.replace(/[^\d-.]*/g, '')) : value);

export const mapMemberFinanceToEdePayload = ({
  formData,
  member_finances,
  periodOptions,
  edeMembers,
  shouldMapMemberFinanceForIncomePage,
}) => {
  const memberIds = _.keys(formData);
  const prevEdeIncomeEntry = mapPrevEdeMemberIncome(edeMembers);
  const mfs = _.reduce(
    memberIds,
    (appIncObj, memberId) => {
      const member = _.find(edeMembers, edeMember => edeMember.id === memberId);
      if (!member) {
        throw new Error(`Could not find member with id ${memberId} in members`);
      }
      const memberSESIncome = getMemberAttestedAnnualizedAptcIndividualIncomeAmt(member);
      const mAttest = _.get(formData, memberId);
      const userConfirmed = mAttest[TOTAL_CONF_FIELD_NAME] === YES_VALUE;
      const annualTaxIncome = {};
      /**
       * TODO: If we don't send TOTAL_CONF_FIELD_NAME and HARD_TO_PREDICT,
       * CMS won't return attestedAnnualizedAptcIndividualIncomeAmt to be used in confirmation page.
       * Decide whether to remove the two above in the income page when CMS gets back to us.
       */
      if (shouldMapMemberFinanceForIncomePage) {
        annualTaxIncome[annualIncomeMap.annualIncomeTotal] = memberSum(mAttest, periodOptions);
        annualTaxIncome[annualIncomeMap[TOTAL_CONF_FIELD_NAME]] = isMemberIncomeVariable(member);
        annualTaxIncome[annualIncomeMap[HARD_TO_PREDICT]] = isMemberIncomeHardToPredict(member);
      } else {
        annualTaxIncome[annualIncomeMap.annualIncomeTotal] = userConfirmed
          ? parseNumber(memberSESIncome)
          : parseNumber(mAttest[ANNUAL_ESTIMATE_FIELD_NAME]);
        // if TOTAL_CONF_FIELD_NAME = true, set variableIncome to false
        annualTaxIncome[annualIncomeMap[TOTAL_CONF_FIELD_NAME]] = !userConfirmed;
        annualTaxIncome[annualIncomeMap[HARD_TO_PREDICT]] = userConfirmed
          ? undefined
          : mAttest[HARD_TO_PREDICT];
      }

      // Set ARP unemployment indicator to false as default
      if (shouldMapMemberFinanceForIncomePage) {
        annualTaxIncome[annualIncomeMap.recievedUnemploymentCompensation] = false;
      }

      const mIncome = _.filter(member_finances, ['member_id', memberId]);
      const mIncomeScholarship = duplicateScholarshipIncome(mIncome, mAttest);
      const mIncomeFinal = appendMultiplier(mIncomeScholarship, periodOptions);
      const currentIncome = _.reduce(
        mIncomeFinal,
        (acc, finance, index) => {
          const { amount, multiplier = 1 } = finance;
          let edeIncomeAmount = amount;
          if (finance.type === 'deduction') {
            edeIncomeAmount *= -1;
          }
          const edeIncomeType = incomeTypeMap[finance[TYPE_FIELD_NAME]];
          const edeFrequency = frequencyMap[finance[FREQ_ID_FIELD_NAME] - 1];
          const keyName = `currentIncome${index}`;
          const currIncObj = {
            [currentIncomeMap.incomeAmount]: parseNumber(edeIncomeAmount),
            [currentIncomeMap[TYPE_FIELD_NAME]]: edeIncomeType,
            [currentIncomeMap[FREQ_ID_FIELD_NAME]]: edeFrequency,
          };

          // Set ARP Unemployment indictator to true if member has any unemployment income (default is set to false above)
          if (edeIncomeType === 'UNEMPLOYMENT') {
            annualTaxIncome[annualIncomeMap.recievedUnemploymentCompensation] = true;
          }

          if (annualTaxIncome[annualIncomeMap[HARD_TO_PREDICT]] === true) {
            _.set(currIncObj, currentIncomeMap.estimatedForAptcIndicator, false);
          }
          // Clears the jobIncome property for users editting incomes
          _.set(currIncObj, 'jobIncome', null);
          if (edeIncomeType === 'JOB') {
            const jobIncome = {
              [jobIncomeObj[EMP_NAME_FIELD_NAME]]: finance[EMP_NAME_FIELD_NAME],
            };
            if (edeFrequency === 'DAILY' || edeFrequency === 'HOURLY') {
              _.set(jobIncome, `[${jobIncomeObj[edeFrequency]}]`, multiplier);
            }
            _.set(currIncObj, 'jobIncome', jobIncome);
          } else if (edeIncomeType === 'SELF_EMPLOYMENT') {
            _.set(currIncObj, 'selfEmploymentIncomeDescription', finance[NAME_FIELD_NAME]);
          } else if (edeIncomeType === 'UNEMPLOYMENT') {
            _.set(currIncObj, 'unemploymentIncome', {
              expirationDate: finance[EXP_DATE],
              incomeDescription: finance[NAME_FIELD_NAME],
            });
          } else if (edeIncomeType === 'SCHOLARSHIP' && finance.educationAmount !== true) {
            /*
              Per CMS, total scholarship amount should be considered other income,
              amount towards education expenses is considered scholarship,
              both need separate income objects
            */
            _.set(currIncObj, currentIncomeMap[TYPE_FIELD_NAME], incomeTypeMap['income.other']);
          } else if (edeIncomeType === 'OTHER_DEDUCTION') {
            _.set(currIncObj, 'otherDeductionDescription', finance[NAME_FIELD_NAME]);
          }
          acc[keyName] = currIncObj;
          return acc;
        },
        {},
      );

      // If user attests to income hard to predict, we add another currentIncome obj
      if (annualTaxIncome[annualIncomeMap[HARD_TO_PREDICT]] === true) {
        const nextIndex = _.keys(currentIncome).length;
        const nextKeyName = `currentIncome${nextIndex}`;
        const existingAptcIncome = getMemberEstimatedForAptcCurrentIncome(member);
        if (shouldMapMemberFinanceForIncomePage && existingAptcIncome) {
          currentIncome[nextKeyName] = existingAptcIncome;
        } else {
          currentIncome[nextKeyName] = {
            [currentIncomeMap.incomeAmount]: annualTaxIncome[annualIncomeMap.annualIncomeTotal],
            [currentIncomeMap[TYPE_FIELD_NAME]]: 'OTHER_INCOME',
            [currentIncomeMap[FREQ_ID_FIELD_NAME]]: 'ANNUALLY',
            [currentIncomeMap.estimatedForAptcIndicator]: true,
          };
        }
      }

      // Clears currentIncome objects on edeApp if user changes incomes
      if (!_.isEmpty(prevEdeIncomeEntry[memberId])) {
        const prevIncKeys = _.keys(prevEdeIncomeEntry[memberId]);
        const keysToClear = _.difference(prevIncKeys, _.keys(currentIncome));
        if (!_.isEmpty(keysToClear)) {
          keysToClear.forEach((prevInc) => {
            currentIncome[prevInc] = null;
          });
        }
      }

      const memberIncObj = {
        [ANNUAL_TAX_INCOME_KEY]: annualTaxIncome,
        [CURRENT_INCOME_KEY]: currentIncome,
      };

      // workaround for previous claimed EDE apps that have this property, hopefully clears it
      // TODO(hum): Move this logic to mapPrevEdeMemberIncome when we have more testing time
      if (
        _.findIndex(
          edeMembers,
          m =>
            m.id === memberId &&
            _.has(m, 'ede_member.ede_payload.attestation.income.documentedAnnualIncome'),
        ) > -1
      ) {
        _.set(memberIncObj, 'documentedAnnualIncome', null);
      }

      const updates = { income: memberIncObj };
      const jobs = _.filter(mIncome, { source_key: 'income.wages' });
      // TODO: may need to create a escOffer1, escOffer2
      // similar to currentIncomes with multiple jobs
      // currently errors out when submitting escOffer1, escOffer2 for multiple jobs
      /*
        TODO(hum): when we pick up old apps with employer info user had offeredEmployeeCoverage: 'YES'
        on their old app, we don't null it if they don't have income.wages still. Fix would be
        to check if offeredEmployeeCoverage is set and 'NO' it if necessary
      */
      if (jobs.length) {
        _.set(updates, 'insuranceCoverage', {
          employerSponsoredCoverageOffers: {
            escOffer: {
              employer: {
                name: jobs[0].emp_name,
                employerPhoneNumber: jobs[0].emp_phone.replace(/[^\d]*/g, ''),
              },
            },
          },
          offeredEmployeeCoverage: 'NO',
        });
      }

      _.set(appIncObj, memberId, updates);
      return appIncObj;
    },
    {},
  );
  return _.reduce(
    mfs,
    (acc, incomeObj, memberId) => {
      acc.push({
        id: memberId,
        ede_payload: {
          member: incomeObj,
        },
      });
      return acc;
    },
    [],
  );
};

// TOOD
// export const mapMemberArpToEdePayload = ({
//   formData,
//   memberFinances,
//   periodOptions,
//   edeMembers,
// }) => {};

export function annualIncomeLess(computedPayload) {
  return _.get(computedPayload, 'income.annualIncomeExplanationRequired', false);
}

export function householdAnnualIncomeLess(applicationPayload) {
  const contactMemberIdentifier = _.get(
    applicationPayload,
    'attestations.application.contactMemberIdentifier',
  );
  const contactMemberAnnualIncome = _.get(
    applicationPayload,
    `computed.taxHouseholds.${contactMemberIdentifier}.annualIncome`,
  );

  return (
    _.get(contactMemberAnnualIncome, 'incomeExplanationRequiredIndicator', false) &&
    _.get(contactMemberAnnualIncome, 'incomeExplanationRequiredReasonType', '') ===
      'INCOME_LOWER_THAN_SOURCE'
  );
}

export function householdAnnualIncomeMore(applicationPayload) {
  const contactMemberIdentifier = _.get(
    applicationPayload,
    'attestations.application.contactMemberIdentifier',
  );
  const contactMemberAnnualIncome = _.get(
    applicationPayload,
    `computed.taxHouseholds.${contactMemberIdentifier}.annualIncome`,
  );

  return (
    _.get(contactMemberAnnualIncome, 'incomeExplanationRequiredReasonType', '') ===
    'INCOME_HIGHER_THAN_SOURCE'
  );
}

export function checkTaxfiler(appMember, applicationPayload) {
  const ffmAssignedId = _.get(appMember, 'ede_member.ffm_assigned_id');
  const contactMemberIdentifier = _.get(
    applicationPayload,
    'attestations.application.contactMemberIdentifier',
  );

  return ffmAssignedId === contactMemberIdentifier;
}

export function jobIncomeLess(computedPayload) {
  const employers = _.get(computedPayload, 'income.employer', []);
  return _.filter(
    employers,
    ({ jobIncomeExplanationRequiredIndicator }) => jobIncomeExplanationRequiredIndicator === true,
  );
}

export function variableIncome(attestedPayload) {
  return _.get(attestedPayload, 'income.annualTaxIncome.variableIncomeIndicator') === true;
}

export function hasDiscrepancy({ computedMemberPayload, applicationPayload }) {
  return (
    _.some(computedMemberPayload, annualIncomeLess) ||
    householdAnnualIncomeLess(applicationPayload) ||
    householdAnnualIncomeMore(applicationPayload) ||
    _.some(computedMemberPayload, m => !_.isEmpty(jobIncomeLess(m)))
  );
}

export const mapFinanceDiscrepancyToEdePayload = ({
  taxfilerId,
  formData,
  attestedMemberPayload,
  computedMemberPayload,
  applicationPayload,
}) =>
  _.map(attestedMemberPayload, (aMember) => {
    const { memberId } = aMember;
    const workingMemberPayload = aMember;
    const shouldUpdateAllTaxhousehold =
      householdAnnualIncomeLess(applicationPayload) ||
      householdAnnualIncomeMore(applicationPayload);

    // Set formVals to taxfilerFormVals, if we need to update all tax household members
    const taxfilerFormVals = shouldUpdateAllTaxhousehold && _.get(formData, taxfilerId);
    const formVals = _.get(formData, memberId, taxfilerFormVals);

    const currentInc = _.get(workingMemberPayload, 'income.currentIncome', {});
    const updatedCurrentInc = _.reduce(
      currentInc,
      (acc, currIncObj, key) => {
        const employerName = _.get(currIncObj, 'jobIncome.employerName', null);
        const userReason = _.get(formVals, `incomeDifferenceReason.${employerName}[0].id`, null);
        const updatedCurrIncObj = currIncObj;
        if (employerName && userReason && userReason !== 'NONE') {
          _.set(updatedCurrIncObj, 'jobIncome.incomeDifferenceReason', userReason);
        } else if (userReason === 'NONE') {
          _.unset(updatedCurrIncObj, 'jobIncome.incomeDifferenceReason');
        }
        acc[key] = updatedCurrIncObj;
        return acc;
      },
      {},
    );
    _.set(workingMemberPayload, 'income.currentIncome', updatedCurrentInc);

    /**
     * [ui_question_companion_guide_06242019_v13 - 186, 187, 188]
     * #186
     *  1. If any selection was made other than "A reason not listed above", set members.income.<incomeLessExplainedIndicator> = "True"
     *  2. If "A reason not listed above" was selected - set members.income.<incomeLessExplainedIndicator> = "False".
     *     Text input to the freeform box should be saved as <taxHouseholdIncomeDiscrepancyDescriptionText>
     *  3. If any member(s) of that tax household has the following status: <members.income.annualIncomeExplanationRequired=true>
     *     "Copy" the same answer that was saved to <taxHouseholdIncomeDifferenceReasonType> to <variableIncomeDescriptionText> for those member(s)
     *  4. If No Tax Household has the following status: <taxHouseholds.annualIncome.incomeExplanationRequiredIndicator = true> and <incomeExplanationRequiredReasonType> = LOWER_THAN_SOURCE, then:
     *     Save the response that we would save to <taxHouseholdIncomeDifferenceReasonType> via logic above to <variableIncomeDescriptionText> for that member instead.
     * #187
     *  5. Save free-form input box as variableIncomeDescriptionText
     */
    const annualIncome = _.get(workingMemberPayload, 'income.annualTaxIncome', {});
    const incomeLessUserReasons = _.map(
      _.get(formVals, 'incomeLessExplainedIndicator', []),
      type => type.id,
    );
    let updatedAnnualIncome = annualIncome;

    if (!_.isEmpty(incomeLessUserReasons)) {
      const variableText = _.get(formVals, 'taxHouseholdIncomeDiscrepancyDescriptionText.variable');
      const descText = _.get(formVals, 'taxHouseholdIncomeDiscrepancyDescriptionText.notVariable');
      const copyToVariableIncomeDescriptionText = _.toString(incomeLessUserReasons);
      const computedMember = _.find(computedMemberPayload, ['memberId', memberId]);

      updatedAnnualIncome = {
        ...annualIncome,
        incomeLessExplainedIndicator: !_.includes(incomeLessUserReasons, OTHER),
        taxHouseholdIncomeDifferenceReasonType: incomeLessUserReasons,
      };
      // remove previous attested answer from payload
      _.unset(updatedAnnualIncome, 'taxHouseholdIncomeDiscrepancyDescriptionText');
      _.unset(updatedAnnualIncome, 'variableIncomeDescriptionText');

      if (_.includes(incomeLessUserReasons, OTHER) && (descText || variableText)) {
        _.set(
          updatedAnnualIncome,
          'taxHouseholdIncomeDiscrepancyDescriptionText',
          descText || variableText,
        );
      }

      // NOTE: the CMS change request #19 (https://stridehealth.atlassian.net/browse/MARKET-1451)
      // Request us to seperate question 186 & 187, while both of them have logic to update 'variableIncomeDescriptionText'
      // If the member has <members.income.annualIncomeExplanationRequired=true>,
      // we copy the <taxHouseholdIncomeDifferenceReasonType> to <variableIncomeDescriptionText>
      // but question 187 (if shows up) is to update the <variableIncomeDescriptionText>, so we handle 186 first and 187 might overwrite it

      // UI question 186: copy reason to variable text
      if (annualIncomeLess(computedMember)) {
        _.set(
          updatedAnnualIncome,
          'variableIncomeDescriptionText',
          copyToVariableIncomeDescriptionText,
        );
      }
      // UI question 187:
      if (variableText) {
        _.set(updatedAnnualIncome, 'variableIncomeDescriptionText', variableText);
      }
    } else {
      // remove previous attested answer from payload
      _.unset(updatedAnnualIncome, 'taxHouseholdIncomeDifferenceReasonType');
      _.unset(updatedAnnualIncome, 'taxHouseholdIncomeDiscrepancyDescriptionText');
      _.unset(updatedAnnualIncome, 'variableIncomeDescriptionText');
    }

    _.set(workingMemberPayload, 'income.annualTaxIncome', updatedAnnualIncome);

    const { income } = workingMemberPayload;
    // Work-around EDE complaining that these fields are on request
    _.unset(income, 'incomeExceedFPL');
    _.unset(income, 'seasonalWorkerIndicator');
    return {
      id: memberId,
      ede_payload: {
        member: { income },
      },
    };
  });
