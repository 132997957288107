/* eslint-disable no-useless-computed-key */
// @flow

/**
 * COLORS are ported from colors.scss.
 * Let's port the colors over from colors.scss to here as we use them.
 */
class COLORS {
  static BLUE_0: string = '#077ab6';
  static BLUE_1: string = '#0b86c5';
  static BLUE_2: string = '#0096e2';
  static BLUE_3: string = '#80CBF0';
  static BLUE_4: string = '#def4ff';
  static BLUE_5: string = '#F4FDFF';
  static BLUE_6: string = '#00689D';
  static BLUE_7: string = '#B9E8FF';
  static DARK_GRAY: string = '#454a57';
  static GRAY_2: string = '#3b3b3b';
  static GRAY_3: string = '#6a6e76';
  static GRAY_4: string = '#b8bcc3';
  static GRAY_5: string = '#dfe1e5';
  static GRAY_6: string = '#fbfbfb';
  static GRAY_7: string = '#606060';
  static GRAY: string = '#e4e4e4';
  static GREEN_0: string = '#0FCC86';
  static GREEN_2: string = '#19e29a';
  static GREEN_3: string = '#77f4c7';
  static LIGHT_GRAY: string = '#aab7be';
  static NAVY_1: string = '#1f465b';
  static NAVY_2: string = '#135574';
  static NAVY_3: string = '#296887';
  static PURPLE_2: string = '#7E5C8C';
  static RED_1: string = '#d0394d';
  static RED_2: string = '#F16B7D';
  static RED_6: string = '#d95c6c';
  static TEAL_1: string = '#10b0d6';
  static TEAL_2: string = '#31CDF2';
  static TEAL_4: string = '#f4fdff';
  static WHITE: string = '#ffffff';
  static BLACK: string = '#000000';

  // 2023 REBRAND
  static BRAND_500: string = '#FFF98D';
  static BRAND_300: string = '#FFF98D';
  static BRAND_50: string = '#EAE8E2';

  static STRIDE_COBALT: string = '#5383FF';
  static STRIDE_ORANGE: string = '#FF6100';
  static ELECTRIC_GREEN: string = '#74E885';

  static PRIMARY_900: string = '#1A1B1E';
  static PRIMARY_800: string = '#1A1B1E';
  static PRIMARY_700: string = '#1A1B1E';
  static PRIMARY_600: string = '#5E5D5A';
  static PRIMARY_500: string = '#636262';
  static PRIMARY_400: string = '#787878';
  static PRIMARY_300: string = '#969696';
  static PRIMARY_200: string = '#BBBAB5';
  static PRIMARY_100: string = '#E2E2E2';
  static PRIMARY_50: string = '#F8F8F6';

  static SECONDARY_900: string = '#24192B';
  static SECONDARY_800: string = '#3A2943';
  static SECONDARY_700: string = '#513B5E';
  static SECONDARY_600: string = '#6E4A7D';
  static SECONDARY_500: string = '#826196';
  static SECONDARY_400: string = '#9C76B4';
  static SECONDARY_300: string = '#B78BD2';
  static SECONDARY_200: string = '#D3A0F2';
  static SECONDARY_100: string = '#E5C6F8';
  static SECONDARY_50: string = '#FBF6FE';

  static SUCCESS_900: string = '#0F2913';
  static SUCCESS_800: string = '#295930';
  static SUCCESS_700: string = '#377440';
  static SUCCESS_600: string = '#459050';
  static SUCCESS_500: string = '#54AC61';
  static SUCCESS_400: string = '#64CA73';
  static SUCCESS_300: string = '#74E885';
  static SUCCESS_200: string = '#A3F0AB';
  static SUCCESS_100: string = '#D9F9DB';
  static SUCCESS_50: string = '#F3FDF3';

  static WARNING_900: string = '#5C2205';
  static WARNING_800: string = '#8B3308';
  static WARNING_700: string = '#B9450A';
  static WARNING_600: string = '#FE6100';
  static WARNING_500: string = '#F37232';
  static WARNING_400: string = '#F69260';
  static WARNING_300: string = '#F8A880';
  static WARNING_200: string = '#FABEA0';
  static WARNING_100: string = '#FBD3BF';
  static WARNING_50: string = '#FFF7F3';

  static ERROR_900: string = '#740B19';
  static ERROR_800: string = '#910E1F';
  static ERROR_700: string = '#AD1025';
  static ERROR_600: string = '#CA132C';
  static ERROR_500: string = '#E71632';
  static ERROR_400: string = '#EC314A';
  static ERROR_300: string = '#EE4E64';
  static ERROR_200: string = '#F16B7D';
  static ERROR_100: string = '#F69CA8';
  static ERROR_50: string = '#FFE9EC';

  static NEUTRAL_900: string = '#1A1B1E';
  static NEUTRAL_800: string = '#35373B';
  static NEUTRAL_700: string = '#505259';
  static NEUTRAL_600: string = '#6A6E76';
  static NEUTRAL_500: string = '#81868E';
  static NEUTRAL_400: string = '#9B9EA5';
  static NEUTRAL_300: string = '#B4B6BB';
  static NEUTRAL_200: string = '#DCDDE2';
  static NEUTRAL_100: string = '#F8F8F8';
  static NEUTRAL_50: string = '#FFFFFF';

  static ELECTRIC_GREEN: string = '#74E885';
}

export class HEX_TRANSPARENT {
  static 100 = 'FF';
  static 99 = 'FC';
  static 98 = 'FA';
  static 97 = 'F7';
  static 96 = 'F5';
  static 95 = 'F2';
  static 94 = 'F0';
  static 93 = 'ED';
  static 92 = 'EB';
  static 91 = 'E8';
  static 90 = 'E6';
  static 89 = 'E3';
  static 88 = 'E0';
  static 87 = 'DE';
  static 86 = 'DB';
  static 85 = 'D9';
  static 84 = 'D6';
  static 83 = 'D4';
  static 82 = 'D1';
  static 81 = 'CF';
  static 80 = 'CC';
  static 79 = 'C9';
  static 78 = 'C7';
  static 77 = 'C4';
  static 76 = 'C2';
  static 75 = 'BF';
  static 74 = 'BD';
  static 73 = 'BA';
  static 72 = 'B8';
  static 71 = 'B5';
  static 70 = 'B3';
  static 69 = 'B0';
  static 68 = 'AD';
  static 67 = 'AB';
  static 66 = 'A8';
  static 65 = 'A6';
  static 64 = 'A3';
  static 63 = 'A1';
  static 62 = '9E';
  static 61 = '9C';
  static 60 = '99';
  static 59 = '96';
  static 58 = '94';
  static 57 = '91';
  static 56 = '8F';
  static 55 = '8C';
  static 54 = '8A';
  static 53 = '87';
  static 52 = '85';
  static 51 = '82';
  static 50 = '80';
  static 49 = '7D';
  static 48 = '7A';
  static 47 = '78';
  static 46 = '75';
  static 45 = '73';
  static 44 = '70';
  static 43 = '6E';
  static 42 = '6B';
  static 41 = '69';
  static 40 = '66';
  static 39 = '63';
  static 38 = '61';
  static 37 = '5E';
  static 36 = '5C';
  static 35 = '59';
  static 34 = '57';
  static 33 = '54';
  static 32 = '52';
  static 31 = '4F';
  static 30 = '4D';
  static 29 = '4A';
  static 28 = '47';
  static 27 = '45';
  static 26 = '42';
  static 25 = '40';
  static 24 = '3D';
  static 23 = '3B';
  static 22 = '38';
  static 21 = '36';
  static 20 = '33';
  static 19 = '30';
  static 18 = '2E';
  static 17 = '2B';
  static 16 = '29';
  static 15 = '26';
  static 14 = '24';
  static 13 = '21';
  static 12 = '1F';
  static 11 = '1C';
  static 10 = '1A';
  static 9 = '17';
  static 8 = '14';
  static 7 = '12';
  static 6 = '0F';
  static 5 = '0D';
  static 4 = '0A';
  static 3 = '08';
  static 2 = '05';
  static 1 = '03';
  static 0 = '00';
}

export default COLORS;
