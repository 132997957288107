// @flow

import styled from 'styled-components';
import React from 'react';

type SvgType = {
  className: string,
  height: string,
  viewBox: string,
  width: string,
};

const SvgWrapper = styled.div`
  display: inline-block;
  height: ${props => props.height};
  width: ${props => props.width};
  color: inherit;
`;

const PhoneIcon = ({ className, width, height, viewBox }: SvgType) => (
  <SvgWrapper className={className} width={width} height={height}>
    <svg
      width="100%"
      height="100%"
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.30484 11.3098C8.74485 14.0999 10.8598 16.2148 13.6498 17.6548L15.7648 15.5398C16.0648 15.2398 16.3948 15.1648 16.7548 15.3148C17.8348 15.6748 18.9748 15.8548 20.1748 15.8548C20.4448 15.8548 20.6698 15.9448 20.8498 16.1248C21.0298 16.3048 21.1198 16.5298 21.1198 16.7998V20.1748C21.1198 20.4448 21.0298 20.6698 20.8498 20.8498C20.6698 21.0298 20.4448 21.1198 20.1748 21.1198C15.6748 21.1198 11.8274 19.5224 8.63234 16.3273C5.43733 13.1323 3.83984 9.28487 3.83984 4.78484C3.83984 4.51484 3.92984 4.28984 4.10984 4.10984C4.28984 3.92984 4.51484 3.83984 4.78484 3.83984H8.15984C8.42984 3.83984 8.65484 3.92984 8.83484 4.10984C9.01484 4.28984 9.10484 4.51484 9.10484 4.78484C9.10484 5.98485 9.28484 7.12484 9.64484 8.20484C9.76484 8.59485 9.68984 8.92484 9.41984 9.19484L7.30484 11.3098Z"
        fill="white" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="19"
        height="19">
        <path
          d="M7.30484 11.3098C8.74485 14.0999 10.8598 16.2148 13.6498 17.6548L15.7648 15.5398C16.0648 15.2398 16.3948 15.1648 16.7548 15.3148C17.8348 15.6748 18.9748 15.8548 20.1748 15.8548C20.4448 15.8548 20.6698 15.9448 20.8498 16.1248C21.0298 16.3048 21.1198 16.5298 21.1198 16.7998V20.1748C21.1198 20.4448 21.0298 20.6698 20.8498 20.8498C20.6698 21.0298 20.4448 21.1198 20.1748 21.1198C15.6748 21.1198 11.8274 19.5224 8.63234 16.3273C5.43733 13.1323 3.83984 9.28487 3.83984 4.78484C3.83984 4.51484 3.92984 4.28984 4.10984 4.10984C4.28984 3.92984 4.51484 3.83984 4.78484 3.83984H8.15984C8.42984 3.83984 8.65484 3.92984 8.83484 4.10984C9.01484 4.28984 9.10484 4.51484 9.10484 4.78484C9.10484 5.98485 9.28484 7.12484 9.64484 8.20484C9.76484 8.59485 9.68984 8.92484 9.41984 9.19484L7.30484 11.3098Z"
          fill="white" />
      </mask>
      <g mask="url(#mask0)" />
    </svg>
  </SvgWrapper>
);

PhoneIcon.defaultProps = {
  className: '',
  width: '20px',
  height: '20px',
  viewBox: '0 0 23 23',
};

export default PhoneIcon;
