import { LD_READY, LD_UPDATE_FLAGS } from 'client/actions/launchDarkly';

const transformUpdatedFlags = (
  flags: Record<string, { previous: unknown; current: unknown }>
) =>
  Object.keys(flags).reduce(
    (acc, key) => ({
      ...acc,
      [key]: flags[key].current,
    }),
    {}
  );

const initialState = {
  isLDReady: false,
  ldClient: null,
  flags: {},
};

const launchDarkly = (state = initialState, action) => {
  switch (action.type) {
    case LD_READY:
      return {
        ...state,
        isLDReady: true,
        ldClient: action.payload,
        flags: action.payload.allFlags(),
      };
    case LD_UPDATE_FLAGS:
      return {
        ...state,
        flags: {
          ...state.flags,
          ...transformUpdatedFlags(action.payload),
        },
      };
    default:
      return state;
  }
};

export default launchDarkly;
