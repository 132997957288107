// @flow
import _ from 'lodash';
import type { LawfulPresenceDocumentationType } from 'routes/marketplace-ede/constants/ede/citizenship';
import {
  isRedactedAlienNumber, isRedactedI94, isRedactedSevisId, isRedactedPassportNumber,
} from 'routes/marketplace-ede/web-ui/utils/validate';

export type LawfulPresenceDocumentationPayloadType = {
  documentExpirationDate?: string | null,
  alienNumber?: string | null,
  employmentAuthorizationCategoryIdentifier?: string | null,
  sevisId?: string | null,
  i94Number?: string | null,
  passportNumber?: string | null,
  cardNumber?: string | null,
  naturalizationCertificateNumber?: string | null,
  citizenshipNumber?: string | null,
  documentAlternateName?: string | null,
  noIdentifiersProvidedIndicator?: boolean | null,
  otherDocumentTypeText?: string | null,
  passportIssuingCountry?: string | null,
};

type LawfulPresencePayloadType = {
  citizenshipIndicator?: boolean,
  naturalizedCitizenIndicator?: ?boolean,
  noAlienNumberIndicator?: ?boolean,
  lawfulPresenceDocumentation?: {
    [LawfulPresenceDocumentationType]: ?LawfulPresenceDocumentationPayloadType,
  },
  lawfulPresenceStatusIndicator?: boolean | null,
};

type UpdateMemberLawfulPresenceModelArgumentsType = {
  memberIdentifier: string,
  lawfulPresencePayload: LawfulPresencePayloadType,
};

export type UpdateMemberLawfulPresenceModelType = {
  memberIdentifier: string,
  edePayload: LawfulPresencePayloadType,
};

export default class UpdateMemberLawfulPresenceModel {
  memberIdentifier: string;
  edePayload: LawfulPresencePayloadType;

  constructor({
    memberIdentifier,
    lawfulPresencePayload,
  }: UpdateMemberLawfulPresenceModelArgumentsType) {
    this.memberIdentifier = memberIdentifier;
    this.edePayload = {
      lawfulPresence: this.cleanRedactedDocuments(lawfulPresencePayload),
    };
  }

  cleanRedactedDocuments(lawfulPresencePayload) {
    const clonedPayload = _.cloneDeep(lawfulPresencePayload);
  
    const documentation = clonedPayload.lawfulPresenceDocumentation;
    if (documentation) {
      Object.keys(documentation).forEach(key => {
        const doc = documentation[key];
        if (doc) {
          if (doc.sevisId && isRedactedSevisId(doc.sevisId)) {
            delete doc.sevisId;
          }
          if (doc.i94Number && isRedactedI94(doc.i94Number)) {
            delete doc.i94Number;
          }
          if (doc.passportNumber && isRedactedPassportNumber(doc.passportNumber)) {
            delete doc.passportNumber;
          }
          if (doc.alienNumber && isRedactedAlienNumber(doc.alienNumber)) {
            delete doc.alienNumber;
          }
        }
      });
    }
    return clonedPayload;
  }
}
