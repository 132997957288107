// @flow

// Onboarding Pages
export const ACCOUNT_ROUTE = '/profile/account';
export const APPLICANTS_ROUTE = '/profile/applicants';
export const CONDITIONS_EDIT_ROUTE = '/profile/conditions/edit';
export const CONDITIONS_ROUTE = '/profile/conditions';
export const DEADLINE_ROUTE = '/profile/deadline';
export const DRUGS_ROUTE = '/profile/drugs';
export const DRUGS_EDIT_SEARCH_ROUTE = '/profile/drugs/edit';
export const DRUGS_EDIT_QUANTITY_ROUTE = '/profile/drugs/edit/quantity/:drugId';
export const DOCTORS_ROUTE = '/profile/doctors';
export const DOCTORS_SAVE_ROUTE = '/profile/doctors/save';
export const DOCTORS_SEARCH_ROUTE = '/profile/doctors/search';
export const HOUSEHOLD_ROUTE = '/profile/subsidy/household';
export const INCOME_ROUTE = '/profile/subsidy/income';
export const LOCATION_COUNTIES_ROUTE = '/profile/location/counties';
export const LOCATION_ZIPCODE_ROUTE = '/profile/location/zipcode';
export const QUALIFY_ROUTE = '/profile/subsidy/qualify';
export const RESET_ROUTE = '/profile/account/reset';
export const SUBSIDY_START_ROUTE = '/profile/subsidy/start';
export const USAGE_ROUTE = '/profile/usage';
export const ICHRA_ROUTE = '/profile/ichra';
export const ICHRA_CONTRIBUTION_ROUTE = '/profile/ichra/contribution';

// Recommendation Pages
export const RECOMMENDATION_ROUTE = '/profile/plans/recommendation';
export const VIEW_ALL_PLANS_ROUTE = '/profile/plans/all';

// Profile Pages
export const PROFILE_APPLICANTS_ROUTE = '/profile/edit/applicants';
export const PROFILE_CONDITIONS_ROUTE = '/profile/edit/conditions';
export const PROFILE_DOCTORS_ROUTE = '/profile/edit/doctors';
export const PROFILE_DOCTORS_SAVE_ROUTE = '/profile/edit/doctors/save';
export const PROFILE_DRUGS_EDIT_ROUTE = '/profile/edit/drugs/edit';
export const PROFILE_DRUGS_QUANTITY_ROUTE = '/profile/edit/drugs/quantity/:drugId';
export const PROFILE_HOUSEHOLD_ROUTE = '/profile/edit/household';
export const PROFILE_INCOME_ROUTE = '/profile/edit/income';
export const PROFILE_ROUTE = '/profile/edit';
export const PROFILE_USAGE_ROUTE = '/profile/edit/usage';
export const RECALCULATE_ROUTE = '/profile/recalculate';

// Plan Detail Page
export const RECOMMENDED_PLANS_DETAIL_ROUTE = '/profile/plans/recommendation/detail/:planId';
export const VIEW_ALL_PLANS_DETAIL_ROUTE = '/profile/plans/all/detail/:planId';

// Plan Detail Compare Page
export const PLANS_COMPARE_ROUTE = '/profile/plans/all/compare';
export const PLANS_DETAIL_COMPARE_ROUTE = '/profile/plans/all/compare/:firstId/:secondId';

export const CART_ROUTE = '/cart';

// Consumer Shop
export const CONSUMER_SHOP = '/shop';
