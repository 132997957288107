import _ from 'lodash';
import axios from 'axios';
import { stringify } from 'qs';
import { updateCache } from '../actions/cache';

const defaultCacheDurationSeconds = 10;

const makeRequest = ({ options }) =>
  axios
    .getAdapter(['xhr', 'http'])(options)
    .then((response) => {
      // pull off unnecessary fields
      // leaves status, statusText, data
      const { config, request, headers, ...payload } = response;
      // data is stringified here, so store it as a parsed object
      payload.data = JSON.parse(payload.data);
      return payload;
    });

// this needs to be separate from the makeRequest handler
// so that when there is a cache hit, this still gets fired
// and populates the store with the cached data
const storeResult = ({ store, cacheKey, result }) => {
  store.dispatch(updateCache(cacheKey, result));
  return result;
};

export const createCacheAdapter = (store, getRedisResult) => (options) => {
  if (options.method !== 'get' || !options.cache) {
    return axios.getAdapter(['xhr', 'http'])(options);
  }

  const { cache } = store.getState();
  const isRelative = options.url.charAt(0) === '/';
  // only client should have relative urls, so safe to assume window.location is present
  // eslint-disable-next-line no-restricted-globals
  const host = isRelative ? `${location.protocol}//${location.host}` : '';
  const query = _.isObject(options.params) ? `?${stringify(options.params)}` : '';
  // this key needs to be absolute, so urls from client and server can be shared
  const cacheKey = `${host}${options.url}${query}`;
  const durationSeconds = _.get(options, 'cache.durationSeconds', defaultCacheDurationSeconds);

  // setting cache.refresh will skip the cache check so the call hits the server
  // but still caches the result on successful response
  const cacheItem = !_.get(options, 'cache.refresh') && cache[cacheKey];

  // for a cache hit, just return a promise with the original response
  const duration = durationSeconds * 1000;
  if (
    cacheItem &&
    cacheItem.data &&
    cacheItem.createdAt &&
    duration + cacheItem.createdAt >= Date.now()
  ) {
    return Promise.resolve(cacheItem.data);
  }

  if (typeof getRedisResult === 'function' && _.get(options, 'cache.server')) {
    const serverCacheDuration = _.get(options, 'cache.server.durationSeconds', durationSeconds);
    return getRedisResult(cacheKey, serverCacheDuration, () => makeRequest({ options })).then(
      (result) => {
        if (!_.get(options, 'cache.server.only')) {
          return storeResult({ store, cacheKey, result });
        }

        return result;
      },
    );
  }

  // if no cache hit, or forced cache.refresh, call through w/ default adapter and cache result
  return makeRequest({ options }).then(result => storeResult({ store, cacheKey, result }));
};
