import type { AppType } from 'web-core/types/ifp-app';

class CORE_PRODUCT_TYPES {
  static HEALTH: AppType = 'health';
  static DENTAL: AppType = 'dental';
  static VISION: AppType = 'vision';
}

export const CORE_PRODUCT_TYPES_LIST = Object.values(CORE_PRODUCT_TYPES);

export type AncillaryProductTypes = 'life' | 'accident' | 'disability' | 'hospital' | 'critical';

export class ANCILLARY_PRODUCT_TYPES {
  static LIFE: AncillaryProductTypes = 'life';
  static ACCIDENT: AncillaryProductTypes = 'accident';
  static DISBILITY: AncillaryProductTypes = 'disability';
  static HOSPITAL: AncillaryProductTypes = 'hospital';
  static CRITICAL: AncillaryProductTypes = 'critical';
}

export default CORE_PRODUCT_TYPES;
