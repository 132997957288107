// @flow

import React from 'react';

const PlatinumIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>1-ATOMS/glyphs 16px/metal-tier-platinum</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
        <stop stopColor="#EAE9E9" offset="0%" />
        <stop stopColor="#A9A9A9" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="18.7546503%"
        y1="-26.4465332%"
        x2="72.2222222%"
        y2="68.112793%"
        id="linearGradient-5">
        <stop stopColor="#F6EFC9" offset="0%" />
        <stop stopColor="#C5A1A1" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="1-ATOMS/glyphs-16px/metal-tier-platinum"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <rect
        id="Rectangle-Copy"
        stroke="#C3C3C3"
        fill="url(#linearGradient-4)"
        x="4.5"
        y="2.5"
        width="7"
        height="11"
        rx="2" />
      <rect
        id="Rectangle-Copy"
        fill="url(#linearGradient-5)"
        opacity="0.450334821"
        style={{ mixBlendMode: 'darken' }}
        x="4"
        y="2"
        width="8"
        height="12"
        rx="2" />
    </g>
  </svg>
);

export default PlatinumIcon;
