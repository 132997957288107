// @flow

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import type { TranslateType } from 'types/translateType.flow';
import { interpolateWith } from 'web-core/src/utils/format';
import {
  getChipAgencyNameFromState,
  getChipProgramNameFromState,
  getMedicaidAgencyNameFromState,
  getMedicaidProgramNameFromState,
} from '../../utils/selectors';

const NOT_FOUND_MSG = 'translation not found';

function getTranslations(state, props): { isFetching: boolean, [key]: Object } {
  if (!props.path) {
    return state;
  }

  const key = props.path.split('.')[0];
  return {
    stateProgramNames: {
      stateMedicaidProgramName: getMedicaidProgramNameFromState(state),
      stateMedicaidAgencyName: getMedicaidAgencyNameFromState(state),
      stateChipProgramName: getChipProgramNameFromState(state),
      stateChipAgencyName: getChipAgencyNameFromState(state),
    },
    // isFetching: state.translations.fetching.indexOf(key) >= 0,
    [key]: _.get(state, ['translations', key], null),
  };
}

class Translate extends PureComponent<TranslateType> {
  static defaultProps = {
    stateProgramNames: null,
    using: null,
    andThen: null,
    className: '',
    fallback: NOT_FOUND_MSG,
    children: null,
  };

  render() {
    const { path, stateProgramNames, using, isFetching, andThen, className, fallback, children } = this.props;

    if (isFetching) {
      return <span />;
    }

    let content = _.get(this.props, path, fallback);
    // if you specify children as a function, call it with the data
    if (children && content !== fallback) {
      // $FlowFixMe, investigate children as boolean value
      return children(content);
    }
    if (content) {
      content = interpolateWith(content, { ...stateProgramNames, ...using });
    }
    if (andThen) {
      content = andThen(content);
    }
    // eslint-disable-next-line react/no-danger
    return <span className={className} dangerouslySetInnerHTML={{ __html: content }} />;
  }
}

export default connect(getTranslations)(Translate);

// TODO: Fully deprecate PropTypes, but many components rely on exported TranslateProps
const propTypes = {
  // $FlowFixMe, looks like a flow bug saying string is not in prop-types
  path: PropTypes.string.isRequired,
  using: PropTypes.object,
  andThen: PropTypes.func,
  // $FlowFixMe, looks like a flow bug saying string is not in prop-types
  fallback: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  // $FlowFixMe, looks like a flow bug saying string is not in prop-types
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export const TranslateProps = PropTypes.shape(_.omit(propTypes, ['isFetching']));
