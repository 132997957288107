import _ from 'lodash';

import {
  EdeApplicationPayloadType,
  FamilyRelationshipAttestationType,
} from 'routes/marketplace-ede/types/ede/edeApplication';
import { EdeMemberType } from 'routes/marketplace-ede/types/ede/edeMember';
import { RelationshipValue, Relationship } from 'routes/marketplace-ede/types/ede/relationship';
import {
  isMemberRequestingCoverage,
  getPrimaryMember as getPrimaryMemberUtil,
  getMemberAge as getMemberAgeUtil,
} from 'routes/marketplace-ede/utils/selectors';

// Typescript util to get correct typing when object is possibly `{}`
const isNotEmpty = <T>(obj: T | unknown): obj is T => !_.isEmpty(obj);
const isDefined = <T>(val: T | undefined | null): val is T => !_.isNil(val);

// The underlying utils here use incorrect flow types for EdeApp/EdeMember, so wrap and use `any` for now
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMemberAge = (edeMember: EdeMemberType): number => getMemberAgeUtil(edeMember as any);

const findPrimaryMember = (edeApp: EdeApplicationPayloadType): EdeMemberType | undefined => {
  const primaryMember = getPrimaryMemberUtil(edeApp as any);

  return isNotEmpty<EdeMemberType>(primaryMember) ? primaryMember : undefined;
};

export const findMembersRelationship = (
  id1: string,
  id2: string,
  relationships: FamilyRelationshipAttestationType[],
): FamilyRelationshipAttestationType | undefined =>
  _.find(
    relationships,
    relation =>
      _.includes(Object.values(relation), id1) && _.includes(Object.values(relation), id2),
  );

// returns an array of members that do not have a relationship set with the primary filer
export const getMissingPrimaryRelationships = (
  edeApp: EdeApplicationPayloadType,
): EdeMemberType[] => {
  const members: EdeMemberType[] = _.get(edeApp, 'edeMembers', []);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const primaryMember = findPrimaryMember(edeApp);
  if (!primaryMember) return [];

  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships');

  return _.reject(members, (m) => {
    const found = findMembersRelationship(
      m.memberIdentifier,
      primaryMember.memberIdentifier,
      familyRelationships,
    );
    return !!found;
  });
};

const getMemberFamily = (edeApp: EdeApplicationPayloadType): FamilyRelationshipAttestationType[] =>
  _.get(edeApp, 'attestations.household.familyRelationships') || [];

export const findSpouseOrPartner = (
  edeApp: EdeApplicationPayloadType,
  id: string,
): FamilyRelationshipAttestationType | undefined => {
  const familyRelationships = getMemberFamily(edeApp);

  return _.find(
    familyRelationships,
    ({ memberIdentifier, subMemberIdentifier, relationship }) =>
      (memberIdentifier === id || subMemberIdentifier === id) &&
      (relationship === 'SPOUSE' || relationship === 'DOMESTIC_PARTNER'),
  );
};

type GetValidRelationshipsArgs = {
  options: Relationship[];
  edeApp: EdeApplicationPayloadType;
  member1: EdeMemberType;
  member2: EdeMemberType;
  pregnancyAgeCutOff?: number;
  marriageAgeMinimum?: number;
};

type InvalidSpouseArgs = Pick<
  GetValidRelationshipsArgs,
  'edeApp' | 'member1' | 'member2' | 'marriageAgeMinimum'
>;

const isRelationshipMember = (
  member: EdeMemberType,
  relationship: FamilyRelationshipAttestationType,
): boolean =>
  relationship.memberIdentifier === member.memberIdentifier ||
  relationship.subMemberIdentifier === member.memberIdentifier;

const invalidSpouse = ({
  edeApp,
  member1,
  member2,
  marriageAgeMinimum,
}: InvalidSpouseArgs): boolean => {
  const member2Spouse = findSpouseOrPartner(edeApp, member2.memberIdentifier);
  const member1Spouse = findSpouseOrPartner(edeApp, member1.memberIdentifier);

  const hasDifferentSpouse =
    (!!member2Spouse && !isRelationshipMember(member1, member2Spouse)) ||
    (!!member1Spouse && !isRelationshipMember(member2, member1Spouse));

  const isBelowMarriageAge =
    isDefined(marriageAgeMinimum) &&
    (getMemberAge(member1) < marriageAgeMinimum || getMemberAge(member2) < marriageAgeMinimum);

  return isBelowMarriageAge || hasDifferentSpouse;
};

type InvalidParentArgs = {
  member: EdeMemberType;
  subMember: EdeMemberType;
  pregnancyAgeCutOff?: number;
};

const invalidParent = ({ member, subMember, pregnancyAgeCutOff }: InvalidParentArgs): boolean =>
  (isDefined(pregnancyAgeCutOff) && getMemberAge(member) < pregnancyAgeCutOff) ||
  getMemberAge(member) < getMemberAge(subMember);

// members are compared as "Member 1 is the [RELATIONSHIP] of Member 2"
export const getValidRelationships = ({
  options,
  edeApp,
  pregnancyAgeCutOff,
  marriageAgeMinimum,
  member1,
  member2,
}: GetValidRelationshipsArgs) => {
  const isInvalidParent = invalidParent({
    member: member1,
    subMember: member2,
    pregnancyAgeCutOff,
  });
  const isInvalidChild = invalidParent({
    member: member2,
    subMember: member1,
    pregnancyAgeCutOff,
  });
  const isInvalidSpouse = invalidSpouse({
    edeApp,
    member1,
    member2,
    marriageAgeMinimum,
  });

  return options.filter((option) => {
    if (option.value === 'SON_DAUGHTER') {
      return !isInvalidChild;
    }
    if (option.value === 'PARENT' || option.value === 'GRANDPARENT') {
      return !isInvalidParent;
    }
    if (option.value === 'SPOUSE' || option.value === 'DOMESTIC_PARTNER') {
      return !isInvalidSpouse;
    }

    return true;
  });
};

type GetValidLegalRelationshipsArgs = {
  relation: RelationshipValue;
  member1: EdeMemberType;
  member2: EdeMemberType;
  optionsCase1: Relationship[];
  optionsCase2: Relationship[];
};

const RELATIONSHIPS_REQUIRING_LEGAL_CASE_1 = [
  'GRANDCHILD',
  'GRANDPARENT',
  'AUNT_UNCLE',
  'NEPHEW_NIECE',
  'FIRST_COUSIN',
  'SIBLING',
  'DOMESTIC_PARTNER',
  'PARENTS_DOMESTIC_PARTNER',
  'CHILD_OF_DOMESTIC_PARTNER',
];

const RELATIONSHIPS_REQUIRING_LEGAL_WITH_AGE_FOR_MEMBER_1 = [
  'SON_DAUGHTER',
  'STEPSON_STEPDAUGHTER',
];

const RELATIONSHIPS_REQUIRING_LEGAL_WITH_AGE_FOR_MEMBER_2 = ['PARENT', 'STEP_PARENT'];

/**
 * See UICG #271
 * members are compared as "Member 1 is the [LEGAL RELATIONSHIP] of Member 2"
 */
export const getValidLegalRelationships = ({
  relation,
  member1,
  member2,
  optionsCase1,
  optionsCase2,
}: GetValidLegalRelationshipsArgs): Relationship[] => {
  // Both members must be requesting coverage
  if (!isMemberRequestingCoverage(member1) || !isMemberRequestingCoverage(member2)) {
    return [];
  }

  if (RELATIONSHIPS_REQUIRING_LEGAL_CASE_1.includes(relation)) {
    return optionsCase1;
  }

  if (relation === 'OTHER_RELATIVE') return optionsCase2;

  if (
    RELATIONSHIPS_REQUIRING_LEGAL_WITH_AGE_FOR_MEMBER_1.includes(relation) &&
    getMemberAge(member1) >= 25
  ) {
    return optionsCase2;
  }
  if (
    RELATIONSHIPS_REQUIRING_LEGAL_WITH_AGE_FOR_MEMBER_2.includes(relation) &&
    getMemberAge(member2) >= 25
  ) {
    return optionsCase2;
  }

  return [];
};

export const hasKnownRelationship = (
  ids: string[],
  familyRelationships: FamilyRelationshipAttestationType[],
) => {
  const [id1, id2] = ids;
  return !!_.find(
    familyRelationships,
    relation =>
      _.includes(Object.values(relation), id1) && _.includes(Object.values(relation), id2),
  );
};

export const getPossibleParents = (edeApp: EdeApplicationPayloadType, member: EdeMemberType) => {
  const parentRelationshipTypes = ['PARENT', 'STEP_PARENT'];
  const members = _.get(edeApp, 'edeMembers');
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships');

  // Display all household members who did not already attest to a relationship
  const noRelationship = _.filter(
    _.without(members, member),
    m => !hasKnownRelationship([m.memberIdentifier, member.memberIdentifier], familyRelationships),
  );

  const memberParentRelationships = _.filter(familyRelationships, (relation) => {
    const hasParentRelation = parentRelationshipTypes.includes(relation.relationship);
    const relationIncludesMember = _.includes(_.values(relation), member.memberIdentifier);

    return hasParentRelation && relationIncludesMember;
  });

  const hasRelationshipType = _.map(memberParentRelationships, rel =>
    _.find(members, {
      memberIdentifier:
        rel.memberIdentifier === member.memberIdentifier
          ? rel.subMemberIdentifier
          : rel.memberIdentifier,
    })).filter(isDefined);

  const eligibleOptions = _.unionBy(noRelationship, hasRelationshipType, 'memberIdentifier');

  return _.filter(eligibleOptions, m => getMemberAge(m) > getMemberAge(member));
};

type GetFilteredRelationshipsFromApplicationArgs = {
  edeApp: EdeApplicationPayloadType;
  pregnancyAgeCutOff?: number;
};

export const getInvalidRelationshipsFromApplication = ({
  edeApp,
  pregnancyAgeCutOff,
}: GetFilteredRelationshipsFromApplicationArgs) => {
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships');

  return familyRelationships.filter((relation) => {
    const member =
      _.find(edeApp.edeMembers, { memberIdentifier: relation.memberIdentifier }) as EdeMemberType;
    const subMember =
      _.find(edeApp.edeMembers, { memberIdentifier: relation.subMemberIdentifier }) as EdeMemberType;
    const relationshipType = relation.relationship;

    const isInvalidParent = invalidParent({
      member,
      subMember,
      pregnancyAgeCutOff,
    });
    const isInvalidChild = invalidParent({
      member: subMember,
      subMember: member,
      pregnancyAgeCutOff,
    });

    if (relationshipType === 'SON_DAUGHTER') {
      return isInvalidChild;
    }
    if (relationshipType === 'PARENT' || relationshipType === 'GRANDPARENT') {
      return isInvalidParent;
    }

    return false;
  });
};

// Export shim for backwards compatibility for renamed functions
export const getMembersRelationship = findMembersRelationship;
export const hasSpouseOrPartner = findSpouseOrPartner;
