// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CookieUtils from 'web-core/src/utils/cookie';

const EXTOLE_ACTIVE_REFERRAL_COOKIE_NAME = 'StrideExtoleReferrerCode';

export const extoleZoneNamesMap = {
  APP_STARTED: 'app_start',
  ONBOARDING_STARTED: 'onboarding_started',
  PLAN_REC: 'plan_rec',
};

export const extoleApplicationTypesMap = {
  DENTAL: 'dental',
  HEALTH: 'health',
  VISION: 'vision',
};

type Props = {
  application_id?: string,
  application_type?: $Values<typeof extoleApplicationTypesMap>, // medical, dental, vision
  email?: string,
  first_name?: string,
  last_name?: string,
  partner_user_id?: string,
  zone_name: string,
  hideExtole: boolean,
};

export type ExtoleCreateZonePayload = {
  zoneName: string,
  data: {
    email?: string,
    first_name?: string,
    last_name?: string,
    partner_user_id?: string,
    application_type: string,
  },
  elementId?: string,
};

function checkForExtole() {
  window.extole = window.extole || {};
  window.extole.q = window.extole.q || [];
  window.extole.createZone =
    window.extole.createZone ||
    // eslint-disable-next-line func-names
    function () {
      // eslint-disable-next-line prefer-rest-params
      window.extole.q.push(['createZone', arguments]);
    };
}

export function createZone(
  { zoneName, data, elementId }: ExtoleCreateZonePayload,
  cb?: (error: string) => void,
): void {
  checkForExtole();

  const extoleIntegration = window?.__INITIAL_STATE__?.useExtoleIntegration || false;

  if (extoleIntegration) {
    window.extole.createZone(
      {
        name: zoneName,
        ...(elementId && { element_id: elementId }),
        data,
      },
      cb,
    );
  }
}

const ExtoleCreateZoneCardF = (props: Props) => {
  const {
    email,
    first_name = '',
    last_name = '',
    application_id = '',
    application_type = '',
    zone_name,
    partner_user_id,
    hideExtole,
  } = props;
  if (hideExtole) return null;
  const elementId = zone_name === 'overlay' ? 'extole_popup_zone_overlay' : undefined;

  const handlePartnerUserId = () => {
    if (partner_user_id) {
      return partner_user_id;
    }

    return window?.__INITIAL_STATE__?.personId || '';
  };

  useEffect(() => {
    const data = {
      email,
      first_name,
      last_name,
      partner_user_id: handlePartnerUserId(),
      application_type,
      application_id,
      advocate_code: CookieUtils.getCookie(EXTOLE_ACTIVE_REFERRAL_COOKIE_NAME),
    };

    createZone({ zoneName: zone_name, data, elementId });
  }, []);

  return (
    <div id={`ExtoleCreateZoneCard-${zone_name}`}>
      {zone_name === 'overlay' && <span id="extole_popup_zone_overlay" />}
    </div>
  );
};

ExtoleCreateZoneCardF.defaultProps = {
  application_id: undefined,
  application_type: undefined,
  email: undefined,
  first_name: undefined,
  last_name: undefined,
  partner_user_id: undefined,
  hideExtole: false,
};

const mapStateToProps = (state) => {
  return {
    hideExtole: !!state.siteConfigs?.brandSiteConfig?.partnerConfig?.agencyInfo?.hideExtole
  };
};

export const ExtoleCreateZoneCard = connect(mapStateToProps, null)(ExtoleCreateZoneCardF);