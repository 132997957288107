// @flow strict

type StatusType = {
  cancelled: string,
};

type GlobalsType = {
  appStoreUrl: string,
  experianPhone: string,
  ffmPhone: string,
  ffmUrl: string,
  googlePlaceId: string,
  LESS_THAN: string,
  MORE_THAN: string,
  NO_VALUE: string,
  NOT_APPLICABLE: string,
  ONE_YEAR_IN_MILLISECONDS: number,
  phone: string,
  phoneText: string,
  playStoreBetaUrl: string,
  playStoreUrl: string,
  status: StatusType,
  strideRecruitingEmail: string,
  strideUrl: string,
  taxHelpEmail: string,
  testFlightUrl: string,
  YES_VALUE: string,
  NA: string,
};

const GLOBALS: GlobalsType = {
  appStoreUrl: 'https://itunes.apple.com/us/app/apple-store/id1041591359',
  experianPhone: '1-866-578-5409',
  ffmPhone: '1-800-318-2596',
  ffmUrl: 'https://www.healthcare.gov/',
  googlePlaceId: 'ChIJD32jE8V_j4ARc5uVXxx9I0w',
  LESS_THAN: 'LESS_THAN',
  MORE_THAN: 'MORE_THAN',
  NO_VALUE: 'NO',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  ONE_YEAR_IN_MILLISECONDS: 31536000,
  phone: '415-930-9110',
  phoneText: '(415) 930-9110',
  playStoreBetaUrl: 'https://play.google.com/apps/testing/com.stridehealth.drive',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.stridehealth.drive',
  status: {
    cancelled: 'cancelled',
  },
  strideRecruitingEmail: 'recruiting@stridehealth.com',
  strideUrl: 'https://stridehealth.com',
  taxHelpEmail: 'taxhelp@stridehealth.com',
  testFlightUrl: 'https://testflight.apple.com/join/vsUPNTO6',
  YES_VALUE: 'YES',
  NA: 'N/A',
};

export type BooleanStringType = 'true' | 'false';

export class BOOLEAN_STRINGS {
  static TRUE: BooleanStringType = 'true';
  static FALSE: BooleanStringType = 'false';
}

export type PlanChangeType = 'invalidPlan' | 'noChanges' | 'plan' | 'price';

export class PLAN_CHANGE_TYPES {
  static INVALID_PLAN: PlanChangeType = 'invalidPlan';
  static NO_CHANGE: PlanChangeType = 'noChanges';
  static PLAN: PlanChangeType = 'plan';
  static PRICE: PlanChangeType = 'price';
}

export type ApplicationMemberType = 'primary' | 'spouse' | 'dependent';

export class APPLICATION_MEMBER_TYPES {
  static PRIMARY: ApplicationMemberType = 'primary';
  static SPOUSE: ApplicationMemberType = 'spouse';
  static DEPENDENT: ApplicationMemberType = 'dependent';
}

export class TRACKING_EVENT_TYPES {
  static ELIGIBILITY_CHANGED: string = 'Eligibility Changed';
}

export const STRIDE_LOGO_URL =
  'https://images.ctfassets.net/heg7gyzix6dq/u3bQRoaT5JHEYngDYqwKd/93efdaf56ff23ed8cca71f1bd6098651/Final_Logo_-_Black.webp?h=80';
export const STRIDE_CO_BRANDING_LOGO_URL =
  'https://images.ctfassets.net/yjgtqlbfutxo/4qSABqwPt2ubQdPjdwbAJm/50ae2822238a8feb97a8dc3e9fc3e785/Stride_Cobranded.png?fm=webp&h=96';
export const STRIDE_LOGO_TEAL_URL =
  'https://images.ctfassets.net/heg7gyzix6dq/u3bQRoaT5JHEYngDYqwKd/93efdaf56ff23ed8cca71f1bd6098651/Final_Logo_-_Black.webp?h=80';
export const STRIDE_CO_BRANDING_LOGO_TEAL_URL =
  'https://images.ctfassets.net/yjgtqlbfutxo/4qSABqwPt2ubQdPjdwbAJm/50ae2822238a8feb97a8dc3e9fc3e785/Stride_Cobranded.png?fm=webp&h=96';

export default GLOBALS;
