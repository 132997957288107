// @flow

import _ from 'lodash';

import { checkApplicants } from 'routes/health/utils/subsidy';
import { SubsidyModel } from 'client/models/health/TaxInfoModel';
import { PLAN_FLAGS, type PlanFlagType } from 'client/constants/health';
import {
  type RecommendationDataType,
  type TransformedRecommendationDataType,
  type TransformedHealthPlanType,
} from 'types/health/plan.flow';

import type { MemberType } from 'web-core/types/member';

export const setOepDate = (limitPlanYear) => {
  const buyDate = new Date();
  let planYear = buyDate.getFullYear();
  const fallback = false;

  const startMonth = 10;
  const startDay = 1;

  // try to set OEP
  const thisMonth = buyDate.getMonth();
  if (thisMonth > startMonth || (thisMonth === startMonth && buyDate.getDate() >= startDay)) {
    planYear++;
  }

  return {
    buyDate,
    planYear: limitPlanYear || planYear,
    fallback,
  };
};

export const setApplicationSubsidy = (aptc: ?number, csr: ?string, fpl: ?number) => {
  let finalCSR = csr;
  let finalFPL = fpl;
  let subsidyModel = new SubsidyModel({});
  if (!_.isNumber(aptc) || aptc < 0) {
    subsidyModel = null;
    return subsidyModel;
  }

  if (!_.isNumber(finalCSR) && !_.isString(finalCSR)) {
    finalCSR = null;
  }
  if (!_.isNumber(finalFPL)) {
    finalFPL = -1;
  }

  return new SubsidyModel({ amount: aptc, csr: finalCSR, fpl: finalFPL });
};

export const getMinSubsidyCost = (plans: Array<Object>, aptc: number) => {
  let aptcAmount = aptc;
  if (!_.isArray(plans)) {
    return null;
  }
  if (!_.isNumber(aptcAmount)) {
    aptcAmount = 0;
  }
  const minCostPlan = _.minBy(
    plans,
    plan => plan.premium - (plan.plan.metalType === 'Catastrophic' ? 0 : aptcAmount),
  );

  const minCost = Math.ceil(_.get(minCostPlan, 'premium', 0));

  return Math.max(Math.floor(minCost - aptcAmount), 0);
};

export const getCoveredMembersEstimate = (
  getSubsidyEstimate: Object,
  newGetSubsidyEstimate: Object,
  members: Array<Object>,
) => {
  // Data object to return
  const membersSubsidyInfo = {
    oldEstimate: null,
    newEstimate: null,
    oldMemberEligibility: {
      ineligibleMembers: [],
      medicaidGapMembers: [],
      medicaidMembers: [],
      subsidyMembers: [],
      subsidyType: '',
    },
    newMemberEligibility: {
      ineligibleMembers: [],
      medicaidGapMembers: [],
      medicaidMembers: [],
      subsidyMembers: [],
      subsidyType: '',
    },
    oldMemberStatuses: [],
    newMemberStatuses: [],
  };

  const applicantEligibility = checkApplicants(getSubsidyEstimate, members);

  _.each(members, (member, index) => {
    membersSubsidyInfo.oldMemberStatuses.push({
      member,
      eligibility: getSubsidyEstimate.eligibilities[index],
    });
  });

  // set new* properties also so consumers don't have to check if it exists and fall back to old
  // these new* properties will get overwritten with new values if the second estimate call is needed
  membersSubsidyInfo.oldMemberEligibility = applicantEligibility;
  membersSubsidyInfo.newMemberEligibility = applicantEligibility;
  membersSubsidyInfo.oldEstimate = getSubsidyEstimate;
  membersSubsidyInfo.newEstimate = getSubsidyEstimate;
  membersSubsidyInfo.newMemberStatuses = membersSubsidyInfo.oldMemberStatuses.slice(0);

  if (
    !applicantEligibility.subsidyMembers.length ||
    applicantEligibility.subsidyMembers.length === members.length
  ) {
    return membersSubsidyInfo;
  }

  membersSubsidyInfo.newMemberEligibility = checkApplicants(
    newGetSubsidyEstimate,
    applicantEligibility.subsidyMembers,
  );
  membersSubsidyInfo.newEstimate = newGetSubsidyEstimate;

  return membersSubsidyInfo;
};

export const getOrdinal = (number: number, locale: string = 'en-US') => {
  let n = number;

  if (!_.isNumber(n)) {
    n = parseInt(n, 10);
  }

  if (!_.isNaN(n)) {
    if (locale === 'es-US') {
      const b = n % 10;
      let suffix;

      switch (b) {
        case 1:
          suffix = 'er';
          break;

        case 2:
          suffix = 'do';
          break;

        case 3:
          suffix = 'er';
          break;

        case 7:
          suffix = 'mo';
          break;

        case 8:
          suffix = 'vo';
          break;

        case 9:
          suffix = 'no';
          break;

        case 0:
          suffix = 'mo';
          break;

        default:
          suffix = 'to';
          break;
      }
      return n + suffix;
    }

    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }
  return '';
};

export const getMaleMember = (members: Array<MemberType>): Array<MemberType> =>
  _.filter(members, { gender: 'M' });

export const getDependant = (members: Array<MemberType>): Array<MemberType> =>
  _.filter(members, { type: 'dependent' });

// Non-dependent female under 40 yrs old
export const hasFemaleUnderLimit = (members: Array<MemberType>): boolean =>
  _.some(members, m => m.type !== 'dependent' && m.gender === 'F' && m.age <= 40);

export const getNonDependent = (members: Array<MemberType>): Array<MemberType> =>
  _.filter(members, m => m.type !== 'dependent');

export const getCarriers = (plans: Array<TransformedRecommendationDataType>) => {
  const carriers = [];
  _.each(plans, (plan) => {
    let existing = _.find(carriers, { name: plan.plan.carrier });

    if (!existing) {
      existing = { name: plan.plan.carrier, id: plan.plan.carrierId };
      carriers.push(existing);
    }
  });

  return carriers;
};

export const getTiers = (
  plans: Array<TransformedRecommendationDataType>,
): Array<TransformedRecommendationDataType> => {
  const metalTiers = {
    platinum: 0,
    gold: 1,
    silver: 2,
    bronze: 3,
    catastrophic: 4,
  };
  return _.uniq(_.map(plans, 'plan.metalType')).sort((a, b) => metalTiers[a] - metalTiers[b]);
};

export const getPlanTypes = (
  plans: Array<TransformedRecommendationDataType>,
): Array<TransformedRecommendationDataType> => _.uniq(_.map(plans, 'plan.planType'));

type ForecastType = {
  [key: string]: {
    key: string,
    count: void | number,
    amount: number,
    totalRawCost?: void | number,
    monthlyPremium?: number,
    planPremiums?: number,
    premiumCredits?: number,
  },
};

export const getForecasts = (plan: TransformedRecommendationDataType): ForecastType => {
  // These are items our model actually forecast: ben_primary, ben_specialist, ben_labs, ben_imaging, ben_er, rx_generic
  // inpatient, er...are fixed numbers across all plans returning from API
  const forecastServices = {};

  _.forEach(plan.mods.forecasts.family.services, (service) => {
    _.set(forecastServices, service.serviceType, service);
  });

  /* eslint-disable no-param-reassign */
  const drugs = _.reduce(
    plan.mods.forecasts.family.drugs,
    (prev, curr) => {
      prev.amount += curr.amount;
      prev.count += 1;
      prev.totalRawCost += curr.totalRawCost;
      return prev;
    },
    {
      amount: 0,
      count: 0,
      totalRawCost: 0,
    },
  );
  /* eslint-enable no-param-reassign */

  _.set(forecastServices, 'ben_drugs', drugs);

  const forecastLineItems = {
    primary: ['primary'],
    specialist: ['specialist'],
    labs: ['labs', 'imaging'], // this row combines lab and imaging
    drugs: ['drugs'],
    hospital: ['inpatient'],
    er: ['er'],
  };

  const forecast = {};

  _.forEach(forecastLineItems, (valueArray, key) => {
    const count = _.reduce(
      valueArray,
      (pre, value) => pre + _.get(forecastServices, `ben_${value}.count`, 0),
      0,
    );
    const amount = _.reduce(
      valueArray,
      (pre, value) => pre + _.get(forecastServices, `ben_${value}.amount`, 0),
      0,
    );
    const totalRawCost = _.reduce(
      valueArray,
      (pre, value) => pre + _.get(forecastServices, `ben_${value}.totalRawCost`, 0),
      0,
    );

    forecast[key] = {
      key,
      count,
      amount,
      totalRawCost,
    };
  });

  forecast.premium = {
    key: 'premium',
    count: 12,
    amount: plan.mods.premiumYear, // yearly premium with subsidy
    totalRawCost: plan.premiumMonth * 12, // yearly premium before subsidy
    monthlyPremium: plan.mods.premiumMonth, // monthly premium with subsidy
    planPremiums: plan.premiumMonth, // monthly premium before subsidy
    premiumCredits: plan.premiumMonth - plan.mods.premiumMonth,
  };

  return forecast;
};

export const transformApiPlansToAppJsonPlans = (
  plans: Array<RecommendationDataType>,
): Array<TransformedRecommendationDataType> =>
  _.map(plans, (plan: RecommendationDataType) => {
    const transformedPlan: TransformedRecommendationDataType = plan;
    const apiBenefits = transformedPlan.plan.benefits;
    const benefits = {
      visits: {},
      drugs: {},
    };
    if (_.isArray(apiBenefits)) {
      _.forEach(apiBenefits, (benefit) => {
        if (benefit.serviceType.indexOf('ben') > -1) {
          benefits.visits[benefit.serviceType] = {
            ...benefit,
          };
        } else {
          benefits.drugs[benefit.serviceType] = {
            ...benefit,
          };
        }
      });
      transformedPlan.plan.benefits = benefits;
    }

    // TODO: https://stridehealth.atlassian.net/browse/MARKET-2345
    transformedPlan.plan.mods = plan.mods;
    transformedPlan.plan.premiumMonth = plan.premiumMonth;
    transformedPlan.plan.special = plan.special;
    transformedPlan.plan.flags = plan.flags;

    return transformedPlan;
  });

/**
 * "carrier": {
    "Blue Shield of California": true,
    "CCHP": true,
    "Health Net": true,
    "Oscar": true,
    "Kaiser": true
  },
  "planType": {
    "HMO": true,
    "EPO": true,
    "PPO": true
  },
  "metalType": {
    "platinum": true,
    "gold": true,
    "silver": true,
    "bronze": true,
    "catastrophic": true
  },
  "hsaStatus": {
    "hsaStatus": true
  }
 */
export type FilterListType = {
  [string]: {
    [string]: boolean,
  },
};

export const getActiveFilterNames = (
  filterList: FilterListType,
  commonTranslations: TranslationType,
) => {
  const activeFilterNames = [];
  if (!_.isEmpty(filterList)) {
    _.forEach(filterList, (value, key) => {
      _.forEach(filterList[key], (innerValue, innerKey) => {
        if (innerValue) {
          activeFilterNames.push(
            _.get(commonTranslations, `form.filters.labels.${innerKey}`, innerKey),
          );
        }
      });
    });
  }
  return activeFilterNames;
};

export const filteringPlans = (
  plans: Array<TransformedRecommendationDataType>,
  filters: FilterListType,
) => {
  if (_.isEmpty(filters)) {
    return plans;
  }

  let filteredPlans = [...plans];
  const keys = Object.keys(filters);
  _.forEach(keys, (key) => {
    const filterItem = filters[key];
    const filterItemKeys = Object.keys(filterItem).filter(
      filterItemKey => filterItem[filterItemKey],
    );
    if (!_.isEmpty(filterItemKeys)) {
      filteredPlans = _.filter(
        filteredPlans,
        plan => _.includes(filterItemKeys, plan.plan[key]) || plan.plan[key] === true,
      );
    }
  });
  return filteredPlans;
};

/**
 * This method transform members into demographic shorthand like p25f,s18f
 */
export function getMemberParams(members: Array<MemberType>) {
  if (!_.isArray(members) || !members.length) {
    return '';
  }

  return _.map(members, (member) => {
    const { age } = member;
    const memberType = member.type || 'dependent';
    const tobacco = member.tobacco ? 't' : 'f';
    let type = memberType.charAt(0);

    if (type === 'd') {
      type = '';
    }

    return type + age + tobacco;
  }).join(',');
}

// Ordered list for display priority
const planFlagPrecedence = [
  PLAN_FLAGS.RECOMMENDED,
  PLAN_FLAGS.CHEAPEST,
  PLAN_FLAGS.CSR,
  PLAN_FLAGS.GOOD_DEAL,
  PLAN_FLAGS.SIMILAR,
  PLAN_FLAGS.ALTERNATE,
];

export const goodDealFlags = [PLAN_FLAGS.CSR, PLAN_FLAGS.GOOD_DEAL];

export const getSingleFlagByPrecedence = (
  plan: TransformedHealthPlanType,
  skipFlags: Array<PlanFlagType> = [],
): PlanFlagType | null =>
  _.difference(planFlagPrecedence, skipFlags).find(
    flag => Array.isArray(plan.flags) && plan.flags.includes(flag),
  ) || null;

export const getLimitPlanYear = (state) => {
  let limitPlanYear = _.get(
    state,
    'siteConfigs.brandSiteConfig.partnerConfig.agencyInfo.limitPlanYear',
  );
  limitPlanYear = limitPlanYear ? limitPlanYear * 1 : null;
  return limitPlanYear;
};

export const removeOscarOffExPlans = (plans: Array<Object>): Array<Object> =>
  _.reject(plans, ({ plan }) => plan?.carrier?.includes('Oscar') && plan?.onExchange === false);

export const transformHealthPlans = (plans: Array<Object> = [], isConsumer: boolean) => {
  if (!plans || !plans.length) return null;
  const clonedPlans = _.cloneDeep(plans);
  const cleanPlans =
    clonedPlans?.length && isConsumer ? removeOscarOffExPlans(clonedPlans) : clonedPlans;
  return transformApiPlansToAppJsonPlans(cleanPlans);
};
