// @flow

import React from 'react';
import { connect } from 'react-redux';

import ErrorPage from 'client/components/error/errorPage';
import { isStridePartner, getDashboardUrl } from 'client/utils/configUtils';

const mapStateToProps: Object = (state: Object) => {
  const { siteConfigs } = state;
  const isPartner = isStridePartner(siteConfigs);
  const dashboardUrl = getDashboardUrl(state);

  return {
    siteConfigs,
    isPartner,
    dashboardUrl,
  };
};

const NotFoundPage = (props: Object) => <ErrorPage {...props} />;

export default connect(mapStateToProps, {})(NotFoundPage);
