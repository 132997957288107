import React, { CSSProperties } from 'react';
import _ from 'lodash';
import {
  STRIDE_CO_BRANDING_LOGO_TEAL_URL,
  STRIDE_CO_BRANDING_LOGO_URL,
  STRIDE_LOGO_TEAL_URL,
  STRIDE_LOGO_URL,
} from 'client/constants/globals';

import 'client/styles/brandLogo.scss';

type SiteConfigs = {
  brandSiteConfig: Record<string, any>;
  defaultSiteConfig: Record<string, any>;
};

export type Props = {
  brandName: string;
  logoUrl: string;
  logoHref: string;
  style?: CSSProperties;
  siteConfigs: SiteConfigs;
  useTeal?: boolean;
};

const BRAND_LOGO_URL_PATH = 'brandSiteConfig.logo.file.url';
const CO_BRANDING_LOGO_FALLBACK_PATH = 'brandSiteConfig.coBrandingLogoLarge.file.url';

const defaultProps: Partial<Props> = {
  style: undefined,
  logoHref: '/',
  brandName: 'stride',
  logoUrl: '',
  useTeal: false,
};

const BrandLogo = (props: Props) => {
  const { brandName, logoHref, logoUrl, style, siteConfigs, useTeal = false } = props;
  const coBrandingLogoUrl = _.get(siteConfigs, CO_BRANDING_LOGO_FALLBACK_PATH);
  const partnerLogoUrl = _.get(siteConfigs, BRAND_LOGO_URL_PATH);
  const LOGO_URL = useTeal ? STRIDE_LOGO_TEAL_URL : STRIDE_LOGO_URL;
  const CO_BRANDING_LOGO_URL = useTeal
    ? STRIDE_CO_BRANDING_LOGO_TEAL_URL
    : STRIDE_CO_BRANDING_LOGO_URL;
  const isEnterprise =
    _.get(siteConfigs, 'partnerAgencyInfo.partnerInfo.partnershipType') === 'ENTERPRISE';

  // fallback for original component usage: one single co-branding image
  const renderCoBrandingLogo = () => (
    <a
      href={logoHref}
      className="logo header-logo-container cobranded-logo"
      style={style}>
      <img src={logoUrl || coBrandingLogoUrl} alt={`${brandName} logo`} />
    </a>
  );

  const renderPartnerLogo = () => {
    if (isEnterprise) {
      return (
        <span className="logo header-logo-container" style={style}>
          <img className="stride-logo-img" src={partnerLogoUrl} alt={`${brandName} logo`} />
        </span>
      );
    }

    return (
      <a href={logoHref} className="logo header-logo-container partner-logo" style={style}>
        <img className="stride-logo-img" src={CO_BRANDING_LOGO_URL} alt="Stride Health logo" />
        <img className="partner-logo-img" src={partnerLogoUrl} alt={`${brandName} logo`} />
      </a>
    );
  };

  const renderDefaultLogo = () => (
    <a href={logoHref} className="logo header-logo-container" style={style}>
      <img src={LOGO_URL} alt={`${brandName} logo`} />
    </a>
  );

  if (logoUrl || coBrandingLogoUrl) {
    return renderCoBrandingLogo();
  }

  if (partnerLogoUrl) {
    return renderPartnerLogo();
  }

  return renderDefaultLogo();
};

BrandLogo.defaultProps = defaultProps;

export default BrandLogo;
