// @flow strict

export const AMOUNT = 'Amount';
export const CALCULATED_YEARLY_ESTIMATE = 'Calculated Yearly Estimate';
export const DISCLAIMER_PAGE_CTA = 'Disclaimer Page CTA';
export const EDE_DMI_REASON = 'EDE DMI Reason';
export const EDE_FFM_REDIRECT_RETRY_ERROR = 'EDE FFM Redirect Retry Error';
export const EDE_ID_VERIFY_CALL_MX = 'web-click-callmx';
export const EDE_ID_VERIFY_OUTCOME = 'EDE ID Verify Outcome';
export const EDE_ID_VERIFY_PHOTO_CLICK = 'web-click-photo';
export const EDE_JUMP_TO_HCG = 'web - click - ede_jump_to_healthcare_gov';
export const ELIGIBLE_FOR_SUBSIDY = 'Eligible for Subsidy';
export const FREQUENCY = 'Frequency';
export const HEALTH_PREMIUM = 'Health Premium';
export const INCOME_DELTA = 'Income Delta';
export const INCOME_DELTA_OLD_NEW = 'Income Estimate Delta (new - old)';
export const INCOME_DISCREPENCY = 'Income Discrepency';
export const INCOME_ENTERED = 'Income Entered';
export const MEDICAID_ELIGIBLE_HOUSEHOLD = 'Medicaid Eligible Household';
export const MEMBERS_DROPPED_TO_MEDICAID_CHIP = 'Members dropped to Medicaid / CHIP';
export const PURCHASE_COMPLETE = 'Purchase Complete';
export const PURCHASED_PLAN_STRIDE_RANK = 'Purchased Plan Stride Rank';
export const SEND_TO_MEDICAID_CLICKED = 'Send to Medicaid Clicked';
export const SEND_TO_MEDICAID_SUCCEEDED = 'Send to Medicaid Succeeded';
export const SOURCE = 'Source';

// Complete Page
export const CROSS_SELL_FOLLOW = 'Cross Sell Follow';
export const PAYMENT_REDIRECT_ERROR = 'Payment Redirects Error';
export const PAYMENT_REDIRECT_FOLLOW = 'Payment Redirects Follow';
export const PAYMENT_REDIRECT_LOADED = 'Payment Redirects Loaded';
