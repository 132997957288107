// @flow

export const ABOVE_THRESHOLD = 'Above threshold';
export const ACTIVE = 'Active';
export const BELOW_THRESHOLD = 'Below threshold';
export const CARRIER_AND_PLAN_NAMES = 'Plan Name';
export const CARRIER_NAME = 'carrier_name';
export const CATEGORY = 'Category';
export const CELEBRATION_ALERT_VIEWED = 'Celebration Alert Viewed';
export const CHECKED = 'Checked';
export const HEALTH_CREATE_ACCOUNT = 'Web: Health: Create Account';
export const HEALTH_LOGIN_ACCOUNT = 'Web: Health: Login';
export const HEALTH_ENROLL_CLICK = 'Health Enroll Click';
export const HEALTH_ONBOARDING_CONDITION_ADDED = 'Health Onboarding Condition';
export const HEALTH_ONBOARDING_ESTIMATED_INCOME = 'Health Onboarding Estimated Income';
export const HEALTH_ONBOARDING_ESTIMATED_SUBSIDY = 'Health Onboarding Estimated Subsidy';
export const HEALTH_ONBOARDING_ESTIMATED_USAGE_LEVEL = 'Health Onboarding Estimated Usage Level';
export const HEALTH_ONBOARDING_DOCTOR_SKIPPED = 'Health Onboarding Doctor Skipped';
export const HEALTH_ONBOARDING_PRESCRIPTIONS_SKIPPED = 'Health Onboarding Prescriptions Skipped';
export const HEALTH_ONBOARDING_CONDITIONS_SKIPPED = 'Health Onboarding Conditions Skipped';
export const HEALTH_PREMIUM = 'Health Premium';
export const HEALTH_SKIP_TO_RECOMMENDATION = 'Web: Health: Skip To Recommendation';
export const HOUSEHOLD_DEMOGRAPHICS = 'Household Demographics';
export const HOUSEHOLD_SIZE = 'household_size';
export const IS_ACTIVE = 'Is Active';
export const METAL_TIER = 'metal_tier';
export const OUT_OF_POCKET = 'Out of Pocket';
export const PANEL_NAME = 'panel_name';
export const PLAN_COMPARE_CANCEL = 'Plan Compare: Cancel Plan Comparison Click';
export const PLAN_COMPARE_DETAIL_CLICK = 'web - click - view_compared_plan';
export const PLAN_COMPARE_FILTER = 'Plan Compare: Filter';
export const PLAN_COMPARE_HEAD_TO_HEAD = 'web - view - head_to_head';
export const PLAN_COMPARE_RECOMMENDATION = 'Plan Compare: Recommendation'; // matching existing event
export const PLAN_COMPARE_SELECT_PLAN = 'Plan Compare: Select Plan'; // matching existing event
export const PLAN_COMPARE_SORT_BY = 'Plan Compare: Sort By'; // matching existing event
export const PLAN_COMPARE_VIEW_PLAN = 'Plan Compare: View Plan'; // matching existing event
export const PLAN_DETAIL_TOGGLE_INFO = 'Plan Detail: Toggle Info'; // matching existing event
export const PLAN_FLAG = 'Plan Flag';
export const PLAN_NAME = 'plan_name';
export const PLAN_YEAR_VIEWED = 'Plan year viewed'; // matching existing event
export const PLANS_COMPARED = 'Plans Compared'; // matching existing event
export const PROFILE_EDIT_CLICK = 'web - click - edit_profile';
export const RECOMMENDATION_DETAIL_CLICK = 'web - click - recommendation_detail';
export const RECOMMENDATION_VIEW_RECOMMENDATION_CLICK =
  'web - click - recommendation_view_recommendation';
export const SORT_BY = 'Sort By';
export const STATE_HEALTH_APP = 'State Health App';
export const STRIDE_RANK = 'Stride Rank';
export const SUBSIDY_SAVINGS = 'Subsidy Savings';
export const SUBSIDY_SPLIT_CHOICE = 'Subsidy Split Choice';
export const SUMMARY_OF_COVERAGE = 'SBC link click'; // matching existing event
export const VALUE = 'Value';
export const WEB_IMPRESSION_INCOME_SUBSIDY = 'web - impression - income_subsidy'; // onboarding /subsidy/income
export const WEB_INCOME_CLARIFICATION_MODAL = 'web - click - income_clarification_modal';
export const WEB_INCORRECT_INCOME_MODAL = 'web - click - incorrect_income_modal';
export const WEB_SKIP_INCOME_ENTRY = 'web - click - skip_income_entry';
export const ICHRA_CONSENT_BOX_CHECKED = 'Ichra Consent Box Checked';

// A/B Recommendation Plans Layout for Mobile
// https://app.optimizely.com/v2/projects/16836890651/features/18812172089/rules
export const HEALTH_RECOMMENDATION_CARD_UPDATE_V2_VIEW_DETAILS =
  'web_recommendation_card_update_v2 - click - view_details';
export const HEALTH_RECOMMENDATION_CARD_UPDATE_V2_VIEW_RECOMMENDED =
  'web_recommendation_card_update_v2 - click - view_recommended';

export type SourceTypes = 'Plan Detail' | 'Plan List' | 'Plan Recommendation';

export class SOURCES {
  static PLAN_DETAIL: SourceTypes = 'Plan Detail';
  static PLAN_LIST: SourceTypes = 'Plan List';
  static PLAN_RECOMMENDATION: SourceTypes = 'Plan Recommendation';
}

export type CelebrationAlertType =
  | 'income_too_high'
  | 'income_too_low'
  | 'medicaid_qualified'
  | 'subsidy_qualified';

export class CELEBRATION_ALERT_TYPES {
  static INCOME_TOO_HIGH: CelebrationAlertType = 'income_too_high';
  static INCOME_TOO_LOW: CelebrationAlertType = 'income_too_low';
  static MEDICAID_QUALIFIED: CelebrationAlertType = 'medicaid_qualified';
  static SUBSIDY_QUALIFIED: CelebrationAlertType = 'subsidy_qualified';
}

export type EnrollClickPathType =
  | 'recommendation'
  | 'view_all'
  | 'detail'
  | 'compare'
  | 'enrollment group';

export class ENROLL_CLICK_PATH {
  static COMPARE: EnrollClickPathType = 'compare';
  static DETAIL: EnrollClickPathType = 'detail';
  static ENROLLMENT_GROUP: EnrollClickPathType = 'enrollment group';
  static RECOMMENDATION: EnrollClickPathType = 'recommendation';
  static VIEW_ALL: EnrollClickPathType = 'view_all';
}
