// @flow

import styled from 'styled-components';
import React from 'react';

type SvgType = {
  className: string,
  height: string,
  stroke: string,
  viewBox: string,
  width: string,
};

const SvgWrapper = styled.div`
  display: inline-block;
  height: ${props => props.height};
  width: ${props => props.width};
  color: inherit;
  margin-right: 0.5rem;
`;

const FeatherEditIcon = ({ stroke, className, width, height, viewBox }: SvgType) => (
  <SvgWrapper className={className} width={width} height={height}>
    <svg
      width="100%"
      height="100%"
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g transform="translate(-13.000000, -13.000000)" stroke={stroke}>
          <g transform="translate(12.000000, 12.000000)">
            <g transform="translate(2.000000, 2.000000)">
              <path
                d="M9,3 L2,3 C0.8954305,3 0,3.8954305 0,5 L0,19 C0,20.1045695 0.8954305,21 2,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,12"
                id="Path"
                strokeWidth="2" />
              <path
                d="M16.5,1.5 C17.3284271,0.671572905 18.6715729,0.671572919 19.5,1.50000003 C20.3284271,2.32842714 20.3284271,3.67157287 19.5,4.5 L10,14 L6,15 L7,11 L16.5,1.5 Z"
                id="Path"
                strokeWidth="2" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SvgWrapper>
);

FeatherEditIcon.defaultProps = {
  stroke: 'currentColor',
  className: '',
  width: '20px',
  height: '20px',
  viewBox: '0 0 23 23',
};

export default FeatherEditIcon;
