// @flow

import React from 'react';

const ShieldIcon = () => (
  <svg
    width="18px"
    height="22px"
    viewBox="0 0 18 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>shield (1)</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.721569 0 0 0 0 0.737255 0 0 0 0 0.764706 0 0 0 1.000000 0" />
      </filter>
    </defs>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g id="d_personalize_all_conditions_option" transform="translate(-376.000000, -437.000000)">
        <g filter="url(#filter-1)" id="shield-(1)">
          <g transform="translate(377.000000, 438.000000)">
            <path
              d="M8,20 C8,20 16,16 16,10 L16,3 L8,0 L0,3 L0,10 C0,16 8,20 8,20 Z"
              id="Path"
              stroke="#B8BCC3"
              strokeWidth="2" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ShieldIcon;
