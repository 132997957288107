import React from 'react';

const LockIcon = () => (
  <svg width="39" height="48" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="lock">
      <g id="Group 104">
        <g id="Group 101">
          <path
            id="Combined Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5994 3.57866C13.5837 2.64757 16.0677 2.1272 19.1454 2.1272C22.2251 2.1272 24.7104 2.64827 26.6953 3.58055C27.737 6.63122 29.9883 9.32966 32.7932 10.8545C33.4188 12.8678 33.6817 15.1242 33.6817 17.5073C33.6817 29.9674 20.4692 40.4181 19.1454 40.4181C17.8215 40.4181 4.60899 30.1859 4.60899 17.5073C4.60899 15.1249 4.87181 12.869 5.49704 10.856C8.30797 9.33043 10.5601 6.62971 11.5994 3.57866Z"
            fill="#F5FCFD" />
          <path
            id="Combined Shape Copy"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1455 1.77271C16.0999 1.77271 13.5421 2.27555 11.4489 3.25775C11.3617 3.29865 11.2949 3.37325 11.2639 3.46439C10.25 6.44077 8.0518 9.06617 5.32802 10.5445C5.24678 10.5886 5.18597 10.6626 5.15855 10.7509C4.5464 12.7217 4.25455 14.9805 4.25455 17.5073C4.25455 23.2097 6.84667 28.8539 11.109 33.9448C14.1323 37.5559 18.0926 40.7727 19.1455 40.7727C20.1897 40.7727 24.1492 37.509 27.1827 33.862C31.4429 28.74 34.0364 23.114 34.0364 17.5073C34.0364 14.9799 33.7444 12.7205 33.1319 10.7493C33.1045 10.6611 33.0437 10.5871 32.9626 10.543C30.2436 9.06489 28.0468 6.44098 27.0309 3.46603C26.9999 3.37504 26.9332 3.30057 26.8461 3.25969C24.7522 2.27622 22.193 1.77271 19.1455 1.77271ZM4.609 17.5074C4.609 15.1249 4.87182 12.869 5.49705 10.8561C8.30797 9.3305 10.5601 6.62979 11.5994 3.57874C13.5837 2.64764 16.0677 2.12728 19.1454 2.12728C22.2251 2.12728 24.7104 2.64835 26.6953 3.58063C27.737 6.6313 29.9883 9.32974 32.7932 10.8545C33.4188 12.8679 33.6817 15.1243 33.6817 17.5074C33.6817 29.9675 20.4692 40.4182 19.1454 40.4182C17.8215 40.4182 4.609 30.1859 4.609 17.5074Z"
            fill="#3A7A98" />
          <path
            id="Combined Shape_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1454 40.4181C19.1454 40.4181 19.1454 40.4181 19.1454 40.4181C20.4692 40.4181 33.6818 29.9674 33.6818 17.5073C33.6818 15.1242 33.4188 12.8678 32.7932 10.8545C29.9883 9.32966 27.737 6.63122 26.6954 3.58055C24.7104 2.64827 22.2251 2.1272 19.1454 2.1272C19.1454 2.1272 19.1454 2.1272 19.1454 2.1272V40.4181Z"
            fill="#DEECEF" />
          <path
            id="Combined Shape Copy 19"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.6953 3.58055C24.7104 2.64827 22.2251 2.1272 19.1454 2.1272C16.0677 2.1272 13.5837 2.64757 11.5994 3.57866C10.5601 6.62971 8.30797 9.33043 5.49704 10.856C4.87181 12.869 4.60899 15.1249 4.60899 17.5073C4.60899 30.1859 17.8215 40.4181 19.1454 40.4181C20.4692 40.4181 33.6817 29.9674 33.6817 17.5073C33.6817 15.1242 33.4188 12.8678 32.7932 10.8545C29.9883 9.32966 27.737 6.63122 26.6953 3.58055ZM5.31804 17.5073C5.31804 15.1969 5.57042 13.1332 6.09336 11.3352C8.84449 9.75731 11.0609 7.11246 12.1672 4.09955C14.0619 3.26723 16.379 2.83631 19.1453 2.83631C21.9137 2.83631 24.2322 3.26787 26.1277 4.10141C27.2355 7.11256 29.4503 9.75576 32.1968 11.3334C32.72 13.1318 32.9726 15.1961 32.9726 17.5073C32.9726 22.8273 30.4803 28.2338 26.3648 33.1818C24.8113 35.0496 23.1075 36.7382 21.5241 38.0433C20.8619 38.5892 20.2549 39.0379 19.7697 39.3463C19.5517 39.4848 19.3664 39.5896 19.2253 39.6559C19.2072 39.6644 19.1909 39.6717 19.1769 39.678C19.1635 39.684 19.1522 39.689 19.1437 39.6932C19.1354 39.6893 19.1247 39.6846 19.1121 39.6791C19.0973 39.6726 19.0799 39.665 19.0605 39.656C18.9192 39.591 18.7338 39.488 18.5161 39.3521C18.0302 39.0489 17.4235 38.6077 16.7626 38.0708C15.1771 36.7829 13.4756 35.1148 11.9244 33.262C7.80828 28.3457 5.31804 22.9233 5.31804 17.5073ZM19.1453 39.709C19.1179 39.709 19.1209 39.7043 19.1437 39.6932C19.1673 39.7043 19.1713 39.709 19.1453 39.709Z"
            fill="#DEECEF" />
        </g>
        <path
          id="Combined Shape_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8454 27.1078C29.7472 29.2637 28.3934 31.2419 26.9922 32.9726H15.3016C15.2141 32.9726 15.1276 32.9682 15.0423 32.9596C13.7637 32.8303 12.7635 31.7551 12.7635 30.4478V30.4454V25.4218V20.3496C12.7635 19.1729 13.5723 18.1829 14.6669 17.902V14.193C14.6669 10.2704 17.862 7.09082 21.8045 7.09082C25.7461 7.09082 28.942 10.27 28.942 14.1909V17.9021C30.0357 18.1829 30.8454 19.1722 30.8454 20.3496V25.4218V27.1078ZM25.4525 14.1909V17.8223H18.1564V14.193C18.1564 12.1878 19.7893 10.5628 21.8045 10.5628C23.8191 10.5628 25.4525 12.1877 25.4525 14.1909Z"
          fill="#CCDCE0" />
        <path
          id="Combined Shape_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1454 7.6001C16.5201 8.64924 14.6669 11.2057 14.6669 14.1931V17.9022C13.5723 18.183 12.7635 19.1731 12.7635 20.3498V25.422V30.4455V30.448C12.7635 31.7552 13.7637 32.8304 15.0423 32.9597C15.1276 32.9684 15.2141 32.9728 15.3016 32.9728H19.1454V17.8225H18.1564V14.1931C18.1564 13.2313 18.532 12.357 19.1454 11.7075V7.6001Z"
          fill="#DEECEF" />
        <g id="Group 102">
          <g id="lock_2">
            <path
              id="Rectangle 5"
              d="M15.6746 15.4904V11.7113C15.6746 9.70608 17.3075 8.08108 19.3226 8.08108C21.3373 8.08108 22.9707 9.70602 22.9707 11.7092V16.0385H26.4602V11.7092C26.4602 7.78834 23.2643 4.60913 19.3226 4.60913C15.3802 4.60913 12.1851 7.7887 12.1851 11.7113V15.4904H15.6746Z"
              fill="#DCE1E5" />
            <path
              id="Rectangle 5 Copy"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6746 15.845C15.8704 15.845 16.0291 15.6863 16.0291 15.4905V11.7113C16.0291 9.90231 17.503 8.43568 19.3227 8.43568C21.1418 8.43568 22.6162 9.90217 22.6162 11.7092V16.0385C22.6162 16.2343 22.7749 16.3931 22.9708 16.3931H26.4602C26.656 16.3931 26.8148 16.2343 26.8148 16.0385V11.7092C26.8148 7.59214 23.4597 4.25464 19.3227 4.25464C15.1849 4.25464 11.8306 7.59244 11.8306 11.7113V15.4905C11.8306 15.6863 11.9893 15.845 12.1851 15.845H15.6746ZM15.6746 11.7113V15.4904H12.1852V11.7113C12.1852 7.78873 15.3803 4.60916 19.3227 4.60916C23.2643 4.60916 26.4603 7.78837 26.4603 11.7092V16.0385H22.9708V11.7092C22.9708 9.70604 21.3373 8.08111 19.3227 8.08111C17.3076 8.08111 15.6746 9.70611 15.6746 11.7113Z"
              fill="#3A7A98" />
            <path
              id="Rectangle 5 Copy_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.1851 15.4904H15.6746V11.7113C15.6746 9.70608 17.3075 8.08108 19.3226 8.08108C21.3373 8.08108 22.9707 9.70602 22.9707 11.7092V16.0385H26.4602V11.7092C26.4602 7.78834 23.2643 4.60913 19.3226 4.60913C15.3802 4.60913 12.1851 7.7887 12.1851 11.7113V15.4904ZM19.3228 7.72656C17.1122 7.72656 15.3201 9.50988 15.3201 11.7113V15.1359H12.5397V11.7113C12.5397 7.98482 15.5758 4.9637 19.3228 4.9637C23.069 4.9637 26.1058 7.98457 26.1058 11.7092V15.6839H23.3254V11.7092C23.3254 9.50966 21.5326 7.72656 19.3228 7.72656Z"
              fill="#EAF0F5" />
            <path
              id="Rectangle 4"
              d="M12.8198 15.3406C11.4181 15.3406 10.2818 16.4731 10.2818 17.8679V27.9636C10.2818 29.3594 11.4197 30.4909 12.8198 30.4909H25.8256C27.2273 30.4909 28.3636 29.3584 28.3636 27.9636V17.8679C28.3636 16.4721 27.2256 15.3406 25.8256 15.3406L12.8198 15.3406Z"
              fill="#DCE1E5" />
            <path
              id="Rectangle 4 Copy"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.8256 14.9861H12.8198C11.2229 14.9861 9.92722 16.2767 9.92722 17.8679V27.9637C9.92722 29.5551 11.2237 30.8455 12.8198 30.8455H25.8256C27.4224 30.8455 28.7181 29.5549 28.7181 27.9637V17.8679C28.7181 16.2765 27.4216 14.9861 25.8256 14.9861ZM10.2818 17.8679C10.2818 16.4732 11.4181 15.3406 12.8198 15.3406H25.8256C27.2257 15.3406 28.3636 16.4722 28.3636 17.8679V27.9637C28.3636 29.3584 27.2273 30.491 25.8256 30.491H12.8198C11.4197 30.491 10.2818 29.3595 10.2818 27.9637V17.8679Z"
              fill="#3A7A98" />
            <path
              id="Rectangle 4 Copy_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.8256 15.3406H12.8198C11.4181 15.3406 10.2818 16.4731 10.2818 17.8679V27.9636C10.2818 29.3594 11.4197 30.4909 12.8198 30.4909H25.8256C27.2273 30.4909 28.3636 29.3584 28.3636 27.9636V17.8679C28.3636 16.4721 27.2256 15.3406 25.8256 15.3406ZM10.6364 17.8679C10.6364 16.6689 11.614 15.6951 12.8198 15.6951H25.8256C27.0308 15.6951 28.0091 16.6688 28.0091 17.8679V27.9636C28.0091 29.1626 27.0315 30.1364 25.8256 30.1364H12.8198C11.6147 30.1364 10.6364 29.1627 10.6364 27.9636V17.8679Z"
              fill="#EAF0F5" />
            <path
              id="Rectangle 4 Copy 2"
              d="M10.2818 22.9402C10.2818 27.1104 13.6624 30.491 17.8326 30.491H20.8128C24.983 30.491 28.3636 27.1104 28.3636 22.9402V22.9402L10.2818 22.9402Z"
              fill="#D5DCE1" />
            <path
              id="Rectangle 4 Copy 4"
              d="M10.2818 22.9402V27.9662V27.9662C10.2818 29.3606 11.4197 30.491 12.8198 30.491H25.8256C27.2273 30.491 28.3636 29.3606 28.3636 27.9662V22.9402"
              stroke="#C9CED3"
              strokeLinecap="round"
              strokeLinejoin="round" />
          </g>
          <path
            id="Combined Shape_5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8937 22.7102C21.4237 22.2943 21.764 21.6496 21.764 20.9259C21.764 19.6716 20.742 18.6548 19.4814 18.6548C18.2208 18.6548 17.1988 19.6716 17.1988 20.9259C17.1988 21.651 17.5403 22.2967 18.072 22.7125L17.0573 25.8121C16.8457 26.4586 17.3274 27.1232 18.0077 27.1232H20.9561C21.6361 27.1232 22.1178 26.4592 21.9067 25.8128L20.8937 22.7102Z"
            fill="#488CAC" />
          <path
            id="Combined Shape Copy 2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9775 27.4778C22.4161 27.4778 22.6973 27.0923 22.5614 26.676L21.3037 22.8238C21.818 22.3345 22.1184 21.6546 22.1184 20.926C22.1184 19.4754 20.9373 18.3003 19.4813 18.3003C18.0252 18.3003 16.8442 19.4754 16.8442 20.926C16.8442 21.656 17.1457 22.337 17.6616 22.8265L16.4022 26.6736C16.2645 27.0942 16.5462 27.4778 16.985 27.4778H21.9775ZM20.4795 22.5844L21.8456 26.7687L17.1171 26.7687L17.076 26.8942C17.0891 26.8543 17.0263 26.7687 16.985 26.7687L17.1171 26.7687L18.4861 22.5865L18.2902 22.4333C17.8278 22.0717 17.5531 21.5216 17.5531 20.9259C17.5531 19.8679 18.4159 19.0094 19.4811 19.0094C20.5464 19.0094 21.4092 19.8679 21.4092 20.9259C21.4092 21.5205 21.1355 22.0696 20.6747 22.4313L20.4795 22.5844ZM21.8457 26.7687L21.8873 26.8961C21.8732 26.8531 21.9348 26.7687 21.9774 26.7687H21.8457ZM21.2445 22.6429L21.2306 22.6001L21.2248 22.6192L21.2445 22.6429Z"
            fill="#3A7A98" />
          <path
            id="Combined Shape Copy 2_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4812 17.9458C17.8298 17.9458 16.4896 19.2793 16.4896 20.926C16.4896 21.6763 16.7703 22.3813 17.2578 22.9202L16.0652 26.5634C15.8522 27.2142 16.306 27.8324 16.985 27.8324H21.9775C22.6567 27.8324 23.1093 27.2119 22.8984 26.566L21.7072 22.9174C22.1932 22.3788 22.4729 21.675 22.4729 20.926C22.4729 19.2793 21.1327 17.9458 19.4812 17.9458ZM16.7391 26.784L18.0719 22.7126C17.5402 22.2968 17.1987 21.6511 17.1987 20.926C17.1987 19.6717 18.2207 18.6549 19.4813 18.6549C20.7419 18.6549 21.7639 19.6717 21.7639 20.926C21.7639 21.6497 21.4236 22.2944 20.8937 22.7103L22.2244 26.7861C22.2852 26.9723 22.1759 27.1233 21.9775 27.1233H16.985C16.7879 27.1233 16.6764 26.9758 16.7391 26.784Z"
            fill="#CDD5DB" />
          <path
            id="Rectangle 125"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1851 14.3938H12.5023V15.0251H12.1851V14.3938Z"
            fill="#DCE1E5" />
          <path
            id="Rectangle 125 Copy"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3573 14.3938H15.6745V15.0251H15.3573V14.3938Z"
            fill="#DCE1E5" />
          <rect
            id="Rectangle 124"
            x="12.5023"
            y="14.3938"
            width="2.85502"
            height="0.631264"
            fill="#CDD5DB" />
          <path
            id="Rectangle 125 Copy 3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9708 14.3938H23.288V15.0251H22.9708V14.3938Z"
            fill="#DCE1E5" />
          <path
            id="Rectangle 125 Copy 2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.143 14.3938H26.4602V15.0251H26.143V14.3938Z"
            fill="#DCE1E5" />
          <rect
            id="Rectangle 124 Copy"
            x="23.288"
            y="14.3938"
            width="2.85502"
            height="0.631264"
            fill="#CDD5DB" />
        </g>
      </g>
      <path
        id="Star 4 Copy 11"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.7718 12.7161C37.6633 12.7389 37.5567 12.8464 37.5341 12.9537L37.3098 14.0201C37.2642 14.2366 37.19 14.2351 37.1447 14.0201L36.9204 12.9537C36.8976 12.8453 36.7901 12.7387 36.6827 12.7161L35.6164 12.4917C35.3998 12.4462 35.4013 12.3719 35.6164 12.3267L36.6827 12.1023C36.7912 12.0795 36.8978 11.972 36.9202 11.8653L37.1447 10.7983C37.1903 10.5818 37.2645 10.5833 37.3098 10.7984L37.5341 11.8647C37.5569 11.9731 37.6644 12.0797 37.7718 12.1023L38.8381 12.3267C39.0547 12.3723 39.0532 12.4465 38.8381 12.4917L37.7718 12.7161Z"
        fill="#D0DFE2" />
      <path
        id="Star 4 Copy 12"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.31724 30.4431C2.20881 30.4659 2.10219 30.5734 2.0796 30.6808L1.85524 31.7471C1.80967 31.9637 1.73547 31.9622 1.69021 31.7471L1.46585 30.6808C1.44304 30.5723 1.33557 30.4657 1.22821 30.4431L0.161876 30.2188C-0.0547153 30.1732 -0.0532007 30.099 0.161876 30.0537L1.22821 29.8294C1.33664 29.8066 1.44328 29.699 1.46571 29.5924L1.69022 28.5254C1.73579 28.3088 1.80999 28.3103 1.85524 28.5254L2.0796 29.5917C2.10242 29.7002 2.20989 29.8068 2.31724 29.8294L3.38358 30.0537C3.60017 30.0993 3.59866 30.1735 3.38358 30.2188L2.31724 30.4431Z"
        fill="#D0DFE2" />
      <path
        id="Star 4 Copy 13"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8627 2.07961C5.75427 2.10242 5.64765 2.20989 5.62506 2.31725L5.4007 3.38358C5.35513 3.60017 5.28092 3.59865 5.23567 3.38358L5.01131 2.31725C4.98849 2.20882 4.88103 2.1022 4.77367 2.07961L3.70733 1.85525C3.49074 1.80968 3.49226 1.73547 3.70733 1.69022L4.77367 1.46586C4.8821 1.44304 4.98874 1.33548 5.01117 1.22887L5.23568 0.161848C5.28124 -0.0547212 5.35545 -0.053189 5.4007 0.161887L5.62506 1.22822C5.64788 1.33665 5.75534 1.44327 5.8627 1.46586L6.92903 1.69022C7.14563 1.73579 7.14411 1.81 6.92903 1.85525L5.8627 2.07961Z"
        fill="#D0DFE2" />
      <ellipse id="shadow" cx="19.5" cy="46.4455" rx="10.6364" ry="1.06364" fill="#DEECEF" />
    </g>
  </svg>
);

export default LockIcon;
