// @flow

// Does not include non-functional "NONE" type
export type LawfulPresenceDocumentationType =
  | 'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94'
  | 'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94'
  | 'CERTIFICATE_OF_CITIZENSHIP'
  | 'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019'
  | 'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20'
  | 'CERTIFICATE_OF_NATURALIZATION'
  | 'CERTIFICATION_FROM_HHS_ORR'
  | 'CUBAN_HAITAN_ENTRANT'
  | 'EMPLOYMENT_AUTHORIZATION_CARD_I_766'
  | 'FOREIGN_PASSPORT'
  | 'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE'
  | 'NOTICE_OF_ACTION_I_797'
  | 'NS1_MEMBERS_OF_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE'
  | 'NS4_NON_CITIZEN_WHO_IS_LAWFULLY_PRESENT_IN_AMERICAN_SAMOA'
  | 'ORR_ELIGIBILITY_LETTER'
  | 'OTHER'
  | 'PERMANENT_RESIDENT_CARD_I_551'
  | 'REENTRY_PERMIT'
  | 'REFUGEE_TRAVEL_DOCUMENT'
  | 'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A'
  | 'VAWA_SELF_PETITIONER';

// Does not have CERTIFICATE_OF_CITIZENSHIP or CERTIFICATE_OF_NATURALIZATION labels
// Sorted to match LawfulPresenceDocumentationType alphabeticization
// Also missing non-functional "None of these" label
export type LawfulPresenceDocumentationLabelType =
  | 'Arrival/Departure Record (I-94/I-94A)'
  | 'Arrival/Departure Record in foreign passport (I-94)'
  | 'Certificate of Eligibility for Exchange Visitor (J-1) Status'
  | 'Certificate of Eligibility for Nonimmigrant (F-1) Student Status (I-20)'
  | 'Employment Authorization Card (I-766)'
  | 'Unexpired foreign passport'
  | 'Machine Readable Immigrant Visa (with temporary I-551 language)'
  | 'Notice of Action (I-797)'
  | "Another document or :firstNameLastName's Alien Number/I-94 Number" // Value is interpolated in component
  | 'I-551 (Permanent Resident Card, "Green Card")'
  | 'I-327 (Reentry Permit)'
  | 'Refugee Travel Document (I-571)'
  | 'Temporary I-551 Stamp (on passport or I-94/I-94A)';

export type QhpLawfulPresenceStatusReasonType =
  | '189_DHS_NAME_MISMATCH'
  | '191_EDS_NOT_AVAILABLE'
  | '227_NO_EDS_DATA_FOUND'
  | '228_CONSULT_SCHOOL'
  | '395_DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP'
  | '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
  | '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
  | '400_VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN'
  | '432_DMI_RESOLVED_BY_ESW_ADJUDICATION'
  | '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
  | '464_NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP'
  | '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
  | '476_ESW_EXPIRED_DMI'
  | '477_ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE'
  | '555_N_A_RULE_DOES_NOT_APPLY'
  | '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE'
  | '566_SEVIS_ID_REQUIRED'
  | '567_NOT_VERIFIED_PENDING_SAVE_STEP_2'
  | '568_NOT_VERIFIED_PENDING_PAPER_VERIFICATION'
  | '569_APPLICANT_ATSTD_CITIZEN'
  | '570_EXPIRATION_DATE_90_DAYS_OR_LESS'
  | '635_DHS_BIRTHDATE_MISMATCH'
  | '636_DHS_DOCUMENT_NUMBER_MISMATCH'
  | '682_FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_LP_EXPIRE_AFTER_90_DAYS'
  | '683_FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_TEMP_LP_EXPIRE_WITHIN_90_DAYS'
  | '684_APPLICATION_REQUIRES_STEP_3'
  | '685_APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED'
  | '999_N_A';

export type CitizenshipStatusReasonType =
  | '177_CITIZENSHIP_NOT_VERIFIED'
  | '187_DEATH_DATA_PRESENT'
  | '189_DHS_DATA_NOT_MATCH_ATTESTATION'
  | '191_EDS_NOT_AVAILABLE'
  | '227_NO_EDS_DATA_FOUND'
  | '288_SSN_NOT_VERIFIED'
  | '341_HUB_DID_NOT_RESPOND'
  | '352_NO_SSN_PROVIDED'
  | '395_DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP'
  | '397_DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION'
  | '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
  | '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
  | '400_VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN'
  | '432_DMI_RESOLVED_BY_ESW_ADJUDICATION'
  | '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
  | '464_NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP'
  | '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
  | '476_ESW_EXPIRED_DMI'
  | '477_ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE'
  | '531_ATSTD_NON_CITIZEN'
  | '555_N_A_RULE_DOES_NOT_APPLY'
  | '559_NO_CITIZENSHIP_ATTESTATION'
  | '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE'
  | '568_NOT_VERIFIED_PENDING_PAPER_VERIFICATION'
  | '573_SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE'
  | '634_SSA_DATA_MISMATCH'
  | '635_DHS_BIRTHDATE_MISMATCH'
  | '636_DHS_DOCUMENT_NUMBER_MISMATCH'
  | '684_APPLICATION_REQUIRES_STEP_3'
  | '685_APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED'
  | '999_N_A_RULE_INDICATOR_IS_Y'
  | '999_N_A';

export type SSNStatusReasonType =
  | '187_DEATH_DATA_PRESENT'
  | '191_EDS_NOT_AVAILABLE'
  | '227_NO_EDS_DATA_FOUND'
  | '287_SSN_WAS_NOT_MATCHED_WITH_ATTESTED_INFORMATION'
  | '288_SSN_NOT_VERIFIED'
  | '341_HUB_DID_NOT_RESPOND'
  | '352_NO_SSN_PROVIDED'
  | '432_DMI_RESOLVED_BY_ESW_ADJUDICATION'
  | '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
  | '476_ESW_EXPIRED_DMI'
  | '477_ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE'
  | '555_N_A_RULE_DOES_NOT_APPLY'
  | '573_SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE'
  | '634_SSA_DATA_MISMATCH'
  | '999_N_A';

/* eslint-disable max-len */
export class LAWFUL_PRESENCE_DOCUMENTS {
  static ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94: LawfulPresenceDocumentationType =
    'ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94';
  static ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94: LawfulPresenceDocumentationType =
    'ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94';
  static CERTIFICATE_OF_CITIZENSHIP: LawfulPresenceDocumentationType = 'CERTIFICATE_OF_CITIZENSHIP';
  static CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019: LawfulPresenceDocumentationType =
    'CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019';
  static CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20: LawfulPresenceDocumentationType =
    'CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20';
  static CERTIFICATE_OF_NATURALIZATION: LawfulPresenceDocumentationType =
    'CERTIFICATE_OF_NATURALIZATION';
  static CERTIFICATION_FROM_HHS_ORR: LawfulPresenceDocumentationType = 'CERTIFICATION_FROM_HHS_ORR';
  static CUBAN_HAITAN_ENTRANT: LawfulPresenceDocumentationType = 'CUBAN_HAITAN_ENTRANT';
  static EMPLOYMENT_AUTHORIZATION_CARD_I_766: LawfulPresenceDocumentationType =
    'EMPLOYMENT_AUTHORIZATION_CARD_I_766';
  static FOREIGN_PASSPORT: LawfulPresenceDocumentationType = 'FOREIGN_PASSPORT';
  static MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE: LawfulPresenceDocumentationType =
    'MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE';
  static NOTICE_OF_ACTION_I_797: LawfulPresenceDocumentationType = 'NOTICE_OF_ACTION_I_797';
  static NS1_MEMBERS_OF_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE: LawfulPresenceDocumentationType =
    'NS1_MEMBERS_OF_A_FEDERALLY_RECOGNIZED_INDIAN_TRIBE';
  static NS4_NON_CITIZEN_WHO_IS_LAWFULLY_PRESENT_IN_AMERICAN_SAMOA: LawfulPresenceDocumentationType =
    'NS4_NON_CITIZEN_WHO_IS_LAWFULLY_PRESENT_IN_AMERICAN_SAMOA';
  static ORR_ELIGIBILITY_LETTER: LawfulPresenceDocumentationType = 'ORR_ELIGIBILITY_LETTER';
  static OTHER: LawfulPresenceDocumentationType = 'OTHER';
  static PERMANENT_RESIDENT_CARD_I_551: LawfulPresenceDocumentationType =
    'PERMANENT_RESIDENT_CARD_I_551';
  static REENTRY_PERMIT: LawfulPresenceDocumentationType = 'REENTRY_PERMIT';
  static REFUGEE_TRAVEL_DOCUMENT: LawfulPresenceDocumentationType = 'REFUGEE_TRAVEL_DOCUMENT';
  static TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A: LawfulPresenceDocumentationType =
    'TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A';
  static VAWA_SELF_PETITIONER: LawfulPresenceDocumentationType = 'VAWA_SELF_PETITIONER';
}
/* eslint-enable max-len */

/* eslint-disable max-len */
export class QHP_LAWFUL_PRESENCE_STATUS_REASONS {
  static DHS_NAME_MISMATCH: QhpLawfulPresenceStatusReasonType = '189_DHS_NAME_MISMATCH';
  static EDS_NOT_AVAILABLE: QhpLawfulPresenceStatusReasonType = '191_EDS_NOT_AVAILABLE';
  static NO_EDS_DATA_FOUND: QhpLawfulPresenceStatusReasonType = '227_NO_EDS_DATA_FOUND';
  static CONSULT_SCHOOL: QhpLawfulPresenceStatusReasonType = '228_CONSULT_SCHOOL';
  static DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP: QhpLawfulPresenceStatusReasonType =
    '395_DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP';
  static ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS: QhpLawfulPresenceStatusReasonType =
    '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS';
  static NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS: QhpLawfulPresenceStatusReasonType =
    '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS';
  static VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN: QhpLawfulPresenceStatusReasonType =
    '400_VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN';
  static DMI_RESOLVED_BY_ESW_ADJUDICATION: QhpLawfulPresenceStatusReasonType =
    '432_DMI_RESOLVED_BY_ESW_ADJUDICATION';
  static DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF: QhpLawfulPresenceStatusReasonType =
    '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF';
  static NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP: QhpLawfulPresenceStatusReasonType =
    '464_NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP';
  static HUB_CALL_RETURNED_TRANSACTIONAL_ERROR: QhpLawfulPresenceStatusReasonType =
    '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR';
  static ESW_EXPIRED_DMI: QhpLawfulPresenceStatusReasonType = '476_ESW_EXPIRED_DMI';
  static ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE: QhpLawfulPresenceStatusReasonType =
    '477_ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE';
  static N_A_RULE_DOES_NOT_APPLY: QhpLawfulPresenceStatusReasonType = '555_N_A_RULE_DOES_NOT_APPLY';
  static INDIVIDUAL_NOT_REQUESTING_COVERAGE: QhpLawfulPresenceStatusReasonType =
    '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE';
  static SEVIS_ID_REQUIRED: QhpLawfulPresenceStatusReasonType = '566_SEVIS_ID_REQUIRED';
  static NOT_VERIFIED_PENDING_SAVE_STEP_2: QhpLawfulPresenceStatusReasonType =
    '567_NOT_VERIFIED_PENDING_SAVE_STEP_2';
  static NOT_VERIFIED_PENDING_PAPER_VERIFICATION: QhpLawfulPresenceStatusReasonType =
    '568_NOT_VERIFIED_PENDING_PAPER_VERIFICATION';
  static APPLICANT_ATSTD_CITIZEN: QhpLawfulPresenceStatusReasonType = '569_APPLICANT_ATSTD_CITIZEN';
  static EXPIRATION_DATE_90_DAYS_OR_LESS: QhpLawfulPresenceStatusReasonType =
    '570_EXPIRATION_DATE_90_DAYS_OR_LESS';
  static DHS_BIRTHDATE_MISMATCH: QhpLawfulPresenceStatusReasonType = '635_DHS_BIRTHDATE_MISMATCH';
  static DHS_DOCUMENT_NUMBER_MISMATCH: QhpLawfulPresenceStatusReasonType =
    '636_DHS_DOCUMENT_NUMBER_MISMATCH';
  static FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_LP_EXPIRE_AFTER_90_DAYS: QhpLawfulPresenceStatusReasonType =
    '682_FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_LP_EXPIRE_AFTER_90_DAYS';
  static FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_TEMP_LP_EXPIRE_WITHIN_90_DAYS: QhpLawfulPresenceStatusReasonType =
    '683_FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_TEMP_LP_EXPIRE_WITHIN_90_DAYS';
  static APPLICATION_REQUIRES_STEP_3: QhpLawfulPresenceStatusReasonType =
    '684_APPLICATION_REQUIRES_STEP_3';
  static APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED: QhpLawfulPresenceStatusReasonType =
    '685_APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED';
  static N_A: QhpLawfulPresenceStatusReasonType = '999_N_A';
}
/* eslint-enable max-len */

/* eslint-disable max-len */
export class CITIZENSHIP_STATUS_REASONS {
  static CITIZENSHIP_NOT_VERIFIED: CitizenshipStatusReasonType = '177_CITIZENSHIP_NOT_VERIFIED';
  static DEATH_DATA_PRESENT: CitizenshipStatusReasonType = '187_DEATH_DATA_PRESENT';
  static DHS_DATA_NOT_MATCH_ATTESTATION: CitizenshipStatusReasonType =
    '189_DHS_DATA_NOT_MATCH_ATTESTATION';
  static EDS_NOT_AVAILABLE: CitizenshipStatusReasonType = '191_EDS_NOT_AVAILABLE';
  static NO_EDS_DATA_FOUND: CitizenshipStatusReasonType = '227_NO_EDS_DATA_FOUND';
  static SSN_NOT_VERIFIED: CitizenshipStatusReasonType = '288_SSN_NOT_VERIFIED';
  static HUB_DID_NOT_RESPOND: CitizenshipStatusReasonType = '341_HUB_DID_NOT_RESPOND';
  static NO_SSN_PROVIDED: CitizenshipStatusReasonType = '352_NO_SSN_PROVIDED';
  static DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP: CitizenshipStatusReasonType =
    '395_DID_NOT_ATTEST_TO_NATURALIZED_CITIZENSHIP_OR_LP';
  static DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION: CitizenshipStatusReasonType =
    '397_DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION';
  static ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS: CitizenshipStatusReasonType =
    '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS';
  static NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS: CitizenshipStatusReasonType =
    '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS';
  static VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN: CitizenshipStatusReasonType =
    '400_VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN';
  static DMI_RESOLVED_BY_ESW_ADJUDICATION: CitizenshipStatusReasonType =
    '432_DMI_RESOLVED_BY_ESW_ADJUDICATION';
  static DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF: CitizenshipStatusReasonType =
    '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF';
  static NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP: CitizenshipStatusReasonType =
    '464_NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP';
  static HUB_CALL_RETURNED_TRANSACTIONAL_ERROR: CitizenshipStatusReasonType =
    '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR';
  static ESW_EXPIRED_DMI: CitizenshipStatusReasonType = '476_ESW_EXPIRED_DMI';
  static ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE: CitizenshipStatusReasonType =
    '477_ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE';
  static ATSTD_NON_CITIZEN: CitizenshipStatusReasonType = '531_ATSTD_NON_CITIZEN';
  static N_A_RULE_DOES_NOT_APPLY: CitizenshipStatusReasonType = '555_N_A_RULE_DOES_NOT_APPLY';
  static NO_CITIZENSHIP_ATTESTATION: CitizenshipStatusReasonType = '559_NO_CITIZENSHIP_ATTESTATION';
  static INDIVIDUAL_NOT_REQUESTING_COVERAGE: CitizenshipStatusReasonType =
    '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE';
  static NOT_VERIFIED_PENDING_PAPER_VERIFICATION: CitizenshipStatusReasonType =
    '568_NOT_VERIFIED_PENDING_PAPER_VERIFICATION';
  static SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE: CitizenshipStatusReasonType =
    '573_SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE';
  static SSA_DATA_MISMATCH: CitizenshipStatusReasonType = '634_SSA_DATA_MISMATCH';
  static DHS_BIRTHDATE_MISMATCH: CitizenshipStatusReasonType = '635_DHS_BIRTHDATE_MISMATCH';
  static DHS_DOCUMENT_NUMBER_MISMATCH: CitizenshipStatusReasonType =
    '636_DHS_DOCUMENT_NUMBER_MISMATCH';
  static APPLICATION_REQUIRES_STEP_3: CitizenshipStatusReasonType =
    '684_APPLICATION_REQUIRES_STEP_3';
  static APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED: CitizenshipStatusReasonType =
    '685_APPLICATION_PUSHED_TO_STEP_2_BUT_HUB_CALL_FAILED';
  static N_A_RULE_INDICATOR_IS_Y: CitizenshipStatusReasonType = '999_N_A_RULE_INDICATOR_IS_Y';
  static N_A: CitizenshipStatusReasonType = '999_N_A';
}
/* eslint-enable max-len */

export class SSN_STATUS_REASONS {
  static DEATH_DATA_PRESENT: SSNStatusReasonType = '187_DEATH_DATA_PRESENT';
  static EDS_NOT_AVAILABLE: SSNStatusReasonType = '191_EDS_NOT_AVAILABLE';
  static NO_EDS_DATA_FOUND: SSNStatusReasonType = '227_NO_EDS_DATA_FOUND';
  static SSN_WAS_NOT_MATCHED_WITH_ATTESTED_INFORMATION: SSNStatusReasonType =
    '287_SSN_WAS_NOT_MATCHED_WITH_ATTESTED_INFORMATION';
  static SSN_NOT_VERIFIED: SSNStatusReasonType = '288_SSN_NOT_VERIFIED';
  static HUB_DID_NOT_RESPOND: SSNStatusReasonType = '341_HUB_DID_NOT_RESPOND';
  static NO_SSN_PROVIDED: SSNStatusReasonType = '352_NO_SSN_PROVIDED';
  static DMI_RESOLVED_BY_ESW_ADJUDICATION: SSNStatusReasonType =
    '432_DMI_RESOLVED_BY_ESW_ADJUDICATION';
  static DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF: SSNStatusReasonType =
    '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF';
  static ESW_EXPIRED_DMI: SSNStatusReasonType = '476_ESW_EXPIRED_DMI';
  static ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE: SSNStatusReasonType =
    '477_ESW_EXPIRED_ANOTHER_DMI_SO_INELGBLE';
  static N_A_RULE_DOES_NOT_APPLY: SSNStatusReasonType = '555_N_A_RULE_DOES_NOT_APPLY';
  static SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE: SSNStatusReasonType =
    '573_SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE';
  static SSA_DATA_MISMATCH: SSNStatusReasonType = '634_SSA_DATA_MISMATCH';
  static N_A: SSNStatusReasonType = '999_N_A';
}

export const SEVIS_LAWFUL_PRESENCE_DOCUMENTS = [
  LAWFUL_PRESENCE_DOCUMENTS.ARRIVAL_DEPARTURE_RECORD_IN_FOREIGN_PASSPORT_I_94,
  LAWFUL_PRESENCE_DOCUMENTS.ARRIVAL_DEPARTURE_RECORD_IN_UNEXPIRED_FOREIGN_PASSPORT_I_94,
  LAWFUL_PRESENCE_DOCUMENTS.CERTIFICATE_OF_ELIGIBILITY_FOR_EXCHANGE_VISITOR_STATUS_DS_2019,
  LAWFUL_PRESENCE_DOCUMENTS.CERTIFICATE_OF_ELIGIBILITY_FOR_NONIMMIGRANT_STUDENT_STATUS_I_20,
  LAWFUL_PRESENCE_DOCUMENTS.EMPLOYMENT_AUTHORIZATION_CARD_I_766,
  LAWFUL_PRESENCE_DOCUMENTS.FOREIGN_PASSPORT,
  LAWFUL_PRESENCE_DOCUMENTS.MACHINE_READABLE_IMMIGRANT_VISA_WITH_TEMPORARY_I_551_LANGUAGE,
  LAWFUL_PRESENCE_DOCUMENTS.NOTICE_OF_ACTION_I_797,
  LAWFUL_PRESENCE_DOCUMENTS.PERMANENT_RESIDENT_CARD_I_551,
  LAWFUL_PRESENCE_DOCUMENTS.REENTRY_PERMIT,
  LAWFUL_PRESENCE_DOCUMENTS.REFUGEE_TRAVEL_DOCUMENT,
  LAWFUL_PRESENCE_DOCUMENTS.TEMPORARY_I_551_STAMP_ON_PASSPORT_OR_I_94_I_94A,
];

export const NATURALIZATION_DOCUMENTS = [
  LAWFUL_PRESENCE_DOCUMENTS.CERTIFICATE_OF_NATURALIZATION,
  LAWFUL_PRESENCE_DOCUMENTS.CERTIFICATE_OF_CITIZENSHIP,
];

export const UNVERIFIED_CITIZENSHIP_STATUS_REASONS = [
  CITIZENSHIP_STATUS_REASONS.DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION,
  CITIZENSHIP_STATUS_REASONS.DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF,
  CITIZENSHIP_STATUS_REASONS.EDS_NOT_AVAILABLE,
  CITIZENSHIP_STATUS_REASONS.HUB_DID_NOT_RESPOND,
  CITIZENSHIP_STATUS_REASONS.SSA_HUB_CALL_HELD_CALL_COUNTER_3_OR_MORE,
  CITIZENSHIP_STATUS_REASONS.SSA_DATA_MISMATCH,
  CITIZENSHIP_STATUS_REASONS.CITIZENSHIP_NOT_VERIFIED,
];

/* eslint-disable max-len */
export const GRANT_DATE_REQUIRED_STATUS_REASONS = [
  QHP_LAWFUL_PRESENCE_STATUS_REASONS.FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_LP_EXPIRE_AFTER_90_DAYS,
  QHP_LAWFUL_PRESENCE_STATUS_REASONS.FIVE_YEAR_BAR_PEND_NEED_GRANT_DATE_AND_TEMP_LP_EXPIRE_WITHIN_90_DAYS,
];
/* eslint-enable max-len */

export const DOCUMENT_VERIFICATION_NEEDED_CITIZENSHIP_STATUS_REASONS = [
  CITIZENSHIP_STATUS_REASONS.DHS_BIRTHDATE_MISMATCH,
  CITIZENSHIP_STATUS_REASONS.DHS_DATA_NOT_MATCH_ATTESTATION,
  CITIZENSHIP_STATUS_REASONS.DHS_DOCUMENT_NUMBER_MISMATCH,
];

export const UNVERIFIED_QHP_LAWFUL_PRESENCE_STATUS_REASONS = [
  QHP_LAWFUL_PRESENCE_STATUS_REASONS.DHS_BIRTHDATE_MISMATCH,
  QHP_LAWFUL_PRESENCE_STATUS_REASONS.DHS_DOCUMENT_NUMBER_MISMATCH,
  QHP_LAWFUL_PRESENCE_STATUS_REASONS.DHS_NAME_MISMATCH,
];
