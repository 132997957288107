// @flow
import React from 'react';
import { string } from 'prop-types';

const ExclamationMark = ({ color }) => (
  <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="10.846" cy="10.867" rx="9.846" ry="9.778" fill={color} stroke={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.501 12.748h-1.274l-.381-7.456h2.028l-.373 7.456zm-1.743 2.337c0-.362.095-.637.286-.825.19-.188.46-.281.812-.281.347 0 .613.097.799.293.185.195.278.466.278.813 0 .346-.094.62-.282.823-.188.203-.453.304-.795.304-.346 0-.616-.097-.809-.293-.193-.195-.29-.473-.29-.835z"
      fill="#fff" />
  </svg>
);

ExclamationMark.propTypes = {
  color: string,
};

ExclamationMark.defaultProps = {
  color: '#F46E80',
};

export default ExclamationMark;
