// @flow

export type PendingMedicaidOrChipStatusType =
  | '150_SAVE_VERIFICATION_PENDING'
  | '191_EDS_NOT_AVAILABLE'
  | '227_NO_EDS_DATA_FOUND'
  | '302_RESIDENCY_INCOME_PENDING'
  | '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
  | '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
  | '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
  | '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
  | '605_HUB_INDICATES_NOT_LPV_OR_QNC'
  | '609_HUB_QNC_INDICATOR_IS_PENDING'
  | '618_VOT_STATUS_REQUIRES_DOCUMENTATION'
  | '619_OLDER_THAN_18_DOCUMENTATION_REQ'
  | '620_GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ'
  | '621_REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ'
  | '622_STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT'
  | '626_ATSTD_VETERAN'
  | '628_NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB'
  | '630_FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE'
  | '645_APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS'
  | '646_PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS';

export type StateDeterminationModelType = 'assessment' | 'determination';

/* eslint-disable max-len */
export const MEDICAID_AND_CHIP_PENDING_STATUS_REASON_CODES: {
  [key: string]: PendingMedicaidOrChipStatusType,
} = {
  APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS:
    '645_APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS',
  ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS: '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS',
  ATSTD_VETERAN: '626_ATSTD_VETERAN',
  DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF:
    '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF',
  EDS_NOT_AVAILABLE: '191_EDS_NOT_AVAILABLE',
  FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE:
    '630_FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE',
  GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ: '620_GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ',
  HUB_CALL_RETURNED_TRANSACTIONAL_ERROR: '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR',
  HUB_INDICATES_NOT_LPV_OR_QNC: '605_HUB_INDICATES_NOT_LPV_OR_QNC',
  HUB_QNC_INDICATOR_IS_PENDING: '609_HUB_QNC_INDICATOR_IS_PENDING',
  NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB:
    '628_NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB',
  NO_EDS_DATA_FOUND: '227_NO_EDS_DATA_FOUND',
  NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS:
    '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS',
  OLDER_THAN_18_DOCUMENTATION_REQ: '619_OLDER_THAN_18_DOCUMENTATION_REQ',
  PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS:
    '646_PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS',
  REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ:
    '621_REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ',
  RESIDENCY_INCOME_PENDING: '302_RESIDENCY_INCOME_PENDING',
  SAVE_VERIFICATION_PENDING: '150_SAVE_VERIFICATION_PENDING',
  STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT:
    '622_STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT',
  VOT_STATUS_REQUIRES_DOCUMENTATION: '618_VOT_STATUS_REQUIRES_DOCUMENTATION',
};

export const MEDICAID_AND_CHIP_PENDING_STATUS_REASONS: Array<PendingMedicaidOrChipStatusType> = Object.keys(
  MEDICAID_AND_CHIP_PENDING_STATUS_REASON_CODES,
)
  .sort()
  .map(k => MEDICAID_AND_CHIP_PENDING_STATUS_REASON_CODES[k]);

export const ASSESSMENT_STATE = 'assessment';
export const DETERMINATION_STATE = 'determination';
