const CONTENTFUL_ENTRY_ID = {
  ABOUT_PAGE: '75bK0I8wyspXShc17Mj1dG',
  APP_PAGE: 'W9n2sQF6f2CjDAEgzn0mz',
  CART_PAGE_TRANSLATIONS: '7phosFr7rCLKgNRICrbAIN',
  DASHBOARD_PAGE: '6cCoEMDRM59UjEZUoDVo1Z',
  COVERAGE_PAGE: '51Fa5VUfxyRxygf4kfloUJ',
  ERROR_PAGE: '3aTn9lrhgqKvE9VM874OE2',
  HEALTH_ACCOUNT_PAGE: 'yqSIeh4GknXzazQdRc6Qt',
  HEALTH_COVERAGE_PAGE: '5KQdapBVW6VDKVyPp5uIuT',
  HEALTH_PAGE: '5rz3x4rPscJNRr06atXVMM',
  HOME_PAGE: '499jDo619krWzysmrjzcUb',
  TAX_PAGE: '221lGVdVVSwkE0AW4O8Ok',

  // COVID SUPPORT
  COVID_19: '4GSHMhFBVXVpyPskUu4Ebu', // COVID 19 Emergency SEP
  COVID_19_FINANCE_GUIDE: '6dNTsV1kBm7NUFcg0s4ewo', // v2
  COVID_19_FIND_WORK: '5lp9epwDiAMebI8DS2UxEV',
  COVID_19_HEALTH_GUIDE: '14qq87TgodtKuVPYFcFjF8',
  COVID_19_SUPPORT_HUB: '2lQtIpViLJlEBm2li3u9V7', // v2
  COVID_19_TAX_GUIDE: '2ghwuMtYOKzM4JL2FLZA1r',
  MX_PHONE_NUMBER: '5Fh1Er0cZ2hXQhUPVAIFpF',

  MEDICAID_HUB_PAGE: '3wh6uNVAdgjk312fhZZD6g',
  MEDICAID_GUIDE_PAGE: '3Zo7FXVCnE7qmEORxE5l87',
};

export default CONTENTFUL_ENTRY_ID;
