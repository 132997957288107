// @flow

import _ from 'lodash';
import { connect } from 'react-redux';

import UnprocessableEntityPage from 'client/components/error/UnprocessableEntityPage';

const mapStateToProps: Object = (state) => {
  const { brand, contentful } = state;
  const contentfulData = _.get(contentful, `brands.${brand}`, {});

  return {
    contentfulData,
  };
};

export default connect(mapStateToProps, {})(UnprocessableEntityPage);
