// @flow

import _ from 'lodash';
import isValid from 'date-fns/is_valid';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import subYears from 'date-fns/sub_years';
import { email, SSN, redactedSSN, phoneNumber } from 'web-core/src/utils/validate';

function cleanDate(date: string | Date) {
  return typeof date === 'string' ? date.replace(/[^0-9/-]/g, '') : date;
}

export function isValidDate(date: string | Date, length: number) {
  let testDate = date;
  const invalidPath = { path: 'common.error.invalidDate' };
  if (typeof date === 'string') {
    const cleanedDate = cleanDate(date);
    if (cleanedDate.length !== length) {
      return invalidPath;
    }
    testDate = new Date(cleanedDate);
  }
  // $FlowFixMe, Flow doesn't like that the date arg might be a string
  return isValid(testDate) ? null : invalidPath;
}

export const isZipcodeValid = (zipcode: string) =>
  (typeof zipcode === 'string' ? zipcode.length === 5 && /\d{5}/.test(zipcode) : false);

export const dateValidation = [(date: string) => !!date && isValidDate(date, 10)];

// TODO: write tests for this, move to web-core, and allow for positive,
// negative number vals for years in past or years in the future, and allow for
// months, days in the past/future, also the now var is flawed, for a long
// running session, now is fixed to time of instantiation

// function dateIsBetween
// takes two arguments, a begin and end and returns a validator
// function that evaluates if a date is between the two specified values
// returns an obj if something is failing

// arguments
// begin and end are Date types or numbers
// if end is omitted, the end date is assumed to be now
// if begin and/or end are numbers they are interpreted as a date x years
// in the past

// validator
// when executed with date, two checks are performed:
// if the date is before begin date bound or after the end date, it returns an
// object that has a path key that can be used to display an error

export function dateIsBetween(begin: Date | number, end: Date | number) {
  const now = new Date();
  let beginDate = begin;
  let endDate = end;
  if (typeof begin === 'number') {
    beginDate = subYears(now, begin);
  }
  if (typeof end === 'number') {
    endDate = subYears(now, end);
  }
  if (!end) {
    endDate = now;
  }
  const checks = [
    {
      check: (date: Date | string | number) => isBefore(date, beginDate),
      path: 'ousideBegin',
    },
    {
      check: (date: Date | string | number) => isAfter(date, endDate),
      path: 'outsideEnd',
    },
  ];
  return (date: Date | string) => {
    const cleanedDate = cleanDate(date);
    const testDate = new Date(cleanedDate);
    return _.find(checks, ({ check }) => check(testDate));
  };
}

// returns undefined for successful match
// used as redux-form validator
export function isValidPhoneNumber(val: string) {
  if (val && !phoneNumber.test(val)) {
    return { path: 'common.error.invalidPhone' };
  }

  return undefined;
}

/*
Application Services Companion Guide 02/04/2019:
Send error when (errorCode = invalid_field_format):
1-alienNumber <> null AND
2-alienNumber <> 9 characters OR
3-alienNumber <> numbers
*/
export function isValidAlienNumber(val: string) {
  if (typeof val !== 'string') {
    return false;
  }
  // TODO: Implement how we want to match these strings in funnel, with range
  // of digits as valid: !!val.match(/^[\d]{7,9}$/);
  return !!val.match(/^[\d]{9}$/);
}

/*
Application Services Companion Guide 02/04/2019:
Send error when (errorCode = invalid_field_format):
1-cardNumber <> null AND
2-cardNumber <> 13 characters OR
3-cardNumber <> first 3 characters are letters OR
4-cardNumber <> last 10 characters are numbers

Stride note: The 'OR' values look incorrect, because both 3-char letters only
and 10-char numbers only throw an error.
*/
export function isValidGreenCardNumber(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  return !!val.match(/^[a-zA-Z]{3}\d{10}$/);
}

/*
Application Services Companion Guide 02/04/2019:
Send error when (errorCode = length_must_be_greater):
1-passportNumber <> null AND
2-passportNumber < 6 characters

Send error when (errorCode = length_must_be_lesser):
1-passportNumber <> null AND
2-passportNumber > 12 characters

Send error when (errorCode = invalid_field_format):
1-passportNumber <> null AND
3-passportNumber <> numbers or letters (no special characters)
*/
export function isValidPassportNumber(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  return !!val.match(/^[a-zA-Z0-9]{6,12}$/);
}

/*
ui_question_companion_guide_040219_v12
For i94 number, allow only numbers other than optionally a letter in the 10th position
*/
export function isValidI94Number(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  return !!val.match(/^[\d]{9}[a-zA-Z\d]{1}[\d]{1}$/);
}

/*
Application Services Companion Guide 02/04/2019:
Send error when (errorCode = invalid_field_format):
1-employmentAuthorizationCategoryIdentifier <> null AND
2-employmentAuthorizationCategoryIdentifier <> 3 characters
3-employmentAuthorizationCategoryIdentifier <> first characters is a letter OR
4-employmentAuthorizationCategoryIdentifier <> last 2 characters are a number

Stride note: The 'OR' statement look incorrect, because anything other than 1
letter followed by 2 numbers is throwing an error
*/
export function isValidCategoryCode(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  return !!val.match(/^[a-zA-Z]{1}\d{2}$/);
}

/*
Application Services Companion Guide 02/04/2019:
Send error when (errorCode = invalid_field_format):
1-sevisID <> null AND
2-sevisID <> 10 characters OR
3-sevisID <> numbers
*/
export function isValidSevisId(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  return !!val.match(/^[\d]{10}$/);
}

/*
Application Services Companion Guide 02/04/2019:
Send error when (errorCode = length_must_be_lesser):
1-otherDocumentTypeText <> null AND
2-otherDocumentTypeText > 35 characters
*/
export function isValidOtherDocumentTypeText(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  return val.length <= 35;
}

/*
– Can include alphanumeric characters (i.e. a-z, A-Z, and 0-9)
– Can include the following special characters: _ . @ - ! " # $ % & ' ( ) * + / = ? ^ ` { } ~ : ; , [ ]
– Can include white space
– Can include diacritics
– Cannot include a combination of two dots and forward slash (i.e. ../)
*/
export function isValidEmployerName(val: string) {
  if (typeof val !== 'string') {
    return false;
  }

  // Pretty sure this, without regex, covers the above criteria
  return val.indexOf('../') === -1;
}

export function isValidLength(val: string, length: number) {
  if (_.isUndefined(val)) {
    return true;
  }
  if (typeof val !== 'string') {
    return false;
  }
  if (typeof length !== 'number') {
    return false;
  }

  return val.length <= length;
}

export function isValidPasswordLength(val: string, length: number = 8) {
  if (_.isUndefined(val)) {
    return false;
  }
  return val.length >= length;
}

export function isValidName(val: string) {
  if (!val) return true;

  const hasLetters = /[a-zA-Z]/;
  const validCharacters = /^[a-zA-Z .'-]+$/;
  return hasLetters.test(val) && validCharacters.test(val.trim());
}

export function isValidAddress(val: string) {
  const addressRegex = /^[A-Za-z0-9 '.\-,#/]+$/;
  return addressRegex.test(val);
}

export const isValidSSN = (str: string) => SSN.test(str);
export const isRedactedSSN = (str: string) => redactedSSN.test(str);

export const nameMatchGenerator = (member: Object) => {
  const regex = new RegExp(`^\\s*${member.first_name}\\s+${member.last_name}\\s*$`, 'i');
  return (val: string) => regex.test(val);
};

export const isValidEmail = (val: string): boolean => email.test(val);
