// @flow

import React from 'react';

const IncomeIcon = () => (
  <svg
    width="12px"
    height="21px"
    viewBox="0 0 12 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Income</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="Artboard"
        transform="translate(-212.000000, -155.000000)"
        stroke="#B8BCC3"
        strokeWidth="2">
        <g id="Dollar" transform="translate(213.000000, 156.000000)">
          <line x1="5" y1="3.00204306e-13" x2="5" y2="18.3333333" id="Path-Copy" />
          <path
            d="M9.16666667,3.33333333 L2.91666667,3.33333333 C1.30583615,3.33333333 -2.03392858e-13,4.63916948 -2.03392858e-13,6.25 C-2.03392858e-13,7.86083052 1.30583615,9.16666667 2.91666667,9.16666667 L7.08333333,9.16666667 C8.69416383,9.1666667 10,10.4725028 10,12.0833333 C10,13.6941638 8.69416383,15 7.08333333,15 L-2.03392858e-13,15"
            id="Path-Copy-2" />
        </g>
      </g>
    </g>
  </svg>
);

export default IncomeIcon;
