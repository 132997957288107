import React from 'react';
import { Router, useRouterHistory, applyRouterMiddleware } from 'react-router';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { createHistory } from 'history';
import { useScroll } from 'react-router-scroll';
import routerRoutes from './components/routes';
import configureStore from '../configureStore';
import * as rootReducer from './reducers';
import { BASE_NAME } from './base';

const store = configureStore(rootReducer, { client: true, initialData: window.__INITIAL_STATE__ });

const renderApp = (routes) => {
  render(
    <AppContainer>
      <Provider store={store}>
        <Router
          history={useRouterHistory(createHistory)({
            basename: `/${BASE_NAME}`,
          })}
          routes={routes}
          render={applyRouterMiddleware(useScroll())} />
      </Provider>
    </AppContainer>,
    document.getElementById(`${BASE_NAME}-root`),
  );
};

renderApp(routerRoutes);

if (module.hot) {
  module.hot.accept('./components/routes', () => {
    const newRoutes = require('./components/routes').default;
    renderApp(newRoutes);
  });
}

export const bundleName = BASE_NAME;
