// @flow

import styled from 'styled-components';
import React from 'react';

type SvgType = {
  className: string,
  height: string,
  stroke: string,
  viewBox: string,
  width: string,
};

const SvgWrapper = styled.div`
  display: inline-block;
  height: ${props => props.height};
  width: ${props => props.width};
  color: inherit;
  margin-right: 0.5rem;
`;

const LogoutIcon = ({ stroke, className, width, height, viewBox }: SvgType) => (
  <SvgWrapper className={className} width={width} height={height}>
    <svg
      width="100%"
      height="100%"
      viewBox={viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Log Out</title>
      <g
        id="LogoutIcon"
        stroke={stroke}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g
          id="Artboard"
          transform="translate(-49.000000, -34.000000)"
          stroke="#FFFFFF"
          strokeWidth="2">
          <g id="log_in" transform="translate(33.000000, 35.000000)">
            <g id="1-ATOMS/glyphs/48px/sign-in-bookmark" transform="translate(17.000000, 0.000000)">
              <g id="1-ATOMS/glyphs/32px/feather_log-in">
                <g
                  id="log-in-(7)"
                  transform="translate(11.000000, 10.500000) scale(-1, 1) translate(-11.000000, -10.500000) ">
                  <path
                    d="M15,0 L19.6666667,0 C20.9553311,0 22,1.04466892 22,2.33333333 L22,18.6666667 C22,19.9553311 20.9553311,21 19.6666667,21 L15,21"
                    id="Path" />
                  <polyline
                    id="Path"
                    transform="translate(3.083333, 10.500000) scale(-1, 1) translate(-3.083333, -10.500000) "
                    points="0.166666667 16.3333333 6 10.5 0.166666667 4.66666667" />
                  <line x1="15" y1="10.5" x2="1" y2="10.5" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SvgWrapper>
);

LogoutIcon.defaultProps = {
  stroke: 'currentColor',
  className: '',
  width: '20px',
  height: '20px',
  viewBox: '0 0 23 23',
};

export default LogoutIcon;
