// @flow

import React from 'react';
import { Tracking } from 'web-core/src/tracking';
import GLOBALS from 'client/constants/globals';

const { phone, phoneText } = GLOBALS;

type Props = {
  className?: string,
  trackingEvent?: string,
  trackingSource?: Object,
};

const handleTracking = (trackingEvent, trackingSource) =>
  // $FlowFixMe, default prop means trackingEvent is not undefined
  Tracking.track(trackingEvent, trackingSource);

const PhoneLink = ({ className, trackingEvent, trackingSource }: Props) => (
  <a
    href={`tel:${phone}`}
    className={className}
    onClick={() => handleTracking(trackingEvent, trackingSource)}>
    <span>{phoneText}</span>
  </a>
);

PhoneLink.defaultProps = {
  className: '',
  trackingEvent: 'Phone link clicked',
  trackingSource: {},
};

export default PhoneLink;
