// @flow

import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps: Object = (state: Object) => {
  const { siteConfigs } = state;
  return {
    siteConfigs,
  };
};

const InMaintenancePage = (props: Object) => {
  const phone = "415-930-9110";
  return (
      <div className="error-page page-dne">
        <div className="error-page--wrapper">
          <div className="error-bg-container">
            <div className="sorry">
              <img
                alt="sorry"
                src="https://images.contentful.com/heg7gyzix6dq/3kEqE5MkYMQes4ec0ouM4W/6614c448fe9f575668d4199890b13f50/sorry.svg" />
            </div>
          </div>
          <div className="error-content-container">
            <div className="text-center pad">
              <h3>
                We're away for a brief site update.<br />Please check back soon. We'll be back up & running shortly!
              </h3>
            </div>
            <div className="pad-top text-center">
              Please call <a href={`tel:${phone}`}>{phone}</a> with any questions
            </div>
          </div>
        </div>
      </div>
    );
};

export default connect(mapStateToProps, {})(InMaintenancePage);
