export { default as launchDarkly } from 'client/reducers/launchDarkly';
export { default as siteConfigs } from 'client/reducers/siteConfigs';

// avoiding Unexpected keys warning in log
export const partnerName = (state: string | null = null) => state;
export const apolloFfmId = (state: string | null = null) => state;
export const isGAEnabled = (state: boolean | null = null) => state;
export const planPageTimeout = (state: string | null = null) => state;
export const phase3Enabled = (state: boolean | null = null) => state;
export const memberDashboard = (state: string | null = null) => state;
export const useExtoleIntegration = (state: boolean | null = null) => state;
export const allowGaAccess = (state: boolean | null = null) => state;
