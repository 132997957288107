/**
 * This is a legacy file - it contains both utils as well as React components
 * True utils should be moved to the typescript file ./family-relationship.ts
 * Components should be moved to their own file, not under the utils folder
 *
 * As you move utils to the typescript file, please remove them from this file
 * and add an export from the typescript file so that we don't break any imports
 * Once this file is all import shims, we can delete it
 * */

// @flow
/* eslint-disable */
import React from 'react';
import _ from 'lodash';

import BoxContainer from 'routes/marketplace-ede/components/common/boxContainer';
import Translate from 'routes/marketplace-ede/web-ui/components/translate';

import { getEdeMemberAddress } from 'routes/marketplace-ede/utils/state';
import {
  filterMembersRequestingCoverage,
  getMemberAge,
  getPrimaryMember,
} from 'routes/marketplace-ede/utils/selectors';
import type { FamilyRelationshipEnum } from 'routes/marketplace-ede/types/profile/profile.flow';
import type { EdeMemberType } from 'routes/marketplace-ede/types/ede/edeMember.flow';
import type {
  EdeApplicationPayloadType,
  FamilyRelationshipAttestationType,
} from 'routes/marketplace-ede/types/ede/edeApplication.flow';
import { getEdeMemberFullName } from './edeMember';
import {
  findMissingPrimaryRelationships,
  getMissingPrimaryRelationships,
  getMembersRelationship,
  hasSpouseOrPartner,
  hasKnownRelationship,
} from './family-relationship';

export {
  hasKnownRelationship,
  findMissingPrimaryRelationships,
  getMissingPrimaryRelationships,
  getMembersRelationship,
  hasSpouseOrPartner,
};

export const RELATIONSHIP_MAP = {
  SON_DAUGHTER: 'PARENT',
  STEPSON_STEPDAUGHTER: 'STEP_PARENT',
  CHILD_OF_DOMESTIC_PARTNER: 'PARENTS_DOMESTIC_PARTNER',
  NEPHEW_NIECE: 'AUNT_UNCLE',
  GRANDCHILD: 'GRANDPARENT',
  DAUGHTER_IN_LAW_SON_IN_LAW: 'MOTHER_IN_LAW_FATHER_IN_LAW',
  PARENT: 'SON_DAUGHTER',
  STEP_PARENT: 'STEPSON_STEPDAUGHTER',
  PARENTS_DOMESTIC_PARTNER: 'CHILD_OF_DOMESTIC_PARTNER',
  AUNT_UNCLE: 'NEPHEW_NIECE',
  GRANDPARENT: 'GRANDCHILD',
  MOTHER_IN_LAW_FATHER_IN_LAW: 'DAUGHTER_IN_LAW_SON_IN_LAW',
  FIRST_COUSIN: 'FIRST_COUSIN',
  BROTHER_IN_LAW_SISTER_IN_LAW: 'BROTHER_IN_LAW_SISTER_IN_LAW',
  SIBLING: 'SIBLING',
  SPOUSE: 'SPOUSE',
  DOMESTIC_PARTNER: 'DOMESTIC_PARTNER',
  UNRELATED: 'UNRELATED',
  OTHER_RELATIVE: 'OTHER_RELATIVE',
};

export const INVALID_SPOUSE = [
  'PARENT',
  'STEP_PARENT',
  'PARENTS_DOMESTIC_PARTNER',
  'AUNT_UNCLE',
  'GRANDPARENT',
  'MOTHER_IN_LAW_FATHER_IN_LAW',
];

export const isResideTogether = (
  familyRelationships: Array<FamilyRelationshipAttestationType>,
  targetId: string,
  sourceId: string
): boolean => {
  const relation = _.find(
    familyRelationships,
    (relationship) =>
      _.includes(Object.values(relationship), targetId) &&
      _.includes(Object.values(relationship), sourceId)
  );
  if (relation) return _.get(relation, 'familyRelationshipIndicator.resideTogetherIndicator');
};

// this is for establishing a new relationship's resideTogetherIndicator value
export const isResideSameAddress = (edeApp, id, subId) => {
  const edeMembers = _.get(edeApp, 'edeMembers', []);
  const member = _.find(edeMembers, { memberIdentifier: id });
  const subMember = _.find(edeMembers, { memberIdentifier: subId });

  return _.isEqual(
    getEdeMemberAddress(member, 'homeAddress'),
    getEdeMemberAddress(subMember, 'homeAddress')
  );
};

export const getSameAddressMembers = ({
  edeApp,
  sourceMemberId,
}: {
  edeApp: EdeApplicationPayloadType,
  sourceMemberId: string,
}): Array<EdeMemberType> => {
  const edeMembers = _.get(edeApp, 'edeMembers', []);

  const resideTogetherMembers = _.filter(
    edeMembers,
    (edeMember) =>
      edeMember && isResideSameAddress(edeApp, edeMember.memberIdentifier, sourceMemberId)
  );
  const self = _.find(edeMembers, { memberIdentifier: sourceMemberId });
  if (self && !_.includes(resideTogetherMembers, self)) {
    resideTogetherMembers.push(self);
  }
  return resideTogetherMembers;
};

export const getResideTogetherMembers = ({
  edeApp,
  sourceMemberId,
}: {
  edeApp: EdeApplicationPayloadType,
  sourceMemberId: string,
}): Array<EdeMemberType> => {
  const familyRelationships: Array<FamilyRelationshipAttestationType> = _.get(
    edeApp,
    'attestations.household.familyRelationships'
  );
  const edeMembers = _.get(edeApp, 'edeMembers', []);

  const resideTogetherMembers = _.filter(
    edeMembers,
    (edeMember) =>
      edeMember && isResideTogether(familyRelationships, edeMember.memberIdentifier, sourceMemberId)
  );
  const self = _.find(edeMembers, { memberIdentifier: sourceMemberId });
  if (self && !_.includes(resideTogetherMembers, self)) {
    resideTogetherMembers.push(self);
  }
  return resideTogetherMembers;
};

type FamilyMembers = Array<FamilyRelationshipAttestationType>;

const getMemberFamily = (edeApp: EdeApplicationPayloadType): FamilyMembers =>
  _.get(edeApp, 'attestations.household.familyRelationships') || [];

export const getMemberSpouse = (
  edeApp: EdeApplicationPayloadType,
  sourceMemberId: string
): EdeMemberType | null => {
  const familyRelationships = getMemberFamily(edeApp);
  const edeMembers = _.get(edeApp, 'edeMembers', []);
  const relation =
    _.find(familyRelationships, {
      memberIdentifier: sourceMemberId,
      relationship: 'SPOUSE',
    }) ||
    _.find(familyRelationships, {
      subMemberIdentifier: sourceMemberId,
      relationship: 'SPOUSE',
    });
  if (!relation) return null;

  const { subMemberIdentifier, memberIdentifier } = relation;
  const id = sourceMemberId === memberIdentifier ? subMemberIdentifier : memberIdentifier;
  return _.find(edeMembers, { memberIdentifier: id });
};

export const membersWithoutRelationship = (
  edeApp: EdeApplicationPayloadType
): Array<EdeMemberType> => {
  const familyRelationships: Array<FamilyRelationshipAttestationType> = _.get(
    edeApp,
    'attestations.household.familyRelationships'
  );
  const edeMembers = _.get(edeApp, 'edeMembers', []);
  const withNone = [];
  _.forEach(edeMembers, (member) => {
    if (
      !_.find(familyRelationships, (relationship) =>
        _.includes(Object.values(relationship), member.memberIdentifier)
      )
    ) {
      withNone.push(member);
    }
  });

  return withNone;
};

export const getRelationshipType = (
  edeApp: EdeApplicationPayloadType,
  id: string,
  subId: string,
  type: string
): FamilyRelationshipEnum | null => {
  const relationships = _.get(edeApp, `attestations.household.${type}Relationships`);

  const relation = _.find(relationships, {
    memberIdentifier: id,
    subMemberIdentifier: subId,
  });

  if (!relation) return null;
  return relation.relationship;
};

export const areRelatedByType = (
  edeApp: EdeApplicationPayloadType,
  id: string,
  subId: string,
  relationship: FamilyRelationshipEnum
): boolean => getRelationshipType(edeApp, id, subId, 'family') === relationship;

export const getRelationships = (primaryId, subIds, relationships) =>
  relationships.filter(({ memberIdentifier, subMemberIdentifier }) => {
    const isPrimary = primaryId === memberIdentifier || primaryId === subMemberIdentifier;
    const isRelationship = subIds.filter(
      (id) => id === subMemberIdentifier || id === memberIdentifier
    );

    return isPrimary && !!isRelationship.length;
  });

export const getRelationshipsNeeded = (
  edeApp: EdeApplicationPayloadType,
  secondaryMembers: Array<EdeMemberType>
): Array<string> => {
  const ids = _.map(secondaryMembers, (m) => m.memberIdentifier);
  return _.reduce(
    ids,
    (relationships, id, i) => relationships.concat(ids.slice(i + 1).map((id2) => [id, id2])),
    []
  );
};

/**
 * HOC that checks whether a child is older than parent or a parent is younger than child.
 * Will return a new component with the relevant warning text if either case is true.
 *
 * @param {JSX.Element} children - Component to be given the warning text.
 * @param {string} className - CSS classes to include for the warning text.
 * @param {Object} member
 * @param {string} relationship - relationship between member and subMember.
 * @param {Objet} subMember
 * @param {number} subMemberAge
 *
 * @returns {JSX.Element} Children components wrapped with the warning text.
 */
export const Warning = ({ children, className, member, relationship, subMember, subMemberAge }) => {
  let path = null;
  const memberAge = getMemberAge(member);
  const WARNING_PATH = 'member.dependentForm.warning';
  const using = {
    firstNameLastName: getEdeMemberFullName(subMember),
    subName: getEdeMemberFullName(member),
  };

  if (relationship === 'SON_DAUGHTER') {
    path = subMemberAge > memberAge ? `${WARNING_PATH}.parentYounger` : null;
  } else if (relationship === 'PARENT') {
    path = subMemberAge < memberAge ? `${WARNING_PATH}.childOlder` : null;
  }
  return (
    <React.Fragment>
      {children}
      {path && (
        <div className={className ? `${className} margin-top-neg` : 'margin-top-neg'}>
          <BoxContainer>
            <Translate path={path} using={using} />
          </BoxContainer>
        </div>
      )}
    </React.Fragment>
  );
};

export const createTaxRelationship = (id, subId, relationship = 'TAX_FILER') => ({
  memberIdentifier: id,
  relationship,
  subMemberIdentifier: subId || id,
});

export const getTaxClaimer = (
  edeApp: EdeApplicationPayloadType,
  member: EdeMemberType
): Array<EdeMemberType> => {
  const taxRelationships = _.get(edeApp, 'attestations.household.taxRelationships', []);
  const members = _.get(edeApp, 'edeMembers', []);
  if (members.length === 1) return [];

  const claimingFilers: Array<EdeMemberType> = _.map(
    _.filter(
      taxRelationships,
      (relation) =>
        relation.subMemberIdentifier === member.memberIdentifier &&
        relation.relationship === 'TAX_FILER'
    ),
    (relation) => _.find(members, { memberIdentifier: relation.memberIdentifier })
  );
  return _.pull(claimingFilers, member);
};

export const getTaxDependents = (
  edeApp: EdeApplicationPayloadType,
  id: string
): Array<EdeMemberType> => {
  const taxRelationships = _.get(edeApp, 'attestations.household.taxRelationships', []);
  const members = _.get(edeApp, 'edeMembers', []);
  // const nonApplicants = _.reject(members, isMemberRequestingCoverage);

  const relationships = _.filter(
    taxRelationships,
    (relation) => relation.memberIdentifier === id && relation.relationship === 'TAX_FILER'
  );
  return _.map(relationships, (relation) =>
    _.find(members, { memberIdentifier: relation.subMemberIdentifier })
  );

  // const relationships = _.filter(
  //   taxRelationships,
  //   relation => relation.memberIdentifier === id && relation.relationship === 'TAX_FILER' && !!_.find(nonApplicants, { memberIdentifier: relation.subMemberIdentifier}));
  // return _.map(relationships, relation => _.find(nonApplicants, { memberIdentifier: relation.subMemberIdentifier}));
};

export const getResidentOptions = (edeApp, relationship, member) => {
  const members = _.get(edeApp, 'edeMembers');
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships');

  // Display all household members under the state Medicaid age who did not already attest to a relationship
  // with the applicant (unless that relationship was "Sibling" )
  const noRelationship = _.filter(
    _.without(members, member),
    (m) => !hasKnownRelationship([m.memberIdentifier, member.memberIdentifier], familyRelationships)
  );
  const hasRelationshipType = _.map(
    _.filter(
      familyRelationships,
      (relation) =>
        relation.relationship === (relationship === 'SON_DAUGHTER' ? 'PARENT' : relationship) &&
        _.includes(_.values(relation), member.memberIdentifier) &&
        _.get(relation, 'familyRelationshipIndicator.resideTogetherIndicator') !== false
    ),
    (rel) =>
      _.find(members, {
        memberIdentifier:
          rel.memberIdentifier === member.memberIdentifier
            ? rel.subMemberIdentifier
            : rel.memberIdentifier,
      })
  );
  const eligibleOptions = _.unionBy(noRelationship, hasRelationshipType, 'memberIdentifier');

  if (relationship === 'PARENT' || relationship === 'STEP_PARENT') {
    return _.filter(eligibleOptions, (m) => getMemberAge(m) > getMemberAge(member));
  }
  if (relationship === 'SIBLING') {
    return _.filter(eligibleOptions, (m) => getMemberAge(m) < 21);
  }
  if (relationship === 'SON_DAUGHTER') {
    return _.filter(
      eligibleOptions,
      (m) => getMemberAge(m) < getMemberAge(member) && getMemberAge(m) < 19
    );
  }
};

export const getCurrentResidentMembers = (edeApp, member) => {
  const familyRelationships: Array<FamilyRelationshipAttestationType> = _.cloneDeep(
    _.get(edeApp, 'attestations.household.familyRelationships')
  );
  const members = _.without(_.get(edeApp, 'edeMembers', []), member);

  // const livesWith = _.filter(
  //   familyRelationships,
  //   rel =>
  //     _.includes(_.values(rel), member.memberIdentifier) &&
  //     isResideSameAddress(edeApp, rel.memberIdentifier, rel.subMemberIdentifier),
  // );
  // _.get(rel, 'familyRelationshipIndicator.resideTogetherIndicator') &&

  // return _.map(livesWith, rel => _.find(members, {
  //   memberIdentifier:
  //       rel.memberIdentifier === member.memberIdentifier
  //         ? rel.subMemberIdentifier
  //         : rel.memberIdentifier,
  // }));
  return _.filter(_.cloneDeep(members), (m) => {
    if (isResideSameAddress(edeApp, member.memberIdentifier, m.memberIdentifier)) {
      const relationship = getMembersRelationship(
        member.memberIdentifier,
        m.memberIdentifier,
        familyRelationships
      );
      if (!relationship) return false;
      if (member.memberIdentifier === relationship.memberIdentifier) {
        relationship.relationship = RELATIONSHIP_MAP[relationship.relationship];
      }
      m.type =
        relationship.relationship === 'SON_DAUGHTER' ? 'CHILDREN' : relationship.relationship;
      return true;
    }
  });
};

export const getDepdendentRelationships = (member: EdeMemberType, edeApp: Object) => {
  const [taxClaimer] = getTaxClaimer(edeApp, member);
  const { taxRelationships, familyRelationships } =
    _.get(edeApp, 'attestations.household', {}) || {};

  const allRelationships = _.reduce(
    taxRelationships,
    (deps, rel) => {
      if (
        rel.memberIdentifier === taxClaimer.memberIdentifier &&
        rel.subMemberIdentifier !== member.memberIdentifier
      ) {
        deps.push(rel.subMemberIdentifier);
      }
      return deps;
    },
    []
  );
  return _.every(allRelationships, (id) =>
    _.find(familyRelationships, (rel) => {
      const ids = Object.values(rel);
      return _.includes(ids, member.memberIdentifier) && _.includes(ids, id);
    })
  );
};

export const parentOptions = [
  { value: 'SON_DAUGHTER', label: 'Child (including adopted children)' },
  {
    value: 'STEPSON_STEPDAUGHTER',
    label: 'Stepchild',
  },
];
