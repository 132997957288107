// @flow

import _ from 'lodash';
import ROUTES from 'client/constants/routes';

import type { IfpAppType } from 'web-core/types/ifp-app';
import type { RouterType } from 'types/react-router.flow';

import { PROFILE_ROUTE } from 'routes/health/constants/routes';

export const goToProfileWithRedirectUrl = (
  e: Event,
  router: RouterType,
  sourcePathname: string,
) => {
  e.preventDefault();
  return router.replace({
    pathname: PROFILE_ROUTE,
    query: {
      redirect: sourcePathname,
    },
  });
};

export const preventCompleteAppEdit = ({
  ifpApp,
  router,
  user,
  phase3Enabled,
}: {
  ifpApp: IfpAppType,
  router: RouterType,
  user: Object,
  phase3Enabled: boolean,
}) => {
  if (user.isLoggedIn) {
    const statuses = ifpApp.ifp_enroll_statuses || [];
    const isComplete = statuses.length && statuses[0].isComplete === true;
    const isEditing = _.get(router, 'location.query.edit');
    if (isComplete && !isEditing) {
      window.location.href = phase3Enabled ? ROUTES.PHASE3_DASHBOARD_ROUTE: '/my/insurance';
    }
  }
};
