import {
  UPDATE_CACHE,
  CLEAR_CACHE,
} from '../constants/cache';

export default function cache(state = {}, action) {
  switch (action.type) {
    case UPDATE_CACHE: {
      const { key, data, createdAt } = action;
      return { ...state, ...{ [key]: { data, createdAt } } };
    }
    case CLEAR_CACHE:
      return {};
    default:
      return state;
  }
}
