// @flow

import React from 'react';

const TargetIcon = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>target</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <g
        id="Artboard"
        transform="translate(-123.000000, -153.000000)"
        stroke="#B8BCC3"
        strokeWidth="2">
        <g id="target" transform="translate(124.000000, 154.000000)">
          <circle id="Oval" cx="10" cy="10" r="10" />
          <circle id="Oval" cx="10" cy="10" r="6" />
          <circle id="Oval" cx="10" cy="10" r="2" />
        </g>
      </g>
    </g>
  </svg>
);

export default TargetIcon;
