import {
  RECEIVE_CONTENTFUL_DATA,
  RECEIVE_CONTENTFUL_TRANSLATIONS,
  RECEIVE_STATE_CONFIG_DATA,
} from '../actions/contentful';

const initialState = {};

export default function contentfulData(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CONTENTFUL_DATA:
      return {
        ...state,
        ...(action.prefix ? { [action.prefix]: action.data } : action.data),
      };
    case RECEIVE_STATE_CONFIG_DATA:
      return {
        ...state,
        ...action.data,
      };
    case RECEIVE_CONTENTFUL_TRANSLATIONS:
      return {
        ...state,
        translations: {
          ...state.transations,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
