import React from 'react';
import cx from 'classnames';
import PhoneLink from 'client/components/phoneLink';
import Translate from 'client/components/translate';
import OsanoCookiePreferencesButton from 'client/components/osano/osanoCookiePreferencesButton';
import { ExtoleReferralSimpleButtonCard } from 'client/components/extole/extoleReferralSimpleButtonCard';
import { ExtoleCreateZoneCard } from 'client/components/extole/extoleCreateZoneCard';

import 'client/styles/footer.scss';

type Props = {
  shouldShowLadderDisclaimer?: boolean;
  show?: boolean;
  showPhone?: boolean;
  showReferralButton?: boolean;
  showRenaissanceDisclaimer?: boolean;
};

const defaultProps: Partial<Props> = {
  shouldShowLadderDisclaimer: false,
  show: true,
  showPhone: true,
  showReferralButton: false,
  showRenaissanceDisclaimer: false,
};

const NpnFooter = (props: Props) => {
  const {
    show = defaultProps.show,
    showPhone = defaultProps.showPhone,
    showRenaissanceDisclaimer = defaultProps.showRenaissanceDisclaimer,
    shouldShowLadderDisclaimer = defaultProps.shouldShowLadderDisclaimer,
    showReferralButton = defaultProps.showReferralButton,
  } = props;

  if (!show) {
    return <br />;
  }

  const footerClass = cx('footer', 'flex-row', 'white-color', {
    'renaissance-npn': showRenaissanceDisclaimer || shouldShowLadderDisclaimer,
    npn: !showRenaissanceDisclaimer && !shouldShowLadderDisclaimer,
  });

  return (
    <div className={footerClass}>
      {showReferralButton && <ExtoleCreateZoneCard zone_name="overlay" />}

      <div className="pad-x text-center">
        <div className="npn-text">
          <Translate
            path="common.footer.npn"
            using={{
              year: new Date().getFullYear(),
            }} />
        </div>

        <div className="flex flex-row center links">
          <div className="pad-x">
            <a className="" href="/legal/privacy-policy">
              <Translate path="common.footer.privacy" />
            </a>
          </div>

          <div className="pad-x">
            <a className="" href="/legal/privacy-policy#notices-for-california-residents">
              <Translate path="common.footer.californiaPrivacy" />
            </a>
          </div>

          <div className="pad-x">
            <a className="" href="/legal/terms-of-use">
              <Translate path="common.footer.terms" />
            </a>
          </div>

          <OsanoCookiePreferencesButton />

          {showPhone && (
            <div className="pad-x">
              <Translate path="common.footer.callUs" />
              <strong>
                <PhoneLink
                  className="white-color"
                  trackingEvent="Call us button clicked"
                  trackingSource={{ Source: 'NPN Footer' }} />
              </strong>
            </div>
          )}

          {showReferralButton && <ExtoleReferralSimpleButtonCard location="footer" />}
        </div>

        {showRenaissanceDisclaimer && (
          <div className="disclaimer-text margin-top mini">
            <Translate path="common.footer.renaissanceDisclaimer" />
          </div>
        )}

        {shouldShowLadderDisclaimer && (
          <div className="disclaimer-text margin-top mini ladder-disclaimer">
            <Translate className="pad-x" path="common.footer.ladderdisclaimer" />
          </div>
        )}
      </div>
    </div>
  );
};

NpnFooter.defaultProps = defaultProps;

export default NpnFooter;
