// @flow

import type { IncomeFrequency } from 'types/base/healthApp';
import { parseNumber } from 'client/utils/finance';

const FREQUENCY = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

const FREQUENCY_YEARLY_MULTIPLIER_MAP = {
  [FREQUENCY.WEEKLY]: 52,
  [FREQUENCY.MONTHLY]: 12,
  [FREQUENCY.YEARLY]: 1,
};

class TaxInfoModel {
  familySize: number | null;
  frequency: IncomeFrequency | null;
  guided: boolean | null;
  income: number | null; // annual income
  amount: number | null; // amount user enter

  constructor({
    familySize = null,
    frequency = null,
    guided = false,
    income = null,
    amount = null,
  }: Object) {
    this.familySize = familySize;
    this.frequency = frequency;
    this.guided = guided;
    this.income = income;
    this.amount =
      amount || (income && parseNumber(income) / FREQUENCY_YEARLY_MULTIPLIER_MAP[frequency]);
  }
}

export class SubsidyModel {
  amount: ?number;
  csr: ?string;
  fpl: ?number;
  constructor({ amount, csr, fpl }: Object) {
    this.fpl = fpl;
    this.amount = amount;
    this.csr = csr;
  }
}

export default TaxInfoModel;
