// @flow

import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import React, { PureComponent } from 'react';

import { LoginIcon, LogoutIcon } from 'client/components/icons/index';
import { logout } from 'client/actions/loginLogout';
import { StyledNavLink } from 'client/components/nav/navPhoneButton';
import Translate from 'client/components/translate';

import { ACCOUNT_ROUTE as HEALTH_ACCOUNT_ROUTE } from 'routes/health/constants/routes';

import type { LocationType, RouterType } from 'types/react-router.flow';

const mapStateToProps: Object = (state: Object) => ({
  user: state.user,
});

type Props = {
  isHealthLogin?: boolean,
  location: LocationType,
  logout: Function,
  router: RouterType,
  user: Object,
  withIcon?: boolean,
};

class LoginLogoutLink extends PureComponent<Props> {
  static defaultProps = {
    isHealthLogin: false,
    user: null,
    withIcon: false,
  };

  redirectToLogin = () => {
    const {
      isHealthLogin,
      location: { pathname: sourcePathname },
      router,
    } = this.props;

    if (!isHealthLogin) {
      window.location.href = '/login';
      return null;
    }

    return router.push({
      pathname: HEALTH_ACCOUNT_ROUTE,
      query: {
        redirect: sourcePathname,
        // Prevent users from skipping account create in health funnel
        source: 'enroll',
      },
    });
  };

  render() {
    const { logout: logoutFunction, user, withIcon } = this.props;
    const isLoggedIn = _.get(user, 'isLoggedIn');
    const loginLabel = <Translate path="common.account.login.cta" />;
    const logoutLabel = <Translate path="common.account.logout" />;

    const renderLogoutLink = (
      <StyledNavLink
        className="transparent"
        onClick={() => {
          logoutFunction('/');
        }}>
        {withIcon ? (
          <div className="flex-column">
            <LogoutIcon className="hide-for-small-only" />
            <div>{logoutLabel}</div>
          </div>
        ) : (
          logoutLabel
        )}
      </StyledNavLink>
    );

    const renderLoginLink = (
      <StyledNavLink className="transparent" onClick={this.redirectToLogin}>
        {withIcon ? (
          <div className="flex-column">
            <LoginIcon className="hide-for-small-only" />
            <div>{loginLabel}</div>
          </div>
        ) : (
          loginLabel
        )}
      </StyledNavLink>
    );

    return <li className="nav-link">{isLoggedIn ? renderLogoutLink : renderLoginLink}</li>;
  }
}

export default connect(mapStateToProps, { logout })(withRouter(LoginLogoutLink));
