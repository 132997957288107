// @flow
import _ from 'lodash';
import { getAppMembers } from 'routes/marketplace/utils/state';
import GLOBALS from 'client/constants/globals';
import {
  CITIZENSHIP_STATUS_REASONS,
  DOCUMENT_VERIFICATION_NEEDED_CITIZENSHIP_STATUS_REASONS,
  GRANT_DATE_REQUIRED_STATUS_REASONS,
  NATURALIZATION_DOCUMENTS,
  QHP_LAWFUL_PRESENCE_STATUS_REASONS,
  SSN_STATUS_REASONS,
  UNVERIFIED_QHP_LAWFUL_PRESENCE_STATUS_REASONS,
} from 'client/constants/ede/citizenship';
import { MEDICAID_AND_CHIP_PENDING_STATUS_REASONS } from 'client/constants/ede/eligibility';
import type {
  EdeMemberIntersectionType,
  EdeMemberType,
  MedicaidDetMemberType,
} from 'types/ede/edeMember.flow';
import type { StateProgramsEnumType } from 'types/ede/statePrograms.flow';
import type { MemberType } from 'web-core/types/member';
import type { EdeApplicationType, SesResponseType } from 'web-core/types/ede/application';
import { isMemberDependent, isMemberApplying } from 'client/utils/member';
import {
  INVALID_CHIP_WAITING_PERIOD_STATUS_REASONS,
  INVALID_DEPENDENT_CHILD_COVERED_STATUS_REASONS,
  INCARCERATION_TYPES,
} from '../constants/additional';
import * as selectors from './selectors';

const { YES_VALUE, NO_VALUE, NOT_APPLICABLE } = GLOBALS;
const TEMPORARY_VALUE = 'TEMPORARY';

export const MEMBER_ATTESTATION_PATH = 'ede_member.ede_payload.attestation';
export const MEMBER_COMPUTED_PATH = 'ede_member.ede_payload.computed';
export const MEDICAID_CHIP_STANDARD_PATH = `${MEMBER_COMPUTED_PATH}.medicaidChipStandard`;

export const isMemberRequestingCoverage = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.requestingCoverageIndicator`, false);

export const filterMembersRequestingCoverage = (
  members: Array<EdeMemberIntersectionType>,
): Array<EdeMemberIntersectionType> => _.filter(members, isMemberRequestingCoverage);

export const isMemberPreliminarilyMedicaidEligible = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }

  return (
    computed.preliminaryMedicaidStatus === YES_VALUE ||
    computed.emergencyMedicaidStatus === YES_VALUE
  );
};

// only check preliminaryMedicaidStatus, not includes emergencyMedicaidStatus
export const isPreliminarilyMedicaid = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }
  return computed.preliminaryMedicaidStatus === YES_VALUE;
};

// only check preliminaryMedicaidStatus, not includes emergencyMedicaidStatus
export const isMemberNotPreliminaryChipAndMedicaid = (computed: Object) =>
  computed.preliminaryMedicaidStatus !== YES_VALUE && computed.preliminaryChipStatus !== YES_VALUE;

// Backend Responses for UI Item 34, "Terminate Coverage Agreement"
// Condition for being "prelimQHP":
// (citizenshipStatusIndicator=Y or qhpLawfulPresenceStatus=Y) and
// qhpResidency = Y and
// incarcerationStatus = N and
// (preliminaryCHIPStatus <> Y AND preliminaryMedicaidStatus <> Y)
export const isMemberPreliminarilyQhpEligible = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }

  const isLawfullyPresent =
    computed.citizenshipStatus === YES_VALUE || computed.qhpLawfulPresenceStatus === YES_VALUE;
  const isNotIncarcerated = computed.incarcerationStatus === NO_VALUE;
  // Fix for isResident check that was skipping test case 1.E to app summary page
  // without asking about qualifying life events
  const isResident = _.includes(
    [YES_VALUE, NOT_APPLICABLE],
    _.get(computed, 'qhpResidency.qhpResidencyStatus'),
  );

  return (
    isLawfullyPresent &&
    isResident &&
    isNotIncarcerated &&
    isMemberNotPreliminaryChipAndMedicaid(computed)
  );
};

const isValidDependentChildCoveredStatusReason = (member: EdeMemberType): boolean =>
  !_.includes(
    INVALID_DEPENDENT_CHILD_COVERED_STATUS_REASONS,
    _.get(member, `${MEMBER_COMPUTED_PATH}.dependentChildCoveredStatusReason`),
  );

const isParentCaretaker = (member: EdeMemberType): boolean =>
  _.get(member, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerCategoryStatus`) === YES_VALUE;

const hasNonApplicantInParentCaretakerChildList = (
  member: EdeMemberType,
  dependentList,
): boolean => {
  const lists = _.get(member, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerChildList`, []);
  return _.some(lists, ffmId => _.includes(dependentList, ffmId));
};

const hasNonApplicantDependant = (
  member: EdeMemberType,
  nonApplicantsDependentFfmIdList: Array<string>,
) =>
  isPreliminarilyMedicaid(member) &&
  isParentCaretaker(member) &&
  hasNonApplicantInParentCaretakerChildList(member, nonApplicantsDependentFfmIdList);

export const isMemberPreliminarilyChipEligible = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryChipStatus`) === YES_VALUE;

export const isMemberPreliminarilyAptcEligible = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryAptcStatus`) === YES_VALUE;

/**
 *  CMS Change Request #25 - backend #17
 *  1. dependentChildCoveredStatusReason <> 555, 652, 655 OR
 *  2. preliminaryMedicaidStatus = YES  and parentCaretakerCategoryStatus = YES  with parentCaretakerChildList containing non-applicant children
 */
export const doesMemberNeedDependentNonApplicantCoverage = (members: Array<EdeMemberType>) => {
  const nonApplicantsDependentFfmIdList = members
    .filter(member => !isMemberRequestingCoverage(member) && isMemberDependent(member))
    .map(member => _.get(member, 'ede_member.ffm_assigned_id'));
  return _.some(
    members,
    member =>
      isValidDependentChildCoveredStatusReason(member) ||
      hasNonApplicantDependant(member, nonApplicantsDependentFfmIdList),
  );
};

export const isMemberAttestedToCitizenship = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.citizenshipIndicator`, false);

export const hasSSNDataMismatch = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.ssnStatusReason`) === SSN_STATUS_REASONS.SSA_DATA_MISMATCH;

const doesMemberNeedAdditionalChipAttestations = (member: EdeMemberType) =>
  !_.includes(
    INVALID_CHIP_WAITING_PERIOD_STATUS_REASONS,
    _.get(member, `${MEMBER_COMPUTED_PATH}.chipWaitingPeriodStatusReason`),
  );

/**
 *  CMS Change request #25 (backend response logic #20):
 *  preliminaryCHIPStatus = YES and chipWaitingPeriodStatusReason <> 555, 652, 657, 470, 551
 */
export const shouldShowChipCoverageEndPage = (members: Array<EdeMemberType>) => {
  const eligibleApplicants = _.filter(members, isMemberPreliminarilyChipEligible);
  return _.some(eligibleApplicants, doesMemberNeedAdditionalChipAttestations);
};

export const hasAbsentParent = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.absentParentIndicator`) === true;

export const isMemberIncomeVariable = (member: MemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.income.annualTaxIncome.variableIncomeIndicator`) ===
  true;

export const isMemberIncomeHardToPredict = (member: MemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.income.annualTaxIncome.unknownIncomeIndicator`) ===
  true;

export const getMemberEstimatedForAptcCurrentIncome = (member: MemberType) => {
  // if income is hard to predict, try to pull from the created currentIncome
  // where estimatedForAptcIndicator === true
  if (isMemberIncomeHardToPredict(member)) {
    const incomes = _.get(member, `${MEMBER_ATTESTATION_PATH}.income.currentIncome`, {});
    const estimatedForAptcIndicatorIncome = _.values(incomes).find(
      income => income !== null && income.estimatedForAptcIndicator === true,
    );
    if (estimatedForAptcIndicatorIncome) {
      return estimatedForAptcIndicatorIncome;
    }
  }
  return null;
};

/**
 * ui_question_companion_guide_09132019_v14: Must use SES calculated annual income amount.
 * Setting the default to 0 because SES may not necessarily return
 * attestedAnnualizedAptcIndividualIncomeAmt, so we should be defensive and allow customers to attest
 * the actual amount when they see 0.
 */
export const getMemberAttestedAnnualizedAptcIndividualIncomeAmt: (MemberType) => number = member => _.get(member, `${MEMBER_COMPUTED_PATH}.income.attestedAnnualizedAptcIndividualIncomeAmt`, 0);

export const getMemberAdjustedIncomeAmt: (MemberType) => number = member =>
  _.get(
    member,
    `${MEMBER_COMPUTED_PATH}.income.adjustAttestedAnnualMedicaidHouseholdIncomeAmount`,
    0,
  );

export const getMemberPreliminaryMedicaidStatus: (EdeMemberType) => string | void = member =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryMedicaidStatus`);

export const getFullTimeStudentAttestation = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fullTimeStatusIndicator`);

export const isMedicaidParent = (member: EdeMemberType): boolean => {
  const parentCaretakerIndicator = _.get(
    member,
    `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerCategoryStatus`,
  );
  return parentCaretakerIndicator === YES_VALUE || parentCaretakerIndicator === TEMPORARY_VALUE;
};

export const getCaretakerChildren = (
  parent: EdeMemberType,
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.get(parent, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerChildList`, [])
    .map(ffm_assigned_id =>
      _.find(members, m => m.ede_member.ffm_assigned_id === ffm_assigned_id))
    .filter(
      m => _.get(m, `${MEDICAID_CHIP_STANDARD_PATH}.adultGroupCategoryStatus`) === NO_VALUE,
    );

export const getChildrenEligibleForOutsideParent = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> => {
  // keep initial _.filter to let lodash handle null/undef and return array
  const medicaidParents = _.filter(members, selectors.isMedicaidParent);
  // only show for children that live with < 2 parents and if parent is prelim eligible for medicaid
  if (
    medicaidParents.length > 1 ||
    !medicaidParents.some(selectors.isMemberPreliminarilyMedicaidEligible)
  ) {
    return [];
  }

  const caretakerChildren = _.flatten(
    medicaidParents.map(p => selectors.getCaretakerChildren(p, members)),
  );
  return (
    _.uniq(caretakerChildren)
      // only show if child is also prelim eligible for medicaid
      .filter(selectors.isMemberPreliminarilyMedicaidEligible)
  );
};

export const getApplicationParents = ({ state, members }: Object): Array<EdeMemberType> => {
  const appMembers = members || getAppMembers(state);
  return _.filter(appMembers, isMedicaidParent);
};

export const getDeprivedChildren = (members: Array<EdeMemberType>): Array<EdeMemberType> => {
  const tempCaretakers = _.filter(
    members,
    member =>
      isMemberRequestingCoverage(member) &&
      isMemberPreliminarilyMedicaidEligible(member) &&
      _.get(member, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerCategoryStatus`) ===
        TEMPORARY_VALUE,
  );

  const caretakerChildren = _.flatten(tempCaretakers.map(p => getCaretakerChildren(p, members)));

  return (
    _.uniq(caretakerChildren)
      // per item 19 of backend ui responses tab,
      // only show for childCaretakerDeprivedStatus "TEMPORARY"
      .filter(
        m =>
          _.get(m, `${MEDICAID_CHIP_STANDARD_PATH}.childCaretakerDeprivedStatus`) ===
          TEMPORARY_VALUE,
      )
  );
};

export const shouldShowChipChildrenPage = (members: Array<EdeMemberType>): boolean =>
  selectors.getChildrenEligibleForOutsideParent(members).length > 0 ||
  selectors.getDeprivedChildren(members).length > 0;

export const shouldShowPaymentHelpPage = (members: Array<EdeMemberType>) =>
  _.some(members, isMemberPreliminarilyMedicaidEligible);

export const getMembersFromUpdateResponse = (res: SesResponseType) =>
  _.get(res, 'ede_application.ifp_app.members', []);

export const getEdeApplicationPostalCode = (state: Object) =>
  _.get(state, 'edeApplication.data.ede_payload.attestations.application.coverageState', '');

export const getMemberBlindOrDisabledIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.nonMagi.blindOrDisabledIndicator`);

export const getMemberLongTermCareIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.nonMagi.longTermCareIndicator`);

export const getMemberMedicaidDeniedIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.medicaidDeniedIndicator`);

export const getMemberUnpaidBillIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.unpaidBillIndicator`);

export const getMemberCoverageLoss = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.member_loss_coverage_3mos`);

export const getMemberCurrentCoverages = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.insuranceCoverage`) ||
  _.get(member, `${MEMBER_ATTESTATION_PATH}.insuranceCoverage.enrolledCoverages`, []);

export const getMemberChangeInCircumstance = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.other.changeInCircumstance`, {});

export const getMemberPregnancyIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.pregnancyIndicator`);

export const getMemberFosterCareIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fosterCareIndicator`);

export const getMemberFullTimeStudent = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fullTimeStatusIndicator`);

export const isMemberIncarcerated = (member: MemberType): boolean => {
  const incarcerationType = _.get(member, `${MEMBER_ATTESTATION_PATH}.other.incarcerationType`);

  if (
    incarcerationType === INCARCERATION_TYPES.INCARCERATED ||
    incarcerationType === INCARCERATION_TYPES.PENDING_DISPOSITION
  ) {
    return true;
  }
  return false;
};

// Addition questions section on summary page also display 'foster care', 'incarceratio' and 'pregnancy' info
export const getMembersAdditionalInfo = (member: EdeMemberType) => {
  const blindOrDisabledIndicator = getMemberBlindOrDisabledIndicator(member);
  const changeInCircumstance = getMemberChangeInCircumstance(member);
  const coverageLoss = getMemberCoverageLoss(member);
  const currentCoverages = getMemberCurrentCoverages(member);
  const fosterCareIndicator = getMemberFosterCareIndicator(member);
  const fullTimeStatusIndicator = getMemberFullTimeStudent(member);
  const incarcerated = isMemberIncarcerated(member);
  const longTermCareIndicator = getMemberLongTermCareIndicator(member);
  const medicaidDeniedIndicator = getMemberMedicaidDeniedIndicator(member);
  const pregnancyIndicator = getMemberPregnancyIndicator(member);
  const unpaidBillIndicator = getMemberUnpaidBillIndicator(member);

  return {
    blindOrDisabledIndicator,
    changeInCircumstance,
    coverageLoss,
    currentCoverages,
    fosterCareIndicator,
    fullTimeStatusIndicator,
    incarcerated,
    longTermCareIndicator,
    medicaidDeniedIndicator,
    member,
    pregnancyIndicator,
    unpaidBillIndicator,
  };
};

export const isRequestingFinancialAssistance = (edeApplication: EdeApplicationType): boolean =>
  _.get(
    edeApplication,
    'ede_payload.attestations.application.requestingFinancialAssistanceIndicator',
    false,
  );

export const getQhpEnrollByDate = (edeApplication: EdeApplicationType, ffmId: string): string => {
  const applicants = _.get(
    edeApplication,
    'fetch_eligibility_payload.ApplicantEligibilityResponse.InsuranceApplication.InsuranceApplicant',
    [],
  );
  const applicant = Array.isArray(applicants)
    ? _.find(applicants, [
      'ExchangeAssignedInsuranceApplicantIdentification.IdentificationID',
      ffmId,
    ])
    : applicants;

  const SepEnrollDate = _.get(
    applicant,
    'SpecialEnrollmentPeriodEligibility.EligibilityDateRange.EndDate.Date',
    null,
  );
  const OepEnrollDate = _.get(
    applicant,
    'InitialEnrollmentPeriodEligibility.EligibilityDateRange.EndDate.Date',
    null,
  );

  return SepEnrollDate || OepEnrollDate;
};

// Consumer is requesting coverage AND consumer is 18-25 years old
export function getPossiblyFosterCareMembers(members: Array<EdeMemberType>): Array<EdeMemberType> {
  return _.filter(
    members,
    (m: EdeMemberType) => isMemberRequestingCoverage(m) && m.age >= 18 && m.age <= 25,
  );
}

export function getCitizenshipUnverifiedMembers(
  members: Array<EdeMemberType>,
): Array<EdeMemberType> {
  return _.filter(
    members,
    (m: EdeMemberType) => isMemberAttestedToCitizenship(m) && hasSSNDataMismatch(m),
  );
}

export const getMemberMedicaidStatus = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.medicaidStatus`);

export const getMemberEmergencyMedicaidStatus = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.emergencyMedicaidStatus`);

export const isMemberCitizen = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.citizenshipStatus`) === YES_VALUE;

export const getMemberCitizenshipStatusReason = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.citizenshipStatusReason`);

export const getMemberNaturalizedCitizenIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.naturalizedCitizenIndicator`);

export const getMemberQhpLawfulPresenceStatusReason = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.qhpLawfulPresenceStatusReason`);

/**
 * CMS Requests Change #25
 * Backend Responses for UI #4
 * (citizenshipStatus = YES and citizenshipStatusReason <> 999_N_A_RULE_INDICATOR_IS_Y)
 * OR (naturalizedCitizenIndicator = true and requestingCoverageIndicator = true)
 */
export function getPotentialNaturalizedMembers(
  members: Array<EdeMemberType>,
): Array<EdeMemberType> {
  return _.filter(
    members,
    (m: EdeMemberType) =>
      (isMemberCitizen(m) &&
        getMemberCitizenshipStatusReason(m) !==
          CITIZENSHIP_STATUS_REASONS.N_A_RULE_INDICATOR_IS_Y) ||
      (getMemberNaturalizedCitizenIndicator(m) && isMemberRequestingCoverage(m)),
  );
}

export const isPotentialNaturalizedMember = (member: EdeMemberType) =>
  (isMemberCitizen(member) &&
    getMemberCitizenshipStatusReason(member) !==
      CITIZENSHIP_STATUS_REASONS.N_A_RULE_INDICATOR_IS_Y) ||
  (getMemberNaturalizedCitizenIndicator(member) && isMemberRequestingCoverage(member));

/**
 * ui_question_companion_guide_040219_v12
 * Backend Responses for UI #33
 * 1. requestingFinancialAssistanceIndicator = true AND
 * Any member on the application:
 * 2. medicaidStatus = N AND
 * 3. medicaidDeniedIndicator = false AND
 * 4. emergencyMedicaidStatus = N
 */
export function getMedicaidDeterminationMembers(
  members: Array<MedicaidDetMemberType>,
): Array<MedicaidDetMemberType> {
  return _.filter(
    members,
    (m: MedicaidDetMemberType) =>
      getMemberMedicaidStatus(m) === NO_VALUE &&
      getMemberMedicaidDeniedIndicator(m) === false &&
      getMemberEmergencyMedicaidStatus(m) === NO_VALUE,
  );
}

export function getNaturalizationUnverifiedMembers(
  members: Array<EdeMemberType>,
): Array<EdeMemberType> {
  return _.filter(members, (m: EdeMemberType) =>
    _.includes(
      DOCUMENT_VERIFICATION_NEEDED_CITIZENSHIP_STATUS_REASONS,
      getMemberCitizenshipStatusReason(m),
    ));
}

// '1997' & '1995' fixes Flow error for member.dob being possibly null. It will never
// happen--we collect dob before using this method--but if it somehow did occur,
// we want to return false to prevent false positives
export const isMemberBornBefore1996 = (member: EdeMemberType) => {
  const cutOffDate = new Date('1996-08-22').getTime();
  const dob = new Date(member.dob || '1997').getTime();
  return dob < cutOffDate;
};

export const isMemberBornAfter1996 = (member: EdeMemberType) => {
  const cutOffDate = new Date('1996-08-22').getTime();
  const dob = new Date(member.dob || '1995').getTime();
  return dob >= cutOffDate;
};

export const getSevisRequiredMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(
    members,
    (member: EdeMemberType) =>
      getMemberQhpLawfulPresenceStatusReason(member) ===
      QHP_LAWFUL_PRESENCE_STATUS_REASONS.SEVIS_ID_REQUIRED,
  );

export const getGrantDateRequiredMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(members, (member: EdeMemberType) =>
    _.includes(GRANT_DATE_REQUIRED_STATUS_REASONS, getMemberQhpLawfulPresenceStatusReason(member)));

export const getMemberCitizenshipIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.citizenshipIndicator`);

export const getLivedInUsSince1996Indicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.livedInUs5yearIndicator`);

export const getPossiblyEligibleImmigrationMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.filter(members, member => getMemberCitizenshipIndicator(member) === false);

export const getMemberLawfulPresenceAttestations = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence`);

export const getLawfulPresenceStatusIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.lawfulPresenceStatusIndicator`);

export const getEligibleImmigrationMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> => _.filter(members, getLawfulPresenceStatusIndicator);

export const isMemberAttestedCitizen = (member: EdeMemberType) =>
  getMemberCitizenshipIndicator(member) || getMemberNaturalizedCitizenIndicator(member);

// TODO: Confirm which attestations qualify as "eligible immigration status"
export const memberHasEligibleImmigrationStatus = (member: EdeMemberType) =>
  (!getMemberCitizenshipIndicator(member) && getMemberNaturalizedCitizenIndicator(member)) ||
  getLawfulPresenceStatusIndicator(member);

export const getLawfulPresenceDocumentAttestations = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.lawfulPresenceDocumentation`);

export function getLawfulPresenceUnverifiedMembers(
  members: Array<EdeMemberType>,
): Array<EdeMemberType> {
  return _.filter(members, (member: EdeMemberType) => {
    const hasStatus = memberHasEligibleImmigrationStatus(member);
    const hasMismatch = _.includes(
      UNVERIFIED_QHP_LAWFUL_PRESENCE_STATUS_REASONS,
      _.get(member, `${MEMBER_COMPUTED_PATH}.qhpLawfulPresenceStatusReason`),
    );
    return hasStatus && hasMismatch;
  });
}
/**
 * Consumer is requesting coverage
 * AND consumer did not attest to U.S. citizenship or nationality
 * AND attested to eligible immigration status
 * AND consumer was born before 8/22/1996
 */
// eslint-disable-next-line max-len
export const getPotentialResidenceMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(
    members,
    (member: EdeMemberType) =>
      isMemberRequestingCoverage(member) &&
      getLawfulPresenceStatusIndicator(member) &&
      isMemberBornBefore1996(member),
  );

export const isAgeGreaterOrEqualThan = (member: EdeMemberType, age: number): boolean =>
  _.get(member, 'age') >= age;

export const isApplicantMarried = (member: EdeMemberType): boolean =>
  _.get(member, 'marital_status') === 'married';

export const getTaxDependentIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.taxDependentIndicator`);

export const doesApplicantMeetVeteranCriteria = (member: EdeMemberType): boolean =>
  isAgeGreaterOrEqualThan(member, 17) ||
  isApplicantMarried(member) ||
  (!isApplicantMarried(member) && isAgeGreaterOrEqualThan(member, 15)) ||
  (getTaxDependentIndicator(member) && isMemberDependent(member));

/**
 * Consumer is requesting coverage AND selected "No" for "Is everyone listed below a U.S. citizen or U.S. national?"
 * AND consumer did not attest to U.S. citizenship or nationality
 * AND attested to eligible immigration status
 * AND consumer was born after 8/22/1996
 * OR selected "No" for "Has x lived in the U.S. since 1996?"
 * AND meets one of the following conditions
 *  - Applicant is age 17 or older
 *  - Applicant is married
 *  - Applicant is not married and over age 14
 *  - Applicant is a tax dependent and has  relationship of child to their tax filer
 */
export const getPotentialVeteranMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(
    members,
    (member: EdeMemberType) =>
      isRequestingFinancialAssistance &&
      isMemberRequestingCoverage(member) &&
      getLawfulPresenceStatusIndicator(member) &&
      (isMemberBornAfter1996(member) || !getLivedInUsSince1996Indicator(member)) &&
      doesApplicantMeetVeteranCriteria(member),
  );

export function getPossiblyPregnantMembers(members: Array<MemberType>): Array<MemberType> {
  // from EDE UI Guide "consumer is female AND consumer is 9-66 years old"
  return members.filter(m => m.gender === 'F' && m.age > 8 && m.age < 67);
}

// eslint-disable-next-line consistent-return
export const getNaturalizationDocument = (member: EdeMemberType) => {
  const documents = getLawfulPresenceDocumentAttestations(member);
  if (documents) {
    const documentType = NATURALIZATION_DOCUMENTS.find((docType: string) => documents[docType]);
    if (documentType) {
      return {
        documentType,
        document: documents[documentType],
      };
    }
  }
};

// If the DE partner does not want to build in these complex rules,
// instead the application could
// ask all 18-20 year old applicants and non-applicants,
// and all 21-22 year old applicants,
// if they are full-time students.
export function getPossiblyFullTimeStudents(members: Array<MemberType>): Array<MemberType> {
  return _.filter(members, (member: MemberType) => {
    const { age } = member;

    if (age >= 18 && age <= 20) {
      return true;
    } else if (isMemberApplying(member) && age >= 21 && age <= 22) {
      return true;
    }

    return false;
  });
}

export const getSSNAlternateName = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic.ssnAlternateName`);

export const getMemberDemographicAttestations = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic`);

export const isPendingChipEligibility = (applicant: EdeMemberType) => {
  const chipStatusReason = _.get(applicant, `${MEMBER_COMPUTED_PATH}.chipStatusReason`);

  return _.includes(MEDICAID_AND_CHIP_PENDING_STATUS_REASONS, chipStatusReason);
};

export const isPendingMedicaidEligibility = (applicant: EdeMemberType) => {
  const medicaidStatusReason = _.get(applicant, `${MEMBER_COMPUTED_PATH}.medicaidStatusReason`);

  return _.includes(MEDICAID_AND_CHIP_PENDING_STATUS_REASONS, medicaidStatusReason);
};

export const getEligibleApplicants = (
  applicants: Array<EdeMemberType>,
  insuranceProgram: StateProgramsEnumType,
): Array<EdeMemberType> =>
  _.reject(
    applicants,
    insuranceProgram === 'chip' ? isPendingChipEligibility : isPendingMedicaidEligibility,
  );

export const getPendingApplicants = (
  applicants: Array<EdeMemberType>,
  insuranceProgram: StateProgramsEnumType,
): Array<EdeMemberType> =>
  _.filter(
    applicants,
    insuranceProgram === 'chip' ? isPendingChipEligibility : isPendingMedicaidEligibility,
  );

export const getMemberNamesString = (members: Array<Object>) =>
  _.map(members, m => `${m.memberInformation.firstName} ${m.memberInformation.lastName}`).join(
    ', ',
  );

export const getExchangeEligibleApplicants = (
  applicants: Array<EdeMemberType>,
): Array<EdeMemberType> => _.filter(applicants, a => a.ede_member.is_exchange_eligible);

// CR 47 - ICHRA and QSEHRA messaging
// Following two functions are for the Eligibility Results Page
export const getIchraMembersForERP = (applicants: Array<Object>) => {
  const criteria = applicant =>
    _.get(applicant, 'ichraIndicator') === true &&
    !_.get(applicant, 'ichraAffordabilityIndicator') === true &&
    parseInt(_.get(applicant, 'aptcAmount'), 10) > 0;

  return _.filter(applicants, criteria);
};

export const getQsehraMembersForERP = (applicants: Array<Object>) => {
  const criteria = applicant =>
    _.get(applicant, 'qsehraIndicator') === true &&
    parseInt(_.get(applicant, 'aptcAmount'), 10) > 0;

  return _.filter(applicants, criteria);
};

// The following two functions are for the shopping/confirmation page
export const getIchraMembersForSCP = (applicants: Array<Object>) => {
  const criteria = (applicant) => {
    const aptcAmount = parseInt(_.get(applicant, 'aptcAmount'), 10);
    const isIchraAffordable = _.get(applicant, 'ichraAffordabilityIndicator');
    const hasIchraOffer = _.get(applicant, 'ichraIndicator') === true;

    return hasIchraOffer && (isIchraAffordable || (!isIchraAffordable && aptcAmount === 0));
  };

  return _.filter(applicants, criteria);
};

export const getQsehraMembersForSCP = (applicants: Array<Object>) => {
  const criteria = (applicant) => {
    const aptcAmount = parseInt(_.get(applicant, 'aptcAmount'), 10);
    const notAptcEligible = _.get(applicant, 'isAPTCEligible') === false;
    const hasQsehraOffer = _.get(applicant, 'qsehraIndicator') === true;

    return hasQsehraOffer && (notAptcEligible || aptcAmount >= 0);
  };

  return _.filter(applicants, criteria);
};
