// @flow

import styled from 'styled-components';
import React from 'react';

type SvgType = {
  className: string,
  height: string,
  stroke: string,
  viewBox: string,
  width: string,
};

const SvgWrapper = styled.div`
  display: inline-block;
  height: ${props => props.height};
  width: ${props => props.width};
  color: inherit;
`;

const LoginIcon = ({ stroke, className, width, height, viewBox }: SvgType) => (
  <SvgWrapper className={className} width={width} height={height}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox={viewBox}
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-log-in">
      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
      <polyline points="10 17 15 12 10 7" />
      <line x1="15" y1="12" x2="3" y2="12" />
    </svg>
  </SvgWrapper>
);

LoginIcon.defaultProps = {
  stroke: 'currentColor',
  className: '',
  width: '20px',
  height: '20px',
  viewBox: '0 0 23 23',
};

export default LoginIcon;
