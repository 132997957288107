/* eslint-disable react/no-children-prop */
import React from 'react';
import { connect } from 'react-redux';
import ContentfulImage from '@moxy/react-contentful-image';
import Translate from 'client/components/translate';
import { isClient } from 'utils/environment';

type Props = {
  enableOsano: boolean;
};

// This function is used to break the line between the text and the icon
const handleBreakLine = (text: string) => {
  const lastWord = text.split(' ').pop();
  const firstPart = text.slice(0, text.length - (lastWord?.length || 0));

  return (
    <>
      <span className="preserve-space">{firstPart}</span>
      <span className="align-image">
        {lastWord}
        <ContentfulImage
          className="osano-icon"
          resize={{ width: 70 }}
          image="https://images.ctfassets.net/heg7gyzix6dq/4qxIfg4D1kScb9XPdzfOya/3c8d95470398dfedd027465b0306ea5e/privacyoptions_1.png" />
      </span>
    </>
  );
};

const OsanoCookiePreferencesButton = (props: Props) => {
  const { enableOsano } = props;
  const isOsanoLoaded = isClient && typeof window?.Osano?.cm?.showDoNotSell === 'function';

  return enableOsano && isOsanoLoaded ? (
    <button
      className="osano-cookie-preferences-button"
      onClick={() => window?.Osano?.cm.showDoNotSell()}>
      <Translate path="common.footer.cookiePreferences" children={handleBreakLine} />
    </button>
  ) : null;
};

const mapStateToProps = state => ({
  enableOsano: state.launchDarkly.flags['enable-osano'] || false,
});

export default connect(mapStateToProps)(OsanoCookiePreferencesButton);
