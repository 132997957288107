import React from 'react';
import { Route, IndexRedirect } from 'react-router';

import ConflictPage from 'client/components/error/conflictPage';
import ServerErrorPage from 'client/components/error/serverErrorPage';
import UnauthorizedErrorPage from 'client/components/error/unauthorizedErrorPage';
import TranslationProvider from 'client/components/translationProvider';
import UnprocessableEntityPageContainer from 'client/components/error/UnprocessableEntityPageContainer';
import 'client/styles/common.scss';

import { Tracking } from 'web-core/src/tracking';

import App from './app';
import NotFoundPage from './notFoundPage';
import InMaintenancePage from './inMaintenancePage';

function bindPageViewTracking() {
  return () => {
    Tracking.trackPageView();
  };
}

export default (
  <Route component={App}>
    <Route component={TranslationProvider} keys={['app', 'common', 'partner']}>
      <Route path="/">
        <IndexRedirect to="/404" />
        <Route path="404" component={NotFoundPage} onEnter={bindPageViewTracking()} />
        <Route
          path="422"
          component={UnprocessableEntityPageContainer}
          onEnter={bindPageViewTracking()} />
        <Route path="500" component={ServerErrorPage} onEnter={bindPageViewTracking()} />
        <Route path="509" component={ConflictPage} onEnter={bindPageViewTracking()} />
        <Route path="403" component={UnauthorizedErrorPage} onEnter={bindPageViewTracking()} />
        <Route path="503" component={InMaintenancePage} onEnter={bindPageViewTracking()} />
      </Route>
    </Route>
  </Route>
);
