import React, { PureComponent } from 'react';
import Translate from 'client/components/translate';
import 'client/styles/error.scss';
import GLOBALS from 'client/constants/globals';

class ConflictPage extends PureComponent {
  render() {
    return (
      <div className="error-page center-col" style={{ paddingTop: '2rem' }}>
        <div className="text-center pad-x">
          <h3><Translate path="common.error.oops" /></h3>
          <h3><Translate path="common.error.somethingWrong" /></h3>

          <div className="error-image">
            <img alt="spilt_coffee" src="https://images.contentful.com/heg7gyzix6dq/40y2o3AzhuQ2WEe62GOWUs/4719e729ea64cfcb599f0f359663b00e/spilt_coffee.svg" />
          </div>

          <div className="pad-top" style={{ fontWeight: 300, maxWidth: 340, margin: 'auto' }}>
            <Translate path="common.error.problems" /> <a href={`tel:${GLOBALS.phone}`} style={{ fontWeight: 700 }}>{ GLOBALS.phoneText }</a>.
          </div>
        </div>
      </div>
    );
  }
}

export default ConflictPage;
