// @flow

import React, { PureComponent } from 'react';
import Translate from 'client/components/translate';
import 'client/styles/error.scss';

const TRANSLATION_PATH = 'common.error';

type Props = {
  isPartner: Boolean,
  dashboardUrl: string,
};

class ErrorPage extends PureComponent<Props> {
  render() {
    const { isPartner, dashboardUrl } = this.props;
    const href = dashboardUrl || '/';
    const paths = {
      title: isPartner
        ? `${TRANSLATION_PATH}.partner.notFoundTitle`
        : `${TRANSLATION_PATH}.pageDNE`,
      message: isPartner
        ? `${TRANSLATION_PATH}.partner.notFoundMessage`
        : `${TRANSLATION_PATH}.checkURL`,
      link: isPartner ? `${TRANSLATION_PATH}.partner.notFoundLink` : `${TRANSLATION_PATH}.goHome`,
    };

    return (
      <div className="error-page page-dne">
        <div className="error-page--wrapper">
          <div className="error-bg-container">
            <div className="sorry">
              <img
                alt="sorry"
                src="https://images.contentful.com/heg7gyzix6dq/3kEqE5MkYMQes4ec0ouM4W/6614c448fe9f575668d4199890b13f50/sorry.svg" />
            </div>
          </div>
          <div className="error-content-container">
            <div className="text-center pad">
              {isPartner ? (
                <h1>
                  <Translate path={paths.title} />
                </h1>
              ) : (
                <h3>
                  <Translate path={paths.title} />
                </h3>
              )}
              <div style={{ fontWeight: 300 }}>
                <Translate path={paths.message} />
              </div>
              <div className="pad-top">
                <a href={href} style={{ fontWeight: 700 }}>
                  <Translate path={paths.link} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
