import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTranslateKeyIfNeeded } from 'client/actions/translations';

class TranslationProvider extends Component {
  UNSAFE_componentWillMount() {
    const { dispatch, route: { keys } } = this.props;
    keys.forEach((key) => {
      dispatch(fetchTranslateKeyIfNeeded(key));
    });
  }
  render() {
    return React.Children.only(this.props.children);
  }
}

TranslationProvider.propTypes = {
  children: PropTypes.element.isRequired,
  dispatch: PropTypes.func.isRequired,
  route: PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default connect()(TranslationProvider);
