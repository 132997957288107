// @flow
import format from 'date-fns/format';
import _ from 'lodash';

type DateFormatType = 'DD/MM/YYYY' | 'MM-DD-YYYY' | 'YYYY/MM/DD';

function cleanVal(val: string | number): string {
  const uncleanValStr = val.toString();
  const valStr = uncleanValStr.replace(/^(0)+|([^0-9.])/g, '') || 0;
  return parseFloat(valStr).toString();
}

function roundDecimals(decimalVals: string = '0', precision: number): string {
  if (precision === 0) {
    return '';
  }
  const precisionMultiplier = 10 ** precision;
  const multipliedVal = parseFloat(`1.${decimalVals}`) * precisionMultiplier;
  // preserve the tenths place
  const fixedVal = parseFloat(multipliedVal.toFixed(1));
  // console.log(fixedVal)
  return fixedVal
    ? Math.round(fixedVal)
      .toString()
      .substr(1)
    : precisionMultiplier.toString().substr(1);
}

function divideBy(valStr: string, qty: number): Array<string> {
  if (!qty || valStr.length <= qty) return [valStr];
  const remainderIndex = valStr.length % qty;
  const nextGroup = remainderIndex ? valStr.substring(0, remainderIndex) : valStr.substr(0, qty);
  const remaining = valStr.substr(remainderIndex || qty);
  return remaining ? [nextGroup].concat(divideBy(remaining, qty)) : [nextGroup];
}

export const formatNumber = (num: number | string) =>
  num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

function formatCurrency(value: string | number, precision: number = 0): any {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return value;
  }
  const valStr = cleanVal(value);
  const valSplit = valStr.split('.');
  const wholes = divideBy(valSplit[0], 3);
  const fractions = roundDecimals(valSplit[1], precision);
  const delimitedWholes = wholes.join(',');
  return fractions ? `$${delimitedWholes}.${fractions}` : `$${delimitedWholes}`;
}

export function formatTitleCase(str: string | Array<string>, delimiter: string = ' '): string {
  const strings = Array.isArray(str) ? str : str.toLowerCase().split(delimiter);
  const firstWord = strings.shift();
  if (!firstWord && firstWord !== '') return undefined;
  const firstLetter = firstWord.match(/([^A-z]*[A-z])/);
  const tCasedFirstWord = firstLetter
    ? `${firstLetter[0].toUpperCase()}${firstWord.substr(firstLetter[0].length)}`
    : firstWord;
  return delimiter
    ? [tCasedFirstWord]
      .concat(formatTitleCase(strings, delimiter))
      .filter(x => x !== undefined)
      .join(delimiter)
    : tCasedFirstWord;
}

// TODO, memoize this but cache key needs to be based on value and precision
export const currency = formatCurrency;
export const titleCase = formatTitleCase;

export function formatPhoneNumber(
  currentString: string | number = '',
  previousString: string = '',
) {
  if (currentString === null) {
    currentString = ''; // eslint-disable-line
  }
  if (typeof currentString === 'number') {
    currentString = '' + currentString; // eslint-disable-line
  }

  // if they're deleting, just return what they're doing
  if (currentString.length < previousString.length) {
    return currentString;
  }

  const current = currentString.replace(/[^0-9]/g, '');
  if (current.length < 3) {
    return current;
  }

  let returnString = `(${current.substr(0, 3)}) ${current.substr(3, 3)}`;
  if (current.length > 5) {
    returnString = `${returnString}-${current.substr(6, 4)}`;
  }

  return returnString;
}

type FullPhoneNumberType = {
  phone_number: string | number,
  phone_number_type?: 'HOME' | 'WORK' | 'MOBILE',
  phone_number_ext?: string | number,
};

export function formatFullPhoneNumber(
  { phone_number, phone_number_type, phone_number_ext }: FullPhoneNumberType,
  phoneTypes: Object,
) {
  let phoneString = '';
  if (phone_number_type) {
    const type = _.get(phoneTypes, phone_number_type);
    phoneString += `${type}: `;
  }
  phoneString += formatPhoneNumber(phone_number);
  if (phone_number_ext) {
    phoneString += ` ext. ${phone_number_ext}`;
  }
  return phoneString;
}

const ISO_REGEX = /(\d{4})(-\d{2}){2}/;

export function isoToSlashDate(dateStr: string | null = '') {
  if (dateStr === null) return '';

  if (ISO_REGEX.test(dateStr)) {
    return format(dateStr, 'MM / DD / YYYY');
  }

  return dateStr;
}

export function slashDateToISO(dateStr: string) {
  // if it's already in ISO format (it hasn't been changed in the form)
  if (ISO_REGEX.test(dateStr)) {
    return dateStr;
  }

  const cleanDate = (dateStr || '').replace(/\s*\/\s*/g, '-');
  let parts = cleanDate.split('-');
  // if no day component (MM-YYYY), assume first day of the month
  if (parts.length === 2) {
    parts = [parts[0], '01', parts[1]];
  }

  parts = [parts[2], parts[0], parts[1]];
  return parts.join('-');
}

export function removeSSNDash(str: string = '') {
  if (str === null) {
    return '';
  }
  return str.replace(/-/gi, '');
}

export function encryptSSN(str: string= '') {
  if (str === null) return '';

  const last = str.substr(5, 4);

  return `XXX-XXX-${last}`;
}

export function formatSSN(str: string = '') {
  if (str === null) {
    return '';
  }

  const first = str.substr(0, 3);
  const middle = str.substr(3, 2);
  const last = str.substr(5, 4);
  let delimitedVal = [first, middle, last].filter(v => v).join('-');
  if (str.length === 3 || str.length === 5) {
    delimitedVal += '-';
  }
  return delimitedVal;
}

// ['apples', 'oranges', 'bananas'] => 'apples, oranges, and bananas'
export function formatStringArray(stringArray: string[], commonTranslations: Object = {}) {
  if (!Array.isArray(stringArray)) {
    return stringArray;
  }

  const { length } = stringArray;

  if (length < 2) {
    return stringArray.join('');
  }
  const andText = _.get(commonTranslations, 'and', 'and');

  if (length === 2) {
    return `${stringArray[0]} ${andText} ${stringArray[1]}`;
  }

  return stringArray
    .slice(0, length - 1)
    .join(', ')
    .concat(`, ${andText} ${stringArray[length - 1]}`);
}

// "Entertainer,Businessman,Singer" --> "Entertainer, Businessman, and Singer"
export function formatStringList(stringArg: string, commonTranslations: Object = {}) {
  if (typeof stringArg !== 'string') {
    return stringArg;
  }

  return formatStringArray(
    stringArg.split(',').map(s => s.trim()),
    commonTranslations,
  );
}

export function getDateFormatString(locale: 'en-US' | 'es-US'): 'DD/MM/YYYY' | 'MM-DD-YYYY' {
  // Two locales supported as of 02/28/2019: en-US and es-US
  return locale === 'es-US' ? 'DD/MM/YYYY' : 'MM-DD-YYYY';
}

export const convertToNumber = (value) => {
  if (!value) {
    return value;
  }
  return parseInt(value, 10);
};

// Ladder API requires YYYY-MM-DD, InputDate component uses MM / DD / YYYY, with spaces
export const getFormattedDob = (dob: string, dateFormat: DateFormatType) =>
  // eslint-disable-next-line no-nested-ternary
  (dob
    ? dateFormat === 'DD/MM/YYYY'
      ? `${dob.slice(5, 7)} / ${dob.slice(8)} / ${dob.slice(0, 4)}`
      : `${dob.slice(10)}-${dob.slice(0, 2)}-${dob.slice(5, 7)}`
    : '');
