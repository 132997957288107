// @flow

import _ from 'lodash';

type SiteConfigsType = {
  brandSiteConfig: Object,
  defaultSiteConfig: Object,
  brokerAgencyInfo?: Object,
  partnerAgencyInfo?: Object,
};

type SiteConfigsPageType = 'home' | 'coverage' | 'partner';

export const PartnershipType = {
  ENTERPRISE: 'ENTERPRISE',
  WHITE_LABEL: 'WHITE_LABEL',
};

export const getConfigValue = (
  siteConfigs: SiteConfigsType,
  path: string,
  defaultValue: string | void,
) => {
  if (!siteConfigs) return defaultValue;

  return _.get(
    siteConfigs.brandSiteConfig,
    path,
    _.get(siteConfigs.defaultSiteConfig, path, defaultValue),
  );
};

export const getHeroContent = (
  siteConfigs: SiteConfigsType,
  page: SiteConfigsPageType,
  isPartner: boolean,
) => {
  const homePageConfigs = {
    // extend from defaultSiteConfig
    heroHeader: getConfigValue(siteConfigs, 'heroHeader'),
    heroSubHeaderSep: getConfigValue(siteConfigs, 'heroSubHeaderSep'),
    heroSubHeaderOep: getConfigValue(siteConfigs, 'heroSubHeaderOep'),
    backgroundImage: getConfigValue(siteConfigs, 'backgroundImage.file.url', ''),
    // only fetch from partner brandSiteConfig
    bannerText: _.get(siteConfigs.brandSiteConfig, 'bannerText', ''),
    ctaText: _.get(siteConfigs.brandSiteConfig, 'ctaText', ''),
    ctaUrl: _.get(siteConfigs.brandSiteConfig, 'ctaUrl', ''),
  };
  // for partner, if no coverage configs, extends hero from homepage;
  // for stride, getting coverage configs from default
  const coveragePageConfigs = isPartner
    ? {
      heroHeader: _.get(siteConfigs.brandSiteConfig, 'coverageHeroHeader'),
      heroSubHeaderSep: _.get(siteConfigs.brandSiteConfig, 'coverageHeroSubHeaderSep'),
      heroSubHeaderOep: _.get(siteConfigs.brandSiteConfig, 'coverageHeroSubHeaderOep'),
      ctaText: _.get(siteConfigs.brandSiteConfig, 'coverageCtaText'),
      ctaUrl: _.get(siteConfigs.brandSiteConfig, 'coverageCtaUrl'),
    }
    : {
      heroHeader: _.get(siteConfigs.defaultSiteConfig, 'coverageHeroHeader'),
      heroSubHeaderSep: _.get(siteConfigs.defaultSiteConfig, 'coverageHeroSubHeaderSep'),
      heroSubHeaderOep: _.get(siteConfigs.defaultSiteConfig, 'coverageHeroSubHeaderOep'),
      ctaText: _.get(siteConfigs.defaultSiteConfig, 'coverageCtaText'),
      ctaUrl: _.get(siteConfigs.defaultSiteConfig, 'coverageCtaUrl'),
    };

  if (page === 'coverage') {
    return _.assignInWith(coveragePageConfigs, homePageConfigs, (objValue, srcValue): Object => {
      if (_.isUndefined(objValue)) return srcValue;
      return objValue;
    });
  }
  return homePageConfigs;
};

export const userIsBroker = (state: Object): boolean => {
  const { partnerUser, agentId } = state;
  const userGroups = (partnerUser && partnerUser.groups) || [];
  return userGroups.includes('broker') || !!agentId;
};

// copied from marketplace-ede state utils
// (will want to delete from here or marketplace-ede)
export const getPartnershipType = (siteConfigs: SiteConfigsType) =>
  _.get(siteConfigs, 'partnerAgencyInfo.partnerInfo.partnershipType');

export const isStridePartner = (siteConfigs: SiteConfigsType) => {
  const partnershipType = getPartnershipType(siteConfigs);
  return (
    partnershipType === PartnershipType.ENTERPRISE ||
    partnershipType === PartnershipType.WHITE_LABEL
  );
};

// copied from marketplace-ede state utils
// (will want to delete from here or marketplace-ede)
export const getDashboardUrl = (state: Object): ?string => {
  const agencyInfo = _.get(state, 'siteConfigs.brandSiteConfig.partnerConfig.agencyInfo');
  if (userIsBroker(state)) {
    return _.get(agencyInfo, 'agentDashboardUrl');
  }

  return _.get(agencyInfo, 'dashboardUrl');
};

export type StrideConsumerArgsType = {
  siteConfigs: SiteConfigsType,
  isIsaAgent?: boolean,
  agentId?: string,
  partnerUser?: Object,
  defaultAgencyNpn: string,
};

export const isStrideConsumer = (state: StrideConsumerArgsType): boolean => {
  // Is Broker or ISA Agent
  if (
    userIsBroker(state) ||
    _.get(state, 'isIsaAgent') ||
    _.has(state, 'siteConfigs.brokerAgencyInfo')
  ) {
    return false;
  }
  const { siteConfigs } = state;
  // Is Partner
  if (isStridePartner(siteConfigs)) return false;
  // I don't think Stride has an agencyCode
  if (getConfigValue(siteConfigs, 'partnerConfig.agencyInfo.agencyCode')) return false;
  return true;
};
