// @flow
/* eslint-disable max-len */

export type CoverageEndedReasonType =
  | 'COMPANY_STOPPED_OFFERING_INSURANCE'
  | 'COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS'
  | 'INSURANCE_TOO_EXPENSIVE'
  | 'MEDICAL_NEEDS_NOT_COVERED'
  | 'OTHER'
  | 'PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY';

export class COVERAGE_ENDED_REASON_TYPES {
  static COMPANY_STOPPED_OFFERING_INSURANCE: CoverageEndedReasonType =
    'COMPANY_STOPPED_OFFERING_INSURANCE';
  static COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS: CoverageEndedReasonType =
    'COMPANY_STOPPED_OFFERING_INSURANCE_TO_DEPENDENTS';
  static INSURANCE_TOO_EXPENSIVE: CoverageEndedReasonType = 'INSURANCE_TOO_EXPENSIVE';
  static MEDICAL_NEEDS_NOT_COVERED: CoverageEndedReasonType = 'MEDICAL_NEEDS_NOT_COVERED';
  static OTHER: CoverageEndedReasonType = 'OTHER';
  static PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY: CoverageEndedReasonType =
    'PARENT_NO_LONGER_WORKS_FOR_THE_COMPANY';
}

export type ChangeInCircumstanceType =
  | 'ADOPTION'
  | 'FUTURE_LOSS_OF_MEC'
  | 'GAINING_LAWFUL_PRESENCE'
  | 'LOSS_OF_MEC'
  | 'MARRIAGE'
  | 'RELEASE_FROM_INCARCERATION'
  | 'RELOCATION';

export class CHANGE_IN_CURCUMSTANCE_TYPES {
  static ADOPTION: ChangeInCircumstanceType = 'ADOPTION';
  static FUTURE_LOSS_OF_MEC: ChangeInCircumstanceType = 'FUTURE_LOSS_OF_MEC';
  static GAINING_LAWFUL_PRESENCE: ChangeInCircumstanceType = 'GAINING_LAWFUL_PRESENCE';
  static LOSS_OF_MEC: ChangeInCircumstanceType = 'LOSS_OF_MEC';
  static MARRIAGE: ChangeInCircumstanceType = 'MARRIAGE';
  static RELEASE_FROM_INCARCERATION: ChangeInCircumstanceType = 'RELEASE_FROM_INCARCERATION';
  static RELOCATION: ChangeInCircumstanceType = 'RELOCATION';
}

export type IncarcerationTypes = 'INCARCERATED' | 'NOT_INCARCERATED' | 'PENDING_DISPOSITION';

export class INCARCERATION_TYPES {
  static PENDING_DISPOSITION: IncarcerationTypes = 'PENDING_DISPOSITION';
  static INCARCERATED: IncarcerationTypes = 'INCARCERATED';
  static NOT_INCARCERATED: IncarcerationTypes = 'NOT_INCARCERATED';
}

export type DependentChildCoveredStatusReasonType =
  | '128_DEPENDENT_CHILD_DOESNT_HAVE_MEC'
  | '551_APPLICATION_NOT_REQUESTING_FA'
  | '555_N_A_RULE_DOES_NOT_APPLY'
  | '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE'
  | '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE'
  | '655_NOT_PRELIM_MEDICAID_ELIGIBLE'
  | '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS'
  | '999_N_A_RULE_INDICATOR_IS_Y';

export class DEPENDENT_CHILD_COVERED_STATUS_REASON_TYPES {
  static DEPENDENT_CHILD_DOESNT_HAVE_MEC: DependentChildCoveredStatusReasonType =
    '128_DEPENDENT_CHILD_DOESNT_HAVE_MEC';
  static APPLICATION_NOT_REQUESTING_FA: DependentChildCoveredStatusReasonType =
    '551_APPLICATION_NOT_REQUESTING_FA';
  static N_A_RULE_DOES_NOT_APPLY: DependentChildCoveredStatusReasonType =
    '555_N_A_RULE_DOES_NOT_APPLY';
  static INDIVIDUAL_NOT_REQUESTING_COVERAGE: DependentChildCoveredStatusReasonType =
    '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE';
  static NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE: DependentChildCoveredStatusReasonType =
    '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE';
  static NOT_PRELIM_MEDICAID_ELIGIBLE: DependentChildCoveredStatusReasonType =
    '655_NOT_PRELIM_MEDICAID_ELIGIBLE';
  static APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS: DependentChildCoveredStatusReasonType =
    '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS';
  static N_A_RULE_INDICATOR_IS_Y: DependentChildCoveredStatusReasonType =
    '999_N_A_RULE_INDICATOR_IS_Y';
}

type ChipWaitingPeriodStatusReasonType =
  | '139_CHIP_WAITING_PERIOD_IN_EFFECT'
  | '470_RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE'
  | '551_APPLICATION_NOT_REQUESTING_FA'
  | '555_N_A_RULE_DOES_NOT_APPLY'
  | '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE'
  | '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE'
  | '656_ATTESTED_TO_CHIP_WAITING_PERIOD'
  | '657_APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE'
  | '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS'
  | '999_N_A_RULE_INDICATOR_IS_Y'
  | '555_N_A_RULE_DOES_NOT_APPLY';

export class CHIP_WAITING_PERIOD_STATUS_REASON_TYPES {
  static CHIP_WAITING_PERIOD_IN_EFFECT: ChipWaitingPeriodStatusReasonType =
    '139_CHIP_WAITING_PERIOD_IN_EFFECT';
  static RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE: ChipWaitingPeriodStatusReasonType =
    '470_RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE';
  static APPLICATION_NOT_REQUESTING_FA: ChipWaitingPeriodStatusReasonType =
    '551_APPLICATION_NOT_REQUESTING_FA';
  static N_A_RULE_DOES_NOT_APPLY: ChipWaitingPeriodStatusReasonType = '555_N_A_RULE_DOES_NOT_APPLY';
  static INDIVIDUAL_NOT_REQUESTING_COVERAGE: ChipWaitingPeriodStatusReasonType =
    '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE';
  static NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE: ChipWaitingPeriodStatusReasonType =
    '652_NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE';
  static ATTESTED_TO_CHIP_WAITING_PERIOD: ChipWaitingPeriodStatusReasonType =
    '656_ATTESTED_TO_CHIP_WAITING_PERIOD';
  static APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE: ChipWaitingPeriodStatusReasonType =
    '657_APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE';
  static APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS: ChipWaitingPeriodStatusReasonType =
    '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS';
  static N_A_RULE_INDICATOR_IS_Y: ChipWaitingPeriodStatusReasonType = '999_N_A_RULE_INDICATOR_IS_Y';
  static N_A_RULE_DOES_NOT_APPLY: ChipWaitingPeriodStatusReasonType = '555_N_A_RULE_DOES_NOT_APPLY';
}

export const INVALID_CHIP_WAITING_PERIOD_STATUS_REASONS = [
  CHIP_WAITING_PERIOD_STATUS_REASON_TYPES.N_A_RULE_DOES_NOT_APPLY,
  CHIP_WAITING_PERIOD_STATUS_REASON_TYPES.NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE,
  CHIP_WAITING_PERIOD_STATUS_REASON_TYPES.APPLICANT_NOT_PRELIM_CHIP_ELIGIBLE,
  CHIP_WAITING_PERIOD_STATUS_REASON_TYPES.RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE,
  CHIP_WAITING_PERIOD_STATUS_REASON_TYPES.APPLICATION_NOT_REQUESTING_FA,
];

export const INVALID_DEPENDENT_CHILD_COVERED_STATUS_REASONS = [
  DEPENDENT_CHILD_COVERED_STATUS_REASON_TYPES.N_A_RULE_DOES_NOT_APPLY,
  DEPENDENT_CHILD_COVERED_STATUS_REASON_TYPES.NOT_PRELIM_MEDICAID_OR_PRELIM_CHIP_ELIGIBLE,
  DEPENDENT_CHILD_COVERED_STATUS_REASON_TYPES.NOT_PRELIM_MEDICAID_ELIGIBLE,
];
