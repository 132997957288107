import {
  UPDATE_CACHE,
  CLEAR_CACHE,
} from '../constants/cache';

export function updateCache(key, data) {
  return {
    type: UPDATE_CACHE,
    key,
    data,
    createdAt: Date.now(),
  };
}

export function clearCache() {
  return {
    type: CLEAR_CACHE,
  };
}
