import { REQUEST_ASNYC } from '../constants/asyncRequestTypes';

export default function asyncRequests(state = [], action) {
  switch (action.type) {
    case REQUEST_ASNYC:
      return state.concat(action.promise);
    default:
      return state;
  }
}
