// @flow
import _ from 'lodash';
import GLOBALS from 'routes/marketplace-ede/web-ui/constants/globals';

import { getAge } from 'routes/marketplace-ede/web-ui/utils/member';

import { getAppMembers, getEdeMemberAddress } from 'routes/marketplace-ede/utils/state';
import {
  getEdeMemberDemographic,
  getEdeMemberFullName,
} from 'routes/marketplace-ede/utils/edeMember';
import {
  getTaxClaimer,
  getMembersRelationship,
  INVALID_SPOUSE,
  getMemberSpouse,
} from 'routes/marketplace-ede/utils/familyRelationship';
import {
  CITIZENSHIP_STATUS_REASONS,
  DOCUMENT_VERIFICATION_NEEDED_CITIZENSHIP_STATUS_REASONS,
  GRANT_DATE_REQUIRED_STATUS_REASONS,
  NATURALIZATION_DOCUMENTS,
  QHP_LAWFUL_PRESENCE_STATUS_REASONS,
  SSN_STATUS_REASONS,
  UNVERIFIED_QHP_LAWFUL_PRESENCE_STATUS_REASONS,
} from 'routes/marketplace-ede/constants/ede/citizenship';
import {
  INVALID_CHIP_WAITING_PERIOD_STATUS_REASONS,
  INVALID_DEPENDENT_CHILD_COVERED_STATUS_REASONS,
  INCARCERATION_TYPES,
  AMERICAN_INDIAN_ALASKAN_NATIVE_TYPES,
} from 'routes/marketplace-ede/constants/additional';
import {
  MEDICAID_AND_CHIP_PENDING_STATUS_REASONS,
  ASYNC_INCOME_STATUS_REASON,
} from 'routes/marketplace-ede/constants/ede/eligibility';
import { METAL_TYPE_ORDERS } from 'routes/marketplace-ede/constants/health';
import type {
  EdeMemberType,
  InsuranceCoverageType,
} from 'routes/marketplace-ede/types/ede/edeMember';
import type { EdeApplicationPayloadType } from 'routes/marketplace-ede/types/ede/edeApplication';
import type { StateProgramsEnumType } from 'routes/marketplace-ede/types/ede/statePrograms.flow';
import type { EdeApplicationType, SesResponseType } from 'web-core/types/ede/application';

const { YES_VALUE, NO_VALUE, NOT_APPLICABLE } = GLOBALS;
const TEMPORARY_VALUE = 'TEMPORARY';

export const MEMBER_ATTESTATION_PATH = 'attestations';
export const MEMBER_COMPUTED_PATH = 'computed';
export const MEDICAID_CHIP_STANDARD_PATH = `${MEMBER_COMPUTED_PATH}.medicaidChipStandard`;

export const isMemberDependent = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.taxDependentIndicator`, false);

export const isMemberAttestedToFosterCare = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fosterCareIndicator`, false);

export const isMemberRequestingCoverage = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.requestingCoverageIndicator`, false);

export const filterMembersRequestingCoverage = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> => _.filter(members, isMemberRequestingCoverage);

export const isMemberPreliminarilyMedicaidEligible = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }

  return (
    computed.preliminaryMedicaidStatus === YES_VALUE ||
    computed.emergencyMedicaidStatus === YES_VALUE
  );
};

export const isMemberPreliminaryEmergencyMedicaidStatus = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }

  return (
    computed.preliminaryEmergencyMedicaidStatus === YES_VALUE &&
    computed.preliminaryMedicaidStatus === NO_VALUE
  );
};

// only check preliminaryMedicaidStatus, not includes emergencyMedicaidStatus
export const isPreliminarilyMedicaid = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }
  return computed.preliminaryMedicaidStatus === YES_VALUE;
};

// only check preliminaryMedicaidStatus, not includes emergencyMedicaidStatus
export const isMemberNotPreliminaryChipAndMedicaid = (computed: Object) =>
  computed.preliminaryMedicaidStatus !== YES_VALUE && computed.preliminaryChipStatus !== YES_VALUE;

// Backend Responses for UI Item 34, "Terminate Coverage Agreement"
// Condition for being "prelimQHP":
// (citizenshipStatusIndicator=Y or qhpLawfulPresenceStatus=Y) and
// qhpResidency = Y and
// incarcerationStatus = N and
// (preliminaryCHIPStatus <> Y AND preliminaryMedicaidStatus <> Y)
export const isMemberPreliminarilyQhpEligible = (member: EdeMemberType): boolean => {
  const computed = _.get(member, MEMBER_COMPUTED_PATH);
  if (!_.isObject(computed)) {
    return false;
  }

  const isLawfullyPresent =
    computed.citizenshipStatus === YES_VALUE || computed.qhpLawfulPresenceStatus === YES_VALUE;
  const isNotIncarcerated = computed.incarcerationStatus === NO_VALUE;
  // Fix for isResident check that was skipping test case 1.E to app summary page
  // without asking about qualifying life events
  const isResident = _.includes(
    [YES_VALUE, NOT_APPLICABLE],
    _.get(computed, 'qhpResidency.qhpResidencyStatus'),
  );

  return (
    isLawfullyPresent &&
    isResident &&
    isNotIncarcerated &&
    isMemberNotPreliminaryChipAndMedicaid(computed)
  );
};

export const getPreliminaryQhpEligibleMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> => {

  return _.filter(members, isMemberPreliminarilyQhpEligible);
};

const isValidDependentChildCoveredStatusReason = (member: EdeMemberType): boolean =>
  !_.includes(
    INVALID_DEPENDENT_CHILD_COVERED_STATUS_REASONS,
    _.get(member, `${MEMBER_COMPUTED_PATH}.dependentChildCoveredStatusReason`),
  );

export const isParentCaretaker = (member: EdeMemberType): boolean =>
  _.get(member, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerCategoryStatus`) === YES_VALUE;

export const memberHasHome = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic.noHomeAddressIndicator`);

export const isMemberPreliminarilyChipEligible = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryChipStatus`) === YES_VALUE;

export const isMemberPreliminarilyAptcEligible = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryAptcStatus`) === YES_VALUE;

/**
 *  CMS Change Request #25 - backend #17
 *  1. dependentChildCoveredStatusReason <> 555, 652, 655 OR
 *  2. preliminaryMedicaidStatus = YES  and parentCaretakerCategoryStatus = YES  with parentCaretakerChildList containing non-applicant children
 */
export const doesMemberNeedDependentNonApplicantCoverage = (members: Array<EdeMemberType>) =>
  _.filter(
    members,
    member =>
      (isValidDependentChildCoveredStatusReason(member) && isPreliminarilyMedicaid(member)) ||
      _.get(member, 'attestations.medicaid.coveredDependentChildIndicator', false),

    // isValidDependentChildCoveredStatusReason(member) ||
    // hasNonApplicantDependant(member, memberIds),
    // );

    // const applicants = filterMembersRequestingCoverage(members);
    // return _.filter(applicants, m =>
    //   isPreliminarilyMedicaid(m) && _.get(m, `${MEMBER_COMPUTED_PATH}.dependentChildCoveredStatusReason`) ===
    //     '670_APTC_INELGBL_DID_NOT_AGREE_TO_MDCAID_CHIP_LEGAL_ATTESTATIONS'
  );
export const getPossibleEnrolledDependentMembers = (
  applicants: Array<EdeMemberType>,
  members: Array<EdeMemberType>,
) =>
  _.reduce(
    applicants,
    (options, caretaker) => {
      const list = _.get(caretaker, 'computed.medicaidChipStandard.parentCaretakerChildList', []);
      const dependents = _.map(list, memberIdentifier => _.find(members, { memberIdentifier }));

      if (_.isEmpty(dependents)) return options;
      return _.unionBy(
        options,
        _.reject(dependents, isMemberRequestingCoverage),
        'memberIdentifier',
      );
    },
    [],
  );

export const isMemberAttestedToCitizenship = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.citizenshipIndicator`, false);

export const hasSSNDataMismatch = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.ssnStatusReason`) === SSN_STATUS_REASONS.SSA_DATA_MISMATCH;

export const doesMemberNeedAdditionalChipAttestations = (member: EdeMemberType) =>
  !_.includes(
    INVALID_CHIP_WAITING_PERIOD_STATUS_REASONS,
    _.get(member, `${MEMBER_COMPUTED_PATH}.chipWaitingPeriodStatusReason`),
  );

/**
 *  CMS Change request #25 (backend response logic #20):
 *  preliminaryCHIPStatus = YES and chipWaitingPeriodStatusReason <> 555, 652, 657, 470, 551
 */
export const shouldShowChipCoverageEndPage = (members: Array<EdeMemberType>) =>
  _.some(members, isMemberPreliminarilyChipEligible);

export const hasEscOrRetireeCoverage = (member: EdeMemberType) => {
  const coverages = _.get(
    member,
    `${MEMBER_ATTESTATION_PATH}.insuranceCoverage.employerSponsoredCoverageOffers`,
    [],
  );
  return _.some(
    coverages,
    coverage => coverage.escEnrolledIndicator && !coverage.cobraAvailableIndicator,
  );
};

export const getChipStateHealthBenefitReasonCode = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.chipStateHealthBenefitStatusReason`, []);

export const hasAbsentParent = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.absentParentIndicator`) === true;

/**
 * ui_question_companion_guide_09132019_v14: Must use SES calculated annual income amount.
 * Setting the default to 0 because SES may not necessarily return
 * attestedAnnualizedAptcIndividualIncomeAmt, so we should be defensive and allow customers to attest
 * the actual amount when they see 0.
 */
export const getMemberAttestedAnnualizedAptcIndividualIncomeAmt: (
  member: EdeMemberType
) => number = member =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.income.attestedAnnualizedAptcIndividualIncomeAmt`, 0);

export const getAttestedIncomeAmount: (member: EdeMemberType) => number = member =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.income.annualTaxIncome.incomeAmount`, 0);

export const getMemberPreliminaryMedicaidStatus: (member: EdeMemberType) => string | void = member => _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryMedicaidStatus`);

export const getFullTimeStudentAttestation = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fullTimeStatusIndicator`);

export const isMedicaidParent = (member: EdeMemberType): boolean => {
  const parentCaretakerIndicator = _.get(
    member,
    `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerCategoryStatus`,
  );
  return (
    (parentCaretakerIndicator === YES_VALUE || parentCaretakerIndicator === TEMPORARY_VALUE) &&
    isPreliminarilyMedicaid(member)
  );
};

export const getCaretakerChildren = (
  parent: EdeMemberType,
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.get(parent, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerChildList`, [])
    .map(memberIdentifier => _.find(members, m => m.memberIdentifier === memberIdentifier))
    .filter(
      m => _.get(m, `${MEDICAID_CHIP_STANDARD_PATH}.adultGroupCategoryStatus`) === NO_VALUE,
    );

export const getResidentParents = (edeApp, member) => {
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  const members = _.get(edeApp, 'edeMembers', []);
  const relations = _.filter(
    familyRelationships,
    rel =>
      rel.subMemberIdentifier === member.memberIdentifier &&
      (rel.relationship === 'PARENT' || rel.relationship === 'STEP_PARENT') &&
      _.get(rel, 'familyRelationshipIndicator.resideTogetherIndicator'),
  );
  return _.map(relations, rel => _.find(members, { memberIdentifier: rel.memberIdentifier }));
};

export const getChildrenEligibleForOutsideParent = (
  edeApp: EdeApplicationPayloadType,
): Array<EdeMemberType> => {
  const members = _.get(edeApp, 'edeMembers', []);
  const filerEligibility = () => {
    const primaryMember = getPrimaryMember(edeApp);
    return (
      !getMemberPregnancyIndicator(primaryMember) &&
      isMemberPreliminarilyMedicaidEligible(primaryMember)
    );
  };
  const children = members.filter(
    member =>
      getMemberAge(member) < 18 &&
      isMemberPreliminarilyMedicaidEligible(member) &&
      getResidentParents(edeApp, member).length <= 1,
  );
  const uniqChildren = _.uniqBy(children, 'memberIdentifier');
  return filerEligibility() ? uniqChildren : [];
};

// UICG #121:
// An applicant is preliminary eligible for Medicaid or emergency Medicaid
// (preliminaryMedicaidStatus = yes or emergencyMedicaidStatus = yes) AND
// The applicant is a PC/R (parentCaretakerIndicator = true) AND
// the child for whom the applicant is caring for lives in a deprivation state (deprivationRequirementRetained = T) AND
// the applicant has not been identified as unemployed/underemployed in the income section (childCaretakerDeprivedStatus = T) AND
// we don't already know if the child lives with both parents
export const getEligibleChildren = (
  edeApp: EdeApplicationPayloadType,
  deprivationState: boolean,
): Array<EdeMemberType> => {
  if (!deprivationState) return [];
  const members = _.get(edeApp, 'edeMembers', []);
  const applicants = filterMembersRequestingCoverage(members);
  const eligibleCaretakers = _.filter(
    applicants,
    m =>
      _.get(m, 'attestations.family.parentCaretakerIndicator') &&
      isMemberPreliminarilyMedicaidEligible(m),
  );

  const children = _.reduce(
    eligibleCaretakers,
    (list, parent) => {
      const childrenIds = _.get(
        parent,
        `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerChildList`,
        [],
      );
      _.forEach(childrenIds, (id) => {
        const child = _.find(members, { memberIdentifier: id });
        const residentialParents = getResidentParents(edeApp, child);
        if (
          residentialParents.length < 2 &&
          _.get(child, 'computed.medicaidChipStandard.childCaretakerDeprivedStatus') ===
            TEMPORARY_VALUE
        ) {
          list.push(child);
        }
      });
      return list;
    },
    [],
  );
  return _.uniqBy(children, 'memberIdentifier');
};

export const getApplicationParents = ({ state, members }: Object): Array<EdeMemberType> => {
  const appMembers = members || getAppMembers(state);
  return _.filter(appMembers, isMedicaidParent);
};

const getChipChildrenPageMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(members, (member) => {
    const { parent1WeeklyWorkHourQuantity, parent2WeeklyWorkHourQuantity } =
      _.get(member, 'attestations.medicaid') || {};
    return parent1WeeklyWorkHourQuantity !== null || parent2WeeklyWorkHourQuantity !== null;
  });

export const getDeprivedChildren = (
  members: Array<EdeMemberType>,
  deprivationState: boolean,
): Array<EdeMemberType> => {
  if (!deprivationState) return [];
  const tempCaretakers = _.filter(
    members,
    member =>
      isMemberRequestingCoverage(member) &&
      isMemberPreliminarilyMedicaidEligible(member) &&
      _.get(member, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerCategoryStatus`) ===
        TEMPORARY_VALUE,
  );

  const caretakerChildren = _.flatten(tempCaretakers.map(p => getCaretakerChildren(p, members)));

  const children = _.uniq(caretakerChildren).filter(
    m =>
      _.get(m, `${MEDICAID_CHIP_STANDARD_PATH}.childCaretakerDeprivedStatus`) === TEMPORARY_VALUE,
  );
  // per item 19 of backend ui responses tab,
  // only show for childCaretakerDeprivedStatus "TEMPORARY"

  return _.isEmpty(children) ? getChipChildrenPageMembers(members) : children;
};

export const shouldShowChipChildrenPage = (
  edeApp: EdeApplicationPayloadType,
  deprivationState: boolean,
): boolean =>
  shouldShowParentLivingOutsideTheHomeQuestion(edeApp) ||
  !_.isEmpty(getDeprivedChildren(_.get(edeApp, 'edeMembers', []), deprivationState)) ||
  !_.isEmpty(getEligibleChildren(edeApp, deprivationState));

export const shouldShowParentLivingOutsideTheHomeQuestion = (edeApp: EdeApplicationPayloadType) =>
  !!getChildrenEligibleForOutsideParent(edeApp).length;

export const shouldShowPaymentHelpPage = (members: Array<EdeMemberType>): boolean =>
  _.some(members, member =>
    isMemberPreliminarilyMedicaidEligible(member) ||
    isMemberPreliminaryEmergencyMedicaidStatus(member));

// TODO: revisit this selector
export const getMembersFromUpdateResponse = (res: SesResponseType) =>
  _.get(res, 'ede_application.ifp_app.members', []);

export const getEdeApplicationPostalCode = (state: Object): string =>
  _.get(state, 'edeApplication.attestations.application.coverageState', '');

export const getMemberBlindOrDisabledIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.nonMagi.blindOrDisabledIndicator`);

export const getMemberLongTermCareIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.nonMagi.longTermCareIndicator`);

export const getMemberMedicaidDeniedIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.medicaidDeniedIndicator`);

export const getMemberMedicaidDeniedDueToImmigrationIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.medicaidDeniedDueToImmigrationIndicator`);

export const getMemberUnpaidBillIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.unpaidBillIndicator`);

// TODO: we don't have this 'member_loss_coverage_3mos' in edePayload
export const getMemberCoverageLoss = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.member_loss_coverage_3mos`);

export const getMemberCurrentCoverages = (member: EdeMemberType): InsuranceCoverageType =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.medicaid.insuranceCoverage`) ||
  _.get(member, `${MEMBER_ATTESTATION_PATH}.insuranceCoverage.enrolledCoverages`, []);

export const getMemberChangeInCircumstance = (member: EdeMemberType): string =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.other.changeInCircumstance`, {});

export const getMemberPregnancyIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.pregnancyIndicator`);

export const getMemberFosterCareIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fosterCareIndicator`);

export const getMemberFullTimeStudent = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.fullTimeStatusIndicator`);

export const isMemberIncarcerated = (member: EdeMemberType): boolean => {
  const incarcerationType = _.get(member, `${MEMBER_ATTESTATION_PATH}.other.incarcerationType`);

  if (
    incarcerationType === INCARCERATION_TYPES.INCARCERATED ||
    incarcerationType === INCARCERATION_TYPES.PENDING_DISPOSITION
  ) {
    return true;
  }
  return false;
};

// Addition questions section on summary page also display 'forster care', 'incarceratio' and 'pregnancy' info
export const getMembersAdditionalInfo = (member: EdeMemberType) => {
  const blindOrDisabledIndicator = getMemberBlindOrDisabledIndicator(member);
  const changeInCircumstance = getMemberChangeInCircumstance(member);
  const coverageLoss = getMemberCoverageLoss(member);
  const currentCoverages = getMemberCurrentCoverages(member);
  const fosterCareIndicator = getMemberFosterCareIndicator(member);
  const fullTimeStatusIndicator = getMemberFullTimeStudent(member);
  const incarcerated = isMemberIncarcerated(member);
  const longTermCareIndicator = getMemberLongTermCareIndicator(member);
  const medicaidDeniedIndicator = getMemberMedicaidDeniedIndicator(member);
  const pregnancyIndicator = getMemberPregnancyIndicator(member);
  const unpaidBillIndicator = getMemberUnpaidBillIndicator(member);
  const memberPrelimMedicaidEligible = isMemberPreliminarilyMedicaidEligible(member);

  return {
    blindOrDisabledIndicator,
    changeInCircumstance,
    coverageLoss,
    currentCoverages,
    fosterCareIndicator,
    fullTimeStatusIndicator,
    incarcerated,
    longTermCareIndicator,
    medicaidDeniedIndicator,
    member,
    pregnancyIndicator,
    unpaidBillIndicator,
    memberPrelimMedicaidEligible,
  };
};

export const isRequestingFinancialAssistance = (
  edeApplication: EdeApplicationPayloadType,
): boolean =>
  _.get(edeApplication, 'attestations.application.requestingFinancialAssistanceIndicator', false);

// TODO: Revisit this selector
export const getQhpEnrollByDate = (edeApplication: EdeApplicationType, ffmId: string): string => {
  const applicants = _.get(
    edeApplication,
    'ApplicantEligibilityResponse.InsuranceApplication.InsuranceApplicant',
    [],
  );
  const applicant = Array.isArray(applicants)
    ? _.find(applicants, [
      'ExchangeAssignedInsuranceApplicantIdentification.IdentificationID',
      ffmId,
    ])
    : applicants;

  const SepEnrollDate = _.get(
    applicant,
    'SpecialEnrollmentPeriodEligibility.EligibilityDateRange.EndDate.Date',
    null,
  );
  const OepEnrollDate = _.get(
    applicant,
    'InitialEnrollmentPeriodEligibility.EligibilityDateRange.EndDate.Date',
    null,
  );

  return SepEnrollDate || OepEnrollDate;
};

export const getMemberDob = (member: EdeMemberType): string =>
  _.get(member, 'attestations.demographic.birthDate', '');
export const getMemberAge = (member: EdeMemberType): number => getAge(getMemberDob(member));
export const getMemberSex = (member: EdeMemberType): string =>
  _.get(member, 'attestations.demographic.sex', '');

// Consumer is requesting coverage AND consumer is 18-25 years old
export const getPossiblyFosterCareMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(members, (m: EdeMemberType) => {
    const age = getMemberAge(m);
    return isMemberRequestingCoverage(m) && age >= 18 && age <= 25;
  });

export const hasValidCitizenshipStatus = (member: EdeMemberType) => {
  const status = _.get(member, `${MEMBER_COMPUTED_PATH}.ssnStatusReason`);

  return (
    status === SSN_STATUS_REASONS.N_A_RULE_INDICATOR_IS_Y ||
    status === SSN_STATUS_REASONS.DMI_RESOLVED_BY_ESW_ADJUDICATION ||
    status === SSN_STATUS_REASONS.N_A
  );
};

export const getSSNMismatchMembers = (members: Array<EdeMemberType>) =>
  _.filter(
    members,
    (m: EdeMemberType) => isMemberAttestedToCitizenship(m) && hasSSNDataMismatch(m),
  );

export const getCitizenshipUnverifiedMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.filter(
    members,
    (m: EdeMemberType) => isMemberAttestedToCitizenship(m) && !hasValidCitizenshipStatus(m),
  );

export const getMemberMedicaidStatus = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.preliminaryMedicaidStatus`);

export const getMemberEmergencyMedicaidStatus = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.emergencyMedicaidStatus`);

export const isMemberCitizen = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.citizenshipStatus`) === YES_VALUE;

export const getMemberCitizenshipStatusReason = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.citizenshipStatusReason`);

export const getMemberNaturalizedCitizenIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.naturalizedCitizenIndicator`);

export const getMemberQhpLawfulPresenceStatusReason = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_COMPUTED_PATH}.qhpLawfulPresenceStatusReason`);

/**
 * CMS Requests Change #25
 * Backend Responses for UI #4
 * (citizenshipStatus = YES and citizenshipStatusReason <> 999_N_A_RULE_INDICATOR_IS_Y)
 * OR (naturalizedCitizenIndicator = true and requestingCoverageIndicator = true)
 */
export const getPotentialNaturalizedMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.filter(
    members,
    (m: EdeMemberType) =>
      (isMemberCitizen(m) &&
        getMemberCitizenshipStatusReason(m) !==
          CITIZENSHIP_STATUS_REASONS.N_A_RULE_INDICATOR_IS_Y &&
        getMemberCitizenshipStatusReason(m) !== CITIZENSHIP_STATUS_REASONS.N_A) ||
      (getMemberNaturalizedCitizenIndicator(m) && isMemberRequestingCoverage(m)),
  );

export const isPotentialNaturalizedMember = (member: EdeMemberType): boolean =>
  (isMemberCitizen(member) &&
    getMemberCitizenshipStatusReason(member) !==
      CITIZENSHIP_STATUS_REASONS.N_A_RULE_INDICATOR_IS_Y) ||
  (getMemberNaturalizedCitizenIndicator(member) && isMemberRequestingCoverage(member));

export const getNaturalizationUnverifiedMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.filter(members, (m: EdeMemberType) =>
    _.includes(
      DOCUMENT_VERIFICATION_NEEDED_CITIZENSHIP_STATUS_REASONS,
      getMemberCitizenshipStatusReason(m),
    ));

// '1997' & '1995' fixes Flow error for member.dob being possibly null. It will never
// happen--we collect dob before using this method--but if it somehow did occur,
// we want to return false to prevent false positives
export const isMemberBornBefore1996 = (member: EdeMemberType) => {
  const cutOffDate = new Date('1996-08-22').getTime();
  const dob = getMemberDob(member);
  const birthDate = new Date(dob || '1997').getTime();
  return birthDate < cutOffDate;
};

export const isMemberBornAfter1996 = (member: EdeMemberType) => {
  const cutOffDate = new Date('1996-08-22').getTime();
  const dob = getMemberDob(member);
  const birthDate = new Date(dob || '1995').getTime();
  return birthDate >= cutOffDate;
};

export const getSevisRequiredMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(
    members,
    (member: EdeMemberType) =>
      getMemberQhpLawfulPresenceStatusReason(member) ===
      QHP_LAWFUL_PRESENCE_STATUS_REASONS.SEVIS_ID_REQUIRED,
  );

export const getGrantDateRequiredMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(members, (member: EdeMemberType) =>
    _.includes(GRANT_DATE_REQUIRED_STATUS_REASONS, getMemberQhpLawfulPresenceStatusReason(member)));

export const getMemberCitizenshipIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.citizenshipIndicator`);

export const getLivedInUsSince1996Indicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.livedInUs5yearIndicator`);

export const getPossiblyEligibleImmigrationMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.filter(members, member => getMemberCitizenshipIndicator(member) === false);

export const getMemberLawfulPresenceAttestations = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence`);

export const getLawfulPresenceStatusIndicator = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.lawfulPresenceStatusIndicator`);

export const getEligibleImmigrationMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> => _.filter(members, getLawfulPresenceStatusIndicator);

export const isMemberAttestedCitizen = (member: EdeMemberType) =>
  getMemberCitizenshipIndicator(member) || getMemberNaturalizedCitizenIndicator(member);

// TODO: Confirm which attestations qualify as "eligible immigration status"
export const memberHasEligibleImmigrationStatus = (member: EdeMemberType) =>
  // (!getMemberCitizenshipIndicator(member) && getMemberNaturalizedCitizenIndicator(member)) ||
  getLawfulPresenceStatusIndicator(member);

export const getLawfulPresenceDocumentAttestations = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.lawfulPresence.lawfulPresenceDocumentation`);

export const getLawfulPresenceUnverifiedMembers = (
  members: Array<EdeMemberType>,
): Array<EdeMemberType> =>
  _.filter(members, (member: EdeMemberType) => {
    const hasStatus = memberHasEligibleImmigrationStatus(member);
    const hasMismatch = _.includes(
      UNVERIFIED_QHP_LAWFUL_PRESENCE_STATUS_REASONS,
      _.get(member, `${MEMBER_COMPUTED_PATH}.qhpLawfulPresenceStatusReason`),
    );
    return hasStatus && hasMismatch;
  });
/**
 * Consumer is requesting coverage
 * AND consumer did not attest to U.S. citizenship or nationality
 * AND attested to eligible immigration status
 * AND consumer was born before 8/22/1996
 */
// eslint-disable-next-line max-len
export const getPotentialResidenceMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(
    members,
    (member: EdeMemberType) =>
      isMemberRequestingCoverage(member) &&
      getLawfulPresenceStatusIndicator(member) &&
      isMemberBornBefore1996(member),
  );

export const isAgeGreaterOrEqualThan = (member: EdeMemberType, age: number): boolean =>
  getMemberAge(member) >= age;

export const isApplicantMarried = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic.maritalStatus`) === 'MARRIED';

export const getTaxDependentIndicator = (member: EdeMemberType): boolean =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.family.taxDependentIndicator`);

export const doesApplicantMeetVeteranCriteria = (member: EdeMemberType): boolean =>
  isAgeGreaterOrEqualThan(member, 17) ||
  isApplicantMarried(member) ||
  (!isApplicantMarried(member) && isAgeGreaterOrEqualThan(member, 15)) ||
  // TODO: refactor how we determine dependent
  (getTaxDependentIndicator(member) && isMemberDependent(member));

/**
 * Consumer is requesting coverage AND selected "No" for "Is everyone listed below a U.S. citizen or U.S. national?"
 * AND consumer did not attest to U.S. citizenship or nationality
 * AND attested to eligible immigration status
 * AND consumer was born after 8/22/1996
 * OR selected "No" for "Has x lived in the U.S. since 1996?"
 * AND meets one of the following conditions
 *  - Applicant is age 17 or older
 *  - Applicant is married
 *  - Applicant is not married and over age 14
 *  - Applicant is a tax dependent and has  relationship of child to their tax filer
 */
export const getPotentialVeteranMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(
    members,
    (member: EdeMemberType) =>
      isMemberRequestingCoverage(member) &&
      getLawfulPresenceStatusIndicator(member) &&
      (isMemberBornAfter1996(member) || !getLivedInUsSince1996Indicator(member)) &&
      doesApplicantMeetVeteranCriteria(member),
  );

export const getPossiblyPregnantMembers = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  // from EDE UI Guide "consumer is female AND consumer is 9-66 years old"
  members.filter((m) => {
    const sex = getMemberSex(m);
    const age = getMemberAge(m);
    return sex === 'FEMALE' && age > 8 && age < 67;
  });

// eslint-disable-next-line consistent-return
export const getNaturalizationDocument = (member: EdeMemberType) => {
  const documents = getLawfulPresenceDocumentAttestations(member);
  if (documents) {
    const documentType = NATURALIZATION_DOCUMENTS.find((docType: string) => documents[docType]);
    if (documentType) {
      return {
        documentType,
        document: documents[documentType],
      };
    }
  }
};

// If the DE partner does not want to build in these complex rules,
// instead the application could
// ask all 18-20 year old applicants and non-applicants,
// and all 21-22 year old applicants,
// if they are full-time students.
export const getPossiblyFullTimeStudents = (members: Array<EdeMemberType>): Array<EdeMemberType> =>
  _.filter(members, (member: EdeMemberType) => {
    const age = getMemberAge(member);

    if (age >= 18 && age <= 20) {
      return true;
    } else if (isMemberRequestingCoverage(member) && age >= 21 && age <= 22) {
      return true;
    }

    return false;
  });

// // UI Item #144: in addition we need to check for:
// // - Whenever there is an 18 year old applicant or non-applicant that has a parent caretaker (a. parent that lives with the child, b. claiming tax filer that lives with the child, or c. attested parent caretaker of the child)
// // - Applicant 18-22 in a state that has the student residency option (optionStudentResidency = Y)
// // - Applicant or non-applicant age is 19 or 20 AND the coverage state's Under21AndFTSOption = Y (Under21AndFTSOption is included in the reference data API)
// export const getPossiblyFullTimeStudents = (edeApp: EdeApplicationPayloadType, under21AndFTSOption: boolean, optionStudentResidency: boolean): Array<EdeMemberType> => {
//   const members = _.get(edeApp, 'edeMembers', []);
//   const familyRelationships = _.get(edeApp, 'household.attestations.familyRelationships', []);
//   return _.filter(members, (member: EdeMemberType) => {
//     const age = getMemberAge(member);
//     //todo: evaluate if we need to go off of computed caretakerList instead
//     if(age === 18 && !!_.find(familyRelationships, rel =>
//       rel.subMemberIdentifier === member.memberIdentifier &&
//       _.get(rel, 'familyRelationshipIndicator.caretakerRelativeIndicator')
//     )) {
//       return true;
//     } else if (optionStudentResidency && isMemberRequestingCoverage(member) && age >= 18 && age <= 22) {
//       return true;
//     } else if (under21AndFTSOption && age >= 19 && age <= 20) {
//       return true;
//     }
//     return false;
//   });
// };

export const getSSN = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic.ssn`);

export const getSSNAlternateName = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic.ssnAlternateName`);

export const getMemberDemographicAttestations = (member: EdeMemberType) =>
  _.get(member, `${MEMBER_ATTESTATION_PATH}.demographic`);

export const isPendingChipEligibility = (applicant: EdeMemberType) => {
  const chipStatusReason = _.get(applicant, `${MEMBER_COMPUTED_PATH}.chipStatusReason`);
  return _.includes(MEDICAID_AND_CHIP_PENDING_STATUS_REASONS, chipStatusReason);
};

export const isPendingMedicaidEligibility = (applicant: EdeMemberType) => {
  const medicaidStatusReason = _.get(applicant, `${MEMBER_COMPUTED_PATH}.medicaidStatusReason`);

  return _.includes(MEDICAID_AND_CHIP_PENDING_STATUS_REASONS, medicaidStatusReason);
};

export const getEligibleApplicants = (
  applicants: Array<EdeMemberType>,
  insuranceProgram: StateProgramsEnumType,
): Array<EdeMemberType> =>
  _.reject(
    applicants,
    insuranceProgram === 'chip' ? isPendingChipEligibility : isPendingMedicaidEligibility,
  );

export const getPendingApplicants = (
  applicants: Array<EdeMemberType>,
  insuranceProgram: StateProgramsEnumType,
): Array<EdeMemberType> =>
  _.filter(
    applicants,
    insuranceProgram === 'chip' ? isPendingChipEligibility : isPendingMedicaidEligibility,
  );

export const getHRAOfferMembers = (
  results: Object,
  applicants: Array<Object>,
  forEligibilityResultsPage: boolean,
) => {
  const ICHRA_INDICATOR = 'HRAEligibility.ICHRAIndicator';
  const ICHRA_AFFORDABILITY_INDICATOR = 'HRAEligibility.ICHRAAffordabilityIndicator';
  const APTC_AMOUNT_INDICATOR = 'ProgramEligibilitySnapshot.APTCEligibility.MonthlyAPTCAmount';
  const APPLICANT_ID_INDICATOR =
    'ExchangeAssignedInsuranceApplicantIdentification.IdentificationID';

  const originalIndicators = _.get(
    results,
    'eligibility.ApplicantEligibilityResponse.InsuranceApplication.InsuranceApplicant',
    [],
  );
  // Make sure indicators is an array. It is returned by CMS as just an object if only 1 person is on the application
  const indicators = Array.isArray(originalIndicators) ? originalIndicators : [originalIndicators];
  console.log('indicators: ', indicators);

  // Filter out indicators that do not have ichra offers
  const ichraOfferIndicators = _.filter(
    indicators,
    indicator => _.get(indicator, ICHRA_INDICATOR) === 'true',
  );

  // Criteria for which indicators to keep depending on what page is requesting, aptcAmount and affordability
  const criteria = (indicator) => {
    const aptcAmount = parseInt(_.get(indicator, APTC_AMOUNT_INDICATOR), 10);
    const isICHRAAffordable =
      _.has(indicator, ICHRA_AFFORDABILITY_INDICATOR) &&
      _.get(indicator, ICHRA_AFFORDABILITY_INDICATOR) === 'true';

    return (
      (!forEligibilityResultsPage &&
        (isICHRAAffordable || (!isICHRAAffordable && aptcAmount === 0))) ||
      (forEligibilityResultsPage && !isICHRAAffordable && aptcAmount > 0)
    );
  };

  const ichraOffers = _.filter(ichraOfferIndicators, criteria);

  console.log('ichraOffers: ', ichraOffers);

  // Return applicants that match ichra offers
  return _.map(ichraOffers, (offer) => {
    const id = _.get(offer, APPLICANT_ID_INDICATOR);
    return _.find(applicants, { id });
  });
};

export const getQsehraMembers = (
  results: Object,
  applicants: Array<Object>,
  forEligibilityResultsPage: boolean,
) => {
  const originalIndicators = _.get(
    results,
    'eligibility.ApplicantEligibilityResponse.InsuranceApplication.InsuranceApplicant',
    [],
  );

  // Make sure indicators is an array, CMS returns an object when there is only one applicant
  const indicators = Array.isArray(originalIndicators) ? originalIndicators : [originalIndicators];

  const criteria = forEligibilityResultsPage
    ? result =>
      _.get(result, 'HRAEligibility.QSEHRAIndicator') === 'true' &&
        parseInt(
          _.get(result, 'ProgramEligibilitySnapshot.APTCEligibility.MonthlyAPTCAmount'),
          10,
        ) > 0
    : result =>
      _.get(result, 'HRAEligibility.QSEHRAIndicator') === 'true' &&
        (parseInt(
          _.get(result, 'ProgramEligibilitySnapshot.APTCEligibility.MonthlyAPTCAmount'),
          10,
        ) >= 0 ||
          _.get(result, 'ProgramEligibilitySnapshot.APTCEligibility.EligibilityIndicator') ===
            'false');

  const qsehraOffers = _.filter(indicators, criteria);

  return _.map(qsehraOffers, (result) => {
    const id = _.get(result, 'ExchangeAssignedInsuranceApplicantIdentification.IdentificationID');
    return _.find(applicants, { id });
  });
};

export const getMemberNamesString = (members: Array<EdeMemberType>) =>
  _.map(members, (m) => {
    const name = _.get(m, 'attestations.demographic.name');
    return `${name.firstName} ${name.lastName}`;
  }).join(', ');

export const getConcatenatedMemberNames = (members: Array<EdeMemberType>) => {
  const names = _.map(members, (m) => {
    const name = _.get(m, 'attestations.demographic.name');
    return `${name.firstName} ${name.lastName}`;
  });

  if (names.length > 1) {
    return `${names.slice(0, -1).join(', ')} & ${names.slice(-1)[0]}`;
  }

  return names[0] || '';
};

export const getMemberNamesStringFromEnrollment = (members: Array<Object> = []) =>
  _.map(members, (m) => {
    const name = _.get(m, 'memberInformation');
    return `${name.firstName} ${name.lastName}`;
  }).join(', ');

// TODO: Revisit this selector later - is_exchange_eligible is derived from eligibility results
export const getExchangeEligibleApplicants = (
  applicants: Array<EdeMemberType>,
): Array<EdeMemberType> => _.filter(applicants, a => a.ede_member.is_exchange_eligible);

export const getPrimaryMember = (edeApplication: EdeApplicationPayloadType) => {
  const primaryId = _.get(edeApplication, 'attestations.application.contactMemberIdentifier');
  return (
    _.find(edeApplication.edeMembers, {
      memberIdentifier: primaryId,
    }) || {}
  );
};

export const getTaxHouseholdMemberIdentifiers = (
  edeApplication: EdeApplicationPayloadType,
  id: string,
): Array<string> => {
  const primaryMember = id
    ? _.find(_.get(edeApplication, 'edeMembers', []), { memberIdentifier: id })
    : getPrimaryMember(edeApplication);
  const memberIdentifier = _.get(primaryMember, 'memberIdentifier');
  const taxHouseholds = _.get(edeApplication, 'computed.taxHouseholds', []);
  const primaryTaxHousehold = _.find(taxHouseholds, (household) => {
    const taxHouseholdMemberIdentifiers = _.get(
      household,
      'taxHouseHoldComposition.taxHouseholdMemberIdentifiers',
      [],
    );
    return _.includes(taxHouseholdMemberIdentifiers, memberIdentifier);
  });
  return _.get(primaryTaxHousehold, 'taxHouseHoldComposition.taxHouseholdMemberIdentifiers');
};

export const isMemberAmericanIndianAlaskanNative = (member: EdeMemberType): boolean => {
  const americanIndianAlaskanNativeType = _.get(
    member,
    `${MEMBER_ATTESTATION_PATH}.other.americanIndianAlaskanNativeType`,
  );

  if (
    americanIndianAlaskanNativeType ===
      AMERICAN_INDIAN_ALASKAN_NATIVE_TYPES.AMERICAN_INDIAN_ALASKAN_NATIVE ||
    americanIndianAlaskanNativeType ===
      AMERICAN_INDIAN_ALASKAN_NATIVE_TYPES.NOT_AMERICAN_INDIAN_ALASKAN_NATIVE
  ) {
    return true;
  }
  return false;
};

export const isAIAN = (member: EdeMemberType): boolean => {
  const { americanIndianAlaskanNativeIndicator } = getEdeMemberDemographic(member);
  return americanIndianAlaskanNativeIndicator;
};

export const getInsuranceMarketType = (edeMember: EdeMemberType) => {
  const enrolledCoverages = _.get(
    edeMember,
    `${MEMBER_ATTESTATION_PATH}.insuranceCoverage.enrolledCoverages`,
    [],
  );
  const insuranceMarketType = _.map(
    enrolledCoverages,
    coverage => coverage.insuranceMarketType,
  ).filter(type => type !== 'NONE');
  return insuranceMarketType;
};

export const hasMemberDisqualifyingCoverage = (edeMember: EdeMemberType) => {
  const disqualifyingCoverages = [
    'MEDICARE',
    'TRICARE',
    'VETERAN_HEALTH_PROGRAM',
    'PEACE_CORPS',
    'EMPLOYER_SPONSORED',
  ];
  const coverage = getInsuranceMarketType(edeMember);
  if (!_.isEmpty(_.intersection(disqualifyingCoverages, coverage))) return true;

  const escOffers = _.get(
    edeMember,
    'attestations.insuranceCoverage.employerSponsoredCoverageOffers',
    [],
  );
  return _.some(escOffers, offer => offer.escEnrolledIndicator);
};

export const isMemberEnrolledInIchra = (edeMember: EdeMemberType) =>
  _.get(edeMember, `${MEMBER_ATTESTATION_PATH}.insuranceCoverage.enrolledInIchraIndicator`, false);

export const isUnknownMarital = (member: EdeMemberType) =>
  !_.isString(_.get(member, 'attestations.demographic.maritalStatus')) && getMemberAge(member) > 14;

export const isUnknownTax = (member: EdeMemberType) =>
  !_.isBoolean(_.get(member, 'attestations.family.taxFilerIndicator')) &&
  !_.isBoolean(_.get(member, 'attestations.family.taxDependentIndicator')) &&
  getMemberAge(member) >= 14;

export const isClaimableDependent = (member: EdeMemberType) => {
  const family = _.get(member, 'attestations.family', {});
  if (!family) return true;
  const { taxReturnFilingStatusType, claimsDependentIndicator } = family;
  return (
    // !taxDependentIndicator &&
    taxReturnFilingStatusType !== 'MARRIED_FILING_JOINTLY' && !claimsDependentIndicator
  );
};

export const isJointFilter = (member: EdeMemberType): boolean =>
  _.get(member, 'attestations.family.taxFilerIndicator') &&
  _.get(member, 'attestations.family.taxReturnFilingStatusType') === 'MARRIED_FILING_JOINTLY';

export const getSpouseOptions = (
  edeApp: EdeApplicationPayloadType,
  member: EdeMemberType,
  spouse: EdeMemberType,
) => {
  // If the member is already married we should not show any spouse options. Users can only attest to one spouse.
  if (spouse) return [];

  const members = _.get(edeApp, 'edeMembers', []);
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  const options = _.filter(
    members,
    m =>
      getMemberAge(m) > 14 &&
      !isApplicantMarried(m) &&
      !_.includes(
        INVALID_SPOUSE,
        _.get(
          getMembersRelationship(member.memberIdentifier, m.memberIdentifier, familyRelationships),
          'relationship',
        ),
      ),
  );
  return options;
};

export const getNonFilers = (members: Array<EdeMemberType>) =>
  _.reject(
    members,
    m => isMemberDependent(m) || _.get(m, 'attestations.family.taxFilerIndicator'),
  );

export const getTaxFilingType = (member: EdeMemberType) => {
  if (!member) return null;
  return _.get(member, 'attestations.family.taxReturnFilingStatusType');
};

export const removeDependent = (taxRelationships: Array<Object>, id: string) =>
  _.remove(taxRelationships, rel => rel.subMemberIdentifier === id);

export const claimedByNonParent = (edeApp: EdeApplicationPayloadType, m: EdeMemberType) => {
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  // get tax claimers
  const taxClaimers = _.map(getTaxClaimer(edeApp, m), (claimer) => {
    const relation = _.find(familyRelationships, {
      memberIdentifier: claimer.memberIdentifier,
      subMemberIdentifier: m.memberIdentifier,
    });
    return relation && relation.relationship;
  });
  return _.every(taxClaimers, claimer => claimer !== 'PARENT' && claimer !== 'STEP_PARENT');
};

export const claimedByNonCustodialParent = (
  edeApp: EdeApplicationPayloadType,
  member: EdeMemberType,
) => {
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  const [taxClaimer] = getTaxClaimer(edeApp, member);
  if (!taxClaimer) return false;

  const relation = _.find(
    familyRelationships,
    rel =>
      rel.subMemberIdentifier === member.memberIdentifier &&
      rel.memberIdentifier === taxClaimer.memberIdentifier &&
      (rel.relationship === 'PARENT' || rel.relationship === 'STEP_PARENT'),
  );
  return (
    relation && _.get(relation, 'familyRelationshipIndicator.resideTogetherIndicator') === false
  );
};

export const possibleNonCustodial = (edeApp: EdeApplicationPayloadType, member: EdeMemberType) =>
  claimedByNonCustodialParent(edeApp, member) && _.isEmpty(getResidentParents(edeApp, member));

// Applicants who meet a tax dependent exception and therefore follow the non-filer rules are:
//  1. Individuals who are a tax dependent of someone other than a parent/parent'sspouse (biological, step, adopted)
//  2. Individuals who are a child under 19 living with both parents but his/her parents don't file jointly
//  3. Individuals who are a child under 19 who expect to be claimed as a tax dependent by a non-custodial parent
export const getNonFilerExceptions = (edeApp: EdeApplicationPayloadType, applicants) =>
  _.filter(applicants, (m) => {
    if (isMemberDependent(m)) {
      if (claimedByNonParent(edeApp, m)) return true;
      if (getMemberAge(m) < 19) {
        const residentParents = getResidentParents(edeApp, m);
        if (residentParents.length === 2) {
          return getTaxFilingType(_.head(residentParents)) !== 'MARRIED_FILING_JOINTLY';
        }
        if (residentParents.length === 1) {
          return claimedByNonCustodialParent(edeApp, m);
        }
      }
    }
    return false;
  });

/** @returns if the dependent is claimed by a parent or stepparent who lives with them */
export const isDependentClaimedByResidentialParent = (
  edeApp: EdeApplicationPayloadType,
  currMember: EdeMemberType,
) => {
  if (!currMember) return false;
  const id = currMember.memberIdentifier;
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  const isDependent = isMemberDependent(currMember);
  const taxClaimers = getTaxClaimer(edeApp, currMember);
  let taxClaimer = taxClaimers[0];

  let relation =
    taxClaimer && getMembersRelationship(taxClaimer.memberIdentifier, id, familyRelationships);
  if (!relation && taxClaimers.length > 1) {
    taxClaimer = taxClaimers[1]; // eslint-disable-line
    relation =
      taxClaimer && getMembersRelationship(taxClaimer.memberIdentifier, id, familyRelationships);
  }
  const isLivingWithParent =
    relation &&
    (_.get(relation, 'relationship') === 'PARENT' ||
      _.get(relation, 'relationship') === 'STEP_PARENT') &&
    _.get(relation, 'familyRelationshipIndicator.resideTogetherIndicator');
  // todo: change to state medicaid age
  return getMemberAge(currMember) < 21 && isDependent && taxClaimer && isLivingWithParent;
};
// if the dependent is claimed by a parent or stepparent who lives with them and it is unknown whether
// they live with another parent or stepparent, we need to ask
export const livingSituationNeeded = (
  edeApp: EdeApplicationPayloadType,
  currMember: EdeMemberType,
) => {
  if (!currMember) return false;
  const id = currMember.memberIdentifier;
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  const isDependent = isMemberDependent(currMember);
  const taxClaimers = getTaxClaimer(edeApp, currMember);
  let taxClaimer = taxClaimers[0];

  let relation =
    taxClaimer && getMembersRelationship(taxClaimer.memberIdentifier, id, familyRelationships);
  if (!relation && taxClaimers.length > 1) {
    taxClaimer = taxClaimers[1]; // eslint-disable-line
    relation =
      taxClaimer && getMembersRelationship(taxClaimer.memberIdentifier, id, familyRelationships);
  }
  const isParent =
    relation &&
    (_.get(relation, 'relationship') === 'PARENT' ||
      _.get(relation, 'relationship') === 'STEP_PARENT') &&
    _.get(relation, 'familyRelationshipIndicator.resideTogetherIndicator');

  // living with any other parents or step-parents?
  const residentialParents = _.filter(
    familyRelationships,
    rel =>
      rel.subMemberIdentifier === id &&
      (rel.relationship === 'PARENT' || rel.relationship === 'STEP_PARENT') &&
      _.get(rel, 'familyRelationshipIndicator.resideTogetherIndicator'),
  );
  // todo: change to state medicaid age
  const eligibleSituation =
    getMemberAge(currMember) < 21 &&
    isDependent &&
    taxClaimer &&
    // getTaxFilingType(taxClaimer) !== 'MARRIED_FILING_JOINTLY' &&
    isParent &&
    residentialParents.length === 1;
  return eligibleSituation;
};

export const getNonApplicantDependents = (edeApp: EdeApplicationPayloadType) => {
  const taxRelationships = _.get(edeApp, 'attestations.household.taxRelationships', []);
  const members = _.get(edeApp, 'edeMembers', []);

  return taxRelationships.reduce((dep, relationship) => {
    if (relationship.memberIdentifier === relationship.subMemberIdentifier) return dep;
    const member = _.find(members, { memberIdentifier: relationship.subMemberIdentifier });
    if (
      !_.find(dep, { memberIdentifier: member.memberIdentifier }) &&
      !isMemberRequestingCoverage(member)
    ) {
      return _.concat(dep, member);
    }
    return dep;
  }, []);
};

export const getTaxDependents = (id: string, edeApp: EdeApplicationPayloadType) => {
  const taxRelationships = _.get(edeApp, 'attestations.household.taxRelationships', []);
  const dependentRelationships = _.filter(
    taxRelationships,
    rel => rel.memberIdentifier === id && rel.subMemberIdentifier !== id,
  );
  return _.map(dependentRelationships, ({ subMemberIdentifier }) => subMemberIdentifier);
};

const getApplicantsIds = (edeApp: EdeApplicationPayloadType) => {
  const members = _.get(edeApp, 'edeMembers', []);
  const applicants = filterMembersRequestingCoverage(members);
  return _.map(applicants, m => m.memberIdentifier);
};

const memberHasTaxRelationshipWithApplicant = (
  memberId: string,
  edeApp: EdeApplicationPayloadType,
) => {
  const applicantIds = getApplicantsIds(edeApp);
  const taxHouseholdMemberIdentifiers = getTaxHouseholdMemberIdentifiers(edeApp, memberId);

  return applicantIds.some(applicantId => _.includes(taxHouseholdMemberIdentifiers, applicantId));
};

const memberLivesWithApplicant = (
  memberId: string,
  edeApp: EdeApplicationPayloadType,
) => {
  const applicantIds = getApplicantsIds(edeApp);
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);

  return familyRelationships.some((familyRelationship) => {
    const membersInRelationship = [
      familyRelationship.memberIdentifier,
      familyRelationship.subMemberIdentifier,
    ];
    const relationshipIncludesMember = _.includes(membersInRelationship, memberId);
    const relationshipIncludesApplicant = applicantIds.some(applicantId =>
      _.includes(membersInRelationship, applicantId));

    return (
      _.get(familyRelationship, 'familyRelationshipIndicator.resideTogetherIndicator') &&
      relationshipIncludesMember &&
      relationshipIncludesApplicant
    );
  });
};

const memberIsMarriedToApplicant = (
  memberId: string,
  edeApp: EdeApplicationPayloadType,
) => {
  const applicantIds = getApplicantsIds(edeApp);
  const memberSpouse = getMemberSpouse(edeApp, memberId);
  return _.includes(applicantIds, _.get(memberSpouse, 'memberIdentifier'));
};

// Definition of non relevant non applicant:
// Not seeking coverage AND
// Does not have a taxRelationship to any other applicant AND
// Does not live with any other applicant AND
// Is not married to any other applicant AND
// Is not the application filer
export const isNonRelevantNonApplicant = (
  member: EdeMemberType,
  edeApp: EdeApplicationPayloadType,
) => {
  const memberId = member.memberIdentifier;
  if (
    isMemberRequestingCoverage(member) ||
    _.isEqual(member, getPrimaryMember(edeApp)) ||
    memberHasTaxRelationshipWithApplicant(memberId, edeApp) ||
    memberLivesWithApplicant(memberId, edeApp) ||
    memberIsMarriedToApplicant(memberId, edeApp)
  ) {
    return false;
  }

  let nonrelevant = true;
  const members = _.get(edeApp, 'edeMembers', []);
  const applicants = filterMembersRequestingCoverage(members);
  _.forEach(applicants, (m) => {
    const medicaidHouseholdMembers = _.get(
      m,
      'computed.medicaidHouseholdComposition.medicaidHouseholdMemberIdentifiers',
      [],
    );
    if (_.includes(medicaidHouseholdMembers, memberId)) nonrelevant = false;
  });

  return nonrelevant;
};

export const getTaxMemberIds = (edeApp: EdeApplicationPayloadType) => {
  const primary = getPrimaryMember(edeApp);
  const members = _.filter(
    _.get(edeApp, 'edeMembers', []),
    m =>
      isMemberRequestingCoverage(m) ||
      _.isEqual(m, primary) ||
      _.get(m, 'attestations.family.taxFilerIndicator'),
    // ((isMemberRequestingCoverage(m) || _.isEqual(m, primary)) &&
    // (livingSituationNeeded(edeApp, m) ||
    // (possibleNonCustodial(edeApp, m) && getMemberAge(m) < 21))) ||
    // _.get(m, 'attestations.family.taxFilerIndicator')
  );
  const eligibleMembers = _.reject(
    members,
    m =>
      isMemberDependent(m) &&
      getMemberAge(m) < 14 &&
      !livingSituationNeeded(edeApp, m) &&
      !possibleNonCustodial(edeApp, m),
  );
  return _.map(eligibleMembers, m => m.memberIdentifier);
};

export const claimedByNonApplicant = (member: EdeMemberType, edeApp: EdeApplicationPayloadType) => {
  const taxClaimers = getTaxClaimer(edeApp, member);
  if (_.isEmpty(taxClaimers)) return false;

  const applicants = filterMembersRequestingCoverage(_.get(edeApp, 'edeMembers', []));
  return !_.find(applicants, { memberIdentifier: _.get(taxClaimers[0], 'memberIdentifier') });
};

export const getMemberAddresses = (
  members: Array<EdeMemberType>,
  primary: EdeMemberType,
): Array<Object> => {
  if (!members) return [];
  const primaryAddress = getEdeMemberAddress(primary, 'homeAddress');
  return _.reduce(
    members,
    (mappedInfo, m) => {
      const newAddress = _.isEmpty(getEdeMemberAddress(m, 'homeAddress'))
        ? getEdeMemberAddress(m, 'mailingAddress')
        : getEdeMemberAddress(m, 'homeAddress');
      if (!newAddress || _.isEqual(newAddress, primaryAddress)) return mappedInfo;
      const entry =
        !_.isEmpty(mappedInfo) && _.find(mappedInfo, info => _.isEqual(newAddress, info.address));
      if (entry) {
        entry.members = _.concat(entry.members, getEdeMemberFullName(m));
        return mappedInfo;
      }
      return _.concat(mappedInfo, [
        {
          address: newAddress,
          members: [getEdeMemberFullName(m)],
        },
      ]);
    },
    [],
  );
};

export const isOutOfState = (member: EdeMemberType) =>
  _.get(member, 'attestations.demographic.liveOutsideStateTemporarilyIndicator', false);

export const hasAsyncTaxHousehold = (edeApp: EdeApplicationPayloadType) => {
  const taxHouseholds = _.get(edeApp, 'computed.taxHouseholds', []);
  return taxHouseholds.some(
    household =>
      _.get(household, 'annualIncome.annualIncomeStatusReason') === ASYNC_INCOME_STATUS_REASON,
  );
};

export const alreadyClaimed = (
  dependents: Array<string>,
  edeApp: EdeApplicationPayloadType,
  id: string,
) => {
  const taxRelationships = _.get(edeApp, 'attestations.household.taxRelationships', []);
  const members = _.get(edeApp, 'edeMembers', []);
  const member = _.find(members, { memberIdentifier: id });
  const spouseId =
    getTaxFilingType(member) === 'MARRIED_FILING_JOINTLY'
      ? _.get(getMemberSpouse(edeApp, id), 'memberIdentifier')
      : null;

  const dependentIds = _.map(dependents, ({ memberIdentifier }) => memberIdentifier);
  const claimedDependents = _.reduce(
    taxRelationships,
    (dependentList, relationship) =>
      (relationship.memberIdentifier !== id && relationship.memberIdentifier !== spouseId
        ? _.concat(dependentList, [relationship.subMemberIdentifier])
        : dependentList),
    [],
  );
  return !!_.intersection(dependentIds, claimedDependents).length;
};

const meetsMedicaidConditions = (member: EdeMemberType): boolean => {
  const medicaidStatusReason = _.get(
    member,
    `${MEMBER_COMPUTED_PATH}.preliminaryMedicaidStatusReason`,
    '',
  );
  return isPreliminarilyMedicaid(member) && !medicaidStatusReason?.includes('670');
};

const meetsPcrRelationshipCount = (list: Array<string>, relationships: Array<Object>): boolean =>
  !!_.find(list, (childId) => {
    const familyRelationships = _.filter(
      relationships,
      ({ subMemberIdentifier, familyRelationshipIndicator }) =>
        subMemberIdentifier === childId &&
        _.get(familyRelationshipIndicator, 'resideTogetherIndicator'),
    );
    return familyRelationships.length < 2;
  });

const meetsAbsentParentCase = (members, member, relationships) => {
  const { memberIdentifier: id } = member;
  const children = _.filter(
    relationships,
    ({ memberIdentifier, relationship }) =>
      memberIdentifier === id && (relationship === 'PARENT' || relationship === 'STEP_PARENT'),
  ).map(({ subMemberIdentifier }) => _.find(members, { memberIdentifier: subMemberIdentifier }));
  const childHasAbsentParent = !!_.find(children, child => hasAbsentParent(child));

  return childHasAbsentParent;
};

// UIG #248
// absentparentagreementIndicator logic from the App Services Companion Guide:
// 1. preliminaryMedicaidStatus = YES and preliminaryMedicaidStatusReasonCode <> 670) or preliminaryEmergencyMedicaidStatus = YES
// 2. There exists an applicant child, under 18, who:(preliminaryMedicaidStatus = YES and preliminaryMedicaidStatusReasonCode <> 670) or preliminaryEmergencyMedicaidStatus = YES AND
// 3. lives with 1 or no parents AND absentParentIndicator = true

const meetsMedicaidCase = (
  members: Array<EdeMemberType>,
  member: EdeMemberType,
  relationships: Array<Object>,
  list: Array<string>,
) => {
  const meetsPreliminaryCondition =
    meetsMedicaidConditions(member) || isMemberPreliminaryEmergencyMedicaidStatus(member);
  const childMeetsPreliminaryCondition =
    !_.isEmpty(list) &&
    _.some(list, (childId) => {
      const child = _.find(members, { memberIdentifier: childId });
      return (
        (child && meetsMedicaidConditions(child)) ||
        isMemberPreliminaryEmergencyMedicaidStatus(child)
      );
    });
  const childMeetsParentConditions =
    meetsPcrRelationshipCount(list, relationships) &&
    meetsAbsentParentCase(members, member, relationships);

  return meetsPreliminaryCondition && childMeetsPreliminaryCondition && childMeetsParentConditions;
};

export const needsAbsentParentIndicator = (edeApp: EdeApplicationPayloadType): boolean => {
  const members = _.get(edeApp, 'edeMembers', []);
  const familyRelationships = _.get(edeApp, 'attestations.household.familyRelationships', []);
  const primaryMember = getPrimaryMember(edeApp);

  return (
    !getMemberPregnancyIndicator(primaryMember) &&
    !!_.find(members, (member) => {
      const pcrList = _.get(member, `${MEDICAID_CHIP_STANDARD_PATH}.parentCaretakerChildList`, []);
      return meetsMedicaidCase(members, member, familyRelationships, pcrList);
    })
  );
};

export const sanitizeIncomeEntries = (edeApp: EdeApplicationPayloadType) => {
  const copy = _.cloneDeep(edeApp);
  const members = _.get(copy, 'edeMembers', []);
  _.forEach(members, (member) => {
    const currentIncome = _.get(member, 'attestations.income.currentIncome', {});
    _.forEach(currentIncome, (entry, index) => {
      if (!entry) delete currentIncome[index];
    });
  });
  return copy;
};

// returns the lowest common deminator of plan metal types allowed by CMS for the enrollment group's members
// returns all metal types if no restrictions are present (i.e.  'allowedPlanMetalLevelType: null')
export const getAllowedPlanMetalLevelType = (
  edeApp: EdeApplicationPayloadType,
  memberIds: Array<string>,
): Array<string> => {
  const memberDeterminations = _.get(
    edeApp,
    'computed.variableDeterminations[0].memberVariableDeterminations',
    [],
  );

  const levels: Array<string> = memberIds.reduce(
    (allowedLevels, id) => {
      const memberDetermination = _.find(memberDeterminations, { memberIdentifier: id });
      const memberAllowedLevels = _.get(memberDetermination, 'allowedPlanMetalLevelType');
      const memberLevels = _.isArray(memberAllowedLevels) ? memberAllowedLevels : [];

      if (!memberLevels.length) return allowedLevels;

      return _.intersection(allowedLevels, memberLevels);
    },
    METAL_TYPE_ORDERS.map(m => m.toUpperCase()),
  );

  return levels.map(level => level.toLowerCase());
};

export const getEdeApplicationComputedAnnualIncome = (edeApp: EdeApplicationPayloadType) => {
  const taxHouseholds = _.get(edeApp, 'computed.taxHouseholds', []);
  return taxHouseholds.reduce((totalIncome, taxHousehold) =>
    totalIncome + _.get(taxHousehold, 'annualIncome.attestedAnnualTaxHouseholdIncomeAmount', 0),
  0);
};

export const getMedicaidProgramNameFromState = (state) => {
  const medicaidProgramName = _.get(state, 'ses.stateReference.medicaidProgramName', 'Medicaid');
  if (medicaidProgramName.includes('Medicaid')) {
    return medicaidProgramName;
  }

  return `${medicaidProgramName} (Medicaid)`;
};

export const getMedicaidAgencyNameFromState = state => _.get(state, 'ses.stateReference.medicaidAgencyName', 'Medicaid Agency');

export const getChipProgramNameFromState = (state) => {
  const chipProgramName = _.get(state, 'ses.stateReference.chipProgramName', "Children's Health Insurance Plan (CHIP)");
  if (chipProgramName.includes('CHIP')) {
    return chipProgramName;
  }

  if (chipProgramName === 'PeachCare for Kids') {
    return `${chipProgramName}® (CHIP)`;
  }

  return `${chipProgramName} (CHIP)`;
};

export const getChipAgencyNameFromState = state => _.get(state, 'ses.stateReference.chipAgencyName', 'CHIP Agency');
