// @flow
import _ from 'lodash';
import {
  FILTER_CATEGORY_TYPES,
  SUBSIDY_TYPES,
  type ConditionsType,
  type FilterCategoryType,
  type UsageLevelType,
} from 'client/constants/health';
import { HIOS_ID, STRIDE_PLAN_ID, SUBSIDY_AMOUNT } from 'client/constants/health/tracking';
import { RECOMMENDATION_ROUTE, VIEW_ALL_PLANS_ROUTE } from 'routes/health/constants/routes';
import {
  ACTIVE,
  CARRIER_AND_PLAN_NAMES,
  CARRIER_NAME,
  CATEGORY,
  CELEBRATION_ALERT_TYPES,
  CELEBRATION_ALERT_VIEWED,
  CHECKED,
  ENROLL_CLICK_PATH,
  HEALTH_ENROLL_CLICK,
  HEALTH_ONBOARDING_CONDITION_ADDED,
  HEALTH_ONBOARDING_ESTIMATED_INCOME,
  HEALTH_ONBOARDING_ESTIMATED_SUBSIDY,
  HEALTH_ONBOARDING_ESTIMATED_USAGE_LEVEL,
  HEALTH_PREMIUM,
  HOUSEHOLD_DEMOGRAPHICS,
  IS_ACTIVE,
  METAL_TIER,
  OUT_OF_POCKET,
  PANEL_NAME,
  PLAN_COMPARE_CANCEL,
  PLAN_COMPARE_DETAIL_CLICK,
  PLAN_COMPARE_FILTER,
  PLAN_COMPARE_HEAD_TO_HEAD,
  PLAN_COMPARE_RECOMMENDATION,
  PLAN_COMPARE_SELECT_PLAN,
  PLAN_COMPARE_SORT_BY,
  PLAN_COMPARE_VIEW_PLAN,
  PLAN_DETAIL_TOGGLE_INFO,
  PLAN_FLAG,
  PLAN_NAME,
  PLAN_YEAR_VIEWED,
  PLANS_COMPARED,
  PROFILE_EDIT_CLICK,
  SORT_BY,
  STRIDE_RANK,
  SUBSIDY_SAVINGS,
  type SourceTypes,
  VALUE,
  ICHRA_CONSENT_BOX_CHECKED,
} from 'routes/health/constants/tracking';
import { checkApplicants } from 'routes/health/utils/subsidy';
import {
  AMOUNT,
  CALCULATED_YEARLY_ESTIMATE,
  ELIGIBLE_FOR_SUBSIDY,
  FREQUENCY,
  INCOME_ENTERED,
  MEDICAID_ELIGIBLE_HOUSEHOLD,
  MEMBERS_DROPPED_TO_MEDICAID_CHIP,
  SOURCE,
} from 'routes/marketplace/constants/tracking';
import {
  getMemberParams,
  getSingleFlagByPrecedence,
  goodDealFlags,
} from 'client/utils/health/plan';
import type { SessionMemberType } from 'client/models/health/Members';
import type { SortByType } from 'routes/health/utils/planSort';
import type { TransformedRecommendationDataType } from 'types/health/plan.flow';

import GLOBALS, { APPLICATION_MEMBER_TYPES } from 'client/constants/globals';

import { Tracking } from 'web-core/src/tracking';
import type { MemberType } from 'web-core/types/member';

import type { TaxInfoType } from 'types/base/healthApp';

/* eslint-disable no-param-reassign */
export const trackEligibilities = (eligibilities: Array<string>) => {
  if (!_.isArray(eligibilities)) {
    eligibilities = [];
  }

  let allMedicaidEligible;
  let someMedicaidEligible;
  const unique = _.uniq(eligibilities);
  const props = {};

  if (unique.indexOf('medicaid') > -1) {
    // medicaidHousehold and medicaidMember should never both be true
    allMedicaidEligible = unique.length === 1;
    someMedicaidEligible = unique.length > 1;
  } else {
    allMedicaidEligible = false;
    someMedicaidEligible = false;
  }
  props[MEDICAID_ELIGIBLE_HOUSEHOLD] = allMedicaidEligible;
  props[MEMBERS_DROPPED_TO_MEDICAID_CHIP] = someMedicaidEligible;
  props[ELIGIBLE_FOR_SUBSIDY] = unique.indexOf('subsidy') > -1;

  Tracking.setPeopleProps(props);
};
/* eslint-enable no-param-reassign */

type PropsDefaultType = {
  coverageCount: number,
  dependentAge: Array<number | null> | string,
  dependentCount: number,
  dependentGender: Array<string | null> | string,
  dependentTobacco: Array<boolean> | string,
  familySize: number,
  primaryAge: string | number,
  primaryGender: string,
  primaryTobacco: string | boolean,
  spouseAge: string | number,
  spouseGender: string,
  spouseTobacco: string | boolean,
};

export const trackApplicantDemoInfo = (members: Array<SessionMemberType>) => {
  const props: PropsDefaultType = {
    coverageCount: 0,
    dependentAge: 'N/A',
    dependentCount: 0,
    dependentGender: 'N/A',
    dependentTobacco: 'N/A',
    familySize: members.length,
    primaryAge: 'N/A',
    primaryGender: 'N/A',
    primaryTobacco: 'N/A',
    spouseAge: 'N/A',
    spouseGender: 'N/A',
    spouseTobacco: 'N/A',
  };

  _.forEach(members, (member) => {
    const { age, gender, status, tobacco } = member;
    if (status === 'coverage') {
      props.coverageCount++;
    }
    if (member.type === APPLICATION_MEMBER_TYPES.PRIMARY) {
      _.set(props, 'primaryAge', age);
      _.set(props, 'primaryGender', gender);
      _.set(props, 'primaryTobacco', tobacco);
    } else if (member.type === APPLICATION_MEMBER_TYPES.SPOUSE) {
      _.set(props, 'spouseAge', age);
      _.set(props, 'spouseGender', gender);
      _.set(props, 'spouseTobacco', tobacco);
    } else {
      if (props.dependentCount < 1) {
        props.dependentAge = [age];
        props.dependentGender = [gender];
        props.dependentTobacco = [tobacco];
      } else {
        // $FlowFixMe dependentAge is an array here
        props.dependentAge.push(age);
        // $FlowFixMe dependentGender is an array here
        props.dependentGender.push(gender);
        // $FlowFixMe dependentTobacco is an array here
        props.dependentTobacco.push(tobacco);
      }
      props.dependentCount++;
    }
  });

  Tracking.setPeopleProps(props);
};

export const trackSubsidyInPeopleProps = (aptc: number | null | void) => {
  Tracking.setPeopleProps({
    [HEALTH_ONBOARDING_ESTIMATED_SUBSIDY]: aptc || 0,
  });
};

export const trackIncomeInPeopleProps = (income: number | string) => {
  Tracking.setPeopleProps({
    [HEALTH_ONBOARDING_ESTIMATED_INCOME]: income,
  });
};

export const trackIncomeEntered = (taxInfo: TaxInfoType) => {
  Tracking.track(INCOME_ENTERED, {
    [SOURCE]: 'onboarding: simple',
    [FREQUENCY]: taxInfo.frequency,
    [AMOUNT]: taxInfo.amount,
    [CALCULATED_YEARLY_ESTIMATE]: taxInfo.income,
  });
};

export const trackUsageLevelInPeopleProps = (usageLevel: UsageLevelType) => {
  Tracking.setPeopleProps({
    [HEALTH_ONBOARDING_ESTIMATED_USAGE_LEVEL]: usageLevel,
  });
};

export const trackConditionsInPeopleProps = (conditions: Array<ConditionsType>) => {
  Tracking.setPeopleProps({
    [HEALTH_ONBOARDING_CONDITION_ADDED]: conditions,
  });
};

/**
 * Function that maintain amplitude tracking category
 * The hard-coded values aren't reused, so it's fine for now.
 */
export const transformCategory = (category: FilterCategoryType | string) => {
  switch (category) {
    case FILTER_CATEGORY_TYPES.PLAN_TYPE:
      return 'networks';
    case FILTER_CATEGORY_TYPES.METAL_TYPE:
      return 'tiers';
    case FILTER_CATEGORY_TYPES.CARRIER:
      return 'carriers';
    case FILTER_CATEGORY_TYPES.HSA_STATUS:
      return 'hsa';
    default:
      return category;
  }
};

export const trackPlanCompareFilter = (
  category: FilterCategoryType | string,
  categoryValue: string,
  isActive: boolean,
) => {
  Tracking.track(PLAN_COMPARE_FILTER, {
    [CATEGORY]: transformCategory(category),
    [VALUE]: categoryValue,
    [IS_ACTIVE]: isActive,
  });
};

export const trackRecommendationPlanData = (
  displayedPlans: Array<TransformedRecommendationDataType>,
  members: Array<MemberType>,
  shouldShowGoodDeal: boolean,
) => {
  const recommendedPlan: TransformedRecommendationDataType = displayedPlans[0];
  const otherPlans = _.without(displayedPlans, recommendedPlan);

  const trackingInfo = {
    // the constant for household size is household_size, which doesn't match the right value.
    'Household Size': members.length,
    [HOUSEHOLD_DEMOGRAPHICS]: getMemberParams(members),
    [`Recommended ${CARRIER_AND_PLAN_NAMES}`]: `${recommendedPlan.plan.carrier} ${recommendedPlan.plan.planName}`,
    [`Recommended Plan ${STRIDE_PLAN_ID}`]: recommendedPlan.plan.id,
    [`Recommended Plan ${HIOS_ID}`]: recommendedPlan.plan.planId,
    [`Recommended Plan ${HEALTH_PREMIUM}`]: recommendedPlan.mods.premiumMonth,
    [`Recommended Plan ${SUBSIDY_AMOUNT}`]: recommendedPlan.mods.savings.subsidy,
    [`Recommended Plan ${OUT_OF_POCKET}`]: recommendedPlan.mods.oop.totalOop,
  };

  _.filter(otherPlans, plan => plan.plan).forEach((plan, index) => {
    const keyStart = `Secondary Plan ${index + 1} `;
    trackingInfo[`${keyStart}Name`] = `${plan.plan.carrier} ${plan.plan.planName}`;
    trackingInfo[`${keyStart}Flag`] = getSingleFlagByPrecedence(
      plan.plan,
      shouldShowGoodDeal ? [] : goodDealFlags,
    );
    trackingInfo[`${keyStart}${STRIDE_RANK}`] = plan.mods.ranks.strideRank;
    trackingInfo[`${keyStart}${STRIDE_PLAN_ID}`] = plan.plan.id;
    trackingInfo[`${keyStart}${HIOS_ID}`] = plan.plan.planId;
    trackingInfo[`${keyStart}${HEALTH_PREMIUM}`] = plan.mods.premiumMonth;
    trackingInfo[`${keyStart}${SUBSIDY_AMOUNT}`] = plan.mods.savings.subsidy.toFixed(2);
    trackingInfo[`${keyStart}${OUT_OF_POCKET}`] = plan.mods.oop.totalOop;
  });

  Tracking.track(PLAN_COMPARE_RECOMMENDATION, trackingInfo);
  Tracking.setPeopleProps({
    [PLAN_YEAR_VIEWED]: recommendedPlan.plan.planYear,
  });
};

export const incrementPlanViewCount = (value: number) => {
  Tracking.add(PLANS_COMPARED, value);
};

export const trackPlanSortBy = (sortBy: SortByType) => {
  Tracking.track(PLAN_COMPARE_SORT_BY, {
    [SORT_BY]: sortBy,
  });
};

const getPlanDetailTrackingInfo = (planData: TransformedRecommendationDataType) => ({
  // These are existing trackings, so keeping this for backward compatibility
  [CARRIER_AND_PLAN_NAMES]: `${planData.plan.carrier} ${planData.plan.planName}`,
  [OUT_OF_POCKET]: planData.mods.oop.totalOop,
  [PLAN_FLAG]: planData.special,
  [STRIDE_RANK]: planData.mods.ranks.strideRank,
  [SUBSIDY_SAVINGS]: planData.mods.savings.subsidy.toFixed(2) || -1,
  // These are new
  [CARRIER_NAME]: planData.plan.carrier,
  [HIOS_ID]: planData.plan.planId,
  [METAL_TIER]: planData.plan.metalType,
  [PLAN_NAME]: planData.plan.planName,
  [PLAN_YEAR_VIEWED]: planData.plan.planYear,
  [STRIDE_PLAN_ID]: planData.plan.id,
  [HEALTH_PREMIUM]: planData.mods.premiumMonth,
});

export const trackHealthPlanCard = (planData: TransformedRecommendationDataType) => {
  Tracking.track(PLAN_COMPARE_VIEW_PLAN, getPlanDetailTrackingInfo(planData));
};

export const trackPlanDetailPanelState = (panelName: string, isActive: boolean) => {
  Tracking.track(PLAN_DETAIL_TOGGLE_INFO, {
    [ACTIVE]: isActive,
    [PANEL_NAME]: panelName,
  });
};

export const trackPlanDetailOnCompare = (
  planData: TransformedRecommendationDataType | null,
  source: SourceTypes,
  isChecked?: boolean,
) => {
  if (!planData) {
    return null;
  }
  return Tracking.track(PLAN_COMPARE_SELECT_PLAN, {
    ...getPlanDetailTrackingInfo(planData),
    [CHECKED]: isChecked,
    [SOURCE]: source,
  });
};

export const trackPlanCompareCancel = () => {
  Tracking.track(PLAN_COMPARE_CANCEL);
};

export const trackCompareHeadToHead = (planData: Array<TransformedRecommendationDataType>) => {
  planData.forEach(plan =>
    Tracking.track(PLAN_COMPARE_HEAD_TO_HEAD, getPlanDetailTrackingInfo(plan)));
};

export const trackPlanCompareDetail = (planData: TransformedRecommendationDataType) => {
  Tracking.track(PLAN_COMPARE_DETAIL_CLICK, getPlanDetailTrackingInfo(planData));
};

export const trackProfileIconClick = () => {
  Tracking.track(PROFILE_EDIT_CLICK);
};

export const trackIchraConsentBoxChecked = (partnerName: string) => {
  Tracking.track(ICHRA_CONSENT_BOX_CHECKED, { partner_name: partnerName });
};

export const trackCelebratoryAlertViewed = ({
  members,
  subsidyEstimateData,
}: {
  members: Array<MemberType>,
  subsidyEstimateData: Object,
}) => {
  if (subsidyEstimateData) {
    const applicantEligibilities = checkApplicants(subsidyEstimateData.getSubsidyEstimate, members);
    const aptcAmount =
      subsidyEstimateData.getSubsidyEstimate.aptc === -1
        ? 0
        : subsidyEstimateData.getSubsidyEstimate.aptc;
    let type;
    if (applicantEligibilities.subsidyType === SUBSIDY_TYPES.ALL_MEDICAID) {
      type = CELEBRATION_ALERT_TYPES.MEDICAID_QUALIFIED;
    } else if (applicantEligibilities.subsidyType === SUBSIDY_TYPES.ALL_SUBSIDY) {
      type = CELEBRATION_ALERT_TYPES.SUBSIDY_QUALIFIED;
    } else if (applicantEligibilities.subsidyType === SUBSIDY_TYPES.MEDICAID_GAP) {
      type = CELEBRATION_ALERT_TYPES.INCOME_TOO_LOW;
    } else if (applicantEligibilities.subsidyType === SUBSIDY_TYPES.NO_SAVINGS) {
      type = CELEBRATION_ALERT_TYPES.INCOME_TOO_HIGH;
    } else if (aptcAmount > 0) {
      type = CELEBRATION_ALERT_TYPES.SUBSIDY_QUALIFIED;
    } else {
      type = CELEBRATION_ALERT_TYPES.MEDICAID_QUALIFIED;
    }
    Tracking.track(CELEBRATION_ALERT_VIEWED, { type });
  }
};

export const trackIncomeSubsidyEligibilitiesReset = () => {
  trackSubsidyInPeopleProps(0);
  trackIncomeInPeopleProps(GLOBALS.NA);
  trackEligibilities([]);
};

export const trackEnrollClick = (pathName: string) => {
  let pathFromName;
  let isDetailPath;
  let isComparePath;
  let isEnrollmentGroupPath;
  let suffix;

  switch (pathName) {
    case RECOMMENDATION_ROUTE:
      pathFromName = ENROLL_CLICK_PATH.RECOMMENDATION;
      break;
    case VIEW_ALL_PLANS_ROUTE:
      pathFromName = ENROLL_CLICK_PATH.VIEW_ALL;
      break;

    default:
      isEnrollmentGroupPath = _.includes(pathName, '/enrollment-group');
      isDetailPath = _.includes(pathName, ENROLL_CLICK_PATH.DETAIL);
      isComparePath = _.includes(pathName, ENROLL_CLICK_PATH.COMPARE);

      if (isEnrollmentGroupPath) {
        suffix =
          isDetailPath || isComparePath
            ? `: ${isDetailPath ? ENROLL_CLICK_PATH.DETAIL : ENROLL_CLICK_PATH.COMPARE}`
            : '';
        pathFromName = `${ENROLL_CLICK_PATH.ENROLLMENT_GROUP}${suffix}`;
      } else {
        pathFromName = isDetailPath ? ENROLL_CLICK_PATH.DETAIL : ENROLL_CLICK_PATH.COMPARE;
      }
      break;
  }
  Tracking.track(HEALTH_ENROLL_CLICK, { path: pathFromName });
};
