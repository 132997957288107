import { parse } from 'date-fns';

export type PendingMedicaidOrChipStatusType =
  | '150_SAVE_VERIFICATION_PENDING'
  | '191_EDS_NOT_AVAILABLE'
  | '227_NO_EDS_DATA_FOUND'
  | '302_RESIDENCY_INCOME_PENDING'
  | '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
  | '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
  | '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
  | '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
  | '605_HUB_INDICATES_NOT_LPV_OR_QNC'
  | '609_HUB_QNC_INDICATOR_IS_PENDING'
  | '618_VOT_STATUS_REQUIRES_DOCUMENTATION'
  | '619_OLDER_THAN_18_DOCUMENTATION_REQ'
  | '620_GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ'
  | '621_REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ'
  | '622_STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT'
  | '626_ATSTD_VETERAN'
  | '628_NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB'
  | '630_FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE'
  | '645_APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS'
  | '646_PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS';

type MedicaidStatusReason =
  | '106_NOT_ALL_MDCAID_ELGBLT_CRITERIA_MET'
  | '128_DEPENDENT_CHILD_DOESNT_HAVE_MEC'
  | '150_SAVE_VERIFICATION_PENDING'
  | '177_CITIZENSHIP_NOT_VERIFIED'
  | '191_EDS_NOT_AVAILABLE'
  | '227_NO_EDS_DATA_FOUND'
  | '288_SSN_NOT_VERIFIED'
  | '302_RESIDENCY_INCOME_PENDING'
  | '305_CITIZENSHIP_OR_INCOME_STATUS_INCONSISTENT'
  | '341_HUB_DID_NOT_RESPOND'
  | '397_DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION'
  | '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS'
  | '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS'
  | '400_VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN'
  | '449_DETERMINED_INELGBL_BY_STATE'
  | '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF'
  | '464_NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP'
  | '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR'
  | '467_CHANGED_INCOME_ATTESTATION_ABOVE_MDCAID_CHIP_LEVEL'
  | '470_RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE'
  | '473_FIVE_YEAR_BAR_IS_PENDING'
  | '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE'
  | '605_HUB_INDICATES_NOT_LPV_OR_QNC'
  | '609_HUB_QNC_INDICATOR_IS_PENDING'
  | '618_VOT_STATUS_REQUIRES_DOCUMENTATION'
  | '619_OLDER_THAN_18_DOCUMENTATION_REQ'
  | '620_GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ'
  | '621_REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ'
  | '622_STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT'
  | '626_ATSTD_VETERAN'
  | '628_NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB'
  | '630_FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE'
  | '645_APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS'
  | '646_PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS'
  | '648_MDCAID_ELGBL_UNDER_FOSTER_CARE'
  | '659_DID_NOT_AGREE_TO_ALL_MDCAID_LEGAL_ATTESTATIONS'
  | '660_PARENT_DID_NOT_AGREE_TO_ABSENT_PARENT_ATTESTATION'
  | '999_N_A_RULE_INDICATOR_IS_Y';

export type StateDeterminationModelType = 'assessment' | 'determination';

/* eslint-disable max-len */
export const MEDICAID_AND_CHIP_PENDING_STATUS_REASON_CODES: {
  [key: string]: PendingMedicaidOrChipStatusType,
} = {
  APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS:
    '645_APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS',
  ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS: '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS',
  ATSTD_VETERAN: '626_ATSTD_VETERAN',
  DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF:
    '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF',
  EDS_NOT_AVAILABLE: '191_EDS_NOT_AVAILABLE',
  FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE:
    '630_FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE',
  GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ: '620_GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ',
  HUB_CALL_RETURNED_TRANSACTIONAL_ERROR: '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR',
  HUB_INDICATES_NOT_LPV_OR_QNC: '605_HUB_INDICATES_NOT_LPV_OR_QNC',
  HUB_QNC_INDICATOR_IS_PENDING: '609_HUB_QNC_INDICATOR_IS_PENDING',
  NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB:
    '628_NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB',
  NO_EDS_DATA_FOUND: '227_NO_EDS_DATA_FOUND',
  NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS:
    '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS',
  OLDER_THAN_18_DOCUMENTATION_REQ: '619_OLDER_THAN_18_DOCUMENTATION_REQ',
  PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS:
    '646_PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS',
  REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ:
    '621_REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ',
  RESIDENCY_INCOME_PENDING: '302_RESIDENCY_INCOME_PENDING',
  SAVE_VERIFICATION_PENDING: '150_SAVE_VERIFICATION_PENDING',
  STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT:
    '622_STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT',
  VOT_STATUS_REQUIRES_DOCUMENTATION: '618_VOT_STATUS_REQUIRES_DOCUMENTATION',
};

export const MEDICAID_AND_CHIP_PENDING_STATUS_REASONS: Array<PendingMedicaidOrChipStatusType> = Object.keys(
  MEDICAID_AND_CHIP_PENDING_STATUS_REASON_CODES,
)
  .sort()
  .map(k => MEDICAID_AND_CHIP_PENDING_STATUS_REASON_CODES[k]);

export const MEDICAID_STATUS_REASONS: {
  [key: string]: MedicaidStatusReason,
} = {
  NOT_ALL_MDCAID_ELGBLT_CRITERIA_MET: '106_NOT_ALL_MDCAID_ELGBLT_CRITERIA_MET',
  DEPENDENT_CHILD_DOESNT_HAVE_MEC: '128_DEPENDENT_CHILD_DOESNT_HAVE_MEC',
  SAVE_VERIFICATION_PENDING: '150_SAVE_VERIFICATION_PENDING',
  CITIZENSHIP_NOT_VERIFIED: '177_CITIZENSHIP_NOT_VERIFIED',
  EDS_NOT_AVAILABLE: '191_EDS_NOT_AVAILABLE',
  NO_EDS_DATA_FOUND: '227_NO_EDS_DATA_FOUND',
  SSN_NOT_VERIFIED: '288_SSN_NOT_VERIFIED',
  RESIDENCY_INCOME_PENDING: '302_RESIDENCY_INCOME_PENDING',
  CITIZENSHIP_OR_INCOME_STATUS_INCONSISTENT: '305_CITIZENSHIP_OR_INCOME_STATUS_INCONSISTENT',
  HUB_DID_NOT_RESPOND: '341_HUB_DID_NOT_RESPOND',
  DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION:
    '397_DID_NOT_PROVIDE_SSN_WITH_CITIZENSHIP_ATTESTATION',
  ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS: '398_ATSTD_TO_SAVE_NON_VERIFIABLE_STATUS',
  NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS:
    '399_NOT_ENOUGH_INFO_PROVIDED_TO_VERIFY_ELGBL_LP_STATUS',
  VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN:
    '400_VERIFIED_STATUS_NOT_CITIZEN_OR_LAWFULLY_PRESENT_OR_QUALIFIED_NON_CITIZEN',
  DETERMINED_INELGBL_BY_STATE: '449_DETERMINED_INELGBL_BY_STATE',
  DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF:
    '456_DATA_SOURCE_NOT_CALLED_DUE_TO_FAILED_ID_PROOF',
  NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP:
    '464_NOT_ENOUGH_INFO_TO_VERIFY_NATURALIZED_CITIZENSHIP',
  HUB_CALL_RETURNED_TRANSACTIONAL_ERROR: '465_HUB_CALL_RETURNED_TRANSACTIONAL_ERROR',
  CHANGED_INCOME_ATTESTATION_ABOVE_MDCAID_CHIP_LEVEL:
    '467_CHANGED_INCOME_ATTESTATION_ABOVE_MDCAID_CHIP_LEVEL',
  RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE:
    '470_RECENTLY_DENIED_MDCAID_CHIP_PREVENT_RETURN_TO_STATE',
  FIVE_YEAR_BAR_IS_PENDING: '473_FIVE_YEAR_BAR_IS_PENDING',
  INDIVIDUAL_NOT_REQUESTING_COVERAGE: '560_INDIVIDUAL_NOT_REQUESTING_COVERAGE',
  HUB_INDICATES_NOT_LPV_OR_QNC: '605_HUB_INDICATES_NOT_LPV_OR_QNC',
  HUB_QNC_INDICATOR_IS_PENDING: '609_HUB_QNC_INDICATOR_IS_PENDING',
  VOT_STATUS_REQUIRES_DOCUMENTATION: '618_VOT_STATUS_REQUIRES_DOCUMENTATION',
  OLDER_THAN_18_DOCUMENTATION_REQ: '619_OLDER_THAN_18_DOCUMENTATION_REQ',
  GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ: '620_GRANT_DATE_NOT_AVAILABLE_DOCUMENTATION_REQ',
  REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ:
    '621_REQ_YEARS_WITH_STATUS_NOT_MET_DOCUMENTATION_REQ',
  STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT:
    '622_STATUS_START_DATE_NOT_AVAILABLE_TO_CALC_7_YR_LIMIT',
  ATSTD_VETERAN: '626_ATSTD_VETERAN',
  NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB:
    '628_NO_ATSTD_GRANT_DATE_AND_NOT_AVAILABLE_FROM_HUB',
  FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE:
    '630_FIVE_YEAR_BAR_INCONSISTENT_WITH_ATSTD_GRANT_DATE',
  APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS:
    '645_APPLICANT_SPOUSE_PARENTS_PRESENT_MISSING_WORK_QUARTERS',
  PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS:
    '646_PARENT_OR_SPOUSE_NOT_ON_APPLICATION_FOR_WORK_QUARTERS',
  MDCAID_ELGBL_UNDER_FOSTER_CARE: '648_MDCAID_ELGBL_UNDER_FOSTER_CARE',
  DID_NOT_AGREE_TO_ALL_MDCAID_LEGAL_ATTESTATIONS:
    '659_DID_NOT_AGREE_TO_ALL_MDCAID_LEGAL_ATTESTATIONS',
  PARENT_DID_NOT_AGREE_TO_ABSENT_PARENT_ATTESTATION:
    '660_PARENT_DID_NOT_AGREE_TO_ABSENT_PARENT_ATTESTATION',
  N_A_RULE_INDICATOR_IS_Y: '999_N_A_RULE_INDICATOR_IS_Y',
};

export const ASSESSMENT_STATE = 'assessment';
export const DETERMINATION_STATE = 'determination';

export const ASYNC_INCOME_STATUS_REASON = '753_PENDING_VERIFICATION_AND_ASYNC_SUBMISSION';

/*
  Starting 5/31/23 - 7/31/2024, LOSS_OF_MEC can be reported for any date
  between 3/31/23 and the current date instead of the normal 60 day window
*/
// The date after which one must have lost Medicaid to qualify for the Medicaid Unwinding extension
export const MEDICAID_UNWINDING_EXTENSION_LOSS_DATE = parse('2023-03-31');
// The dates between which the normal 60 day window does not apply
export const MEDICAID_UNWINDING_EXTENSION_BEGIN_DATE = parse('2023-05-31');
export const MEDICAID_UNWINDING_EXTENSION_END_DATE = parse('2024-07-31');
