import {
  REQUEST_TRANSLATIONS,
  REQUEST_TRANSLATIONS_FAILED,
  RECEIVE_TRANSLATIONS
} from '../constants/translationTypes';
import _ from 'lodash';

const initialState = {
  fetching: [],
};

export default function(state = initialState, action) {
  switch(action.type){
    case REQUEST_TRANSLATIONS:
      return !_.includes(state.fetching, action.key) ?
        { ...state, ...{ fetching: [...state.fetching, action.key] } } : state;
    case RECEIVE_TRANSLATIONS:
      const updatedFetching = _.filter(state.fetching, key => key !== action.key);
      return {
        ...state,
        ...{ fetching: updatedFetching },
        ...{ [action.key]: action.data },
      };
    default:
      return state;
  }
}