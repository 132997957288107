import CORE_PRODUCT_TYPES from 'client/constants/productTypes';

import type { AppType } from 'web-core/types/ifp-app';

export default class ROUTES {
  static CART_ROUTE = '/cart';
  static CHECKOUT_ROUTE = '/checkout';
  static PHASE2_DASHBOARD_ROUTE = '/my/insurance';
  static PHASE3_DASHBOARD_ROUTE = '/my/insurance';
  static DENTAL_ONBOARDING_ROUTE = '/dental/location';
  static DENTAL_APPLICANTS_ROUTE = '/dental/family';
  static DENTAL_PLANS_ROUTE = '/dental/plans';
  static EDE_ELIGIBILITY_RESULTS = '/marketplace/eligibility/results';
  static EDE_BASE_ROUTE = '/marketplace-ede';
  static NEW_EDE_APP_BASE_ROUTE = '/ede';
  static EDE_PLAN = '/marketplace/plan';
  static FFM_REDIRECT_ROUTE = '/shop-ffm';
  static GA_ACCESS_BASE_ROUTE = '/marketplace-ga';
  static HEALTH_PROFILE_LOCATION_PATH = '/health/profile/location';
  static HEALTH_PROFILE_APPLICANTS_PATH = '/health/profile/applicants';
  static HEALTH_PROFILE_RECOMMENDATIONS_ROUTE = '/health/profile/plans/recommendation';
  static OB_LOCATION_PATH = '/onboarding/location';
  static STATE_EXCHANGE_BUY_ROUTE = '/buy/apply';
  static VISION_PURCHASE_ROUTE = '/purchase';
  static VISION_COMPLETE = '/purchase/complete';
  static VISION_ESIGN = '/purchase/esign';
  static VISION_ONBOARDING_ROUTE = '/vision/onboarding/location';
  static VISION_APPLICANTS_ROUTE = '/vision/onboarding/applicants';
  static VISION_PLANS_ROUTE = '/vision/onboarding/plans';
}

export class CROSS_SELL_ROUTE_MAP {
  static [CORE_PRODUCT_TYPES.HEALTH]: AppType = ROUTES.HEALTH_PROFILE_APPLICANTS_PATH;
  static [CORE_PRODUCT_TYPES.DENTAL]: AppType = ROUTES.DENTAL_APPLICANTS_ROUTE;
  static [CORE_PRODUCT_TYPES.VISION]: AppType = ROUTES.VISION_APPLICANTS_ROUTE;
}
