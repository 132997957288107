import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import cx from 'classnames';
import _ from 'lodash';

import StrideHeader from 'client/components/strideHeader';
import Nav from 'client/components/nav/nav';

import 'client/styles/tabs.scss';
import 'client/styles/strideHeaderWrapper.scss';

const mapStateToProps = (state: any) => {
  let partnerConfigDisplayPhoneNumber = false;

  const partnerConfig = _.get(state, 'siteConfigs.brandSiteConfig.partnerConfig', {});
  const phoneNumber: string = _.get(partnerConfig, 'agencyInfo.phoneNumber', '');
  const mxPath = _.get(partnerConfig, 'mXPhoneNumberEnablePaths');
  if (mxPath && typeof window !== 'undefined') {
    partnerConfigDisplayPhoneNumber = mxPath.reduce(
      (prev: boolean, cur: string) => prev || window.location.href.includes(cur),
      false,
    );
  }

  return {
    brandName: state.brand as string,
    phoneNumber,
    partnerConfigDisplayPhoneNumber,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  activeLink?: string;
  brandName: string;
  children: React.ReactNode;
  className?: string;
  isHealthLogin?: boolean;
  logoHref?: string;
  navItems?: string[];
  noShadow?: boolean;
  showLoginIcon?: boolean;
  showPhone?: boolean;
  showMobileBackBtn?: boolean;
};

const defaultProps: Partial<Props> = {
  activeLink: undefined,
  className: '',
  isHealthLogin: false,
  logoHref: '/',
  navItems: [],
  noShadow: false,
  showLoginIcon: false,
  showPhone: true,
  showMobileBackBtn: false,
};

const StrideHeaderWrapper = (props: Props) => {
  const {
    activeLink,
    brandName,
    children,
    className,
    isHealthLogin,
    logoHref,
    navItems,
    noShadow,
    showLoginIcon,
    showPhone,
    showMobileBackBtn,
    phoneNumber,
    partnerConfigDisplayPhoneNumber,
  } = props;

  const shouldShowPhone = showPhone || partnerConfigDisplayPhoneNumber;
  const navItemsToShow = shouldShowPhone ? ['phone', ...(navItems || [])] : navItems;

  const nav = !_.isEmpty(navItemsToShow) ? (
    <Nav
      className={shouldShowPhone ? 'home-header-nav' : 'home-header-nav pad-right'}
      byName={navItemsToShow}
      phoneNumber={phoneNumber}
      activeLink={activeLink}
      showLoginIcon={showLoginIcon}
      isHealthLogin={isHealthLogin} />
  ) : null;

  return (
    <div className={className}>
      <StrideHeader
        brandName={brandName}
        logoHref={logoHref}
        showMobileBackBtn={showMobileBackBtn}
        className={cx({ 'no-shadow': noShadow })}
        rightNav={nav} />
      <div className="header-padding">{children}</div>
    </div>
  );
};

StrideHeaderWrapper.defaultProps = defaultProps;

export default connector(StrideHeaderWrapper);
