import _ from 'lodash';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import createLogger from 'redux-logger';
import axios from 'axios';
import requestAsync from '../universal/actions/asyncRequests';
import asyncRequests from '../universal/reducers/asyncRequests';
import * as sharedAppConfig from '../universal/reducers/sharedAppConfig';
import { createCacheAdapter } from '../universal/utils/cache';

function createThunkMiddleware(collect, options) {
  return ({ dispatch, getState }) =>
    next =>
      (action) => {
        if (typeof action === 'function') {
          const maybePromise = action(dispatch, getState, options);
          if (
            collect &&
          typeof maybePromise === 'object' &&
          typeof maybePromise.then === 'function'
          ) {
            dispatch(requestAsync(maybePromise));
          }
          return maybePromise;
        }
        return next(action);
      };
}

export default function createReduxStore(rootReducer, options = {}) {
  const {
    withPromiseMiddleWare,
    initialData,
    requestCookies,
    requestMap,
    getRedisResult,
    middlewares = [],
  } = options;

  const apiClient = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  });

  apiClient.interceptors.request.use(
    (config) => {
      const headers = _.merge({}, config.headers);
      if (requestCookies) {
        _.merge(headers, {
          cookie: requestCookies,
        });
      }

      const newConfig = {
        ...config,
        headers,
      };

      if (typeof requestMap === 'object') {
        const serviceType = config.url.split('/').filter(p => p)[0];
        const base = requestMap[serviceType];
        if (base) {
          let { url } = config;
          if (_.isString(base.baseURL)) {
            url = base.baseURL + url;
          } else if (_.isFunction(base.baseURL)) {
            url = base.baseURL(url);
          }

          newConfig.url = url;
          _.merge(newConfig.headers, base.headers || {});
        }
      }

      return newConfig;
    },
    error => Promise.reject(error),
  );

  const combinedReducers = {
    ...sharedAppConfig,
    ...rootReducer,
  };

  const thunkOptions = { apiClient };
  const finalMiddleware =
    options.client && __IS_LOCAL__
      ? applyMiddleware(
        createThunkMiddleware(withPromiseMiddleWare, thunkOptions),
        createLogger(),
        ...middlewares,
      )
      : applyMiddleware(createThunkMiddleware(withPromiseMiddleWare, thunkOptions), ...middlewares);
  const createStoreWithMiddleware = finalMiddleware(createStore);
  const store = withPromiseMiddleWare
    ? createStoreWithMiddleware(
      combineReducers({ ...combinedReducers, asyncRequests }),
      initialData,
    )
    : createStoreWithMiddleware(combineReducers(combinedReducers), initialData);

  apiClient.defaults.adapter = createCacheAdapter(store, getRedisResult);

  return store;
}
